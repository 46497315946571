import React, { Fragment, useMemo } from "react";
import { Dropdown, Icon } from "antd";
import SettingMenu from "./menu";
import { sortBy } from "lodash";

const SettingDropdownOption = ({
  handleSignOut,
  dropdownIconType,
  className,
  handleProfileAction,
  routeOptions,
  loading,
  isCurrentUserLawFirm,
  user
}) => {
  const routesOptions = useMemo(() => { 
    return sortBy(routeOptions, (a) => a.id == 9 || a.name);
  }, [routeOptions]);

  return (
    <Dropdown
      trigger={["click"]}
      overlay={
        <Fragment>
          <SettingMenu
            handleSignOut={handleSignOut}
            handleProfileAction={handleProfileAction}
            routeOptions={routesOptions}
            loading={loading}
            isCurrentUserLawFirm={isCurrentUserLawFirm}
            user={user}
          />
        </Fragment>
      }
      getPopupContainer={(node) =>{
        return document.getElementById("customPopupContainer");
      }
    }
      className={`dropdown ${className ? className : ""} settings-dropdown-icon`}
    >
      <Icon type={dropdownIconType || "menu"} className="f-s-16"/>
    </Dropdown>
  );
};

export default SettingDropdownOption;
