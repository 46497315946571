import React, { useEffect, useState, useCallback } from "react";
import { Form, Radio, Input, Row, Col, Button, message, Select } from "antd";
import { connect, useDispatch } from "react-redux";
import { updateHeader } from "../../actions/member";
import { createNewCompany } from "../../actions/company";
import { statesLookup, getOmicronBdmLookup } from "../../actions/lookup";
import axios from "axios";
import { debounce } from "lodash";
import RequiredSign from "../../Common/RequiredSign";
import history from "../../routes/history";
import { errorDisplay, mustBeArray } from "../../libs/utilities";
import {
  DecoratedFormControl,
  FormDecoratorProvider
} from "../../components/Common/decoratedFormControl";
import { Visible } from "../../components/Common/visiblityWrapper";
import authService from "../../actions/AuthorizeService";

const FormItem = Form.Item;

const CreateCompany = props => {
  const { statesLookup, getOmicronBdmLookup } = props;
  const signal = axios.CancelToken.source();
  const [saveLoading, setSaveLoading] = useState(false);
  const [statesList, setStatesList] = useState([]);
  const [omicronBdmList, setOmicronBdmList] = useState([]);
  const dispatchAction = useDispatch();
  const [isOmicronBdmRequired, setIsOmicronBdmRequired] = useState(true);

  useEffect(() => {
    props.updateHeader({
      header: {
        title: "Add New Company",
        action: "",
        page: "companies",
        enableBack: false,
        showSettings: false,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    getStatesList();

    getOmicronBdmList("");
    getUserDetails();

  }, []);

  const getUserDetails = async () => {
    let userDetails = await authService.getUser();
    let systemUserId = userDetails["app.user.systemuserid"];
    const { form } = props;
    form.setFieldsValue({"bdmId": Number(systemUserId)});
  }

  const checkSubmitDisabled = () => {
    const { form } = props;
    if (
      form &&
      form.getFieldValue("profileTypeKey") &&
      form.getFieldValue("firstName") &&
      form.getFieldValue("lastName") &&
      form.getFieldValue("mobileNumber") &&
      form.getFieldValue("email") &&
      form.getFieldValue("stateKey") &&
      form.getFieldValue("companyName") &&
      form.getFieldValue("companyACN")
    ) {
      return false;
    }
    return true;
  };

  const getStatesList = async () => {
    let response = await statesLookup();
    if (response && response.data) {
      setStatesList(mustBeArray(response.data.data));
    }
  };

  const getOmicronBdmList = async (search) => {
    let response = await getOmicronBdmLookup({ search: search });
    if (response && response.data) {
      setOmicronBdmList(mustBeArray(response.data));
    }
  };

  const handleProfileTypeChange = ({ target: { value } }) => {
    setIsOmicronBdmRequired(value !== "LAWFIRM");
  }


  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        setSaveLoading(true);
        values.bdmId = values.profileTypeKey === "LAWFIRM" ? null : values.bdmId;
        const resp = await props.createNewCompany({
          payLoad: { ...values },
          cancelToken: signal.token
        });

        if (resp.status) {
          message.success("Company created successfully");
          if (values.profileTypeKey === "LENDER" || values.profileTypeKey === "DUAL") {
            history.push(`/users/${resp.data.lenderId}/lender`);
          } else if (values.profileTypeKey === "BROKER") {
            history.push(`/users/${resp.data.brokerId}/broker`);
          }
          else if(values.profileTypeKey === "LAWFIRM"){
            history.push(`/users/${resp.data.lawFirmId}/lawfirm`);
          }
        } else {
          errorDisplay(resp?.data?.errors);
        }
      } finally {
        setSaveLoading(false);
      }
    });
  };
  
  const debouncedSearch = useCallback(debounce(getOmicronBdmList, 300));

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const { form } = props;
  const { getFieldDecorator } = form;
  const itemOneSpan = 14;
  const itemTwoSpan = 2;
  const colOffset = 2;
  const gutterSpan = 4;

  
  return (
    <>
    <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 m-b-15 flex-center">
        Add New Company
      </div>
    <div className="flex flex-center label-right">
      <Form
        style={{ minWidth: "800px" }}
        colon={false}
        hideRequiredMark={true}
        onSubmit={handleSubmit}
      >
        <FormDecoratorProvider decorator={getFieldDecorator}>
          <div className="m-b-16">
            <DecoratedFormControl
              {...formLayout}
              label=""
              name="profileTypeKey"
              className="flex flex-center"
              rules={[
                {
                  required: true,
                  message: "Please select profile type"
                }
              ]}
            >
              <Radio.Group onChange={handleProfileTypeChange}>
                <Radio value={"BROKER"} className="m-l-36">
                  Broker
                </Radio>
                <Radio value={"LENDER"} className="m-l-36">
                  Lender
                </Radio>
                <Radio value={"DUAL"} className="m-l-36">
                  Dual
                </Radio>
                <Radio value={"LAWFIRM"} className="m-l-36">
                  Law Firm
                </Radio>
              </Radio.Group>
            </DecoratedFormControl>
          </div>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="Company"
                name="companyName"
                rules={[
                  {
                    required: true
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span="1">
              <RequiredSign />
            </Col>
            <Col span="7">
              <Row gutter={gutterSpan}>
                <Col span="22">
                  <DecoratedFormControl
                    {...{
                      labelCol: { span: 6 },
                      wrapperCol: { span: 18 }
                    }}
                    label="A.C.N"
                    name="companyACN"
                    rules={[
                      {
                        required: true,
                        message: "Please enter company's ACN"
                      }
                    ]}
                  >
                    <Input placeholder="Enter" maxLength={9} />
                  </DecoratedFormControl>
                </Col>
                <Col span="1">
                  <RequiredSign />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={gutterSpan} hidden={!isOmicronBdmRequired}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="BDM"
                name="bdmId"
                rules={[
                  {
                    required: {isOmicronBdmRequired},
                    message: "Please select BDM"
                  }
                ]}
              >
                <Select
                  allowClear={false}
                  onSearch={debouncedSearch}
                  showSearch
                  filterOption={false}
                  placeholder="Select"
                >
                  {omicronBdmList.map(data => {
                  return (
                    <Select.Option key={data.id} value={data.id} selected="">
                      {data.name}
                    </Select.Option>
                  );
                })}
                </Select>
              </DecoratedFormControl>
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="Mobile"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile number"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={colOffset}>
              <DecoratedFormControl
                {...formLayout}
                label="State"
                name="stateKey"
                rules={[
                  {
                    required: true,
                    message: "Please select state"
                  }
                ]}
              >
                <Select
                  filterOption={true}
                  showSearch={true}
                  optionFilterProp={"searchby"}
                  autoComplete="arbitrary-text"
                  placeholder="Select"
                >
                  {mustBeArray(statesList).map((s, key) => (
                    <Select.Option key={key} value={s.id} searchby={s.name}>
                      {s.id}
                    </Select.Option>
                  ))}
                </Select>
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span="24">
              <FormItem
                {...{
                  labelCol: { span: 12 },
                  wrapperCol: { span: 12 }
                }}
                label={
                  <Button
                    icon="file"
                    type="primary"
                    loading={saveLoading}
                    className={`f-w-500 ${
                      checkSubmitDisabled()
                        ? "bg-light-primary color-white"
                        : ""
                    }`}
                    htmlType="submit"
                    disabled={checkSubmitDisabled()}
                  >
                    Save
                  </Button>
                }
              >
                {getFieldDecorator("cancel", {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <Button
                    className="f-w-500"
                    htmlType="button"
                    disabled={saveLoading}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                )}
              </FormItem>
            </Col>
          </Row>
        </FormDecoratorProvider>
      </Form>
    </div>
    </>
    
  );
};

const mapDispatchToProps = {
  updateHeader,
  createNewCompany,
  statesLookup,
  getOmicronBdmLookup
};

export default connect(
  null,
  mapDispatchToProps
)(Form.create("createCompany")(CreateCompany));
