import { mustBeArray } from "./utilities";

export const getItemName = ({value, list, field="id", outputField="name" }) => {
    const name = mustBeArray(list).find(o => o[field] === value)?.[outputField];
    return name ?? null; 
  }

export const getSettlementItemName = ({value, list, field="id", outputField="name" }) => {
  const name = mustBeArray(list).find(o => o[field] === value)?.[outputField];
  return name.replace("ago", "").trim() ?? null; 
}

 export const convertCurrenyToHighestFigure = (value) => {
  const thousand = 1000;
  const million = 1000000;
  const billion = 1000000000;
  if(value >= billion){
    const amount = (value/billion).toFixed(2);
    return `$${amount}B`
  }
  if(value >= million){
    const amount = (value/million).toFixed(2);
    return `$${amount}M`
  }
  if(value){
    const amount = (value/thousand).toFixed(2);
    return `$${amount}K`
  }
  return `$${value}`;
 }
