import React, { Fragment } from "react";
import { useProfile } from "./useProfile";
import { Form, Spin } from "antd";
import { ADMIN_USER, LAW_FIRM_USER } from "../../constants/systemUsers";
import { FormDecoratorProvider } from "../../components/Common/decoratedFormControl";
import PortraitView from "../../components/Profile/protrait-view";
import { Visible } from "../../components/Common/visiblityWrapper";
import ProfileUtility from "../../components/Profile/Utility/utility";
import ConfirmationModal from "../../Common/ConfirmationModal";
import LawFirmProfileTabs from "../../components/Profile/LawFirm/lawFirmProfileTabs";

const LawFirmProfile = ({ match, form }) => {
  const {
    loading,
    userProfile,
    current,
    setCurrent,
    isSystemUser,
    setCurrentPortrait,
    portraitsUpdated,
    member,
    saveLoading,
    confirmationModalVisible,
    confirmationText,
    handleFormChange,
    handleRemove,
    handleProfileActiveChange,
    handleMasterSwitchChange,
    handleModalClose,
    handleConfirm,
    canToggleProfileSwitch,
    handleSave,
    user,
    dispatchAction,
    activeTab,
    activeSubTab,
    setActiveTab,
    setActiveSubTab,
    loanSearchQueryParams,
    isOwnProfile
  } = useProfile({ userType: LAW_FIRM_USER, match, form });
  const { getFieldDecorator } = form;
  if (loading) return <Spin />;

  return (
    <Fragment>
      <Visible visible={!loading && userProfile !== {}}>
        <Form hideRequiredMark colon={false}>
          <FormDecoratorProvider decorator={getFieldDecorator}>
            <div className="flex align-center gap-16 m-t-20">
              <PortraitView
                portraits={userProfile?.portraits}
                form={form}
                handleFormChange={handleFormChange}
                currentPage={current}
                setCurrent={setCurrent}
                setCurrentPortrait={setCurrentPortrait}
                isSystemUser={isSystemUser}
                company={{
                  companyACN: userProfile?.companyACN,
                  companyName: userProfile?.companyName,
                  website: userProfile?.website
                }}
                portraitsUpdated={portraitsUpdated}
                handleRemove={handleRemove}
              />
              <ProfileUtility
                user={userProfile}
                userType={LAW_FIRM_USER}
                showIsProfileActive={member?.header.showIsProfileActive}
                canToggleProfileSwitch={canToggleProfileSwitch}
                handleProfileActiveChange={handleProfileActiveChange}
                showMasterSwitch={member?.header.showMasterSwitch}
                handleMasterSwitchChange={handleMasterSwitchChange}
                form={form}
                handleFormChange={handleFormChange}
                handleSave={handleSave}
                saveLoading={saveLoading}
                isSystemUser={isSystemUser}
              />
            </div>
            <LawFirmProfileTabs 
             activeTab={activeTab} 
             onTabChange={value => setActiveTab(value)}
             match={match}
             userType={isSystemUser ? ADMIN_USER : LAW_FIRM_USER }
             id={userProfile?.id}
             userHasPermissionToUpdate
             user={user}
             dispatchAction={dispatchAction}
             activeSubTab={activeSubTab}
             handleSubTabChange={value => setActiveSubTab(value) }
             loanSearchQueryParams={loanSearchQueryParams}
             isOwnProfile={isOwnProfile}
             isSytemUser={isSystemUser}
            />
          </FormDecoratorProvider>
        </Form>
      </Visible>
      <ConfirmationModal
        confirmationText={confirmationText}
        visible={confirmationModalVisible}
        handleModalClose={handleModalClose}
        handleConfirm={handleConfirm}
        handleProfileActiveChange={handleProfileActiveChange}
      />
    </Fragment>
  );
};

export default Form.create({ name: "LawFormProfile" })(LawFirmProfile);
