import member from "./member";
import callback from "./callback";
import ui from "./ui";
import navbar from "./navbar";
import authorization from "./authorization";
import dashboard from "./dashboard";
import profile from "./profile";
import triggers from "./triggers";
import users from "./users";
import notes from "./notes";

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      return true;
    default:
      return state;
  }
};
export default {
  rehydrated,
  member,
  callback,
  ui,
  navbar,
  authorization,
  dashboard,
  triggers,
  profile,
  users,
  notes
};
