import { Card, Form, Radio } from "antd";
import React from "react";

const BrokerSubscriptions = ({
  getFieldDecorator,
  canEdit
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative">
        <div className="title-center card-title">What you will receive as a Broker</div>
      <Form.Item label="1st Ranking">
        {getFieldDecorator("brokerFirstRanking")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="2nd Ranking">
        {getFieldDecorator("brokerSecondRanking")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Construction">
        {getFieldDecorator("brokerConstruction")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Mezzanine">
        {getFieldDecorator("brokerMezzanine")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Land Bank">
        {getFieldDecorator("brokerLandBank")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Rural">
        {getFieldDecorator("brokerRural")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Card>
  );
};
export default BrokerSubscriptions;
