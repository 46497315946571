const initialState = {
  actions: {
      list: [],
      total: 0
  },
  claims: {}
};
  
  export default function dashboardReducer(state = initialState, action){
    const {type, data, total} = action;
    switch (type) {
      case 'LIST_DASHBOARD_ACTIONS': {
        return {
         ...state,
        actions: {
            list: data,
            total
        }
        };
      }
      case 'GET_DASHBOARD_CLAIMS_DETAIL': {
        return{
          ...state,
          claims: data
        }
      }
      default:
        return state;
    }
  }
  