import { Button, Modal } from "antd";
import React from "react";

type StatusErrorModalProps = {
  statusErrorModal: {
    visible: boolean;
    message: string;
  };
  closeStatusErrorModal: () => void;
};

export default function StatusErrorModal({
  statusErrorModal,
  closeStatusErrorModal
}: StatusErrorModalProps) {
  return (
    <Modal
      visible={statusErrorModal.visible}
      title="Sorry"
      onCancel={closeStatusErrorModal}
      footer={[
        <Button key="b" onClick={closeStatusErrorModal}>
          Cancel
        </Button>
      ]}
    >
      {statusErrorModal.message}
    </Modal>
  );
}
