import React, { useEffect, useState, Fragment } from "react";
import {
  Table,
  Input,
  message,
  Popconfirm,
  Button,
  Spin,
  Select,
  Pagination
} from "antd";
import _ from "lodash";
import { tableColumnGenerator } from "../../libs/table";
import {
  asDefaultDisplayFormatDate,
  guid,
  mustBeArray,
  errorDisplay
} from "../../libs/utilities";
import axios from "axios";
import {
  getLenderFileNotes,
  updateLenderFileNotes,
  deleteLenderFileNotes,
  createLenderFileNotes,
  getBrokerFileNotes,
  updateBrokerFileNotes,
  deleteBrokerFileNotes,
  createBrokerFileNotes,
  getLawFirmFileNotes,
  updateLawFirmFileNotes,
  createLawFirmFileNotes,
  deleteLawFirmFileNotes
} from "../../actions/profile";
import { connect } from "react-redux";
import { ADMIN_USER, LAW_FIRM_USER } from "../../constants/systemUsers";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import AddIcon from "../../assets/icons/AddIcon";
import moment from "moment";
import { notesDropdownLookUpHandler } from "../../actions/lookup";
import { Visible } from "../../components/Common/visiblityWrapper";

const FilesList = props => {
  const {
    match,
    activeKey,
    userId,
    getLenderFileNotes,
    updateLenderFileNotes,
    deleteLenderFileNotes,
    createLenderFileNotes,
    getBrokerFileNotes,
    updateBrokerFileNotes,
    deleteBrokerFileNotes,
    createBrokerFileNotes,
    notesDropdownLookUpHandler,
    profileType,
    userType,
    getLawFirmFileNotes,
    updateLawFirmFileNotes,
    createLawFirmFileNotes,
    deleteLawFirmFileNotes
  } = props;
  const signal = axios.CancelToken.source();
  const [filesList, setFilesList] = useState([]);
  const [loading, setListLoading] = useState(true);
  const [editedList, setEditedList] = useState([]);
  const [sortInfo, setSortInfo] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [noteTypesList, setNoteTypesList] = useState([]);

  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const pageSizeSelection = [
    {
      name: "20",
      value: "20"
    },
    {
      name: "50",
      value: "50"
    },
    {
      name: "100",
      value: "100"
    },
    {
      name: "200",
      value: "200"
    }
  ];

  const getActionName = 
    profileType === "lender" ? getLenderFileNotes : profileType === LAW_FIRM_USER ? getLawFirmFileNotes : getBrokerFileNotes;
  const updateActionName =
    profileType === "lender" ? updateLenderFileNotes : profileType === LAW_FIRM_USER ? updateLawFirmFileNotes :  updateBrokerFileNotes;
  const createActionName =
    profileType === "lender" ? createLenderFileNotes : profileType === LAW_FIRM_USER ? createLawFirmFileNotes :  createBrokerFileNotes;
  const deleteActionName =
    profileType === "lender" ? deleteLenderFileNotes : profileType === LAW_FIRM_USER ? deleteLawFirmFileNotes : deleteBrokerFileNotes;

  useEffect(() => {
    if (Number(activeKey) === 2 || activeKey === "fileNotes") {
      listFileNotes({ actionedRecordId: null });
      getNoteTypes();
    }
    const cleanUp = () => {
      signal.cancel("");
    };
    return cleanUp;
  }, [activeKey, sortInfo, pageSize, page]);

  useEffect(() => {
    listFileNotes({ actionedRecordId: null });
    const cleanUp = () => {
      signal.cancel("");
    };
    return cleanUp;
  }, [window.location.href]);

  const getNoteTypes = () => {
    setListLoading(true);
    return notesDropdownLookUpHandler({}).then(resp => {
      if (resp.status) {
        setNoteTypesList(mustBeArray(resp.data.data));
        setListLoading(false);
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    });
  };

  const listFileNotes = async ({
    actionedRecordId = null,
    action = "update"
  }) => {
    await getActionName({
      id: userId,
      cancelToken: signal.token,
      queryParams: getQueryParams(),
      userType
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        if (!actionedRecordId) {
          setFilesList(x => {
            setTotalPages(resp.total);
            return mustBeArray(resp.data);
          });
        } else {
          let updatedRecord =
            action === "delete"
              ? null
              : _.find(mustBeArray(resp.data), o => o.id === actionedRecordId);
          let newList = !updatedRecord
            ? _.filter(filesList, o => o.id !== actionedRecordId)
            : filesList.map(file => {
                return file.id === actionedRecordId ? updatedRecord : file;
              });

          setFilesList(x => {
            setTotalPages(resp.total);
            return newList;
          });
        }
      }
    });
  };

  const handleEdit = ({ record, field, value }) => {
    let validationId = record && record.id;

    if (!editedList.includes(validationId)) {
      setEditedList([...editedList, validationId]);
    }
    let newUpdatedList = [...filesList].map(data => {
      return data.id === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });
    setFilesList(newUpdatedList);
  };

  const updateRecord = ({ record, field, value }) => {
    let validationId = record && record.id;

    if (field === "noteType") {
      value = noteTypesList.find(x => x.key === value);
    }

    let newUpdatedList = filesList.map(data => {
      return data.id === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });
    setFilesList(newUpdatedList);
  };

  const handleSave = ({ record }) => {
    const id = match && match.params && match.params.id;
    setListLoading(true);
    let payLoad = {
      note: record && record.note,
      noteType:
        record &&
        (record.noteType == null || _.isUndefined(record.noteType)
          ? ""
          : record.noteType.key)
    };
    switch(profileType) {
      case "lender":
        payLoad.lenderFileNoteId = record?.id;
        break;
      case LAW_FIRM_USER:
        payLoad.lawFirmFileNoteId = record?.id;
        break;
      default:
        payLoad.brokerFileNoteId = record?.id;
    }

    let actionName = updateActionName;
    if (record && record.newRecord) {
      actionName = createActionName;

      if (profileType === "lender") {
        delete payLoad.lenderFileNoteId;
      } else {
        delete payLoad.brokerFileNoteId;
      }
    }

    actionName({ payLoad, cancelToken: signal.token, id, userType }).then(
      resp => {
        setListLoading(false);
        if (resp.status) {
          message.success(
            `File notes ${
              record && record.newRecord ? "added" : "updated"
            } successfully.`
          );
          let updatedEditedList = _.filter(
            editedList,
            o => o !== (record && record.id)
          );
          setEditedList(updatedEditedList);
          listFileNotes({
            actionedRecordId: _.isInteger(record && record.id)
              ? record.id
              : null,
            action: "update"
          });
        }
      }
    );
  };

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field,
        pageSize: pageSize,
        page: page
      };
    }
    queryParams = {
      pageSize: pageSize,
      page: page
    };
    return queryParams;
  };

  const handleDelete = ({ record }) => {
    const id = match && match.params && match.params.id;
    setListLoading(true);
    let payLoad = {
      id: record && record.id
    };

    deleteActionName({ payLoad, cancelToken: signal.token, id, userType }).then(
      resp => {
        setListLoading(false);
        if (resp.status) {
          message.success(`File note deleted successfully.`);
          listFileNotes({
            actionedRecordId: record && record.id,
            action: "delete"
          });
        }
      }
    );
  };

  const addNewRecord = () => {
    let id = guid();
    let newFileNote = {
      date: moment().format(),
      note: "",
      newRecord: true,
      id
    };
    let updatedList = [newFileNote, ...filesList];
    setFilesList(updatedList);
    setEditedList([...editedList, id]);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortInfo({
      field: sorter.field,
      order: !sorter.order
        ? undefined
        : sorter.order === "ascend"
        ? "asc"
        : "desc"
    });
  };

  const handlePageSizeChange = pageSize => {
    setPageSize(pageSize);
  };

  const handlePageChange = page => {
    setPage(page);
  };
  let colTemplate =
    userType === ADMIN_USER
      ? [
          {
            title: "Date",
            dataIndex: "date",
            sorter: true,
            sortOrder:
              sortInfo && sortInfo.field === "date" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            dataType: "date"
          },
          {
            title: "Taken by",
            dataIndex: "takenBy",
            sorter: true,
            sortOrder:
              sortInfo && sortInfo.field === "takenBy" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false
          }
        ]
      : [
          {
            title: "Date",
            dataIndex: "date",
            sorter: true,
            sortOrder:
              sortInfo && sortInfo.field === "date" && sortInfo.order
                ? sortInfo.order === "asc"
                  ? "ascend"
                  : "descend"
                : false,
            render: record => {
              return asDefaultDisplayFormatDate(record);
            }
          }
        ];

  colTemplate = _.concat(colTemplate, [
    {
      title: "Notes",
      dataIndex: "note",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "note" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "70%",
      render: (data, record) => {
        return editedList.includes(record && record.id) ? (
          <Input.TextArea
            rows={2}
            value={data}
            onChange={e =>
              updateRecord({
                record,
                field: "note",
                value: e.currentTarget.value
              })
            }
          />
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: "Note Type",
      dataIndex: "noteType",
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "note" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "9%",
      render: (data, record) => {
        return editedList.includes(record && record.id) ? (
          <Select
            style={{ width: "100%" }}
            notFoundContent={
              <Fragment>
                <Visible visible={loading}>
                  <Spin size="small" />
                  ...
                </Visible>
                <Visible visible={!loading}>No data</Visible>
              </Fragment>
            }
            filterOption={false}
            onChange={e =>
              updateRecord({
                record,
                field: "noteType",
                value: e
              })
            }
            defaultValue={data === null || _.isUndefined(data) ? "" : data.key}
          >
            {mustBeArray(noteTypesList).map(noteType => {
              return (
                <Select.Option key={noteType.key} value={noteType.key}>
                  {noteType.name}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          <span>{data === null || _.isUndefined(data) ? "" : data.name}</span>
        );
      }
    }
  ]);

  const actions = [
    {
      title: (
        <span className="hand" onClick={addNewRecord}>
          <AddIcon />
        </span>
      ),
      dataIndex: "",
      sorter: false,
      render: record => {
        return editedList.includes(record && record.id) ? (
          <Button
            size="small"
            icon="file"
            type="primary"
            loading={saveLoading}
            onClick={() => handleSave({ record })}
          >
            Save
          </Button>
        ) : (
          <React.Fragment>
            <EditIcon onClick={() => handleEdit({ record })} />
            <Popconfirm
              title="Are you sure you want to delete this file note?"
              onConfirm={() => handleDelete({ record })}
            >
              <DeleteIcon className="m-l-6" />
            </Popconfirm>
          </React.Fragment>
        );
      }
    }
  ];

  const tableCoumn = _.concat(tableColumnGenerator(colTemplate), actions);

  return (
    <div style={{ width: "100%" }}>
      <Table
        columns={tableCoumn}
        dataSource={mustBeArray(filesList)}
        pagination={false}
        loading={loading}
        rowKey={(record, index) => index}
        onChange={handleTableChange}
        bordered
        className="file-list"
      />

      <div style={{ width: "100%", marginTop: "10px" }}>
        <Pagination
          defaultCurrent={1}
          onChange={page => handlePageChange(page)}
          total={totalPages}
          pageSize={pageSize}
        ></Pagination>

        <Select
          placeholder="20"
          onChange={value => handlePageSizeChange(value)}
          className="w-70 float-right"
          allowClear={false}
          style={{ marginTop: "-30px" }}
        >
          {mustBeArray(pageSizeSelection).map((option, index) => {
            return (
              <Select.Option key={index} value={option.value}>
                {option.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender,
    broker: state.profile.broker
  };
};

const mapDispatchToProps = {
  getLenderFileNotes,
  updateLenderFileNotes,
  deleteLenderFileNotes,
  createLenderFileNotes,
  getBrokerFileNotes,
  updateBrokerFileNotes,
  deleteBrokerFileNotes,
  createBrokerFileNotes,
  notesDropdownLookUpHandler,
  getLawFirmFileNotes,
  updateLawFirmFileNotes,
  createLawFirmFileNotes,
  deleteLawFirmFileNotes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilesList);
