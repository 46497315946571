import React, { Fragment, useMemo } from "react";
import { Dropdown, Icon, Menu } from "antd";
import { connect } from "react-redux";
import {
  BROKER_PROFILE_ACTIVE,
  LENDER_PROFILE_ACTIVE
} from "../constants/action-type/profile";
import { switchActiveProfile } from "../actions/profile";
import { Visible } from "./Common/visiblityWrapper";
import _ from "lodash";
const ProfileSwitch = ({
  activeProfile,
  dispatch,
  className,
  allowSwitchProfile = true
}) => {
  const showLender =
    !_.isNull(activeProfile.lenderId) &&
    !_.isUndefined(activeProfile.lenderId) &&
    activeProfile.active !== LENDER_PROFILE_ACTIVE;
  const showBroker =
    !_.isNull(activeProfile.brokerId) &&
    !_.isUndefined(activeProfile.brokerId) &&
    activeProfile.active !== BROKER_PROFILE_ACTIVE;

  const ProfileOption = (
    <Menu
      onClick={active => {
        dispatch(switchActiveProfile(active.key));
      }}
      selectedKeys={[activeProfile.active]}
    >
      {showBroker && (
        <Menu.Item key={BROKER_PROFILE_ACTIVE}>
          <h3>Broker Profile</h3>
        </Menu.Item>
      )}
      {showLender && (
        <Menu.Item key={LENDER_PROFILE_ACTIVE}>
          <h3>Lender Profile</h3>
        </Menu.Item>
      )}
    </Menu>
  );

  const activeProfileTitle = useMemo(() => {
    return activeProfile.active === LENDER_PROFILE_ACTIVE
      ? "Lender Profile"
      : "Broker Profile";
  }, [activeProfile.active]);

  return (
    <Fragment>
      <Visible visible={(showBroker || showLender) && allowSwitchProfile}>
        <Dropdown trigger={["click"]} overlay={ProfileOption}>
          <div
            className={`hand ${className || ""}`}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <h1 className="m-b-0">{activeProfileTitle}</h1>

            <Icon className="m-l-5 m-t-10" type="down" />
          </div>
        </Dropdown>
      </Visible>
      <Visible visible={(!showBroker && !showLender) || !allowSwitchProfile}>
        <div
          className={`hand ${className || ""}`}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <h1 className="m-b-0">{activeProfileTitle}</h1>
        </div>
      </Visible>
    </Fragment>
  );
};

export default connect(state => ({
  activeProfile: state.profile.activeProfile
}))(ProfileSwitch);
