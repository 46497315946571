import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Col, Row, Skeleton } from "antd";
import Logo from "../components/Logo";

class TemplateNothing extends Component {
  render() {
    return (
      <Fragment>
        <Row type="flex" justify="start">
          <Col>
            <Logo height="50px" />
          </Col>
        </Row>
        <Row className="bg-white" type="flex" align="middle" justify="center">
          <Col sm={{ span: "24" }} md={{ span: "16" }} className="login">
            <Col span={20} className="m-5 p-5" style={{ height: "300px" }}>
              <div className="login-content">
                <div>
                  <h2>Please wait a bit while we process your request...</h2>
                </div>
                <Row align="middle">
                  <Col style={{ textAlign: "center" }}>
                    {this.props.children}
                  </Col>
                </Row>
                <Skeleton active />
                <Skeleton active />
              </div>
            </Col>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

TemplateNothing.propTypes = { children: PropTypes.element.isRequired };

export default TemplateNothing;
