import { Card, Form, Radio } from "antd";
import React from "react";

const DayActivity = ({
  getFieldDecorator
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative">
        <div className="title-center">90 Day Activity</div>
      <Form.Item label="Make my name private">
        {getFieldDecorator("ninetyDayActivityShowName")(
          <Radio.Group>
            <Radio value={true}>Visible</Radio>
            <Radio value={false}>Private</Radio>
          </Radio.Group>
        )}
      </Form.Item> 
    </Card>
  );
};
export default DayActivity;
