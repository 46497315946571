import React, { useState } from "react";
import { Button, Icon, Input, Popconfirm, Radio, Select, Table } from "antd";
import _ from "lodash";
import { tableColumnGenerator } from "../../libs/table";
import { mustBeArray } from "../../libs/utilities";
import EditIcon from "../../assets/icons/EditIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { isSystemAdminUserPolicy } from "../../Common/Authorization/policies/isSystemUserAdminPolicy";
import { AuthorizedView } from "../../Common/Authorization/AuthorizedView";
import { mobileNumberRegex } from "../../constants/inputFields";

const UsersList = props => {
  const {
    list,
    loading,
    handleAction,
    sortInfo,
    handleEdit,
    handleSave,
    updatedUserList,
    positionsList
  } = props;

  const [processing, setProcessing] = useState(false);

  async function onSaveClicked(record) {
    try {
      setProcessing(true);
      if(checkValidMobile(record.mobile)){
        await handleSave({ record });
      }
    } finally {
      setProcessing(false);
    }
  }

  function checkValidMobile(value = "") {
    return mobileNumberRegex.test(value);
  }

  const colTemplate = [
    {
      title: "First name",
      dataIndex: "userNameFirst",
      width: "13%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "userNameFirst" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return updatedUserList.includes(record && record.id) ? (
          <Input
            value={data}
            onChange={e =>
              handleEdit({
                record,
                field: "userNameFirst",
                value: e.target.value
              })
            }
          />
        ) : (
          data
        );
      }
    },
    {
      title: "Last name",
      dataIndex: "userNameLast",
      width: "13%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "userNameLast" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return updatedUserList.includes(record && record.id) ? (
          <Input
            value={data}
            onChange={e =>
              handleEdit({
                record,
                field: "userNameLast",
                value: e.target.value
              })
            }
          />
        ) : (
          data
        );
      }
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      width: "20%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "userEmail" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return updatedUserList.includes(record && record.id) ? (
          <Input
            value={data}
            onChange={e =>
              handleEdit({ record, field: "userEmail", value: e.target.value })
            }
          />
        ) : (
          data
        );
      }
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      width: "10%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "mobile" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return updatedUserList.includes(record && record.id) ? (
          <Input
            value={data}
            onChange={e => {
              handleEdit({ record, field: "mobile", value: e.target.value });
            }}
            style={{
              outline: !checkValidMobile(data) ? '1px solid red' : ''
            }}
          />
        ) : (
          data
        );
      }
    },
    {
      title: "Status",
      dataIndex: "userIsActive",
      width: "10%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "userIsActive" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return updatedUserList.includes(record && record.id) ? (
          <Select
            value={data ? 1 : 2}
            onChange={e =>
              handleEdit({
                record,
                field: "userIsActive",
                value: e === 1 ? true : false
              })
            }
          >
            <Select.Option value={1}>Current</Select.Option>
            <Select.Option value={2}>Former</Select.Option>
          </Select>
        ) : data ? (
          <React.Fragment>
            Current <Icon type="caret-down" className="m-l-10" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            Former <Icon type="caret-down" className="m-l-10" />
          </React.Fragment>
        );
      }
    },
    {
      title: "Administrator",
      dataIndex: "isAdmin",
      width: "15%",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "isAdmin" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return (
          <Radio
            style={{ zIndex: 99 }}
            checked={data}
            className={
              updatedUserList.includes(record && record.id)
                ? ""
                : "no-pointer-event"
            }
            onClick={e =>
              handleEdit({
                record,
                field: "isAdmin",
                value: e.currentTarget.checked
              })
            }
          />
        );
      }
    }
  ];
  const actions = [
    {
      title: "",
      dataIndex: "",
      sorter: false,
      width: "9%",
      render: record => {
        return updatedUserList.includes(record && record.id) ? (
          <AuthorizedView policy={isSystemAdminUserPolicy}>
            <Button
              size="small"
              icon="file"
              type="primary"
              loading={processing}
              onClick={() => onSaveClicked(record)}
            >
              Save
            </Button>
          </AuthorizedView>
        ) : (
          <React.Fragment>
            <AuthorizedView policy={isSystemAdminUserPolicy}>
              <EditIcon
                onClick={() => handleAction({ record, action: "edit" })}
              />
              <Popconfirm
                title="Are you sure you want to delete this user?"
                onConfirm={() => handleAction({ record, action: "delete" })}
              >
                <DeleteIcon className="m-l-6" />
              </Popconfirm>
            </AuthorizedView>
          </React.Fragment>
        );
      }
    }
  ];
  const tableCoumn = _.concat(tableColumnGenerator(colTemplate), actions);
  return (
    <Table
      columns={tableCoumn}
      dataSource={mustBeArray(list)}
      pagination={false}
      loading={loading}
      rowKey={record => record.id}
    />
  );
};

export default UsersList;
