import React, { useEffect, useState, InputNumber } from "react";
import { connect } from "react-redux";
import { Input, Table } from "antd";
import { getAttributedLenders } from "../../actions/loans";
import { tableColumnGenerator } from "../../libs/table";
import { mustBeArray } from "../../libs/utilities";
import NumberInput from "../../Common/NumberInput";
import history from "../../routes/history";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER, SYSTEM_ADMIN_USER } from "../../constants/systemUsers";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import useCheckUserRoles from "../../Common/Hooks/useCheckUserRoles";

const AttributedLenders = props => {
  const { user, getAttributedLenders, match } = props;

  const loanId = match.params.id;

  const { isLawFirmUser, checkIfUserIsSystemAdmin } = useCheckUserRoles();

  const [attributedLenderList, setAttributedLenderList] = useState([]);

  useEffect(() => {
    getAttributedLendersList();
  }, [loanId]);

  const getAttributedLendersList = async () => {
    console.log("getAttributedLendersList")
    checkIfUserIsSystemAdmin().then(isSystemAdmin => {
      const userType = isSystemAdmin ? SYSTEM_ADMIN_USER : "";
      getAttributedLenders({
        id: match.params.id,
        userType
      }).then(resp => {
        if (resp.status) {
          setAttributedLenderList(resp.data);
        }
      });
    });
  };

  let colTemplate =
    attributedLenderList.length > 1
      ? [
          {
            title: "",
            dataIndex: "loanLenderName",
            width: 100,
            sorter: false,
            render: (data, record) => {
              return (
                <span>
                  <Input
                    style={{ width: "250px" }}
                    value={data}
                    onClick={() => alert("Lender Id: " + record.loanLenderId)}
                  ></Input>
                </span>
              );
            }
          },
          {
            dataIndex: "loanLenderContribution",
            width: 100,
            sorter: false,
            render: (data, record) => {
              return (
                <span>
                  <Input
                    style={{ width: "150px", marginLeft: "-15px" }}
                    value={"$" + data}
                  ></Input>
                </span>
              );
            }
          }
        ]
      : [
          {
            dataIndex: "loanLenderName",
            width: 100,
            sorter: false,
            render: (data, record) => {
              return (
                <span>
                  <Input style={{ width: "400px" }} value={data}></Input>
                </span>
              );
            }
          }
        ];

  const columns = tableColumnGenerator(colTemplate);

  const handleLenderProfileClick = lenderId => {
    history.push(`/users/${lenderId}/lender`);
  };

  return (
    <div>
      {mustBeArray(attributedLenderList).map((data, i) => {
        return (
          <div className="m-b-8 m-l-4" key={i} style={{ marginTop: "-6px" }}>
            {data.loanLenderId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: !isLawFirmUser ? "blue" : "inherit",
                  cursor: !isLawFirmUser ? "pointer" : "default"
                }}
                disabled={false}
                value={data.loanLenderName || ""}
                onClick={() => {
                  if (!isLawFirmUser) {
                    handleLenderProfileClick(data.loanLenderId);
                  }
                }}
              ></Input>
            )}
            {!data.loanLenderId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: "darkred",
                  cursor: "default"
                }}
                disabled={false}
                value={data.loanLenderName || ""}
              ></Input>
            )}
            <NumberInput
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\$\s?|(,*)/g, "")}
              className="m-l-6 m-r-6 hideNumberPointers w-150"
              value={data?.loanLenderContribution}
              disabled
            ></NumberInput>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    attributedLenders: state.attributedLenders
  };
};

const mapDispatchToProps = {
  getAttributedLenders
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttributedLenders);
