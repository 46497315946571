import React, {useContext } from 'react';
import { LENDER_USER } from '../../../constants/systemUsers';
import { UserProfileImage } from '../../Common';
import FinderUtilityContext from '../FinderUtilityContext';

const FinderUserBasicDetail = ({user}) => {
    const {userType} = useContext(FinderUtilityContext);
    const userFullName = userType === LENDER_USER ? "lenderName" : 'brokerName';
 
    return (
        <div className='flex align-center flex-1-1-19 m-b-6 gap-16'>
            <UserProfileImage url={user?.profileImageFile?.fileUrl} height={165} width={165}/>
            <div className='f-s-16 text-overflow' style={{maxWidth: "280px"}}>
                <div className='m-b-12'>{user?.[userFullName]}</div>
                <div className='m-b-12'>{user?.mobileNumber}</div>
                <a target="_top" href={`mailto:${user?.email}`}>{user?.email}</a>
            </div>
        </div>
    );
}
 
export default FinderUserBasicDetail;