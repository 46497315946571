import { Checkbox, Col, Radio, Row, Skeleton } from "antd";
import React, { Fragment } from "react";
import { mustBeArray } from "../../libs/utilities";
import { Visible } from "../Common/visiblityWrapper";

const StatesForm = props => {
  const {
    statesList,
    handleChangeDistance,
    regionsList,
    selectedCity,
    distancesList,
    handleChangeState,
    loading,
    fields,
    handleChangeRegion,
    userHasPermissionToUpdate
  } = props;
  return (
    <Fragment>
      <Visible visible={loading}>
        <Skeleton active />
      </Visible>
      <Visible visible={!loading}>
        <div>
          <div className="flex flex-wrap m-t-12">
            <div className="flex-1-1-17">States</div>
            <div className="flex-1-1-83">
              {mustBeArray(statesList).map((state, i) => {
                return (
                  <Checkbox
                    checked={
                      fields.stateRegionDetails.length > 0 &&
                      fields.stateRegionDetails
                        .map(x => x.stateKey)
                        .includes(state.id)
                    }
                    onChange={e =>
                      handleChangeState({
                        isChecked: e.target.checked,
                        value: e.target.value
                      })
                    }
                    disabled={!userHasPermissionToUpdate}
                    value={state.id}
                    key={i}
                    className="p-b-6"
                  >
                    {state.id}
                  </Checkbox>
                );
              })}
              <span className="p-b-30" 
                style={{color:"red"}}>* </span>
            </div>
          </div>
          <div className="m-b-12">
            {mustBeArray(regionsList).map((state, i) => {
              return (
                <React.Fragment key={i}>
                  <Visible visible={state.regions.length > 0}>
                    {state.id}
                  </Visible>
                  <div className="flex flex-wrap m-b-12">
                    <div className="flex-1-1-83 ">
                      <Radio.Group
                        value={
                          fields.stateRegionDetails.find(
                            x => x.stateKey === state.id
                          )?.distanceKey
                        }
                        className="flex space-between"
                        disabled={!userHasPermissionToUpdate}
                        onChange={e =>
                          handleChangeDistance({
                            value: e.target.value,
                            stateId: state.id
                          })
                        }
                      >
                        {mustBeArray(distancesList).map((distance, i) => {
                          return (
                            <Radio value={distance.id} key={i}>
                              {distance.name}
                            </Radio>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="m-b-12">
                    <Row>
                      {mustBeArray(state.regions).map((region, i) => {
                        return (
                          <Col span={12} key={i}>
                            <Checkbox
                              disabled={!userHasPermissionToUpdate}
                              value={region.id}
                              checked={
                                fields.stateRegionDetails.length > 0 &&
                                fields.stateRegionDetails
                                  .find(st => st.stateKey === state.id)
                                  .regionKeys?.includes(region.id)
                              }
                              onChange={e =>
                                handleChangeRegion({
                                  isChecked: e.target.checked,
                                  value: e.target.value,
                                  stateId: state.id
                                })
                              }
                              key={region.id}
                              className="p-b-6 flex-1-1-25"
                            >
                              {region.name}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </Visible>
    </Fragment>
  );
};
export default StatesForm;
