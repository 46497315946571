import { Select } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loansStatesAndSuburbsLookups } from "../../../actions/lookup";
import axios from "axios";
import { mustBeArray } from "../../../libs";
import LoanCreateContext from "./LoanCreateContext";
import { debounce } from "lodash";

const SuburbAndStateLookup = ({ value, handleFieldChange, className, disabled = false }) => {
  const [list, setList] = useState([]);
  const { setStateAndSuburbList, validateOnBlur } = useContext(
    LoanCreateContext
  );
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  useEffect(() => {
    dispatchAction(
      loansStatesAndSuburbsLookups({ cancelToken: signal.token })
    ).then(resp => {
      const data = mustBeArray(resp);
      updateList(data);
      setStateAndSuburbList(data);
    });
  }, []);

  const updateList = currentList => {
    const listIncludesState = currentList.find(o => o.name === value);
    if (value && !listIncludesState) {
      const SuburbAndState = value.split(", ");
      setList([
        ...currentList,
        { name: value, suburb: SuburbAndState[0], state: SuburbAndState[1] }
      ]);
      return;
    }
    setList(currentList);
  };

  const handleSearch = e => {
    dispatchAction(
      loansStatesAndSuburbsLookups({ cancelToken: signal.token, search: e })
    ).then(resp => {
      updateList(mustBeArray(resp));
    });
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300));
  return (
    <div className={`flex flex-align-center m-r-20 ${className || ""}`}>
      <b>Suburb and State</b>
      <Select
        className="w-280 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "suburbState", value })}
        allowClear={false}
        onBlur={e => validateOnBlur({ field: "suburbState", value: e })}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
        disabled={disabled}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.name} value={data.name}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-12 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default SuburbAndStateLookup;
