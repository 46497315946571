import React from "react";
import { connect } from "react-redux";
import {
  checkSession,
  getProfileInfo,
  getUserData,
  signoutUser
} from "../actions/member";
import history from "../routes/history";
import Logo from "./Logo";
import authService from "../actions/AuthorizeService";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      authenticated: false
    };
  }

  async componentDidMount(){
    await this.populateAuthenticationState();
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ authenticated });
  }

  redirect(authenticated = false){
    if(authenticated){
      history.push("/")
    }
    else {
      history.push('/general/finder-utility');
    }
  }

  render() {
    const { index } = this.props;
    const {authenticated} = this.state;
    return (
      <div
        className={`flex space-between p-t-12 ${
          index ? "p-t-10 p-b-2" : "p-t-27 p-b-15"
        }`}
        onClick={()=>this.redirect(authenticated)}
      >
        <Logo width={"min(50vw, 200px)"} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  header: state.member && state.member.header
});

const mapDispatchToProps = {
  signoutUser,
  getUserData,
  getProfileInfo,
  checkSession
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
