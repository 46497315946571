import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Loading from "./components/loading";
import { PersistGate } from "redux-persist/es/integration/react";
import "ant-design-pro/dist/ant-design-pro.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import * as serviceWorker from "./serviceWorker";

import configureStore from "./store/index";

import Routes from "./routes/index";
import history from "./routes/history";
import "./styles/app.scss";

library.add(fab, fas, far);

const rootElement = document.getElementById("root");
const { persistor, store } = configureStore();

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Router history={history}>
        <Routes />
      </Router>
    </PersistGate>
  </Provider>
);

render(<Root />, rootElement);

serviceWorker.unregister();
