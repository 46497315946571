const initialState = {
  details: {},
  currentList: [],
  currentTotal: 0,
  formerList: [],
  formerTotal: 0
};

export default function usersReducer(state = initialState, action) {
  const { type, data, total } = action;
  switch (type) {
    case "LIST_USERS_CURRENT": {
      return {
        ...state,
        currentList: [...data],
        currentTotal: total
      };
    }
    case "LIST_USERS_FORMER": {
      return {
        ...state,
        formerList: [...data],
        formerTotal: total
      };
    }
    case "CLEAR_USERS": {
      return {
        ...state,
        details: {},
        currentList: [],
        currentTotal: 0,
        formerList: [],
        formerTotal: 0
      };
    }
    default:
      return state;
  }
}
