import {
  SET_DAILY_SCORE_PAGE,
  SET_MONTHLY_SCORE_PAGE,
  SET_NOTE_ACTION
} from "../constants/action-type/notes";

const initialState = {
  dailyScorePage: 1,
  monthlyScorePage: 1,
  noteAction: ""
};

export default function notesReducer(state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case SET_DAILY_SCORE_PAGE: {
      return {
        ...state,
        dailyScorePage: data.dailyScorePage
      };
    }
    case SET_MONTHLY_SCORE_PAGE: {
      return {
        ...state,
        monthlyScorePage: data.monthlyScorePage
      };
    }
    case SET_NOTE_ACTION: {
      return {
        ...state,
        noteAction: data.noteAction
      };
    }
    default:
      return state;
  }
}
