import { Col, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import produce from "immer";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import {
  getBrokerPersonalDetails,
  getBrokerProfessionalDetails,
  saveBrokerPersonalDetailsField,
  saveBrokerProfessionalDetailsField
} from "../../actions/profile";
import { Visible } from "../Common/visiblityWrapper";
import Axios from "axios";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { asDefaultDisplayFormatDate, errorDisplay } from "../../libs/utilities";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../constants/systemUsers";
import {useParams} from "react-router-dom";

const defaultDetails = {
  detail: "",
  lastModifiedDate: "",
  lastModifiedByFirstName: "",
  lastModifiedByLastName: ""
}

const Implementation = ({ dispatch, activeKey, user }) => {
  const [professionalDetails, setProfessionalDetails] = useState({...defaultDetails});
  const [personalDetails, setPersonalDetails] = useState({...defaultDetails});

  const [saving, setSaving] = useState(false);
  const [isInitialized, setIsInitialized] = useState(null);

  const {id:brokerId} = useParams();

  useEffect(() => {
    if (activeKey === "details") {
      setIsInitialized(false);
    }
  }, [activeKey, brokerId]);

  useEffect(() => {
    if(isInitialized===false){
      onInitialized();
    }
  },[isInitialized]);

  async function onInitialized() {
    setProfessionalDetails({...defaultDetails});
    setPersonalDetails({...defaultDetails});
    await Promise.all([loadPersonalDetails(brokerId), loadProfessionalDetails(brokerId)]).then(
      () => {
        setIsInitialized(true);
      }
    );
  }

  const loadPersonalDetails = async (id) => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    const resp = await dispatch(
      getBrokerPersonalDetails({ id, userType: userType })
    );
    setPersonalDetails(
      produce(personalDetails, x => {
        return resp.data;
      })
    );
  };

  const loadProfessionalDetails = async (id) => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    const resp = await dispatch(
      getBrokerProfessionalDetails({ id, userType: userType })
    );
    setProfessionalDetails(
      produce(professionalDetails, x => {
        return resp.data;
      })
    );
  };

  const savePersonalDetailsChangesImpl = async (cancelToken, payLoad,id) => {
    setSaving(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    dispatch(
      saveBrokerPersonalDetailsField({
        cancelToken: cancelToken,
        payLoad: payLoad,
        id,
        userType: userType
      })
    ).then(resp => {
      if (!resp.status) {
        errorDisplay(resp.data.errors);
      } else {
        loadPersonalDetails(id);
      }
      setSaving(false);
    });
  };

  const saveProfessionDetailsChangesImpl = async (cancelToken, payLoad, id) => {
    setSaving(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    dispatch(
      saveBrokerProfessionalDetailsField({
        cancelToken: cancelToken,
        payLoad: payLoad,
        id,
        userType: userType
      })
    ).then(resp => {
      if (!resp.status) {
        errorDisplay(resp.data.errors);
      } else {
        loadProfessionalDetails(id);
      }
      setSaving(false);
    });
  };

  const savePersonalDetailsChanges = useCallback(
    AwesomeDebouncePromise(savePersonalDetailsChangesImpl, 2000),
    [brokerId]
  );
  const saveProfessionalDetailsChanges = useCallback(
    AwesomeDebouncePromise(saveProfessionDetailsChangesImpl, 2000),
    [brokerId]
  );

  useEffect(() => {
    if (isInitialized) {
      let cancelToken = Axios.CancelToken.source();
      savePersonalDetailsChanges(cancelToken, personalDetails, brokerId);
    }
  }, [personalDetails && personalDetails.detail]);

  useEffect(() => {
    if (isInitialized) {
      let cancelToken = Axios.CancelToken.source();
      saveProfessionalDetailsChanges(
        cancelToken,
        professionalDetails,
        brokerId
      );
    }
  }, [professionalDetails && professionalDetails.detail]);

  return (
    <Fragment>
      <Visible visible={saving}>
        <span className="p-5">Saving...</span>
      </Visible>
      <Row className="p-5">
        <Col span={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "1em"
            }}
          >
            <span>Professional:</span>
            <TextArea
              id="professional"
              rows="12"
              cols="110"
              value={professionalDetails.detail}
              onChange={e =>
                setProfessionalDetails(
                  produce(professionalDetails, x => {
                    x.detail = e.currentTarget.value;
                  })
                )
              }
              style={{ flex: "1", marginTop: "1em" }}
            />
            <Row
              type="flex"
              className="p-5"
              style={{ marginTop: "1em" }}
              gutter={15}
              justify="space-around"
              align="middle"
            >
              <Col order="1">Last edited by</Col>
              <Col order="2">
                <Input
                  disabled={true}
                  value={`${
                    professionalDetails &&
                    professionalDetails.lastModifiedByFirstName
                      ? `${professionalDetails.lastModifiedByFirstName} `
                      : ""
                  }${
                    professionalDetails &&
                    professionalDetails.lastModifiedByLastName
                      ? `${professionalDetails.lastModifiedByLastName}`
                      : ""
                  }`}
                />
              </Col>
              <Col order="3">Last edit date</Col>
              <Col order="4">
                <Input
                  disabled={true}
                  value={asDefaultDisplayFormatDate(
                    professionalDetails.lastModifiedDate
                  )}
                ></Input>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Personal:</span>
            <TextArea
              id="personal"
              rows="12"
              cols="110"
              value={personalDetails.detail}
              onChange={e =>
                setPersonalDetails(
                  produce(personalDetails, x => {
                    x.detail = e.currentTarget.value;
                  })
                )
              }
              style={{ flex: "1", marginTop: "1em" }}
            />
            <Row
              type="flex"
              className="p-5"
              style={{ marginTop: "1em" }}
              gutter={15}
              justify="space-around"
              align="middle"
            >
              <Col order="1">Last edited by</Col>
              <Col order="2">
                <Input
                  disabled={true}
                  value={`${
                    personalDetails && personalDetails.lastModifiedByFirstName
                      ? `${personalDetails.lastModifiedByFirstName} `
                      : ""
                  }${
                    personalDetails && personalDetails.lastModifiedByLastName
                      ? `${personalDetails.lastModifiedByLastName}`
                      : ""
                  }`}
                ></Input>
              </Col>
              <Col order="3">Last edit date</Col>
              <Col order="4">
                <Input
                  disabled={true}
                  value={asDefaultDisplayFormatDate(
                    personalDetails.lastModifiedDate
                  )}
                ></Input>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = state => {
  return {
    user: state.authorization && state.authorization.user
  };
};
export const BrokerDetailsTab = connect(mapStateToProps)(Implementation);
