import { Button, Modal } from "antd";
import React, { useContext } from "react";

const AddInactiveFinancierConfirmationModal = ({
  confirmModal,
  handleConfirm,
  message
}) => {
  return (
    <Modal
      visible={confirmModal.visible}
      onCancel={() =>
        handleConfirm(false, confirmModal.financier)}
      closable={false}
      footer={[
        <Button
          type="primary"
          key="a"
          onClick={() =>
            handleConfirm(true, confirmModal.financier)
          }
        >
          Yes
        </Button>,
        <Button key="b" onClick={() =>
          handleConfirm(false, confirmModal.financier)}>
          No
        </Button>
      ]}
    >
      {message}
    </Modal>
  );
};
export default AddInactiveFinancierConfirmationModal;
