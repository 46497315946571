import React, { useEffect, useState } from "react";
import {
  Table,
  Switch,
  Select,
  Badge,
  Input,
  message,
  Modal,
  Button
} from "antd";
import _ from "lodash";
import { tableColumnGenerator } from "../../libs/table";
import { errorDisplay, mustBeArray, delay } from "../../libs/utilities";
import axios from "axios";
import { colors } from "../../constants/colors";
import {
  updateMatches,
  updateMatchesBroker,
  updateMatchesLender
} from "../../actions/profile";
import { connect } from "react-redux";
import { Visible } from "../Common/visiblityWrapper";
import {
  ADMIN_USER,
  BROKER_USER,
  LENDER_USER
} from "../../constants/systemUsers";
import history from "../../routes/history";
import CustomSorter from "../Common/CustomSorter";
import {
  INACTIVE_PROFILE_MATCHES_LIST_WARNING,
  MASTER_SWITCH_OFF_OMICRON_STATUS_CHANGE_ERROR
} from "../../constants/warnings";
import authService from "../../actions/AuthorizeService";

const MatchesList = props => {
  const {
    match,
    activeKey,
    listLendersMatches,
    userId,
    listLendersMatchesLookup,
    profileType,
    userType,
    lender,
    broker,
    dispatch
  } = props;

  const signal = axios.CancelToken.source();
  const [viewInactiveProfileWarning, setViewInactiveProfileWarning] = useState(
    false
  );
  const [matchesList, setMatchesList] = useState([]);
  const [loading, setListLoading] = useState(true);
  const [statusList, setStatusList] = useState([]);
  const [sortInfo, setSortInfo] = useState({});
  const [sortByCompany, setSortByCompany] = useState(false);
  const [editedList, setEditedList] = useState([]);

  const getValidationIdAndParam = ({ record }) => {
    if (profileType === "lender") {
      return {
        id: record && record.brokerId,
        param: "brokerId"
      };
    } else {
      return {
        id: record && record.lenderId,
        param: "lenderId"
      };
    }
  };

  const isMasterSwitchOn = () => {
    if (profileType === "lender") {
      return lender && lender.isMasterSwitchOn ? true : false;
    }
    return broker && broker.isMasterSwitchOn ? true : false;
  };
  const isProfileActive = () => {
    if (profileType === "lender") {
      return lender && lender.isProfileActive ? true : false;
    }
    return broker && broker.isProfileActive ? true : false;
  };
  const handleEdit = async ({ record, field, value }) => {
    let validationId = getValidationIdAndParam({ record }).id;
    let validationParam = getValidationIdAndParam({ record }).param;

    if (field === "omicronStatusKey" && !isMasterSwitchOn()) {
      return message.error(MASTER_SWITCH_OFF_OMICRON_STATUS_CHANGE_ERROR);
    }

    let userDetails = await authService.getUser();
    let usersLenderId = userDetails["app.user.lenderid"];
    let usersBrokerId = userDetails["app.user.brokerid"];

    if (
      userType !== ADMIN_USER &&
      !isProfileActive() &&
      (profileType === "lender" ? usersLenderId : usersBrokerId) ===
        match.params.id
    ) {
      return setViewInactiveProfileWarning(true);
    }
    if (!editedList.includes(validationId)) {
      setEditedList([...editedList, validationId]);
    }
    let newUpdatedList = [...matchesList].map(data => {
      return data[validationParam] === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });

    let updatedRecord = newUpdatedList.find(x => {
      return x.id === record.id;
    });
    setMatchesList(newUpdatedList);
    delay(200).then(() => {
      const noteField = document.getElementById(
        `note-${
          profileType === "lender"
            ? record && record.brokerId
            : record && record.lenderId
        }`
      );
      if (noteField) {
        noteField.focus();
      }
    });

    if (userType !== ADMIN_USER) {
      saveMatchesUpdate(updatedRecord);
    }
  };

  const updateRecord = ({ record, field, value }) => {
    let validationId = getValidationIdAndParam({ record }).id;
    let validationParam = getValidationIdAndParam({ record }).param;

    let newUpdatedList = matchesList.map(data => {
      return data[validationParam] === validationId
        ? { ...data, [field]: value }
        : { ...data };
    });
    setMatchesList(newUpdatedList);
  };

  const saveMatchesUpdate = record => {
    setListLoading(true);

    if (userType === LENDER_USER) {
      saveMatchesLender(record);
    } else if (userType === ADMIN_USER) {
      saveMatchesBransgroves(record);
    } else if (userType === BROKER_USER) {
      saveMatchesBroker(record);
    }
  };

  const saveMatchesBransgroves = async record => {
    const lenderId =
      profileType === "lender"
        ? Number(match && match.params && match.params.id)
        : record && record.lenderId;
    const brokerId =
      profileType === "broker"
        ? Number(match && match.params && match.params.id)
        : record && record.brokerId;

    let payLoad = {
      lenderId,
      brokerId,
      note: record && record.note
    };
    payLoad.omicronStatusKey = record && record.omicronStatusKey;
    payLoad.lenderStatusKey = record && record.lenderStatusKey;

    await dispatch(updateMatches({ payLoad, canelToken: signal.token })).then(
      resp => {
        setListLoading(false);
        let validationParam = getValidationIdAndParam({ record }).param;
        if (resp.status) {
          message.success("Matches updated successfully.");
          let updatedEditedList = _.filter(
            editedList,
            o => o !== (record && record[validationParam])
          );
          setEditedList(updatedEditedList);
          listLenders();
        } else {
          errorDisplay(resp && resp.data && resp.data.errors);
        }
      }
    );
  };

  const saveMatchesLender = async record => {
    let payLoad = {
      matchId: record.id,
      lenderStatusKey: record && record.lenderStatusKey
    };

    await dispatch(
      updateMatchesLender({ payLoad, canelToken: signal.token })
    ).then(resp => {
      setListLoading(false);
      let validationParam = getValidationIdAndParam({ record }).param;
      if (resp.status) {
        message.success("Matches updated successfully.");
        let updatedEditedList = _.filter(
          editedList,
          o => o !== (record && record[validationParam])
        );
        setEditedList(updatedEditedList);
        listLenders();
      } else {
        errorDisplay(resp && resp.data && resp.data.errors);
      }
    });
  };

  const saveMatchesBroker = async record => {
    let payLoad = {
      matchId: record.id,
      brokerStatusKey: record && record.brokerStatusKey
    };

    await dispatch(
      updateMatchesBroker({ payLoad, canelToken: signal.token })
    ).then(resp => {
      setListLoading(false);
      let validationParam = getValidationIdAndParam({ record }).param;
      if (resp.status) {
        message.success("Matches updated successfully.");
        let updatedEditedList = _.filter(
          editedList,
          o => o !== (record && record[validationParam])
        );
        setEditedList(updatedEditedList);
        listLenders();
      } else {
        errorDisplay(resp && resp.data && resp.data.errors);
      }
    });
  };

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field
      };
    }
    return queryParams;
  };

  const getBadgeClassName = record => {
    if (
      record &&
      _.find(
        [
          record.brokerStatusKey,
          record.omicronStatusKey,
          record.lenderStatusKey
        ],
        o => o !== "MATCHED"
      )
    )
      return colors.danger;
    return colors.success;
  };

  useEffect(() => {
    if (lender?.isProfileActive || broker?.isProfileActive) {
      listLenders();
      listLendersMatchesLookUp();
    }
  }, [lender, broker]);

  useEffect(()=> {
    listLenders();
    listLendersMatchesLookUp();
  }, [userId]);

  useEffect(() => {
    if (!lender?.isProfileActive && !broker?.isProfileActive) {
      return;
    }

    if (Number(activeKey) === 1 || activeKey === "matches") {
      listLenders();
      listLendersMatchesLookUp();
    }
    const cleanUp = () => {
      signal.cancel("");
    };
    return cleanUp;
  }, [
    activeKey,
    sortInfo,
    profileType === "lender"
      ? lender && lender.isMasterSwitchOn
      : broker && broker.isMasterSwitchOn,
    profileType === "lender"
      ? lender && lender.isProfileActive
      : broker && broker.isProfileActive
  ]);

  const listLenders = () => {
    listLendersMatches({
      id: userId,
      cancelToken: signal.token,
      queryParams: getQueryParams()
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        setMatchesList(mustBeArray(resp.data));
      }
    });
  };

  const listLendersMatchesLookUp = () => {
    listLendersMatchesLookup({
      id: userId,
      userType,
      canelToken: signal.token
    }).then(resp => {
      if (resp.status) {
        setStatusList(mustBeArray(resp.data));
      }
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (!sorter.field && sorter.order) {
      setSortInfo({
        field: sortByCompany ? "company" : "brokerName",
        order: sorter.order === "ascend" ? "asc" : "desc"
      });
    } else {
      setSortInfo({
        field: sorter.field,
        order: !sorter.order
          ? undefined
          : sorter.order === "ascend"
          ? "asc"
          : "desc"
      });
    }
  };

  const handleSortByCompany = e => {
    if (sortInfo && sortInfo.field === "company") {
      if (sortInfo.order === "asc")
        setSortInfo({ field: "company", order: "desc" });
      else if (sortInfo.order === "desc") setSortInfo({});
      else setSortInfo({ field: "company", order: "asc" });
    } else {
      setSortInfo({ field: "company", order: "asc" });
    }
  };

  const handleSortByName = e => {
    if (["brokerName", "lenderName"].includes(sortInfo && sortInfo.field)) {
      if (sortInfo.order === "asc")
        setSortInfo({
          field: profileType === "lender" ? "brokerName" : "lenderName",
          order: "desc"
        });
      else if (sortInfo.order === "desc") setSortInfo({});
      else
        setSortInfo({
          field: profileType === "lender" ? "brokerName" : "lenderName",
          order: "asc"
        });
    } else {
      setSortInfo({
        field: profileType === "lender" ? "brokerName" : "lenderName",
        order: "asc"
      });
    }
  };

  const getMatchesNameSortOrder = () => {
    if (
      sortInfo &&
      (sortInfo.field === "brokerName" || sortInfo.field === "company") &&
      sortInfo.order
    ) {
      return sortInfo.order === "asc" ? "ascend" : "descend";
    }
    return false;
  };
  const handleCloseInactiveProfileWarning = () => {
    setViewInactiveProfileWarning(false);
  };
  let colTemplate = [
    {
      title: (
        <div className="flex flex-direction-row flex-justify-between">
          <span
            className="flex flex-direction-row flex-align-center hand"
            onClick={handleSortByName}
          >
            Sort by name
            <CustomSorter
              className="m-l-6"
              sortType={
                sortInfo &&
                ["brokerName", "lenderName"].includes(sortInfo.field)
                  ? sortInfo.order
                  : ""
              }
            />
          </span>
          <span
            className="flex flex-direction-row flex-align-center hand"
            onClick={handleSortByCompany}
          >
            Sort by company
            <CustomSorter
              className="m-l-6"
              sortType={
                sortInfo && sortInfo.field === "company" ? sortInfo.order : ""
              }
            />
          </span>
        </div>
      ),
      dataIndex: "",
      sorter: false,
      sortOrder: getMatchesNameSortOrder(),
      render: record => {
        return (
          <div className="matches-name">
            <Visible visible={profileType === "lender"}>
              <span
                className="hand color-primary"
                onClick={() =>
                  history.push(`/users/${record && record.brokerId}/broker`)
                }
              >
                <span>{record.brokerName}</span>
                {`${record.company ? `, ${record.company}` : ""}`}
              </span>
            </Visible>
            <Visible visible={profileType === "broker"}>
              <span
                className="hand color-primary"
                onClick={() =>
                  history.push(`/users/${record && record.lenderId}/lender`)
                }
              >
                <span>{record.lenderName}</span>
                {`${record.company ? `, ${record.company}` : ""}`}
              </span>
            </Visible>
            <Badge className="f-s-24" color={getBadgeClassName(record)} />
          </div>
        );
      }
    },
    {
      title: "Broker",
      dataIndex: "brokerStatusKey",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "brokerStatusKey" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return userType === BROKER_USER ? (
          <Select
            value={data}
            onChange={e =>
              handleEdit({ record, field: "brokerStatusKey", value: e })
            }
            className="w-percent-100 table-dropdown"
          >
            {mustBeArray(statusList).map(status => {
              return (
                <Select.Option value={status.id} key={status.id}>
                  {status.id}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          data
        );
      }
    },
    {
      title: "Lender",
      dataIndex: "lenderStatusKey",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "lenderStatusKey" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return userType === LENDER_USER ? (
          <Select
            value={data}
            onChange={e =>
              handleEdit({ record, field: "lenderStatusKey", value: e })
            }
            className="w-percent-100 table-dropdown"
          >
            {mustBeArray(statusList).map(status => {
              return (
                <Select.Option value={status.id} key={status.id}>
                  {status.id}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          data
        );
      }
    },
    {
      title: "Omicron",
      dataIndex: "omicronStatusKey",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "omicronStatusKey" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data, record) => {
        return userType === ADMIN_USER ? (
          <Select
            value={data}
            onChange={e =>
              handleEdit({ record, field: "omicronStatusKey", value: e })
            }
            className="w-percent-100 table-dropdown"
          >
            {mustBeArray(statusList).map(status => {
              return (
                <Select.Option value={status.id} key={status.id}>
                  {status.id}
                </Select.Option>
              );
            })}
          </Select>
        ) : (
          data
        );
      }
    }
  ];
  const editedColumnsForAdmin = [
    {
      title: "Notes",
      dataIndex: "note",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "note" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "38%",
      render: (data, record) => {
        const recordIndex =
          profileType === "lender"
            ? record && record.brokerId
            : record && record.lenderId;
        return userType === ADMIN_USER &&
          editedList.includes(getValidationIdAndParam({ record }).id) ? (
          <Input.TextArea
            rows={2}
            id={`note-${recordIndex}`}
            value={data}
            onBlur={() => saveMatchesUpdate(record)}
            onChange={e =>
              updateRecord({
                record,
                field: "note",
                value: e.currentTarget.value
              })
            }
          />
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: "Edited by",
      dataIndex: "editedBy",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "editedBy" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false
    },
    {
      title: "Date",
      dataIndex: "editedDate",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "editedDate" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      dataType: "date"
    }
  ];
  const editedColumnsForOther = [
    {
      title: "Last file note",
      dataIndex: "lastFileNote",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "lastFileNote" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      width: "38%",
      render: (data, record) => {
        const recordIndex =
          profileType === "lender"
            ? record && record.brokerId
            : record && record.lenderId;
        return userType === ADMIN_USER &&
          editedList.includes(getValidationIdAndParam({ record }).id) ? (
          <Input.TextArea
            rows={2}
            id={`note-${recordIndex}`}
            value={data}
            onBlur={() => saveMatchesUpdate(record)}
            onChange={e =>
              updateRecord({
                record,
                field: "lastFileNote",
                value: e.currentTarget.value
              })
            }
          />
        ) : (
          <span>{data}</span>
        );
      }
    },
    {
      title: "Date",
      dataIndex: "lastFileNoteDate",
      sorter: true,
      sortOrder:
        sortInfo && sortInfo.field === "lastFileNoteDate" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      dataType: "date"
    }
  ];
  if (userType === ADMIN_USER) {
    colTemplate = _.concat(colTemplate, editedColumnsForAdmin);
  } else {
    colTemplate = _.concat(colTemplate, editedColumnsForOther);
  }
  const tableCoumn = tableColumnGenerator(colTemplate);
  return (
    <React.Fragment>
      <Table
        columns={tableCoumn}
        dataSource={mustBeArray(matchesList)}
        pagination={false}
        loading={loading}
        rowKey={(record, index) => index}
        onChange={handleTableChange}
        bordered
      />
      <Modal
        visible={viewInactiveProfileWarning}
        onCancel={handleCloseInactiveProfileWarning}
        footer={[
          <Button onClick={handleCloseInactiveProfileWarning}>Close</Button>
        ]}
      >
        {INACTIVE_PROFILE_MATCHES_LIST_WARNING}
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender,
    broker: state.profile.broker,
    firstRanking: state.profile.firstRanking
  };
};

export default connect(mapStateToProps)(MatchesList);
