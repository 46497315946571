import { useEffect, useState } from "react";
import { useResizeListener } from "../../Common/Hooks";

export const useFilterView = () => {
    const [viewFilter, setViewFilter] = useState(false);
    const [smallView, setSmallView] = useState(window.innerWidth <= 1200);
    const {windowWidth} = useResizeListener();
    const [cardWidth, setCardWidth] = useState({
        sm: window.innerWidth <= 1200 ? 24 : 14,
        md: window.innerWidth <= 1200 ? 24 : 16,
        lg: window.innerWidth <= 1200 ? 24 : 20
    });
    
    const toggleViewFilter = () => {
        setViewFilter(!viewFilter);
    };

    useEffect(() => {
        if(windowWidth > 1200){
            setViewFilter(true);
            setSmallView(false);
            setCardWidth({
                sm: 14,
                md: 16,
                lg: 20
            })
        }else{
            setViewFilter(false);
            setSmallView(true);
            setCardWidth({
                sm: 24,
                md: 24,
                lg: 24
            })
        }
    },[windowWidth]);

    useEffect(() => {
        if(viewFilter){
            setCardWidth({
                sm: 14,
                md: 16,
                lg: 20
            })
        }else{
            setCardWidth({
                sm: smallView ? 24 : 14,
                md: smallView ? 24 : 16,
                lg: smallView ? 24 : 20
            })
        }
    },[viewFilter]);
    
    return { viewFilter, toggleViewFilter, smallView, cardWidth };
}