import { Button, message, Modal } from "antd";
import React, { Fragment, useMemo, useState, useEffect, useContext } from "react";
import { isNullEmptyOrUndefined } from "../../../libs";
import FinderUtilityContext from "../FinderUtilityContext";
import { finderFilterTypes } from "../types";
import LowAndHighPercentageFilters from "./LowAndHighPercentageFilters";
import LowAndHighAmountFilters from "./LowHighAmountFilter";
import PriorityLookup from "./priority";
import RadiusLookup from "./radius";
import Ratings from "./ratings";
import ScenarioLookup from "./scenario";
import SecurityTypeLookup from "./securityType";
import SettlementDate from "./settlementDate";
import SuburbAndStateLookup from "./suburbAndState";
import TermLookup from "./term";
import PrincipalFilters from "./principal";
import Valuation from "./Valuation";
import Credit from "./Credit";
import Interest from "./Interest";
import Construction from "./Construction";
import Subdivision from "./Subdivision";

const FinderUtilitySearchModal = ({
  title,
  value,
  type,
  visible,
  handleSearch,
  handleClose
}) => {
  const {selectedSubAndState} = useContext(FinderUtilityContext);
  const [tempValue, setValue] = useState("");
  const [valueLoaded, setValueLoaded] = useState(false);
  useEffect(() => {
    if (visible) {
      setValue(value);
      setValueLoaded(true);
      return;
    }
    setValueLoaded(false);
  }, [value, visible]);

  const handleFieldChange = ({ field, value, sub }) => {
    switch (field) {
      case finderFilterTypes.lowerRate:
      case finderFilterTypes.higherRate:
      case finderFilterTypes.lvr:
      case finderFilterTypes.establishmentFee:
      case finderFilterTypes.principal:
      case finderFilterTypes.term:
        setValue({ ...tempValue, [sub]: value });
        return;
      default:
        setValue(value);
        return;
    }
  };

  const validateLowAndHighValues = () => {
    let error = "";
    const { low, high } = tempValue;
    if (
      (isNullEmptyOrUndefined(low) && high) ||
      (isNullEmptyOrUndefined(high) && low)
    ) {
      error = "Please enter values for lower end and higher end.";
    }
    if (!isNullEmptyOrUndefined(low) && !isNullEmptyOrUndefined(high)) {
      error = low >= high ? "The lower end of your range must be less than the higher end of your range." : "";
      switch(type){
        case finderFilterTypes.principal:
          if(low<50000 || high>150000000){
            error="You must choose a Principal Range between $50K and $100M."
          }
          break;
        case finderFilterTypes.higherRate:
        case finderFilterTypes.lowerRate:
          if(low<1 || high>150){
            error="You must choose an interest rate range between 1% and 150%."
          }
          break;
        case finderFilterTypes.establishmentFee:
          if(low<0 || high>4){
            error="You must choose an interest rate range between 0% and 4%."
          }
          break;
        case finderFilterTypes.lvr:
          if(low<5 || high>99){
            error="You must choose an interest rate range between 5% and 99%."
          }
          break;
        default:
          break;
      }
    }
    
    return error;
  };

  const validateSearchField = () => {
    let error = "";
    switch (type) {  
      case finderFilterTypes.lowerRate:
      case finderFilterTypes.higherRate:
      case finderFilterTypes.lvr:
      case finderFilterTypes.establishmentFee:
      case finderFilterTypes.principal:
        error = validateLowAndHighValues();
        break;
      case finderFilterTypes.radius:
        if(!selectedSubAndState){
          error="Please select suburb and state";
        }
        break;
      default:
        break;
    }

    if (error) {
      message.error(error);
      return;
    }
    handleSearch(tempValue);
  };
  const FilterComponent = useMemo(() => {
    if (!visible) return <Fragment />;
    switch (type) {
      case finderFilterTypes.lowerRate:
      case finderFilterTypes.higherRate:
      case finderFilterTypes.lvr:
      case finderFilterTypes.establishmentFee:
        return (
          <LowAndHighPercentageFilters
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.suburbAndState:
        return (
          <SuburbAndStateLookup
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.radius:
        return (
          <RadiusLookup
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.principal:
        return (
          <PrincipalFilters
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.priority:
        return (
          <PriorityLookup
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.scenario:
        return (
          <ScenarioLookup
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.securityType:
        return (
          <SecurityTypeLookup
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.settlementDate:
        return (
          <SettlementDate
            handleFieldChange={handleFieldChange}
            value={tempValue}
          />
        );
      case finderFilterTypes.term:
        return (
          <TermLookup handleFieldChange={handleFieldChange} value={tempValue} />
        );
      case finderFilterTypes.lenderStarsFromBroker:
      case finderFilterTypes.brokerStarsFromLender:
        return (
          <Ratings
            handleFieldChange={({ value }) =>
              handleFieldChange({ value, field: type })
            }
            value={tempValue}
          />
        );
      case finderFilterTypes.valuation:
        return <Valuation
          handleFieldChange={handleFieldChange}
          value={tempValue}
        />;
      case finderFilterTypes.credit:
        return <Credit handleFieldChange={handleFieldChange} value={tempValue} />;
      case finderFilterTypes.interest:
        return <Interest handleFieldChange={handleFieldChange} value={tempValue} />;
      case finderFilterTypes.construction:
        return <Construction handleFieldChange={handleFieldChange} value={tempValue} />;
      case finderFilterTypes.subdivision:
        return <Subdivision handleFieldChange={handleFieldChange} value={tempValue} />;
      default:
        return <Fragment />;
    }
  }, [type, tempValue, visible]);
  return (
    <Modal
      onCancel={handleClose}
      title={title}
      visible={visible}
      footer={[
        <Button type="primary" onClick={validateSearchField} key={"search"}>
          Search
        </Button>
      ]}
    >
      {valueLoaded && FilterComponent}
    </Modal>
  );
};

export default FinderUtilitySearchModal;
