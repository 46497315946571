export const FINDER_FILTER = "finderFilter";
export const FINDER_USER_TYPE = "finderUserType";

export const MIN_PRINCIPAL_FINDER_LIST = [
  {
    name: "$100,000",
    value: 100000,
    displayName: "$100K"
  },
  {
    name: "$200,000",
    value: 200000,
    displayName: "$200K"
  },
  {
    name: "$500,000",
    value: 500000,
    displayName: "$500K"
  },
  {
    name: "$1,000,000",
    value: 1000000,
    displayName: "$1M"
  },
  {
    name: "$2,000,000",
    value: 2000000,
    displayName: "$2M"
  },
  {
    name: "$3,000,000",
    value: 3000000,
    displayName: "$3M"
  },
  {
    name: "$5,000,000",
    value: 5000000,
    displayName: "$5M"
  },
  {
    name: "$8,000,000",
    value: 8000000,
    displayName: "$8M"
  },
  {
    name: "$10,000,000",
    value: 10000000,
    displayName: "$10M"
  },
  {
    name: "$20,000,000",
    value: 20000000,
    displayName: "$20M"
  },
  {
    name: "$40,000,000",
    value: 40000000,
    displayName: "$40M"
  },
  {
    name: "$60,000,000",
    value: 60000000,
    displayName: "$60M"
  },
  {
    name: "$100,000,000",
    value: 100000000,
    displayName: "$100M"
  }
];

export const MAX_PRINCIPAL_FINDER_LIST = [
    {
      name: "$200,000",
      value: 200000,
      displayName: "$200K"
    },
    {
      name: "$500,000",
      value: 500000,
      displayName: "$500K"
    },
    {
      name: "$1,000,000",
      value: 1000000,
      displayName: "$1M"
    },
    {
      name: "$2,000,000",
      value: 2000000,
      displayName: "$2M"
    },
    {
      name: "$3,000,000",
      value: 3000000,
      displayName: "$3M"
    },
    {
      name: "$5,000,000",
      value: 5000000,
      displayName: "$5M"
    },
    {
      name: "$8,000,000",
      value: 8000000,
      displayName: "$8M"
    },
    {
      name: "$10,000,000",
      value: 10000000,
      displayName: "$10M"
    },
    {
      name: "$20,000,000",
      value: 20000000,
      displayName: "$20M"
    },
    {
      name: "$40,000,000",
      value: 40000000,
      displayName: "$40M"
    },
    {
      name: "$60,000,000",
      value: 60000000,
      displayName: "$60M"
    },
    {
      name: "$100,000,000",
      value: 100000000,
      displayName: "$100M"
    },
    {
      name: "$150,000,000",
      value: 150000000,
      displayName: "$150M"
    }
  ];

