import { http } from "../libs/http";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { processRequest } from "./asyncActionProcessor";
import { ADMIN_USER } from "../constants/systemUsers";
import { requestBuilder } from "../libs/utilities";
import {
  SET_DAILY_SCORE_PAGE,
  SET_MONTHLY_SCORE_PAGE,
  SET_NOTE_ACTION
} from "../constants/action-type/notes";

export const getMonthlyScores = ({ userType, cancelToken, queryParams }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.MONTHLYSCORES_SYSTEM_USER_URL
            : apiUrls.MONTHLYSCORES_INDIVIDUAL_USER_URL
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_MONTHLYSCORES"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const setDailyScorePage = dailyScorePage => {
  return dispatch => {
    dispatch({
      type: SET_DAILY_SCORE_PAGE,
      data: {
        dailyScorePage
      }
    });
  };
};

export const setMonthlyScorePage = monthlyScorePage => {
  return dispatch => {
    dispatch({
      type: SET_MONTHLY_SCORE_PAGE,
      data: {
        monthlyScorePage
      }
    });
  };
};

export const setNotesAction = noteAction => {
  return dispatch => {
    dispatch({
      type: SET_NOTE_ACTION,
      data: {
        noteAction
      }
    });
  };
};

export const getDailyScores = ({ userType, cancelToken, queryParams }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.DAILYSCORES_SYSTEM_USER_URL
            : apiUrls.DAILYSCORES_INDIVIDUAL_USER_URL
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_MONTHLYSCORES"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getSystemUserNotes = ({ cancelToken, queryParams }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.NOTES_SYSTEM_USER_URL
      });
      try {
        // const response = await http.get(url);
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_NOTES_SYSTEM_USER"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getNonSystemUserNotes = ({ cancelToken, queryParams }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.NOTES_NON_SYSTEM_USER_URL
      });
      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_NOTES_NON_SYSTEM_USER")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const updateFileNotesAsSystemUser = ({
  id,
  payLoad,
  cancelToken,
  profileType
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          profileType === "Lender"
            ? apiUrls.UPDATE_LENDER_FILE_NOTES_AS_SYSTEM_ADMIN_URL
            : profileType === "Law Firm" ? apiUrls.UPDATE_LAWFIRM_FILE_NOTES_AS_SYSTEM_ADMIN_URL : apiUrls.UPDATE_BROKER_FILE_NOTES_AS_SYSTEM_ADMIN_URL,
        options: { id }
      });
      let scoreQueryParams = {
        page: 1,
        pageSize: 2
      };
      try {
        const response = await http.put(url, payLoad, cancelToken);
        resolve(processRequest(dispatch, response, "SET_NOTE_ACTION"));
        if (response.data === "") {
          getDailyScores({
            userType: ADMIN_USER,
            cancelToken: cancelToken,
            queryParams: scoreQueryParams
          });
        }
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const updateFileNotesAsNonSystemUser = ({
  id,
  payLoad,
  cancelToken,
  profileType
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          profileType === "Lender"
            ? apiUrls.UPDATE_LENDER_FILE_NOTES_AS_INDIVIDUAL_URL
            : profileType === "Law Firm" ? apiUrls.UPDATE_LAWFIRM_FILE_NOTES_AS_INDIVIDUAL_URL: apiUrls.UPDATE_BROKER_FILE_NOTES_AS_INDIVIDUAL_URL,
        options: { id }
      });
      try {
        const response = await http.put(url, payLoad, cancelToken);
        resolve(processRequest(dispatch, response, "SET_NOTE_ACTION"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteFileNotesAsSystemUser = ({
  id,
  payLoad,
  cancelToken,
  profileType
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          profileType === "Lender"
            ? apiUrls.DELETE_LENDER_FILE_NOTES_AS_SYSTEM_ADMIN_URL
            : profileType === "Law Firm" ? apiUrls.DELETE_LAWFIRM_FILE_NOTES_AS_SYSTEM_ADMIN_URL : apiUrls.DELETE_BROKER_FILE_NOTES_AS_SYSTEM_ADMIN_URL,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payLoad && payLoad.id}`,
          payLoad,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "SET_NOTE_ACTION"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteFileNotesAsNonSystemUser = ({
  id,
  payLoad,
  cancelToken,
  profileType
}) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("Id is required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint:
          profileType === "Lender"
            ? apiUrls.DELETE_LENDER_FILE_NOTES_AS_INDIVIDUAL_URL
            : profileType === "Law Firm" ? apiUrls.DELETE_LAWFIRM_FILE_NOTES_AS_INDIVIDUAL_URL : apiUrls.DELETE_BROKER_FILE_NOTES_AS_INDIVIDUAL_URL,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payLoad && payLoad.id}`,
          payLoad,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "SET_NOTE_ACTION"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};
