import { Button, Modal } from "antd";
import React, { useContext } from "react";
import { mustBeArray } from "../../../libs";
import AddedBrokersDisplayAndUpdate from "./AddedBrokersDisplayAndUpdate";
import LoanCreateContext from "./LoanCreateContext";

const AddBrokersModal = ({
  list,
  loanBrokers,
  handleAdd,
  handleDelete,
  handleChange,
  handleBrokerChange,
  handleSave,
  handleDeleteAll,
  disabled = false
}) => {
  const {brokersAddModal, handleBrokersModalClose} = useContext(LoanCreateContext)
  return (
    <Modal visible={brokersAddModal.visible} width={500}
      footer={
        <div className="flex space-between">
          <span>
             <Button type="primary" onClick={handleSave} className="m-r-10" disabled={disabled}>OK</Button>
             <Button onClick={handleBrokersModalClose}>Cancel</Button>
          </span>
          <Button className="bg-danger" onClick={handleDeleteAll} disabled={disabled}>Delete All</Button>
        </div>
      }
      onCancel={handleBrokersModalClose}
    >
      <div className="m-b-8 m-l-8">Attributed Brokers</div>
      {mustBeArray(loanBrokers).map((data, i) => {
        return (
          <AddedBrokersDisplayAndUpdate
            data={data}
            key={i}
            index={i}
            list={list}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleChange={handleChange}
            handleBrokerChange={handleBrokerChange}
            loanBrokers={loanBrokers}
            disabled={disabled}
          />
        );
      })}
    </Modal>
  );
};
export default AddBrokersModal;
