import { Button, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import NumberInput from "../../../Common/NumberInput";
import { ILookupItem } from "../../../libs/types";
import { mustBeArray } from "../../../libs";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { loansSubdivisionTypeLookups } from "../../../actions/lookup";

interface Subdivision {
  typeKey: string | null;
  type: string | null;
  presalesDebtCoverRequired: string;
  loanAmountPercentageGstEx: number | null;
  qsRequired: string;
  isBuilderDeveloper: string;
  isBuildToHold: string;
  tdcEquity: number | null;
  projectManagerRequired: string;
  contingenciesAllowance: number | null;
  developerSuccessfulProjects: number | null;
}

interface SubdivisionModalProps {
  loanDetails: any;
  handleFieldChange?: (payload: { field: string; value: any }) => void;
  generalView?: boolean;
  className?: string;
}

const YesNoOptions = [
  {
    label: "Yes",
    value: "true"
  },
  {
    label: "No",
    value: "false"
  }
];

const defaultSubdivisionDetails: Subdivision = {
  typeKey: null,
  type: "",
  presalesDebtCoverRequired: "false",
  loanAmountPercentageGstEx: null,
  qsRequired: "false",
  isBuilderDeveloper: "false",
  isBuildToHold: "false",
  tdcEquity: null,
  projectManagerRequired: "false",
  contingenciesAllowance: null,
  developerSuccessfulProjects: 0
};

function SubdivisionModal({
  loanDetails,
  handleFieldChange = () => {},
  generalView = false,
  className = ""
}: SubdivisionModalProps) {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState<ILookupItem[]>([]);
  const [subdivision, setSubdivision] = useState<Subdivision>(
    defaultSubdivisionDetails
  );

  function loadDetails() {
    if (loanDetails.subdivisionScenario) {
      setSubdivision({
        typeKey: loanDetails.subdivisionScenario.typeKey || null,
        type: loanDetails.subdivisionScenario.type || null,
        presalesDebtCoverRequired: toStringValue(
          loanDetails.subdivisionScenario.presalesDebtCoverRequired
        ),
        loanAmountPercentageGstEx:
          loanDetails.subdivisionScenario.loanAmountPercentageGstEx || null,
        qsRequired: toStringValue(loanDetails.subdivisionScenario.qsRequired),
        isBuilderDeveloper: toStringValue(
          loanDetails.subdivisionScenario.isBuilderDeveloper
        ),
        isBuildToHold: toStringValue(
          loanDetails.subdivisionScenario.isBuildToHold
        ),
        tdcEquity: loanDetails.subdivisionScenario.tdcEquity || null,
        projectManagerRequired: toStringValue(
          loanDetails.subdivisionScenario.projectManagerRequired
        ),
        contingenciesAllowance:
          loanDetails.subdivisionScenario.contingenciesAllowance || null,
        developerSuccessfulProjects:
          loanDetails.subdivisionScenario.developerSuccessfulProjects || null
      });
    } else {
      setSubdivision(defaultSubdivisionDetails);
    }
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function toBoolean(value: string) {
    return value === "true";
  }

  function toStringValue(value: boolean | undefined | null) {
    return value ? "true" : "false";
  }

  function typeCheckConvert(
    value: string | number | null,
    regularString: boolean = false
  ) {
    return typeof value === "string" && !regularString
      ? toBoolean(value)
      : value;
  }

  function handleLocalChange(field: string, value: string | number) {
    setSubdivision(s => ({
      ...s,
      [field]: value
    }));
  }

  function handleOk() {
    if (!generalView) {
      const payload = {
        typeKey: subdivision.typeKey || null,
        presalesDebtCoverRequired: typeCheckConvert(
          subdivision.presalesDebtCoverRequired
        ),
        loanAmountPercentageGstEx: typeCheckConvert(
          subdivision.loanAmountPercentageGstEx
        ),
        qsRequired: typeCheckConvert(subdivision.qsRequired),
        isBuilderDeveloper: typeCheckConvert(subdivision.isBuilderDeveloper),
        isBuildToHold: typeCheckConvert(subdivision.isBuildToHold),
        tdcEquity: typeCheckConvert(subdivision.tdcEquity),
        projectManagerRequired: typeCheckConvert(
          subdivision.projectManagerRequired
        ),
        contingenciesAllowance: typeCheckConvert(
          subdivision.contingenciesAllowance
        ),
        developerSuccessfulProjects: typeCheckConvert(
          subdivision.developerSuccessfulProjects
        )
      };

      handleFieldChange({
        field: "subdivisionScenario",
        value: payload
      });
    }

    closeModal();
  }

  function handleCancel() {
    closeModal();
    loadDetails();
  }

  useEffect(() => {
    loadDetails();
  }, [loanDetails]);

  useEffect(() => {
    if (!generalView) {
      dispatch(loansSubdivisionTypeLookups({ cancelToken: signal.token })).then(
        x => {
          setTypeOptions(mustBeArray(x));
        }
      );
    }

    return () => {
      signal.cancel();
    };
  }, [generalView]);

  return (
    <>
      <Button className={className} onClick={openModal}>
        Subdivision
      </Button>
      <Modal
        visible={isModalOpen}
        width={500}
        onCancel={handleCancel}
        footer={
          <div className="flex space-between">
            <span>
              <Button type="primary" onClick={handleOk} className="m-r-10">
                OK
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </span>
          </div>
        }
      >
        <div className="m-b-8 m-l-8">Subdivision</div>
        <div className="flex flex-align-center m-b-10">
          <span>Type</span>
          {generalView ? (
            <Input
              className="w-300 m-l-6"
              value={subdivision.type || undefined}
              disabled
            />
          ) : (
            <Select
              className="w-300 m-l-6"
              value={subdivision.typeKey || undefined}
              onChange={(v: string) => handleLocalChange("typeKey", v)}
              allowClear
              disabled={generalView}
            >
              {typeOptions.map(data => {
                return (
                  <Select.Option key={data.id} value={data.id}>
                    {data.name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Presales debt cover required?</span>
          <Select
            className="w-200 m-l-6"
            value={subdivision.presalesDebtCoverRequired}
            onChange={(v: string) =>
              handleLocalChange("presalesDebtCoverRequired", v)
            }
            disabled={generalView}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        {subdivision.presalesDebtCoverRequired === "true" && (
          <div className="flex flex-align-center m-b-10">
            <span>Percentage of loan amount exclusive of GST</span>
            <NumberInput
              formatter={(value: any) => `${value}%`}
              parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
              className="m-l-6 hideNumberPointers"
              value={subdivision.loanAmountPercentageGstEx}
              onBlur={() => {}}
              onChange={(value: number) =>
                handleLocalChange("loanAmountPercentageGstEx", value)
              }
              placeholder={""}
              maxLength={undefined}
              maxValue={undefined}
              disabled={generalView}
            />
          </div>
        )}
        <div className="flex flex-align-center m-b-8">
          <span>QS required</span>
          <Select
            className="w-200 m-l-6"
            value={subdivision.qsRequired}
            onChange={(v: string) => handleLocalChange("qsRequired", v)}
            disabled={generalView}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Builder/Developer</span>
          <Select
            className="w-200 m-l-6"
            value={subdivision.isBuilderDeveloper}
            onChange={(v: string) => handleLocalChange("isBuilderDeveloper", v)}
            disabled={generalView}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Build to hold</span>

          <Select
            className="w-200 m-l-6"
            value={subdivision.isBuildToHold}
            onChange={(v: string) => handleLocalChange("isBuildToHold", v)}
            disabled={generalView}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Equity percentage of total development costs* (TDC) %</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={subdivision.tdcEquity}
            onBlur={() => {}}
            onChange={(value: number) => handleLocalChange("tdcEquity", value)}
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
            disabled={generalView}
          />
        </div>
        <div>
          <p className="f-s-11">
            *Residual land value, post DA professional fees, council fees and
            contributions, SBBIS bond, contract sum including builder's margin,
            contingency allowance, project manager costs, provision for 50% of
            agents presales, capitalised interest and line fee, holding costs
            during construction (land tax and rates), project marketing costs
          </p>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Project manager required</span>

          <Select
            className="w-200 m-l-6"
            value={subdivision.projectManagerRequired}
            onChange={(v: string) =>
              handleLocalChange("projectManagerRequired", v)
            }
            disabled={generalView}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Contingencies allowance percentage of construction sum</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={subdivision.contingenciesAllowance}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange("contingenciesAllowance", value)
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
            disabled={generalView}
          />
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Developer past successful projects (number up to 100)</span>
          <NumberInput
            formatter={(value: any) => `${value}`}
            parser={(value: string) => value}
            className="m-l-6 hideNumberPointers"
            value={subdivision.developerSuccessfulProjects}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange(
                "developerSuccessfulProjects",
                value > 100 ? 100 : value
              )
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={100}
            disabled={generalView}
          />
        </div>
      </Modal>
    </>
  );
}

export default SubdivisionModal;
