import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getBrokerMatches } from "../../actions/matches";
import { brokersLookUpHandler } from "../../actions/lookup";
import MatchesList from "./MatchesList";
import { GET_BROKER_MATCHES_STATUS_LOOKUP } from "../../constants/action-type/brokers";
import { Visible } from "../Common/visiblityWrapper";
import { Spin } from "antd";

const BrokerMatchesImpl = ({
  dispatch,
  profileType,
  userType,
  activeTab,
  brokerId,
  user,
  match
}) => {
  function getActiveKey() {
    if (activeTab === "matches") {
      return 1;
    }

    return 999;
  }

  async function getMatches(options) {
    return await dispatch(
      getBrokerMatches({
        id: options.id,
        queryParams: options.queryParams,
        userType
      })
    );
  }

  async function getLookup(options) {
    return await dispatch(
      brokersLookUpHandler({
        ...options,
        action: GET_BROKER_MATCHES_STATUS_LOOKUP
      })
    );
  }

  return (
    <Fragment>
      <Visible visible={userType === null}>
        <Spin />
      </Visible>
      <Visible visible={userType !== null}>
        <MatchesList
          userType={userType}
          activeKey={getActiveKey()}
          listLendersMatches={opt => getMatches(opt)}
          listLendersMatchesLookup={opt => getLookup(opt)}
          userId={brokerId}
          userType={userType}
          profileType={profileType}
          match={match}
          user={user}
          dispatch={dispatch}
        />
      </Visible>
    </Fragment>
  );
};

export const BrokerMatchesList = connect(state => ({
  user: state.authorization.user
}))(BrokerMatchesImpl);
