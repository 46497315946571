import { Button, Modal, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import NumberInput from "../../../Common/NumberInput";
import { FinderFilterFieldChangeHandlerParams } from "../../../libs/types";
import FinderUtilityContext from "../FinderUtilityContext";

interface Construction {
  presalesDebtCoverRequired: string;
  loanAmountPercentageGstEx: number | null;
  qsRequired: string;
  isBuilderDeveloper: string;
  isBuildToHold: string;
  tdcEquity: number | null;
  projectManagerRequired: string;
  contingenciesAllowance: number | null;
  developerSuccessfulProjects: number | null;
  preCommittedCommercialTenants: string;
}

interface ConstructionProps {
  value: Construction | undefined;
  handleFieldChange: (
    payload: FinderFilterFieldChangeHandlerParams<any>
  ) => void;
}

function Construction({
  value,
  handleFieldChange = () => {}
}: ConstructionProps) {
  const { yesNoList }: any = useContext(FinderUtilityContext);

  const [construction, setConstruction] = useState<Construction>({
    presalesDebtCoverRequired: "",
    loanAmountPercentageGstEx: null,
    qsRequired: "",
    isBuilderDeveloper: "",
    isBuildToHold: "",
    tdcEquity: null,
    projectManagerRequired: "",
    contingenciesAllowance: null,
    developerSuccessfulProjects: 0,
    preCommittedCommercialTenants: ""
  });

  function handleLocalChange(field: string, value: string | number) {
    setConstruction(s => ({
      ...s,
      [field]: value
    }));
    const payload = {
      presalesDebtCoverRequired: construction.presalesDebtCoverRequired,
      loanAmountPercentageGstEx: construction.loanAmountPercentageGstEx,
      qsRequired: construction.qsRequired,
      isBuilderDeveloper: construction.isBuilderDeveloper,
      isBuildToHold: construction.isBuildToHold,
      tdcEquity: construction.tdcEquity,
      projectManagerRequired: construction.projectManagerRequired,
      contingenciesAllowance: construction.contingenciesAllowance,
      developerSuccessfulProjects: construction.developerSuccessfulProjects,
      preCommittedCommercialTenants: construction.preCommittedCommercialTenants,
      [field]: value // Overwrites the field with same key
    };
    handleFieldChange({
      field: "constructionScenario",
      value: payload
    });
  }

  useEffect(() => {
    if (value) {
      setConstruction({
        ...value,
        presalesDebtCoverRequired: value.presalesDebtCoverRequired
          ? value.presalesDebtCoverRequired.toString()
          : "",
        qsRequired: value.qsRequired ? value.qsRequired.toString() : "",
        isBuilderDeveloper: value.isBuilderDeveloper
          ? value.isBuilderDeveloper.toString()
          : "",
        isBuildToHold: value.isBuildToHold
          ? value.isBuildToHold.toString()
          : "",
        projectManagerRequired: value.projectManagerRequired
          ? value.projectManagerRequired.toString()
          : "",
        preCommittedCommercialTenants: value.preCommittedCommercialTenants
          ? value.preCommittedCommercialTenants.toString()
          : ""
      });
    }
  }, [value]);

  return (
    <>
      <div className="flex flex-align-center m-b-10">
        <span>Presales debt cover required?</span>
        <Select
          className="w-200 m-l-6"
          value={construction.presalesDebtCoverRequired}
          onChange={(v: string) =>
            handleLocalChange("presalesDebtCoverRequired", v || "")
          }
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {construction.presalesDebtCoverRequired === "true" && (
        <div className="flex flex-align-center m-b-10">
          <span>Percentage of loan amount exclusive of GST</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={construction.loanAmountPercentageGstEx}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange("loanAmountPercentageGstEx", value)
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
          />
        </div>
      )}
      <div className="flex flex-align-center m-b-8">
        <span>QS required</span>
        <Select
          className="w-200 m-l-6"
          value={construction.qsRequired}
          onChange={(v: string) => handleLocalChange("qsRequired", v || "")}
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Builder/Developer</span>
        <Select
          className="w-200 m-l-6"
          value={construction.isBuilderDeveloper}
          onChange={(v: string) =>
            handleLocalChange("isBuilderDeveloper", v || "")
          }
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Build to hold</span>

        <Select
          className="w-200 m-l-6"
          value={construction.isBuildToHold}
          onChange={(v: string) => handleLocalChange("isBuildToHold", v || "")}
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Equity percentage of total development costs* (TDC) %</span>
        <NumberInput
          formatter={(value: any) => `${value}%`}
          parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
          className="m-l-6 hideNumberPointers"
          value={construction.tdcEquity}
          onBlur={() => {}}
          onChange={(value: number) => handleLocalChange("tdcEquity", value)}
          placeholder={""}
          maxLength={undefined}
          maxValue={undefined}
          disabled={undefined}
        />
      </div>
      <div>
        <p className="f-s-11">
          *Residual land value, post DA professional fees, council fees and
          contributions, SBBIS bond, contract sum including builder's margin,
          contingency allowance, project manager costs, provision for 50% of
          agents presales, capitalised interest and line fee, holding costs
          during construction (land tax and rates), project marketing costs
        </p>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Project manager required</span>

        <Select
          className="w-200 m-l-6"
          value={construction.projectManagerRequired}
          onChange={(v: string) =>
            handleLocalChange("projectManagerRequired", v || "")
          }
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Contingencies allowance percentage of construction sum</span>
        <NumberInput
          formatter={(value: any) => `${value}%`}
          parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
          className="m-l-6 hideNumberPointers"
          value={construction.contingenciesAllowance}
          onBlur={() => {}}
          onChange={(value: number) =>
            handleLocalChange("contingenciesAllowance", value)
          }
          placeholder={""}
          maxLength={undefined}
          maxValue={undefined}
          disabled={undefined}
        />
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Developer past successful projects (number up to 100)</span>
        <NumberInput
          formatter={(value: any) => `${value}`}
          parser={(value: string) => value}
          className="m-l-6 hideNumberPointers"
          value={construction.developerSuccessfulProjects}
          onBlur={() => {}}
          onChange={(value: number) =>
            handleLocalChange(
              "developerSuccessfulProjects",
              value > 100 ? 100 : value
            )
          }
          placeholder={""}
          maxLength={undefined}
          maxValue={100}
          disabled={undefined}
        />
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Pre-committed commercial tenants</span>

        <Select
          className="w-200 m-l-6"
          value={construction.preCommittedCommercialTenants}
          onChange={(v: string) =>
            handleLocalChange("preCommittedCommercialTenants", v || "")
          }
          allowClear
        >
          {yesNoList.map((data: { id: string; name: string }) => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </>
  );
}

export default Construction;
