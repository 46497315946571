import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMCE_APIKEY } from "../../constants/api_key";

/**
 * @typedef TemplateLoaderProps
 * @property {string=} initialValue
 * @property {string=} value
 * @property {(value: string) => void=} onChange
 * @property {boolean=} disabled
 *
 * @param {TemplateLoaderProps} props
 * @returns {JSX.Element}
 *
 * Toolbar options can be found in old TinyMCE docs: https://www.tiny.cloud/docs-3x/reference/buttons/#defaultbuttonsavailableintheadvancedtheme
 *
 * Plugin options: https://www.tiny.cloud/docs-3x/reference/TinyMCE3x@Plugins/
 */
const TemplateLoader = ({
  initialValue = "",
  value = "",
  onChange = v => {},
  disabled
}) => {
  const editorRef = useRef(null);

  return (
    <Editor
      disabled={disabled}
      initialValue={initialValue || ""}
      value={value}
      // {state.content}
      onInit={(a, editor) => (editorRef.current = editor)}
      init={{
        plugins: "link image code lists wordcount table", //preview table
        toolbar:
          "undo redo |  fontsizeselect fontselect | bold italic underline strikethrough blockquote | alignleft aligncenter alignright | bullist numlist | outdent indent | sizeselect | table code | sup sub | forecolor backcolor forecolorpicker backcolorpicker | image link unlink",
        // skin: "lightgray", // table | preview code |
        // theme: "modern",
        relative_urls: true,
        remove_script_host: false,
        convert_urls: true,
        fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
        height: "40vh",
        visual: false
      }}
      onEditorChange={(v)=>onChange(v)} // Reference for cursor reset on pressing ENTER: https://github.com/tinymce/tinymce-react/issues/88
      apiKey={TINYMCE_APIKEY}
    />
  );
};

export default TemplateLoader;
