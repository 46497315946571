import { Select } from "antd";
import React, { useContext } from "react"; 
import FinderUtilityContext from "../FinderUtilityContext";

const RadiusLookup = ({ value, handleFieldChange }) => { 
  const {radiusList} = useContext(FinderUtilityContext); 

  return (
      <Select
        className="w-260 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "radius", value })}
        allowClear={false}
        filterOption={false}
      >
        {radiusList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
  );
};

export default RadiusLookup;
