import { BROKER_USER, LENDER_USER } from "../../../constants/systemUsers";
import { convertCurrenyToHighestFigure } from "../../../libs";
import LabelValuePair from "./LabelValuePair";
import { List } from "antd";
import React from 'react';

const KeyMetrics = ({ user, userType }) => {
  const {
    dischargedLoansCount,
    currentLoanBook,
    dischargedLoanBook,
    currentLoansCount,
    shortfall, 
    positiveLenderReviewsCount,
    positiveBrokerReviewsCount,
    positiveReviewsCount
  } = user;
  const userTypeText = userType === LENDER_USER ? "Broker" : userType === BROKER_USER ? "Lender" : "Financier";
  return (
    <div className="flex-1-1-47 flex m-b-6 p-l-10 keyMetric-list align-self-center">
      <List bordered className="list-1">
        <List.Item>
          <LabelValuePair
            value={convertCurrenyToHighestFigure(currentLoanBook)}
            label="Current Loan Book"
            valueClassName="color-primary"
          />
        </List.Item>
        <List.Item>
        <LabelValuePair
          value={convertCurrenyToHighestFigure(dischargedLoanBook)}
          label="Discharged Loan Book"
          valueClassName="color-primary"
        />
        </List.Item>
        <List.Item>
        <LabelValuePair
          value={currentLoansCount}
          label="Current Loans"
          valueClassName="color-primary"
        />
        </List.Item>
      </List>
      <List bordered className="list-2">
        <List.Item>
        <LabelValuePair
          value={dischargedLoansCount}
          label="Discharged Loans"
          valueClassName="color-primary"
          smallValue
        />
        </List.Item>
        <List.Item>
        <LabelValuePair
          value={`${shortfall}%`}
          label="Shortfalls (of capital loaned)"
          valueClassName="color-primary"
          smallValue
        />
        </List.Item>
        <List.Item>
        <LabelValuePair
          value={
            userType === LENDER_USER
              ? positiveBrokerReviewsCount
              : userType === BROKER_USER ? positiveLenderReviewsCount : positiveReviewsCount
          }
          label={`Positive ${userTypeText} Reviews`}
          valueClassName="color-primary"
          smallValue
        />
        </List.Item>
      </List>  
    </div>
  );
};

export default KeyMetrics;
