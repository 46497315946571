import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { getLawFirmProfile, masterSwitchTurnOff, masterSwitchTurnOn, profileStatusTurnOff, 
        profileStatusTurnOn, updateIndividualProfileV2, updateProfileLoading } from "../../actions/profile";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import _, { isUndefined } from "lodash";
import {
  ALL_LOANS_OPTION,
  LAWFIRM_PROFILE_ROUTES_SYSTEM_USER,
  EMAIL_SUBSCRIPTIONS_OPTION_BROKER_USER,
  PROFILE_EDIT_OPTION,
  CAN_RESET_PROFILE_PASSWORD,
  PROFILE_HAS_NOT_ACCEPTED_INVITATION
} from "../../constants/routeOptions";
import { getProfileStatusWarning } from "../../libs/user";
import { LAW_FIRM_MASTER_SWITCH_OFF_CONFIRMATION_TEXT, LAW_FIRM_MASTER_SWITCH_ON_CONFIRMATION_TEXT } from "../../constants/warnings";
import { LAW_FIRM_USER } from "../../constants/systemUsers";
import { message } from "antd";
import { errorDisplay, mustBeArray } from "../../libs";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import history from "../../routes/history";

export const useProfile = ({ userType, match, form }) => {
  const [loading, setLoading] = useState(true);
  const [portraitsUpdated, setPortraitsUpdated] = useState(false);
  const [activeTab, setActiveTab] = useState("fileNotes");
  const [activeSubTab, setActiveSubTab] = useState("preSettlementLoans");
  const [canToggleProfileSwitch, setCanToggleProfileSwitch] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [confirmationText, setConfirmationText] = useState("");
  const [activeField, setActiveField] = useState("");
  const [current, setCurrent] = useState(1);
  const [currentPortrait, setCurrentPortrait] = useState(null);
  const [isSystemUser, setIsSystemUser] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [saveLoading, setSaveLoading] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  const profile = useSelector(state => state.profile?.lawFirm);
  const user = useSelector(state => state.authorization?.user);
  const member = useSelector(state => state.member);
  const header = useSelector(state => state.member?.header);
  const individualUser = useSelector(state => state.authorization && state.authorization.individualUser);
  const dispatchAction = useDispatch();

  const loansSearch = useLocation().search;
  const loanSearchQueryParams = loansSearch?.length
    ? queryString.parse(loansSearch)
    : null;

  useEffect(() => {
    // Set as false by default to prevent checking on notes button when page loads slower
    dispatchAction(
      updateHeader({
        header: {
          ...(header ?? {}),
          showSettings: false,
          showNotesButton: false,
          showPageTitle: false,
          showSearchBox: false
        }
      })
    );
    if (!initialized) {
      loadUserInformation();
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    loadUserInformation();
  }, [match.params.id]);

  // Override updateRouteOptions from components/Profile/protrait-view
  useEffect(() => {
    let userId = user["app.user.lawfirmid"];
    // Update active tab to loans if viewing own profile
    const id = match?.params?.id;
    const isOwnProfile = Number(userId) === Number(id);
    if(isOwnProfile){
      setActiveTab("loans")
    }
    // Profile Edit Option available only to SystemUsers or own profile
    const routes = isSystemUser || isOwnProfile ? [...LAWFIRM_PROFILE_ROUTES_SYSTEM_USER, ...PROFILE_EDIT_OPTION] : LAWFIRM_PROFILE_ROUTES_SYSTEM_USER;
    if(currentPortrait){
      let updatedOptions = routes;
      if(currentPortrait.hasAcceptedInvitation){
        updatedOptions = [...routes.filter(o => o.id != CAN_RESET_PROFILE_PASSWORD[0].id && o.id != PROFILE_HAS_NOT_ACCEPTED_INVITATION[0].id), CAN_RESET_PROFILE_PASSWORD[0]];
      }
      else {
        updatedOptions = [...routes.filter(o => o.id != CAN_RESET_PROFILE_PASSWORD[0].id && o.id != PROFILE_HAS_NOT_ACCEPTED_INVITATION[0].id), PROFILE_HAS_NOT_ACCEPTED_INVITATION[0]];
      }

      updatedOptions = updatedOptions.map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });
      dispatchAction(
        updateRouteOptions({
          routeOptions: updatedOptions
        })
      );
      return;
    }
  }, [currentPortrait, isSystemUser, user]);

  useEffect(()=>{
    dispatchAction(updateHeader({
      header: {
        ...header,
        showDeactivatedMessage: !isSystemUser && (userProfile.isMasterSwitchOn === undefined ? false : !userProfile.isMasterSwitchOn),
      }
    }))
  },[userProfile, isSystemUser])

  const redirectLawFirmOnViewOther = (userId = 0) => {
    history.push(`/users/${userId}/lawfirm`);
    return;
  }

  const loadUserInformation = async (updatedEditMode=undefined) => {
    setLoading(true);
    setCurrent(1);
    const id = match?.params?.id;
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isLawFirmUser = await isLawFirmUserPolicy(user.roles,
      user.permissions,
      user
    );
    setIsSystemUser(systemUser);
    let userId = user["app.user.lawfirmid"];

    // Note:
    // Update active tab to loans if viewing own profile
    const isOwnProfile = Number(userId) === Number(id);
    if(isOwnProfile){
      setActiveTab("loans")
    }
    setIsOwnProfile(isOwnProfile);
    setCanToggleProfileSwitch(systemUser || isOwnProfile);

    if(isLawFirmUser && !isOwnProfile){
      redirectLawFirmOnViewOther(userId);
    }
    else {
      const resp = await dispatchAction(
        getLawFirmProfile({
          id,
          isUserViewingSelfProfile: isOwnProfile,
          isAdmin: systemUser
        })
      );
  
      if (resp.status && resp.data) {
        dispatchAction(
          updateHeader({
            header: {
              ...(header ?? {}),
              title: `${resp.data.firstname} ${resp.data.lastname}`,
              hasSwitchProfile: false,
              showOmicronBDM: false,
              showNotesButton: !isLawFirmUser,
              showMasterSwitch: true,
              showIsProfileActive: false,
              showSettings: true,
              omicronBdm: {},
              showPageTitle: true,
              editMode: isUndefined(updatedEditMode) ? header?.editMode : updatedEditMode,
              showSearchBox: !isLawFirmUser,
              showDeactivatedMessage: !systemUser ? !resp.data.isMasterSwitchOn : false,
            }
          })
        );
  
        const sortedPortraits = _.sortBy(
          resp.data.portraits ? resp.data.portraits : [],
          "appearanceOrder"
        );
        setUserProfile({ ...resp.data, portraits: sortedPortraits });
        setCurrentPortrait(sortedPortraits[0]);
        setPortraitsUpdated(!portraitsUpdated);
      }
      setLoading(false);
      dispatchAction(updateProfileLoading(false));
    }
    
  };

  const handleFormChange = ({ label, value }) => {
    if (
      label === "website" ||
      label === "companyName" ||
      label === "companyACN" ||
      label === "previewCompanyLogo" ||
      label === "portraits"
    ) {
      setUserProfile({ ...userProfile, [label]: value });
      setPortraitsUpdated(label === "portraits" ? !portraitsUpdated : portraitsUpdated);
      return;
    }
    const updatedPortraits = [...userProfile.portraits].map((portrait, i) => {
      if (i === current - 1) {
        portrait[label] = value;
      }
      return portrait;
    });
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
  };

  const handleRemove = () => {
    const updatedPortraits = [...userProfile.portraits].filter(
      (_portrait, i) => i !== current - 1
    );
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
    setCurrent(1);
    setPortraitsUpdated(!portraitsUpdated);

    message.success("User successfully deleted from this Profile and the Platform.");
  };

  const handleProfileActiveChange = () => {
    const warningMessage = getProfileStatusWarning({
      profileType: LAW_FIRM_USER,
      userType,
      isProfileActive: !!profile?.isProfileActive
    });
    setConfirmationText(warningMessage);
    setActiveField("isProfileActive");
    setConfirmationModalVisible(true);
  };

  const handleMasterSwitchChange = () => {
    const text = profile?.isMasterSwitchOn
      ? LAW_FIRM_MASTER_SWITCH_OFF_CONFIRMATION_TEXT
      : LAW_FIRM_MASTER_SWITCH_ON_CONFIRMATION_TEXT;
    setConfirmationText(text);
    setActiveField("isMasterSwitchActive");
    setConfirmationModalVisible(true);
  };

  const handleConfirm = () => {
    activeField === "isProfileActive"
      ? handleConfirmProfileActiveChange()
      : handleConfirmMasterSwitchChange();
    handleModalClose();
  };

  const handleSave = () => {
    form.validateFields(err => {
      if (err) {
        console.log(err, "error");
        return;
      }
      setSaveLoading(true);
      const updatedProfile = {
        portraits: userProfile.portraits,
        website: userProfile.website,
        omicronBdmId: header?.omicronBdm?.id,
        logoFile: userProfile.logoFile,
        individualId: userProfile.individualId,
        companyACN: userProfile.companyACN,
        companyName: userProfile.companyName,
        previewCompanyLogo: userProfile.previewCompanyLogo
      };
      dispatchAction(updateIndividualProfileV2(updatedProfile, isSystemUser, LAW_FIRM_USER, profile?.id)).then(
        resp => {
          setCurrent(1);
          setCurrentPortrait(userProfile.portraits[0]);
          setSaveLoading(false);
          if (resp.status) {
            message.success("Profile updated successfully.");
           dispatchAction(updateHeader({header: {...(header ?? {}), editMode: false, showNotesButton: true, showPageTitle: true}})).then(() => loadUserInformation(false));
          }
        }
      );
    });
  };

  const handleConfirmProfileActiveChange = () => {
    const actionName = !!profile?.isProfileActive
      ? profileStatusTurnOff
      : profileStatusTurnOn;
    const actionMessage = !!profile?.isProfileActive
      ? "Profile paused successfully."
      : "Profile set to active successfully.";
    dispatchAction(actionName({ id: profile?.id, profileType: LAW_FIRM_USER })).then(resp => {
      handleModalClose();
      if (resp.status) {
        message.success(actionMessage);
        loadUserInformation();
      } else {
        errorDisplay(resp?.data?.errors);
      }
    });
  };

  const handleConfirmMasterSwitchChange = () => {
    const actionName = profile?.isMasterSwitchOn
      ? masterSwitchTurnOff
      : masterSwitchTurnOn;
    const actionMessage = profile?.isMasterSwitchOn ? "off" : "on";
    dispatchAction(
      actionName({ id: profile?.companyId, profileType: LAW_FIRM_USER })
    ).then(resp => {
      if (resp.status) {
        message.success(`Master switch turned ${actionMessage} successfully.`);
        loadUserInformation();
      }
    });
  };

  const handleModalClose = () => {
    setConfirmationModalVisible(false);
    setActiveField("");
    setConfirmationText("");
  };

  return {
    loading,
    setLoading,
    portraitsUpdated,
    setPortraitsUpdated,
    activeTab,
    setActiveTab,
    activeSubTab,
    setActiveSubTab,
    canToggleProfileSwitch,
    setCanToggleProfileSwitch,
    confirmationModalVisible,
    activeField,
    setActiveField,
    current,
    setCurrent,
    currentPortrait,
    setCurrentPortrait,
    isSystemUser,
    setIsSystemUser,
    userProfile,
    member,
    saveLoading,
    confirmationText,
    handleFormChange,
    handleRemove,
    handleProfileActiveChange,
    handleMasterSwitchChange,
    handleModalClose,
    handleConfirm,
    handleSave,
    user,
    dispatchAction,
    activeSubTab,
    setActiveSubTab,
    loanSearchQueryParams,
    isOwnProfile
  };
};
