import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import authService from "../../actions/AuthorizeService";
import { QueryParameterNames } from "../../constants/ApiAuthorizationConstants";
import { Spin } from "antd";

class AuthorizedRoute extends Component {
  _subscription = {};

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false
    };
  }

  async componentDidMount() {
    await authService.ensureUserManagerInitialized();
    this._subscription = authService.subscribe(() =>
      this.authenticationChanged()
    );
    await this.authenticationChanged();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = this.props.location.pathname;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `/login?${QueryParameterNames.ReturnUrl}=${encodeURI(
      returnUrl
    )}`;

    const children = this.props.children;

    if (!ready) {
      return (
        <Fragment>
          <Spin />
        </Fragment>
      );
    } else {
      if (authenticated) {
        return <Fragment>{children}</Fragment>;
      } else {
        return <Redirect to={redirectUrl} />;
      }
    }
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.authorization.isLoggedIn
});

export default connect(mapStateToProps)(AuthorizedRoute);
