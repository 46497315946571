import React from 'react';
import {Spin} from 'antd';

const Loading = (props) => {
  return (
    <Spin size={props?.spinSize || 'default'} className={props?.className || ""} tip={props.title || ""}/>
  )
}

export default Loading;
