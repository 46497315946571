import { message, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import { saveProfile } from "../../actions/profile";
import { getUsersList, deleteUser, updateUser } from "../../actions/users";
import { positionsLookup } from "../../actions/lookup";
import axios from "axios";
import { errorDisplay, mustBeArray, delay } from "../../libs/utilities";
import UsersList from "../../components/Users/UsersList";
import _ from "lodash";
import { DEFAULT_ROUTES_SYSTEM_USER } from "../../constants/routeOptions";

const userTypes = ["CURRENT", "FORMER"];

class SystemUsersList extends Component {
  signal = axios.CancelToken.source();
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      roles: [],
      saveLoading: false,
      currentUserList: [],
      formerUserList: [],
      currentUserEdited: [],
      formerUserEdited: [],
      positionsList: [],
      loading: false
    };
  }

  componentDidMount() {
    this.props.updateHeader({
      header: {
        title: "System Users Console",
        action: "",
        page: "users",
        enableBack: false,
        showSettings: true,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    this.props.updateRouteOptions({routeOptions: DEFAULT_ROUTES_SYSTEM_USER});
    userTypes.forEach(type => {
      return this.getUsersList({ type });
    });
    this.getPositionsList();
  }
  getUsersList = ({ type }) => {
    this.setState({
      loading: true
    });
    this.props
      .getUsersList({
        options: { activeOnly: type === "CURRENT" ? true : false },
        cancelToken: this.signal.token,
        type
      })
      .then(resp => {
        if (resp.status) {
          this.setState({
            loading: false
          });
          this.setState({
            [type === "CURRENT"
              ? "currentUserList"
              : "formerUserList"]: mustBeArray(resp.data)
          });
        }
      });
  };
  getPositionsList = () => {
    this.props
      .positionsLookup({ cancelToken: this.signal.token })
      .then(resp => {
        if (resp.status) {
          this.setState({
            positionsList: mustBeArray(resp.data && resp.data.data)
          });
        }
      });
  };
  handleAction = ({ type, record, action }) => {
    if (action === "edit") {
      if (type === "current") {
        this.setState({
          currentUserEdited: [
            ...this.state.currentUserEdited,
            record && record.id
          ]
        });
      } else {
        this.setState({
          formerUserEdited: [
            ...this.state.formerUserEdited,
            record && record.id
          ]
        });
      }
    } else if (action === "delete") {
      this.deleteSystemUser(record);
    }
  };

  async deleteSystemUser(record) {
    this.setState({ loading: true });
    try {
      const res = await this.props.deleteUser({ id: record.id });
      if(res.data.errors){
        errorDisplay(res.data.errors);
      }
      userTypes.forEach(type => {
        return this.getUsersList({ type });
      });
    }
    finally {
      this.setState({ loading: false });
    }
  }

  handleTabChange = activeKey => {
    this.setState({ activeKey });
  };
  handleSubmit = values => {
    this.setState({ saveLoading: true });
    this.props
      .saveProfile({ payLoad: values, cancelToken: this.signal.token })
      .then(resp => {
        this.setState({ saveLoading: false, ...this.props.header });
        this.props.updateHeader({
          header: {
            editMode: false
          }
        });
        if (resp.status) {
        } else {
          errorDisplay(resp?.data?.errors);
        }
      });
  };

  handleEdit = ({ type, record, value, field }) => {
    if (type === "current") {
      this.setState(prevState => {
        prevState.currentUserList = prevState.currentUserList.map(user => {
          let newData = { [field]: value };
          if (field === "positionKey") {
            const currentPosition = _.find(
              prevState.positionsList,
              o => o.id === value
            );
            newData.positionName = currentPosition && currentPosition.name;
          }
          if (field === "isAdmin" && user.id === record.id) {
            newData[field] = !user.isAdmin;
          }
          return user.id === record.id ? { ...user, ...newData } : user;
        });
        return prevState;
      });
    } else {
      this.setState(prevState => {
        prevState.formerUserList = prevState.formerUserList.map(user => {
          let newData = { [field]: value };
          if (field === "positionKey") {
            const currentPosition = _.find(
              prevState.positionsList,
              o => o.id === value
            );
            newData.positionName = currentPosition && currentPosition.name;
          }
          if (field === "isAdmin" && user.id === record.id) {
            newData[field] = !user.isAdmin;
          }
          return user.id === record.id ? { ...user, ...newData } : user;
        });
        return prevState;
      });
    }
    delay(200).then(() => {
      this.forceUpdate();
    });
  };

  handleSave = async ({ record }) => {
    let payLoad = {};
    if (record) {
      payLoad = {
        firstname: record.userNameFirst,
        lastname: record.userNameLast,
        isActive: record.userIsActive,
        positionKey: record.positionKey,
        isAdmin: record.isAdmin,
        email: record.userEmail,
        mobile: record.mobile
      };
    }
    try {
      const resp = await this.props.updateUser({
        id: record && record.id,
        payLoad,
        cancelToken: this.signal.token
      });

      if (resp.status) {
        message.success("User updated successfully.");
        userTypes.forEach(type => {
          return this.getUsersList({ type });
        });
        this.setState(prevState => {
          if (record.isUserActive) {
            prevState.currentUserEdited = _.filter(
              prevState.currentUserEdited,
              o => o !== record.id
            );
          } else {
            prevState.formerUserEdited = _.filter(
              prevState.formerUserEdited,
              o => o !== record.id
            );
          }
          return prevState;
        });
        const currentEditingRows = this.state.currentUserEdited.filter(
          x => x !== record.id
        );
        this.setState({
          currentUserEdited: currentEditingRows
        });
      } else {
        errorDisplay(resp?.data?.errors);
      }
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const {
      activeKey,
      currentUserList,
      formerUserList,
      positionsList
    } = this.state;
    return (
      <>
        <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 flex-center">
          System Users Console
        </div>
        <div className="card-container">
          <Tabs onChange={this.handleTabChange} activeKey={activeKey} type="card">
            <Tabs.TabPane tab="Current" key="1">
              <UsersList
                list={currentUserList}
                positionsList={positionsList}
                updatedUserList={this.state.currentUserEdited}
                loading={this.state.loading}
                handleEdit={options =>
                  this.handleEdit({ ...options, type: "current" })
                }
                handleSave={this.handleSave}
                handleAction={options =>
                  this.handleAction({ ...options, type: "current" })
                }
                {...this.props}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Former" key="2">
              <UsersList
                list={formerUserList}
                updatedUserList={this.state.formerUserEdited}
                loading={this.state.loading}
                handleEdit={options =>
                  this.handleEdit({ ...options, type: "former" })
                }
                positionsList={positionsList}
                handleSave={this.handleSave}
                handleAction={options =>
                  this.handleAction({ ...options, type: "former" })
                }
                {...this.props}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    currentList: mustBeArray(state.users && state.users.currentList),
    formerList: mustBeArray(state.users && state.users.formerList),
    currentTotal: (state.users && state.users.currentTotal) || 0,
    formerTotal: (state.users && state.users.formerTotal) || 0,
    userDetails: (state.users && state.users.details) || {}
  };
};

const mapDispatchToProps = {
  updateHeader,
  saveProfile,
  getUsersList,
  deleteUser,
  updateUser,
  positionsLookup,
  updateRouteOptions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemUsersList);
