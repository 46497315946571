import { Select } from "antd";
import React, { useContext } from "react"; 
import FinderUtilityContext from "../FinderUtilityContext";

const ScenarioLookup = ({ value, handleFieldChange }) => { 
  const {scenarioList} = useContext(FinderUtilityContext); 


  return (
      <Select
        className="w-260 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "priority", value })}
        allowClear={false}
        filterOption={false}
      >
        {scenarioList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
  );
};

export default ScenarioLookup;
