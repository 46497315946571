import { Col, Input, Row } from "antd";
import produce from "immer";
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import {
  getPersonalDetails,
  getProfessionalDetails,
  saveProfessionalDetailsField,
  savePersonalDetailsField
} from "../../actions/profile";
import Axios from "axios";
import { asDefaultDisplayFormatDate, errorDisplay } from "../../libs/utilities";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Visible } from "../Common/visiblityWrapper";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../constants/systemUsers";

const DetailsImpl = ({ activeKey, dispatch, userId, user, profileType }) => {
  const [professionalDetails, setProfessionalDetails] = useState({
    detail: null,
    lastModifiedDate: null,
    lastModifiedByFirstName: null,
    lastModifiedByLastName: null
  });

  const [personalDetails, setPersonalDetails] = useState({
    detail: null,
    lastModifiedDate: null,
    lastModifiedByFirstName: null,
    lastModifiedByLastName: null
  });

  const [isInitialized, setIsInitialized] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      let cancelToken = Axios.CancelToken.source();
      savePersonalDetailsChanges(cancelToken, personalDetails, userId);
    }
  }, [personalDetails && personalDetails.detail]);

  useEffect(() => {
    if (isInitialized) {
      let cancelToken = Axios.CancelToken.source();
      saveProfessionalDetailsChanges(cancelToken, professionalDetails, userId);
    }
  }, [professionalDetails && professionalDetails.detail]);

  useEffect(() => {
    if (activeKey === "3" || activeKey === "details") {
      onInitialized();
    }
  }, [activeKey]);

  async function onInitialized() {
    setIsInitialized(false);
    await Promise.all([loadPersonalDetails(), loadProfessionalDetails()]).then(
      resp => {
        setIsInitialized(true);
      }
    );
  }

  const loadPersonalDetails = async () => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    await dispatch(getPersonalDetails({ id: userId, userType: userType, profileType })).then(
      resp => {
        if (resp.status) {
          setPersonalDetails(
            produce(personalDetails, x => {
              return resp.data;
            })
          );
        }
      }
    );
  };

  const loadProfessionalDetails = async () => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    await dispatch(
      getProfessionalDetails({ id: userId, userType: userType, profileType })
    ).then(resp => {
      if (resp.status) {
        setProfessionalDetails(
          produce(professionalDetails, x => {
            return resp.data;
          })
        );
      }
    });
  };

  const savePersonalDetailsChangesImpl = async (cancelToken, payLoad, id) => {
    setIsSaving(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    const resp = await dispatch(
      savePersonalDetailsField({
        cancelToken: cancelToken,
        payLoad: payLoad,
        id: id,
        userType: userType,
        profileType
      })
    ).then(resp => {
      if (!resp.status) {
        errorDisplay(resp.data.errors);
      } else {
        loadPersonalDetails();
      }
      setIsSaving(false);
    });
  };

  const saveProfessionDetailsChangesImpl = async (cancelToken, payLoad, id) => {
    setIsSaving(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    await dispatch(
      saveProfessionalDetailsField({
        cancelToken: cancelToken,
        payLoad: payLoad,
        id: id,
        userType: userType,
        profileType
      })
    ).then(resp => {
      if (!resp.status) {
        errorDisplay(resp.data.errors);
      } else {
        loadProfessionalDetails();
      }
      setIsSaving(false);
    });
  };

  const savePersonalDetailsChanges = useCallback(
    AwesomeDebouncePromise(savePersonalDetailsChangesImpl, 2000),
    []
  );
  const saveProfessionalDetailsChanges = useCallback(
    AwesomeDebouncePromise(saveProfessionDetailsChangesImpl, 2000),
    []
  );

  const handleChangeProfessionalDetails = value => {
    setProfessionalDetails(
      produce(professionalDetails, x => {
        x.detail = value;
      })
    );
  };

  const handleChangePersonalDetails = value => {
    setPersonalDetails(
      produce(personalDetails, x => {
        x.detail = value;
      })
    );
  };

  return (
    <React.Fragment>
      <Visible visible={isSaving}>
        <span className="p-5">Saving...</span>
      </Visible>
      <Row gutter={36}>
        <Col span={12}>
          Professional
          <Input.TextArea
            rows={12}
            value={professionalDetails.detail}
            onChange={e =>
              handleChangeProfessionalDetails(e.currentTarget.value)
            }
          />
          <div className="flex p-lr-36 m-t-16">
            <div className="flex-1-1-50 flex flex-justify-space-between flex-align-center p-r-20">
              <span>Last edited by</span>
              <Input
                disabled={true}
                value={`${
                  professionalDetails &&
                  professionalDetails.lastModifiedByFirstName
                    ? `${professionalDetails.lastModifiedByFirstName} `
                    : ""
                }${
                  professionalDetails &&
                  professionalDetails.lastModifiedByLastName
                    ? `${professionalDetails.lastModifiedByLastName}`
                    : ""
                }`}
                className="w-percent-49"
              />
            </div>
            <div className="flex-1-1-50 flex flex-justify-space-between flex-align-center p-l-20">
              <span>Last edited date</span>
              <Input
                disabled={true}
                value={asDefaultDisplayFormatDate(
                  professionalDetails.lastModifiedDate
                )}
                className="w-percent-49"
              />
            </div>
          </div>
        </Col>
        <Col span={12}>
          Personal
          <Input.TextArea
            rows={12}
            value={personalDetails.detail}
            onChange={e => handleChangePersonalDetails(e.currentTarget.value)}
          />
          <div className="flex p-lr-36 m-t-16">
            <div className="flex-1-1-50 flex flex-justify-space-between flex-align-center p-r-20">
              <span>Last edited by</span>
              <Input
                disabled={true}
                value={`${
                  personalDetails && personalDetails.lastModifiedByFirstName
                    ? `${personalDetails.lastModifiedByFirstName} `
                    : ""
                }${
                  personalDetails && personalDetails.lastModifiedByLastName
                    ? `${personalDetails.lastModifiedByLastName}`
                    : ""
                }`}
                className="w-percent-49"
              />
            </div>
            <div className="flex-1-1-50 flex flex-justify-space-between flex-align-center p-l-20">
              <span>Last edited date</span>
              <Input
                disabled={true}
                value={asDefaultDisplayFormatDate(
                  personalDetails.lastModifiedDate
                )}
                className="w-percent-49"
              />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  return {
    user: state.authorization && state.authorization.user
  };
};

export default connect(mapStateToProps)(DetailsImpl);
