import React, { useEffect, useState, useCallback } from "react";
import { Form, Radio, Input, Row, Col, Button, message, Select } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateHeader } from "../../actions/member";
import { createUser, createIndividualProfile } from "../../actions/users";
import { statesLookup, getOmicronBdmLookup } from "../../actions/lookup";
import axios from "axios";
import { debounce } from "lodash";
import RequiredSign from "../../Common/RequiredSign";
import history from "../../routes/history";
import { errorDisplay, mustBeArray } from "../../libs/utilities";
import {
  DecoratedFormControl,
  FormDecoratorProvider
} from "../../components/Common/decoratedFormControl";
import { Visible } from "../../components/Common/visiblityWrapper";

const FormItem = Form.Item;

const AddCompanyUser = props => {
  const { statesLookup, match } = props;
  const signal = axios.CancelToken.source();
  const [saveLoading, setSaveLoading] = useState(false);
  const lender = useSelector(state => state?.profile?.lender);
  const broker = useSelector(state => state?.profile?.broker);
  const lawFirm = useSelector(state => state?.profile?.lawFirm);
  const [statesList, setStatesList] = useState([]);
  const [companyName, setCompanyName] = useState([]);
  const dispatchAction = useDispatch();

  useEffect(() => {
    props.updateHeader({
      header: {
        title: "",
        action: "",
        page: "users",
        enableBack: false,
        showSettings: false,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false,
      }
    });
    getStatesList();

  }, []);

  const checkSubmitDisabled = () => {
    const { form } = props;
    if (
      form &&
      form.getFieldValue("firstName") &&
      form.getFieldValue("lastName") &&
      form.getFieldValue("mobileNumber") &&
      form.getFieldValue("email") &&
      form.getFieldValue("stateKey")
    ) {
      return false;
    }
    return true;
  };

  const getStatesList = async () => {
    let response = await statesLookup();
    if (response && response.data) {
      setStatesList(mustBeArray(response.data.data));
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      try {
        setSaveLoading(true);
        const companyId = match?.params?.id;
        const resp = await props.createIndividualProfile({
          payLoad: { ...values, companyId: companyId },
          cancelToken: signal.token
        });

        if (resp.status) {
          message.success("User added successfully");
          if (resp.data.lenderId) {
            history.push(`/users/${resp.data.lenderId}/lender`);
          } else if (resp.data.brokerId) {
            history.push(`/users/${resp.data.brokerId}/broker`);
          }
          else if (resp.data.lawFirmId) {
            history.push(`/users/${resp.data.lawFirmId}/lawfirm`);
          }
        } else {
          errorDisplay(resp?.data?.errors);
        }
      } finally {
        setSaveLoading(false);
      }
    });
  };
  
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const { form } = props;
  const { getFieldDecorator } = form;
  const itemOneSpan = 15;
  const offsetSpan = 2;
  const itemTwoSpan = 3;
  const gutterSpan = 4;

  return (
    <>
     <div className="flex flex-wrap f-s-17 f-w-500 m-b-50 flex-center">
            {/* Add New User to &nbsp;<span className="color-danger">{`${lender?.companyName}`}</span> */}
            Add New User to&nbsp;<span className="color-danger">{`${
              lender?.companyName ? `${lender?.companyName}` : 
              broker?.companyName ? `${broker?.companyName}` : lawFirm?.companyName ?  `${lawFirm?.companyName}`: ""
            }`}</span>
      </div>
    <div className="flex flex-center label-right">
      <Form
        style={{ minWidth: "800px" }}
        colon={false}
        hideRequiredMark={true}
        onSubmit={handleSubmit}
      >
        <FormDecoratorProvider decorator={getFieldDecorator}>
          
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={offsetSpan}>
              <DecoratedFormControl
                {...formLayout}
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={offsetSpan}>
              <DecoratedFormControl
                {...formLayout}
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={offsetSpan}>
              <DecoratedFormControl
                {...formLayout}
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={offsetSpan}>
              <DecoratedFormControl
                {...formLayout}
                label="Mobile"
                name="mobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile number"
                  }
                ]}
              >
                <Input placeholder="Enter" />
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan} offset={offsetSpan}>
              <DecoratedFormControl
                {...formLayout}
                label="State"
                name="stateKey"
                rules={[
                  {
                    required: true,
                    message: "Please select state"
                  }
                ]}
              >
                <Select
                  filterOption={true}
                  showSearch={true}
                  optionFilterProp={"searchby"}
                  autoComplete="arbitrary-text"
                  placeholder="Select"
                >
                  {mustBeArray(statesList).map((s, key) => (
                    <Select.Option key={key} value={s.id} searchby={s.name}>
                      {s.id}
                    </Select.Option>
                  ))}
                </Select>
              </DecoratedFormControl>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span="24">
              <FormItem
                {...{
                  labelCol: { span: 12 },
                  wrapperCol: { span: 12 }
                }}
                label={
                  <Button
                    icon="file"
                    type="primary"
                    loading={saveLoading}
                    className={`f-w-500 ${
                      checkSubmitDisabled()
                        ? "bg-light-primary color-white"
                        : ""
                    }`}
                    htmlType="submit"
                    disabled={checkSubmitDisabled()}
                  >
                    Save
                  </Button>
                }
              >
                {getFieldDecorator("cancel", {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <Button
                    className="f-w-500"
                    htmlType="button"
                    disabled={saveLoading}
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                )}
              </FormItem>
            </Col>
          </Row>
        </FormDecoratorProvider>
      </Form>
    </div>
    </>
    
  );
};

const mapDispatchToProps = {
  updateHeader,
  createUser,
  createIndividualProfile,
  statesLookup,
  getOmicronBdmLookup
};

export default connect(
  null,
  mapDispatchToProps
)(Form.create("addCompanyUser")(AddCompanyUser));
