import React, { Children, cloneElement, isValidElement } from "react";

export const Visible = ({ visible, children, ...ascyclicalProps }) => {
  if (visible) {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          ...ascyclicalProps
        });
      }

      return child;
    });
  }

  return null;
};
