import React from 'react';
import TopBarProgress from "react-topbar-progress-indicator";
import {connect} from 'react-redux';


TopBarProgress.config({
  barColors: {
    "0": "#132bad",
    "0.5": "#0a3a8e",
    "1.0": "#000",
  },
  shadowBlur: 0,
  barThickness: 2
});


export class ProgressBar extends React.Component {

  render() {

    const {progressBarStatus} = this.props;

    if (progressBarStatus) {
      return (<TopBarProgress/>)
    } else {
      return ('');
    }
  }
}

//redux container component
const mapStateToProps = (state) => {
  return ({
    progressBarStatus: state.ui.progressBarStatus
  });
};

export const HandleProgressBar = connect(
  mapStateToProps,
  null
)(ProgressBar);

export default HandleProgressBar;
