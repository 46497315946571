import React from "react";
import LenderProfile from "../../pages/Profile/Lender";

const UserDetails = (props) => {
  return (
    <LenderProfile {...props} />
  );
};
 
export default UserDetails;
