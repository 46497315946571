import { Input, Rate } from "antd";
import React from "react";

const RatingsAndReview = ({
  ratingsText,
  reviewText,
  responseText,
  handleFieldChange,
  fields,
  loanDetails,
  disabledFields = {
    rating: false,
    review: false,
    response: false
  }
}) => {
  return (
    <React.Fragment>
      <div className="flex flex-wrap flex-align-center  m-b-12">
        <b>{ratingsText}</b>
        <Rate
          allowClear
          value={loanDetails[fields.rating]}
          className="m-l-6"
          onChange={e => handleFieldChange({ value: e, field: fields.rating })}
          disabled={ disabledFields.rating }
        />
      </div>
      <div className="flex flex-wrap space-between m-b-12">
        <div className="flex flex-1-1 p-r-40">
          <b>{reviewText}</b>
          <Input.TextArea
            rows={4}
            value={loanDetails[fields.review]}
            onChange={e => handleFieldChange({ value: e.currentTarget.value, field: fields.review })}
            disabled={disabledFields.review}
          />
        </div>
        <div className="flex flex-1-1 p-r-40">
          <b>{responseText}</b>
          <Input.TextArea
            rows={4}
            value={loanDetails[fields.response]}
            onChange={e => handleFieldChange({ value: e.currentTarget.value, field: fields.response })}
            disabled={disabledFields.response}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default RatingsAndReview;
