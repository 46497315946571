import React, { useEffect, useState } from "react";
import {
  ADMIN_USER,
  BROKER_USER,
  DUAL_USER,
  LAW_FIRM_USER,
  LENDER_USER,
  SYSTEM_ADMIN_USER
} from "../../constants/systemUsers";
import useCheckUserRoles from "./useCheckUserRoles";

type Role = "admin" | "lender" | "broker" | "dual" | "lawFirm" | "systemAdmin"; // update with constants/systemUsers

/**
 * Check user's roles if any listed is applicable
 *
 * Use constants from src/constants/systemUsers
 *
 * @param {Role[]} rolesToAllow
 * @returns
 */
function useAllowUserWithRoles(rolesToAllow: Role[]) {
  const {
    isSystemAdmin,
    isBrokerUser,
    isDualUser,
    isLawFirmUser,
    isLenderUser
  } = useCheckUserRoles();

  const [userHasRole, setUserHasRole] = useState(false);

  useEffect(() => {
    rolesToAllow.forEach(role => {
      if (role === LAW_FIRM_USER && isLawFirmUser) {
        setUserHasRole(true);
      }
      if (role === LENDER_USER && isLenderUser) {
        setUserHasRole(true);
      }
      if (role === BROKER_USER && isBrokerUser) {
        setUserHasRole(true);
      }
      if (role === DUAL_USER && isDualUser) {
        setUserHasRole(true);
      }
      if (
        (role === ADMIN_USER || role === SYSTEM_ADMIN_USER) &&
        isSystemAdmin
      ) {
        setUserHasRole(true);
      }
    });
  }, [rolesToAllow]);

  return userHasRole;
}

export default useAllowUserWithRoles;
