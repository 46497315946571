import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import history from "../routes/history";
import authService from "../actions/AuthorizeService";
import {
  getLastVisitedProfile,
  getLoggedInUserProfile,
  getLoggedInIndividualUserDetails
} from "../actions/authorization";
import { isSystemUserPolicy } from "../Common/Authorization/policies/isSystemUserPolicy";
import { isBrokerUserPolicy } from "../Common/Authorization/policies/isBrokerUserPolicy";
import { isLawFirmUserPolicy } from "../Common/Authorization/policies/isLawFirmUserPolicy";
import { Spin } from "antd";
import { isLenderUserPolicy } from "../Common/Authorization/policies/isLenderUserPolicy";
import { clearProfileDetails } from "../actions/profile";

function redirect(url) {
  history.push(url);
}

const InitializeApplication = ({
  dispatch,
  user,
  lastVisitedProfile,
  individualUser
}) => {
  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    completeUserLogin();
  }, [user, individualUser, lastVisitedProfile]);

  async function completeUserLogin() {
    const authenticated = await authService.isAuthenticated();
    if (!authenticated) {
      history.push("/login");
    }
    
    if(user.roles.length > 0){
      const lenderUser = await isLenderUserPolicy(
        user.roles,
        user.permissions,
        user
      );
      const systemUser = await isSystemUserPolicy(
        user.roles,
        user.permissions,
        user
      );
      const brokerUser = await isBrokerUserPolicy(
        user.roles, 
        user.permissions,
        user
      );

      const lawFirmUser = await isLawFirmUserPolicy(user.roles);

      dispatch(clearProfileDetails());
      
  
      if(!systemUser)
        await dispatch(getLoggedInIndividualUserDetails());

      if (lenderUser && brokerUser) {
        return redirect(`/users/${user["app.user.lenderid"]}/lender`);
      } 
      if (brokerUser) {
        return redirect(`/users/${user["app.user.brokerid"]}/broker`);
      }  
      if (lenderUser) {
       return   redirect(`/users/${user["app.user.lenderid"]}/lender`);
      } 
      if (systemUser) {
        return redirect(`/users/notes`);
      }
      if(lawFirmUser) {
        return redirect(`/users/${user["app.user.lawfirmid"]}/lawfirm`);
      }
    }
    
  }

  async function onInit() {
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    
    await dispatch(getLoggedInUserProfile());

    if(systemUser)
    await dispatch(getLastVisitedProfile());
  }

  return <Spin />;
};

const mapStateToProps = state => {
  return {
    authenticated: authService.isAuthenticated,
    user: state.authorization.user,
    lastVisitedProfile: state.authorization.lastVisitedProfile,
    individualUser: state.authorization.individualUser
  };
};

export default connect(mapStateToProps)(InitializeApplication);
