import { Select } from "antd";
import React, { useContext, useMemo, } from "react";
import { mustBeArray } from "../../../libs";
import FinderUtilityContext from "../FinderUtilityContext";

const SecurityTypeLookup = ({ value, handleFieldChange }) => {
  const {securityList} = useContext(FinderUtilityContext);
  

  const subTypeList = useMemo(() => {
    const selectedType = mustBeArray(value)[0];
    if(selectedType){
      return securityList.find(o => o.id === selectedType)?.securitySubTypes;
    }
    return [];
  },[value, securityList]);

  const handleChange = e => {
    handleFieldChange({ field: "securityType", value: [value[0], e] })
  }

  return (
    <div className="flex flex-align-center flex-wrap gap-12">
    <div>
      <div className="bold">Type:</div>
      <Select
        className="w-200 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "securityType", value: [value] })}
        allowClear={false}
        filterOption={false}
      >
        {mustBeArray(securityList).map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
    </div>
    <div >
      <div className="bold">Subtype:</div> 
      <Select
        className="w-200 m-l-6"
        value={mustBeArray(value)[1]}
        onChange={handleChange}
        allowClear={false}
        filterOption={false}
      >
        {mustBeArray(subTypeList).map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
    </div>
  </div>




     
  );
};

export default SecurityTypeLookup;
