import React, { createElement } from "react";
import { Button, Col } from "antd";
import { connect } from "react-redux";

class InactiveProfileException extends React.Component {
  static defaultProps = {
    backText: "Back to home",
    redirect: "/"
  };

  render() {
    const {
      backText,
      linkElement = "a",
      title,
      img,
      actions,
      redirect,
      match
    } = this.props;
    const type = match && match.params && match.params.type;
    const pageType = "inactive";
    const config = {
        img: "https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg",
        title: "403",
        desc: "The profile you are attempting to access is inactive and therefore can not be viewed"
      }
      
    return (
      <div
        className="bg-white p-24"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Col md={12} sm={24} className="m-b-12">
          <img
            src={img || config.img}
            alt="Exception"
            height="500px"
            width="100%"
          />
        </Col>
        <Col
          md={6}
          sm={12}
          className={`m-b-6`}
        >
          <div className="f-s-48 f-w-600 m-b-16">
            {title || config.title}
          </div>
          <div className="f-s-24">{config.desc}</div>
          <div>
            {actions ||
              createElement(
                linkElement,
                {
                  to: redirect,
                  href: redirect
                },
                <Button type="primary m-t-16" size="large">
                  {backText}
                </Button>
              )}
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InactiveProfileException);
