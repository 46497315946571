import React, { Fragment, useEffect, useState } from "react";
import { Row, Col } from 'antd';
import _, { filter } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import { getAllLoansAsSystemUser, getAllLoansAsIndividual, getAllLoansExpiryLookups } from "../../actions/loans";
import { evaluatePolicy, mustBeArray } from "../../libs";
import AllLoansList from "../../components/Loans/AllLoansList";
import LoanStatusFilter from "../../components/Loans/AllFilters/status";
import LoanExpiryFilter from "../../components/Loans/AllFilters/expiry";
import { ALL_LOANS_STATUS } from "../../constants";
import AllLoansOmicronBdmLookup from "../../components/Loans/AllFilters/omicronBdm";
import AllLoansPartnerLookup from "../../components/Loans/AllFilters/partner";
import { isLenderUserPolicy, isLenderOwnProfilePolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isBrokerUserPolicy, isBrokerOwnProfilePolicy } from "../../Common/Authorization/policies/isBrokerUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { isLawFirmUserPolicy, isLawFirmOwnProfilePolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import { ADMIN_USER, DUAL_USER, BROKER_USER, LENDER_USER, LAW_FIRM_USER } from "../../constants/systemUsers";
import {
  EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER,
  LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
  LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER,
  BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
  LAWFIRM_PROFILE_ROUTES_SYSTEM_USER,
  PROFILE_EDIT_OPTION,
  DEFAULT_ROUTES_SYSTEM_USER
} from "../../constants/routeOptions";
import { getAllLoansBdmLookup, getAllLoansPartnerLookups } from "../../actions/loans";

const LoansView = () => {
  const dispatchAction = useDispatch();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [expiryList, setExpiryList] = useState([]);
  const user = useSelector(state => state?.authorization?.user);
  const individualUser = useSelector(state => state.authorization && state.authorization.individualUser);
  const [initialized, setInitialized] = useState(false);

  const [filters, setFilters] = useState({
    expiryKey: "all",
    statusKey: "SETTLED",
    omicronBdmId: null,
    partnerId: null
  });
  useEffect(() => {
    dispatchAction(
      updateHeader({
        header: {
          title: " ",
          action: "",
          page: `loans-view`,
          enableBack: false,
          showSettings: true,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: false,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );

    getFilterCurrentUser();
    getExpiryList();
  }, []);

  useEffect(() => {
    if (initialized) {
        getLoansList();
        return;
    }
  }, [initialized]);

  const getFilterCurrentUser = async () => {
    var isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    var isLawFirm = await isLawFirmUserPolicy(user.roles);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    const isSystemUser = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ));

    const roleType = isSystemUser
      ? ADMIN_USER
      : isLawFirm
      ? LAW_FIRM_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : isBroker
      ? BROKER_USER
      : "";
    
    if(roleType === LAW_FIRM_USER){
      dispatchAction(
        getAllLoansPartnerLookups({ search: "" })
      ).then(resp => {
        let partnerList = mustBeArray(resp?.data);
        if(roleType === LAW_FIRM_USER){
          let usersLawfirmId = parseInt(user["app.user.individualid"]);
          let partner = partnerList.filter(partner => partner.id == usersLawfirmId);
          if(partner.length > 0){
            setFilters({ ...filters, omicronBdmId: 'all', partnerId: usersLawfirmId });
          }else {
            setFilters({ ...filters, omicronBdmId: 'all', partnerId: 'all' });
          }
        }
  
        setInitialized(true);
      });
    }
    else if(roleType === ADMIN_USER){
      dispatchAction(
        getAllLoansBdmLookup({ search: "" })
      ).then(resp => {
        let bdmList = mustBeArray(resp?.data);
        let usersSystemUserId = parseInt(user["app.user.systemuserid"]);
          let bdm = bdmList.filter(bdm => bdm.id == usersSystemUserId);
          if(bdm.length > 0){
            setFilters({ ...filters, omicronBdmId: usersSystemUserId, partnerId: 'all' });
          }else {
            setFilters({ ...filters, omicronBdmId: 'all', partnerId: 'all' });
          }
  
        setInitialized(true);
      });
    }else{
      setFilters({ ...filters, omicronBdmId: 'all', partnerId: 'all' });
      setInitialized(true);
    }
  }

  useEffect(() => {
    updateUsersRouteOptions();
  }, [window.location.href]);
  
  const updateUsersRouteOptions = async () => {

    let isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    let systemUser = await isSystemUserPolicy(user.roles, user.permissions, user);
    let isLawFirm = await isLawFirmUserPolicy(user.roles);

    const isOwnProfile =
      (await evaluatePolicy(user, isBrokerOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLenderOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLawFirmOwnProfilePolicy(user.id)));

    const roleType = systemUser
      ? ADMIN_USER
      : isLawFirm
      ? LAW_FIRM_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : isBroker
      ? BROKER_USER
      : "";
     
      let newRouteOptions;
      if(roleType === DUAL_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER;
      }
      else if(roleType === LENDER_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER
      }
      else if(roleType === BROKER_USER){
        newRouteOptions = BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER
      }
      else if(roleType === LAW_FIRM_USER){
        newRouteOptions = LAWFIRM_PROFILE_ROUTES_SYSTEM_USER;
      }
      else {
        newRouteOptions = DEFAULT_ROUTES_SYSTEM_USER;
      }

      newRouteOptions = [...newRouteOptions].map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });

      const menuOptions = isOwnProfile
        ? [...EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, ...PROFILE_EDIT_OPTION]
        : [];
      
      dispatchAction(
        await updateRouteOptions({
          routeOptions: _.concat(newRouteOptions, menuOptions)
        })
      );
  }

  const getExpiryList = () => {
    dispatchAction(
        getAllLoansExpiryLookups({})
      ).then(resp => {
        setExpiryList(mustBeArray(resp?.data));
      });
  }

  const getSearchParams = () => {
    const {omicronBdmId, expiryKey, statusKey, partnerId} = filters

    return {
      omicronBdmId:  omicronBdmId && omicronBdmId !== "all" ? omicronBdmId : null,
        partnerId:  partnerId && partnerId !== "all" ? partnerId : null,
        statusKey,
        expiryKey: expiryKey && expiryKey !== "all" ? expiryKey : null
    }
  }

  const getLoansList = async () => {
    var isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    var isLawFirm = isLawFirm = await isLawFirmUserPolicy(user.roles);
    const isSystemUser = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ));

    if(isSystemUser){
      dispatchAction(getAllLoansAsSystemUser({ queryParams: {...getSearchParams(), page, pageSize: 20} })).then(resp => {
        setLoading(false);
        setList(mustBeArray(resp?.data));
        setTotal(resp?.total);
      });
    }
    else{
      dispatchAction(getAllLoansAsIndividual({ queryParams: {...getSearchParams(), page, pageSize: 20} })).then(resp => {
        setLoading(false);
        setList(mustBeArray(resp?.data));
        setTotal(resp?.total);
      });
    }
  };

  const handleExpiryChange = e => {
    setFilters({ ...filters, expiryKey: e.target.value });
  };

  const handleStatusChange = e => {
    setFilters({
      ...filters,
      statusKey: e.target.value,
      expiryKey: [ALL_LOANS_STATUS.Settled, ALL_LOANS_STATUS.Default, ALL_LOANS_STATUS.VariationInProgress].includes(
        e.target.value
      )
        ? filters.expiryKey
        : "all"
    });
  };

  const handleOmicronBdmChange = (e) => {
    setFilters({...filters, omicronBdmId: e})
  }

  const handlePartnerChange = (e) => {
    setFilters({...filters, partnerId: e})
  }

  useEffect(() => {
    if (!loading && initialized) {
      if(page === 1 && filter.omicronBdmId !== null) {
        setLoading(true);
        getLoansList();
        return;
      }
      setPage(1);
    }
  }, [filters]);

  useEffect(() => {
    if (!loading && initialized) {
      setLoading(true);
      getLoansList();
    }
  }, [page]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  }
  return (
    <div className="profile-content">
    <div className=" m-n-t-80 m-l-205 w-percent-80">
      <Row>
        <Col span={10}>
          
          <div className="flex gap-12 flex-wrap w-percent-96">
            <Row>
              <Col span={10}>
                <AllLoansOmicronBdmLookup value={filters.omicronBdmId} handleOmicronBdmChange={handleOmicronBdmChange} className="m-t-16"/>
              </Col>
              <Col span={10} className="m-t-16 text-center">
                <h3>All Loans Report</h3>
              </Col>
              <Col span={24} className="m-t-16">
                <LoanStatusFilter
                  value={filters.statusKey}
                  handleStatusChange={handleStatusChange}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={10} className="m-n-l-20">
          {[ALL_LOANS_STATUS.Settled, ALL_LOANS_STATUS.Default, ALL_LOANS_STATUS.VariationInProgress].includes(
            filters.statusKey
          ) && (
            <LoanExpiryFilter
              value={filters.expiryKey}
              handleExpiryChange={handleExpiryChange}
              list={expiryList}
            />
          )}
        </Col>
        <Col span={4}>
          <AllLoansPartnerLookup value={filters.partnerId} handlePartnerChange={handlePartnerChange} className="m-t-16 m-l-20"/>
        </Col>
    </Row>
      
    </div>
    <AllLoansList loading={loading} list={list} page={page} className="m-t-16" handleTableChange={handleTableChange} total={total}/>
    </div>
  );
};

export default LoansView;
