import React, { useEffect, useState } from "react";
import { DecoratedFormControl } from "../Common/decoratedFormControl";
import { Input, Radio, Select } from "antd";
import { useDispatch } from "react-redux";
import { statesLookup } from "../../actions/lookup";
import { mustBeArray } from "../../libs";

const PortraitForm = ({
  form,
  isSystemUser,
  user,
  handleFormChange,
  company,
  portraits
}) => {
  const [statesList, setStatesList] = useState([]);
  const dispatchAction = useDispatch();

  const getStatesList = async () => {
    dispatchAction(statesLookup()).then(response =>
      setStatesList(mustBeArray(response?.data?.data))
    );
  };

  useEffect(() => {
    getStatesList();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      firstname: user.firstname,
      lastname: user.lastname,
      stateKey: user.stateKey,
      email: user.email,
      mobileNumber: user.mobileNumber,
      linkedInProfile: user.linkedInProfile,
      isVisible: user.isVisible,
      companyACN: company.companyACN,
      companyName: company.companyName,
      website: company.website
    });
  }, [user?.individualId]);

  const isVisibleCount = portraits?.filter(p => p.isVisible)?.length;
  return (
    <div>
      <DecoratedFormControl
        {...{
          labelCol: { span: 7 },
          wrapperCol: { span: 17 }
        }}
        label="First Name"
        name="firstname"
        rules={[
          {
            required: true,
            message: "Please enter first name"
          }
        ]}
      >
        <Input
          placeholder="Enter"
          autoComplete="arbitrary-text"
          disabled={!isSystemUser}
          onChange={e =>
            handleFormChange({ label: "firstname", value: e.target.value })
          }
        />
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 7 },
          wrapperCol: { span: 17 }
        }}
        label="Last Name"
        name="lastname"
        rules={[
          {
            required: true,
            message: "Please enter last name"
          }
        ]}
      >
        <Input
          placeholder="Enter"
          autoComplete="arbitrary-text"
          disabled={!isSystemUser}
          onChange={e =>
            handleFormChange({ label: "lastname", value: e.target.value })
          }
        />
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 0 },
          wrapperCol: { span: 24 }
        }}
        label=""
        name="isVisible"
        rules={[
          {
            required: false
          }
        ]}
      >
        <Radio.Group
          onChange={e =>
            handleFormChange({
              label: "isVisible",
              value: e.target.value
            })
          }
          disabled={isVisibleCount === 1 && user.isVisible}
        >
          <Radio value={true}>Visible</Radio>
          <Radio value={false}>Hidden</Radio>
        </Radio.Group>
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        }}
        label="State"
        name="stateKey"
        rules={[
          {
            required: true,
            message: "Please select state"
          }
        ]}
      >
        <Select
          filterOption
          showSearch
          optionFilterProp={"searchby"}
          autoComplete="arbitrary-text"
          placeholder="State"
          onChange={e => handleFormChange({ label: "stateKey", value: e })}
        >
          {mustBeArray(statesList).map((s, key) => (
            <Select.Option key={key} value={s.id} searchby={s.name}>
              {s.id}
            </Select.Option>
          ))}
        </Select>
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        }}
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter email"
          }
        ]}
      >
        <Input
          placeholder="Enter"
          onChange={e =>
            handleFormChange({ label: "email", value: e.currentTarget.value })
          }
          disabled={!isSystemUser}
        />
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        }}
        label="Mobile"
        name="mobileNumber"
        rules={[
          {
            required: true,
            message: "Please enter mobile"
          }
        ]}
      >
        <Input
          placeholder="Enter"
          autoComplete="arbitrary-text"
          onChange={e =>
            handleFormChange({
              label: "mobileNumber",
              value: e.currentTarget.value
            })
          }
          disabled={!isSystemUser}
        />
      </DecoratedFormControl>
      <DecoratedFormControl
        {...{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 }
        }}
        label="Linked In"
        name="linkedInProfile"
        rules={[
          {
            required: false
          }
        ]}
      >
        <Input
          placeholder="Enter"
          autoComplete="arbitrary-text"
          onChange={e =>
            handleFormChange({
              label: "linkedInProfile",
              value: e.currentTarget.value
            })
          }
        />
      </DecoratedFormControl>
    </div>
  );
};

export default PortraitForm;
