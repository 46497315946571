import React, { useContext } from "react";
import { LENDER_USER } from "../../../constants/systemUsers";
import { convertCurrenyToHighestFigure } from "../../../libs";
import FinderUtilityContext from "../FinderUtilityContext";
import LabelValuePair from "./LabelValuePair";
import { List } from "antd";

const MobileKeyMetrics = ({ user }) => {
  const { userType } = useContext(FinderUtilityContext);
  const {
    dischargedLoansCount,
    currentLoanBook,
    dischargedLoanBook,
    currentLoansCount,
    shortfall,
    positiveLenderReviewsCount,
    positiveBrokerReviewsCount
  } = user;
  const userTypeText = userType === LENDER_USER ? "Broker" : "Lender";
  return (
    <div className="flex column gap-16 width-full">
      <List bordered className="list-responsive">
        <List.Item>
          <LabelValuePair
            value={convertCurrenyToHighestFigure(currentLoanBook)}
            label="Current Loan Book"
            valueClassName="color-primary"
            mobileView
          />
        </List.Item>
        <List.Item>
          <LabelValuePair
            value={convertCurrenyToHighestFigure(dischargedLoanBook)}
            label="Discharged Loan Book"
            valueClassName="color-primary"
            mobileView
          />
        </List.Item>
        <List.Item>
          <LabelValuePair
            value={currentLoansCount}
            label="Current Loans"
            valueClassName="color-primary"
            mobileView
          />
        </List.Item>
      </List>
      <List bordered className="list-responsive">
        <List.Item>
          <LabelValuePair
            value={dischargedLoansCount}
            label="Discharged Loans"
            valueClassName="color-primary"
            smallValue
            mobileView
          />
        </List.Item>
        <List.Item>
          <LabelValuePair
            value={`${shortfall}%`}
            label="Shortfalls (of capital loaned)"
            valueClassName="color-primary"
            smallValue
            mobileView
          />
        </List.Item>
        <List.Item>
          <LabelValuePair
            value={
              userType === LENDER_USER
                ? positiveBrokerReviewsCount
                : positiveLenderReviewsCount
            }
            label={`Positive ${userTypeText} Reviews`}
            valueClassName="color-primary"
            smallValue
            mobileView
          />
        </List.Item>
      </List>
    </div>
  );
};

export default MobileKeyMetrics;
