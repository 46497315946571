import { Checkbox, Col, Radio, Row, Skeleton } from "antd";
import React, { Fragment } from "react";
import { mustBeArray } from "../../libs/utilities";
import { Visible } from "../Common/visiblityWrapper";

const StatesForm = props => {
  const {
    statesList,
    handleChangeDistance,
    regionsList,
    distancesList,
    handleChangeState,
    loading,
    fields,
    handleChangeRegion,
    userHasPermissionToUpdate
  } = props;
  return (
    <Fragment>
      <Visible visible={loading}>
        <Skeleton active />
      </Visible>
      <Visible visible={!loading}>
        <div>
          <div className="flex flex-wrap m-t-12">
            <div className="flex-1-1-17">States</div>
            <div className="flex-1-1-83">
              {mustBeArray(statesList).map((state, i) => {
                return (
                  <Checkbox
                    checked={
                      fields.stateRegionDetails.length > 0 &&
                      fields.stateRegionDetails
                        .map(x => x.stateKey)
                        .includes(state.id)
                    }
                    onChange={e =>
                      handleChangeState({
                        isChecked: e.target.checked,
                        value: e.target.value
                      })
                    }
                    disabled={!userHasPermissionToUpdate}
                    value={state.id}
                    key={i}
                    className="p-b-6"
                  >
                    {state.id}
                  </Checkbox>
                );
              })}
              <span className="p-b-30" style={{ color: "red" }}>
                *{" "}
              </span>
            </div>
          </div>
          {mustBeArray(regionsList).map((state, j) => {
            return (
              <React.Fragment key={j}>
                {state.id}
                <div className="flex flex-wrap m-b-12">
                  <div className="flex-1-1-17">{state.capitalCity}</div>
                  <div className="flex-1-1-83 ">
                    <Radio.Group
                      value={
                        fields.stateRegionDetails.find(
                          x => x.stateKey === state.id
                        )?.distanceKey
                      }
                      disabled={!userHasPermissionToUpdate}
                      className="flex space-between"
                      onChange={e =>
                        handleChangeDistance({
                          value: e.target.value,
                          stateId: state.id
                        })
                      }
                    >
                      {mustBeArray(distancesList).map((distance, i) => {
                        return (
                          <Radio value={distance.id} key={i}>
                            {distance.name}
                          </Radio>
                        );
                      })}
                      <span
                        className="p-b-30 p-r-20"
                        style={{ color: "red", marginLeft: "-45px" }}
                      >
                        *{" "}
                      </span>
                    </Radio.Group>
                  </div>
                </div>
                <div className="m-b-12">
                  <Row>
                    {mustBeArray(state.states).map((region, i) => {
                      return (
                        <Col span={8} key={i}>
                          <Checkbox
                            value={region.id}
                            disabled={!userHasPermissionToUpdate}
                            checked={
                              fields.stateRegionDetails.length > 0 &&
                              fields.stateRegionDetails.find(
                                st => st.stateKey === state.id
                              ) !== undefined &&
                              fields.stateRegionDetails
                                .find(st => st.stateKey === state.id)
                                .regionKeys?.includes(region.id)
                            }
                            onChange={e =>
                              handleChangeRegion({
                                isChecked: e.target.checked,
                                value: e.target.value,
                                stateId: state.id
                              })
                            }
                            key={region.id}
                            className="p-b-6 flex-1-1-25"
                          >
                            {region.name}
                          </Checkbox>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Visible>
    </Fragment>
  );
};
export default StatesForm;
