import { Select } from "antd";
import React, { useContext, useEffect, } from "react"; 
import FinderUtilityContext from "../FinderUtilityContext";

const PriorityLookup = ({ value, handleFieldChange }) => { 
  const {priorityList} = useContext(FinderUtilityContext);

  useEffect(() => {
    
  }, []);  

  return (
      <Select
        className="w-260 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "priority", value })}
        allowClear={false}
        filterOption={false}
      >
        {priorityList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
  );
};

export default PriorityLookup;
