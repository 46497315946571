import React, { Component } from "react";
import { Form, Radio, Input, Row, Col, Button, message, Select } from "antd";
import { connect } from "react-redux";
import { updateHeader } from "../../actions/member";
import { createUser } from "../../actions/users";
import { positionsLookup, statesLookup } from "../../actions/lookup";
import axios from "axios";
import RequiredSign from "../../Common/RequiredSign";
import history from "../../routes/history";
import { errorDisplay, mustBeArray } from "../../libs/utilities";
import { mobileNumberRegex } from "../../constants/inputFields";

const FormItem = Form.Item;
class AddSystemUser extends Component {
  signal = axios.CancelToken.source();
  constructor(props) {
    super(props);
    this.state = {
      saveLoading: false,
      positionsList: [],
      statesList: []
    };
  }
  componentDidMount() {
    this.props.updateHeader({
      header: {
        title: "Add System Users",
        action: "",
        page: "users",
        enableBack: false,
        showSettings: false,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    this.getPositionsList();
    this.getStatesList();
  }

  getStatesList = () => {
    this.props.statesLookup().then(response => {
      if(response && response.data){
        this.setState({ statesList:(mustBeArray(response.data.data))});
      }
    });

  }
  getPositionsList = () => {
    this.props
      .positionsLookup({ cancelToken: this.signal.token })
      .then(resp => {
        if (resp.status) {
          this.setState({
            positionsList: mustBeArray(resp.data && resp.data.data)
          });
        }
      });
  };
  checkSubmitDisabled = () => {
    const { form } = this.props;
    if (
      form &&
      form.getFieldValue("firstName") &&
      form.getFieldValue("lastName") &&
      form.getFieldValue("mobile") &&
      form.getFieldValue("email") &&
      form.getFieldValue("stateKey")
    ) {
      return false;
    }
    return true;
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({ saveLoading: true });
      this.props
        .createUser({ payLoad: { ...values,positionKey: "PRTNR", cancelToken: this.signal.token } })
        .then(resp => {
          this.setState({ saveLoading: false });
          if (resp.status) {
            message.success("User added successfully.");
            history.push("/users");
          } else {
            errorDisplay(resp?.data?.errors);
          }
        });
    });
  };

  render() {
    const formLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const itemOneSpan = 15;
    const itemTwoSpan = 9;
    const gutterSpan = 4;
    return (
      <>
      <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 m-b-15 flex-center">
        Add System Users
      </div>
      <div className="flex flex-center label-right">
        <Form
          style={{ minWidth: "800px" }}
          colon={false}
          hideRequiredMark={true}
          onSubmit={this.handleSubmit}
        >
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem {...formLayout} label="First Name">
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter first name"
                    }
                  ]
                })(<Input placeholder="Enter" />)}
              </FormItem>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem {...formLayout} label="Last Name">
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter last name"
                    }
                  ]
                })(<Input placeholder="Enter" />)}
              </FormItem>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem {...formLayout} label="Email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter email"
                    }
                  ]
                })(<Input placeholder="Enter" />)}
              </FormItem>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem {...formLayout} label="Mobile">
                {getFieldDecorator("mobile", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter mobile number"
                    },
                    {
                      pattern: mobileNumberRegex,
                      message: "Please enter a valid mobile number"
                    }
                  ]
                })(<Input placeholder="Enter" />)}
              </FormItem>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem {...formLayout} label="State">
                {getFieldDecorator("stateKey", {
                  rules: [
                    {
                      required: true,
                      message: "Please select state"
                    }
                  ]
                })(
                  <Select
                  filterOption={true}
                  showSearch={true}
                  optionFilterProp={'searchby'}
                  autoComplete="arbitrary-text"
                  placeholder="Select"
              >
                  {
                      mustBeArray(this.state.statesList).map((s, key) => (
                          <Select.Option key={key} value={s.id} searchby={s.name}>
                              {s.id}
                          </Select.Option>
                      ))
                  }
              </Select>
                )}
              </FormItem>
            </Col>
            <Col span={itemTwoSpan}>
              <RequiredSign />
            </Col>
          </Row>
          <Row gutter={gutterSpan}>
            <Col span={itemOneSpan}>
              <FormItem
                {...formLayout}
                label={
                  <Button
                    icon="file"
                    type="primary"
                    loading={this.state.saveLoading}
                    className={`f-w-500 ${
                      this.checkSubmitDisabled()
                        ? "bg-light-primary color-white"
                        : ""
                    }`}
                    htmlType="submit"
                    disabled={this.checkSubmitDisabled()}
                  >
                    Save
                  </Button>
                }
              >
                {getFieldDecorator("cancel", {
                  rules: [
                    {
                      required: false
                    }
                  ]
                })(
                  <Button
                    className="f-w-500"
                    onClick={() => history.push("/users")}
                  >
                    Cancel
                  </Button>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      </>
      
    );
  }
}

const mapDispatchToProps = {
  updateHeader,
  createUser,
  positionsLookup,
  statesLookup
};

export default connect(
  null,
  mapDispatchToProps
)(Form.create("addLenderBroker")(AddSystemUser));
