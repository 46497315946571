import { Card, Radio } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mustBeArray } from "../../../libs";
import { loansStatusLookups } from "../../../actions/lookup";
import { isSystemUserPolicy } from "../../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../../constants/systemUsers";
import { ALL_LOANS_STATUS } from "../../../constants";

const LoanStatusFilter = ({ value, handleStatusChange }) => {
  const [list, setList] = useState([]);
  const signal = axios.CancelToken.source();
  const dispatchAction = useDispatch();
  const user = useSelector(state => state?.authorization?.user);

  const handleOnLoad = async() => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";

    dispatchAction(loansStatusLookups({ cancelToken: signal.token })).then(
      resp => {
        if(userType === ADMIN_USER){
          setList(mustBeArray(resp));
        }else{
          setList(mustBeArray(resp).filter(a => a.id !== ALL_LOANS_STATUS.PreSettlement));
        }
      }
    );
  }
  useEffect(() => {
    handleOnLoad();
  }, []);

  return (
    <Card className="loan-filter">
      <div className="title-center f-w-700 f-s-14">Status</div>
      <Radio.Group value={value} onChange={handleStatusChange} size="small" className="w-percent-100">
      <div className="flex flex-wrap space-between">
        {list.map(option => (
            <Radio value={option.id} key={option.id}>
              {option.name}
            </Radio>
          ))}
      </div>
       
      </Radio.Group>
    </Card>
  );
};

export default LoanStatusFilter;
