import React from 'react';
import {Button, Form, Icon, Input} from 'antd';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {forgotPassword, getUserData, login, signUp} from '../actions/member';

const FormItem = Form.Item;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false,
      errorDisplay: 'none',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err, 'error');
        return
      }
      this.setState({loadingSubmit: true})
      this.props.onForgotPassword(values).then((resp, reject) => {
        console.log("forgotPassword response", resp, 'reject', reject);
      })
    });
  }


  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <Form className="forgotPassword"
            onSubmit={this.handleSubmit}
            hideRequiredMark={false}
      >
        <FormItem>
          <div className="forgotPassword-title">Recover Password</div>
        </FormItem>

        <FormItem>
          <Button
            className="forgotPassword__errorButton error-button"
            size={'large'}
            style={{display: this.props.error ? 'block' : 'none', width: '100%', textAlign: 'left'}}
          >
            {this.props.error}
          </Button>
        </FormItem>

        <FormItem
          colon={false}
          label={'Enter email address to recover your password'}
        >
          {getFieldDecorator('username', {
            rules: [
              {type: 'email', message: 'The input is not valid E-mail!'},
              {required: true, message: 'Please input your username'},
            ],
          })(
            <Input prefix={<Icon type="user"
                                 style={{color: 'rgba(0,0,0,.25)'}}/>}
                   placeholder={'yourname@example.com'}
                   size="large"/>
          )}
        </FormItem>

        <FormItem>

          <Button type="primary" htmlType="submit"
                  className="forgotPassword-button"
                  size="large"
                  theme="filled"
                  loading={this.state.loadingSubmit}
          >
            <span className="forgotPassword-button__text"
                  style={{float: "left"}}>
              {'RECOVER'}
            </span>
            <Icon type="arrow-right" className="forgotPassword-button__icon"
                  size="large"/>
          </Button>
          <Link to='/login'><Icon type="left"/> Go back and login</Link>
        </FormItem>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  getUserData,
  onLoginUser: login,
  onSignUpUser: signUp,
  onForgotPassword: forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(ForgotPassword));
