export default {
  "400": "Bad Request",
  "401": "Unauthorized",
  "402": "Payment Required",
  "403": "Forbidden",
  "404": "Not Found",
  "405": "Method Not Allowed",
  "406": "Not Acceptable",
  "407": "Authentication Required",
  "408": "Request Timeout",
  "500": "An error occured"
};

export const loanErrors = {
  loanNumberRequired: "Loan number is required.",
  loanNumberContainsSpecial: "Loan number contains special characters",
  attributedOmicronLenderRequired: "Attributed Omicron Lender is required.",
  attributedOmicronBrokerRequired: "Attributed Omicron Broker is required.",
  validAttributedOmicronLender:
    "Please select valid lender and amount for Attributed Omicron Lender.",
  principalRequired: "Principal is required.",
  suburbAndStateRequired: "Suburb and State are required.",
  lowerRateRequired: "Lower rate is required",
  lowerRateValidation:
    "Lower Rate must be between 2%-80% and less than the Higher Rate",
  higherRateRequired: "Higher Rate is required",
  higherRateValidation:
    "Higher Rate must be between 2%-80% and greater than the Lower Rate",
  lvrRequired: "LVR is required",
  lvrValidation: "LVR must be between 5%-99%",
  statusKeySettled: "You cannot change the Status to Pre-Settlement if the Settlement Date is populated",
  statusKeyDischarged: "You cannot change the Status away from Discharged if the Discharged Date field is populated",
  repaymentDateRequired: "Repayment Date is required",
  settlementDateRequired: "Settlement Date is required",
  lawfirmRequired: "Law Firm is required",
  priorityRequired: "Priority is required",
  scenarioRequired: "Scenario is required",
  securityTypeRequired: "Security Type is required",
  securitySubTypeRequired: "Security Sub Type is required",
  interestRequired: "Interest Payable is required",
  permissionAttributedLender: (lender ="" ) => `Ooops! You only have permissions to create loan records if ${lender} is one of the Attributed Lenders.`,
};

export const loanFieldErrors = {
  lvrRequired: loanErrors.lvrRequired,
  lvr: loanErrors.lvrValidation,
  loanLenders: loanErrors.attributedOmicronLenderRequired,
  suburbState: loanErrors.suburbAndStateRequired,
  attributedBrokerId: loanErrors.attributedOmicronBrokerRequired,
  principal: loanErrors.principalRequired,
  higherRateRequired: loanErrors.higherRateRequired,
  higherRate: loanErrors.higherRateValidation,
  lowerRateRequired: loanErrors.loanNumberRequired,
  lowerRate: loanErrors.lowerRateValidation,
  loanNo: loanErrors.loanNumberRequired,
  loanNumberContainsSpecial: loanErrors.loanNumberRequired,
  statusKeySettled: loanErrors.statusKeySettled,
  statusKeyDischarged: loanErrors.statusKeyDischarged,
  repaymentDate: loanErrors.repaymentDateRequired,
  settlementDate: loanErrors.settlementDateRequired
};
