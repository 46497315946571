import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";
import { mustBeArray } from "../../../libs/utilities";
import history from "../../../routes/history";
import { isLawFirmUserPolicy } from "../../../Common/Authorization/policies/isLawFirmUserPolicy";
import { ILoanSnapshotBroker } from "../../../libs/types";

interface SnapshotAttributedBrokersProps {
  attributedBrokerList: ILoanSnapshotBroker[] | null;
}

const SnapshotAttributedBrokers = ({
  attributedBrokerList
}: SnapshotAttributedBrokersProps) => {
  const { user } = useSelector((state: any) => state.authorization);

  const [isUserLawfirm, setIsUserLawfirm] = useState(false);

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsUserLawfirm(isLawfirm);
  };

  useEffect(() => {
    checkIfUserIsLawfirm();
  }, [user]);

  const handleBrokerProfileClick = (brokerId: number | null) => {
    if (brokerId) {
      history.push(`/users/${brokerId}/broker`);
    }
  };

  return (
    <div className="m-n-t-15">
      {mustBeArray(attributedBrokerList).map(
        (data: ILoanSnapshotBroker, i: number) => {
          return (
            <div className="m-b-8 m-l-4 m-t-6" key={i}>
              {data.loanBrokerId && (
                <Input
                  style={{
                    width: "220px",
                    caretColor: "transparent",
                    color: !isUserLawfirm ? "blue" : "inherit",
                    cursor: !isUserLawfirm ? "pointer" : "default"
                  }}
                  disabled={false}
                  value={data.loanBrokerName}
                  onClick={() => {
                    if (!isUserLawfirm) {
                      handleBrokerProfileClick(data.loanBrokerId);
                    }
                  }}
                ></Input>
              )}
              {!data.loanBrokerId && (
                <Input
                  style={{
                    width: "220px",
                    caretColor: "transparent",
                    color: "darkred",
                    cursor: "default"
                  }}
                  disabled={false}
                  value={data.loanBrokerName}
                ></Input>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default SnapshotAttributedBrokers;
