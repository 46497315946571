import React from 'react';
import { useSelector } from 'react-redux';

const OmicronBDMView = () => {
    const header = useSelector(state => state.member.header);
  return ( header?.omicronBdm?.name ?
    <div className="flex align-center m-l-32">
      <h1 className='m-b-0 m-r-8'>Omicron BDM</h1>
      <span className='f-s-16'>{header?.omicronBdm?.name}</span>
    </div>
    :
    null
  );
};
export default OmicronBDMView;
