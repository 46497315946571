import { Select } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LoanCreateContext from "./LoanCreateContext";
import { loansLawFirmUsersLookup } from "../../../actions/lookup";
import { mustBeArray } from "../../../libs";
import { debounce } from "lodash";
import axios from "axios";
import useCheckUserRoles from "../../../Common/Hooks/useCheckUserRoles";

const PartnerLookup = ({
  value,
  handleFieldChange,
  lawFirmId,
  disabled = false
}) => {
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  const { isSystemAdmin, checkIfUserIsSystemAdmin } = useCheckUserRoles();

  useEffect(() => {
    if (lawFirmId) {
      checkIfUserIsSystemAdmin().then(isSystemUser => {
        dispatchAction(
          loansLawFirmUsersLookup({
            cancelToken: signal.token,
            search: "",
            id: lawFirmId,
            isSystemUser
          })
        ).then(resp => {
          setList(mustBeArray(resp));
        });
      });
    }
  }, [lawFirmId]);

  const handleSearch = e => {
    checkIfUserIsSystemAdmin().then(isSystemUser => {
      dispatchAction(
        loansLawFirmUsersLookup({
          cancelToken: signal.token,
          search: "",
          id: lawFirmId,
          isSystemUser
        })
      ).then(resp => {
        setList(mustBeArray(resp));
      });
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300));

  return (
    <div className="flex m-l-16 flex-align-center m-r-16 m-b-10">
      <b>Partner</b>
      <Select
        disabled={!lawFirmId || disabled}
        className="w-260 m-l-6"
        value={value}
        onChange={value =>
          handleFieldChange({ field: "assignedPartnerId", value })
        }
        allowClear={false}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default PartnerLookup;
