const LOGIN = 'LOGIN';
const SIGNUP = 'SIGNUP';
const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const LOGOUT = 'LOGOUT';
const LIST_USERS = 'LIST_USERS';
const USER_DETAIL = 'USER_DETAIL';

const AUTH_USER = 'AUTH_USER';
const UNAUTH_USER = 'UNAUTH_USER';
const AUTH_IN_PROGRESS = 'AUTH_IN_PROGRESS';
const AUTH_ERROR = 'AUTH_ERROR';
const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
const CLEARDOWN = 'CLEARDOWN';
const USER_PROFILE = 'USER_PROFILE';
const USER_AUTHORIZATION = 'USER_AUTHORIZATION';


const memberAction = {
  LOGIN, SIGNUP, FORGOT_PASSWORD, LOGOUT, LIST_USERS, USER_DETAIL, AUTH_USER, UNAUTH_USER,
  AUTH_IN_PROGRESS, AUTH_ERROR, FORGOT_SUCCESS, CLEARDOWN, USER_PROFILE, USER_AUTHORIZATION
};

export default memberAction;
