/**
 * For reference purposes
 * @typedef ExceptionConfigItem
 * @property {string} img
 * @property {string} title
 * @property {string} desc
 * @property {string=} redirect
 * @property {string=} backText
 */

const config = {
  401: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg",
    title: "401",
    desc: "You do not have access to view this page",
    redirect: "/general/finder-utility"
  },
  403: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg",
    title: "403",
    desc: "You do not have access to view this page",
    redirect: "/general/finder-utility"
  },
  404: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg",
    title: "404",
    desc: "Page is still undergoing construction. Please check back later.",
    redirect: "/general/finder-utility"
  },
  500: {
    img: "https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg",
    title: "500",
    desc: "An unexpected error occured. Please try again later.",
    redirect: "/general/finder-utility"
  }
};

export default config;
