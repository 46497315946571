import { Card, Form, Radio } from "antd";
import React from "react";

const FundsAvailableEmail = ({
  getFieldDecorator
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative">
        <div className="title-center">Funds Available Email</div>
      <Form.Item label="Current Loan Book">
        {getFieldDecorator("fundsAvailableShowCurrentLoanBook")(
          <Radio.Group>
            <Radio value={true}>Visible</Radio>
            <Radio value={false}>Private</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Discharged Loan Book">
        {getFieldDecorator("fundsAvailableShowDischargedLoanBook")(
          <Radio.Group>
            <Radio value={true}>Visible</Radio>
            <Radio value={false}>Private</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Suburbs">
        {getFieldDecorator("fundsAvailableShowSuburbs")(
          <Radio.Group>
            <Radio value={true}>Visible</Radio>
            <Radio value={false}>Private</Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Card>
  );
};
export default FundsAvailableEmail;
