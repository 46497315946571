import React, { Component, Fragment, useEffect, useState } from "react";
import NotesList from "../../components/Notes/NotesList";
import MonthlyScores from "../../components/Notes/MonthlyScores";
import { connect } from "react-redux";
import { updateRouteOptions } from "../../actions/member";
import { DEFAULT_ROUTES_SYSTEM_USER } from "../../constants/routeOptions";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../constants/systemUsers";

const Notes = props => {
  const { match, user, dispatch } = props;
  const [userType, setUserType] = useState();

  useEffect(() => {
    dispatch(updateRouteOptions({ routeOptions: DEFAULT_ROUTES_SYSTEM_USER }));
    checkUserType();
  }, []);

  const checkUserType = async () => {
    let userAccess = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    setUserType(userAccess);
  };

  return (
    <div>
      <h1 className="m-b-5 float-right m-r-100">
        Notes
      </h1>
      <div className="clearfix"/>
      <div className="m-t-8">
          <MonthlyScores {...props}></MonthlyScores>
      </div>
      <Fragment>
        <NotesList {...props}></NotesList>
      </Fragment>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.authorization && state.authorization.user
  };
};
export default connect(mapStateToProps)(Notes);
