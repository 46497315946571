import React from "react";

const EditIcon = ({className, onClick, width = 14, height = 12}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}`}
      height={`${height}`}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={`feather feather-edit-2 ${className || ""}`}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
    </svg>
  );
}

export default EditIcon;
