import { Select } from "antd";
import React, { useContext } from "react";
import LoanCreateContext from "./LoanCreateContext";

const SecuritySubTypeLookup = ({ value, handleFieldChange, disabled, className}) => {
  const {securitySubTypesList} = useContext(LoanCreateContext)
  return (
    <div className={`flex m-l-16 flex-align-center m-r-20 ${className || ""}`}>
      <b>Security Sub Type</b>
      <Select
        className="w-150 m-l-6"
        value={value}
        onChange={value =>
          handleFieldChange({ field: "securitySubTypeKey", value })
        } 
        allowClear={false}
        disabled={disabled}
      >
        {securitySubTypesList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default SecuritySubTypeLookup;
