import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Skeleton,
  Modal
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  LENDERS_DISTANCES_TYPE,
  LENDERS_FIRST_RANKING_SECURITY_TYPE,
  LENDERS_STATES_SUBURBS_TYPE
} from "../../constants/lookup";
import {
  asDefaultDisplayFormatDate,
  defaultIfUndefinedOrNull,
  delay,
  errorDisplay,
  errorDisplayCustom,
  isCategoryOffered,
  millisecondsInADay,
  mustBeArray
} from "../../libs/utilities";
import StatesForm from "./StatesForm";
import Axios from "axios";
import _, { first } from "lodash";
import produce from "immer";
import {
  getLendersFirstRankingProfile,
  saveFirstRankingField,
  saveFirstRankingFieldAsAdmin,
  verifyFirstRankingFields,
  verifyFirstRankingFieldsAsAdmin,
  clearDetailsFieldsAsAdmin,
  clearDetailsFields
} from "../../actions/profile";
import { lendersLookUpHandler } from "../../actions/lookup";
import { connect } from "react-redux";
import { Visible } from "../Common/visiblityWrapper";
import { ADMIN_USER } from "../../constants/systemUsers";
import {
  LENDER_PROFILE_INCOMPLETE_MANATORY_FIELD_WARNING,
  LENDER_PROFILE_MORE_THAN_MONTH_LAST_VERIFIED_WARNING,
  STATE_REQUIRED_WARNING
} from "../../constants/warnings";
import moment from "moment";
import ZeroAvailableWarning from "./ZeroAvailableWarning";
import authService from "../../actions/AuthorizeService";
import RadioGroup from "antd/lib/radio/group";

const FirstRankingImprovedImpl = ({
  activeKey,
  activeSubKey,
  dispatch,
  userId,
  userType,
  isSystemUser,
  lender,
  userHasPermissionToUpdate,
  profileType,
  broker,
  getLenderProfile,
  getBrokerProfile,
  toggleSubscriptions
}) => {
  const [firstRanking, setFirstRanking] = useState({
    available: null,
    bookSize: null,
    bransgrovesComments: null,
    distanceKey: null,
    estabFeeMin: null,
    estabFeeMax: null,
    isVerified: false,
    lastVerifiedByFirstName: null,
    lastVerifiedByLastName: null,
    lastVerifiedDate: null,
    lenderId: "",
    lenderCategoryId: "",
    lvrNonResidentialMin: null,
    lvrNonResidentialMax: null,
    lvrResidentialMin: null,
    lvrResidentialMax: null,
    pointOfDifference: null,
    preferredParcelSizeMax: null,
    preferredParcelSizeMin: null,
    rateMax: null,
    rateMin: null,
    higherRateMax: null,
    higerRateMin: null,
    securityTypeKeys: [],
    stateRegionDetails: [],
    isSyndicateLender: false,
    advertiseToOtherSyndicateLenders: false
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [errorsList, setErrorsList] = useState([]);
  const [
    zeroAvailableWarningVisible,
    setZeroAvailableWarningVisible
  ] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [securityList, setSecurityList] = useState([]);
  const [residentialType, setResidentialType] = useState([]);
  const [industrialType, setIndustrialType] = useState([]);
  const [commercialType, setCommercialType] = useState([]);
  const [specialisedType, setSpecialisedType] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [distancesList, setDistancesList] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState("");
  const [lookupsLoading, setLookupsLoading] = useState(false);
  const [isResidential, setIsResidential] = useState(false);
  const [isNonResidential, setIsNonResidential] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isNotAvailableToBrokers, setIsNotAvailableToBrokers] = useState(false);
  const [
    hasIncompleteMandatoryFields,
    setHasIncompleteMandatoryFields
  ] = useState(false);
  const [isVerifiedButNotSwitchedOn, setIsVerifiedButNotSwitchedOn] = useState(
    false
  );
  const [hasPendingSave, setHasPendingSave] = useState(false);
  const debounceHanldeSave = useCallback(
    _.debounce(options => saveChangesImpl(options), 2000),
    []
  );
  const [showClearDetailsModal, setShowClearDetailsModal] = useState(false);
  const [isClearingDetails, setClearingDetails] = useState(false);
    
  useEffect(() => {
    checkIfCategoriesEnabled({
      data: profileType === "lender" ? lender : broker,
      firstRankingData: firstRanking
    });
  }, [profileType === "lender" ? lender : broker]);

  useEffect(() => {
    const { min, max } = recalculateTotalCostOfFunds(
      firstRanking.rateMin,
      firstRanking.rateMax,
      firstRanking.estabFeeMin,
      firstRanking.estabFeeMax
    );
    setFirstRanking(
      produce(firstRanking, x => {
        x.totalCostOfFundsLow = min;
        x.totalCostOfFundsHigh = max;
      })
    );
  }, [
    firstRanking.rateMax,
    firstRanking.rateMin,
    firstRanking.estabFeeMin,
    firstRanking.estabFeeMax
  ]);

  function recalculateTotalCostOfFunds(
    rateMin,
    rateMax,
    estabFeeMin,
    estabFeeMax
  ) {
    return {
      min:
        defaultIfUndefinedOrNull(rateMin, 0) +
        defaultIfUndefinedOrNull(estabFeeMin, 0),
      max:
        defaultIfUndefinedOrNull(rateMax, 0) +
        defaultIfUndefinedOrNull(estabFeeMax, 0)
    };
  }

  const checkIfCategoriesEnabled = ({ data, firstRankingData }) => {
    if (data && data.categoriesOffered && firstRankingData) {
      let isSwitchedOn = data.categoriesOffered.firstRanking;
      setIsVerifiedButNotSwitchedOn(
        firstRankingData.isVerified === true && !isSwitchedOn ? true : false
      );
    }
  };

  useEffect(() => {
    // if (activeSubKey === "4") {
    if (activeSubKey === "4") {
      onInitialized();
    }
  }, [activeSubKey, lender]);

  useEffect(() => {
    var hasResidential =
      residentialType.filter(type =>
        firstRanking.securityTypeKeys.includes(type)
      ).length > 0;
    var hasNonResidential =
      [...industrialType, ...commercialType, ...specialisedType].filter(type =>
        firstRanking.securityTypeKeys.includes(type)
      ).length > 0;
    if (!hasNonResidential) {
      setFirstRanking(
        produce(firstRanking, x => {
          x.lvrNonResidentialMin = null;
          x.lvrNonResidentialMax = null;
        })
      );
    }
    if (!hasResidential) {
      setFirstRanking(
        produce(firstRanking, x => {
          x.lvrResidentialMin = null;
          x.lvrResidentialMax = null;
        })
      );
    }
    setIsResidential(hasResidential);
    setIsNonResidential(hasNonResidential);
  }, [firstRanking.securityTypeKeys]);

  useEffect(() => {
    setLookupsLoading(regionsList.length === 0);
  }, [regionsList]);

  async function onInitialized() {
    setIsInitialized(false);
    await Promise.all([
      listDistances(),
      listSecurityTypes(),
      getFirstRankingDetails()
    ]).then(resp => {
      setIsInitialized(true);
    });
  }

  useEffect(() => {
    if (isInitialized && firstRanking.id !== null) {
      let cancelToken = Axios.CancelToken.source();
      setHasPendingSave(true);
      debounceHanldeSave({
        cancelToken,
        firstRanking,
        userId,
        residentialType,
        specialisedType,
        industrialType,
        commercialType,
        userType
      });
    }
  }, [firstRanking]);

  const getUserProfile = async () => {
    let userDetails = await authService.getUser();
    let usersLenderId = userDetails["app.user.lenderid"];
    let usersBrokerId = userDetails["app.user.brokerid"];
    const actionName =
      profileType === "lender" ? getLenderProfile : getBrokerProfile;
    actionName({
      id: userId,
      userType,
      notClearProfileImage: true,
      doNotResetCurrentTab: true,
      isUserViewingSelfProfile:
        (profileType === "lender" ? usersLenderId : usersBrokerId) === userId
    });
  };

  function onStateChanged(stateCheckBoxDetails) {
    if (
      !stateCheckBoxDetails.isChecked &&
      mustBeArray(selectedStates).length === 1
    ) {
      return message.warning(STATE_REQUIRED_WARNING);
    }
    if (
      stateCheckBoxDetails.isChecked === true &&
      !selectedStates.map(x => x.id).includes(stateCheckBoxDetails.value)
    ) {
      let stateToAppend = statesList.find(
        x => x.id === stateCheckBoxDetails.value
      );
      selectedStates.push(stateToAppend);
      setRegionsList(
        selectedStates.map(s => ({
          id: s.id,
          capitalCity: s.capitalCity + "+",
          states: s.states
        }))
      );

      setFirstRanking(
        produce(firstRanking, x => {
          x.stateRegionDetails.push({
            stateKey: stateCheckBoxDetails.value,
            distanceKey: null,
            regionKeys: []
          });
        })
      );
    } else if (
      stateCheckBoxDetails.isChecked === false &&
      selectedStates.map(x => x.id).includes(stateCheckBoxDetails.value)
    ) {
      let filteredStates = selectedStates.filter(
        x => x.id !== stateCheckBoxDetails.value
      );
      setSelectedStates(filteredStates);
      setRegionsList(
        filteredStates.map(s => ({
          id: s.id,
          capitalCity: s.capitalCity + "+",
          states: s.states
        }))
      );
      setFirstRanking(
        produce(firstRanking, x => {
          x.stateRegionDetails = x.stateRegionDetails.filter(
            x => x.stateKey !== stateCheckBoxDetails.value
          );
        })
      );
    }
  }

  const checkProductState = async () => {
    const resp = await dispatch(getLendersFirstRankingProfile({ id: userId }));
    let responseData = resp.data;
    let today = new Date();

    if (responseData.modifiedDate) {
      let modifiedDate = new Date(responseData.modifiedDate);
      setIsNotAvailableToBrokers(
        !responseData.isVerified &&
          Math.round((today - modifiedDate) / millisecondsInADay)
      );
    }

    if (responseData.lastVerifiedDate) {
      setIsNotAvailableToBrokers(
        moment().diff(responseData.lastVerifiedDate, "days") > 30
      );
    }

    // let isSwitchedOn = lender.categoriesOffered.firstRanking === true;
    let isSwitchedOn =
      (lender &&
        lender.categoriesOffered &&
        lender.categoriesOffered.firstRanking) === true;

    setIsVerifiedButNotSwitchedOn(
      responseData.isVerified === true && !isSwitchedOn
    );

    setHasIncompleteMandatoryFields(hasMissingMandatoryFields(responseData));
  };

  const getFirstRankingDetails = async () => {
    const resp = await dispatch(getLendersFirstRankingProfile({ id: userId }));
    const initialStates = await listStates();
    const hasNoStateRegionDetail =
      resp.data.stateRegionDetails.map(x => x.stateKey).length < 1;
    if (hasNoStateRegionDetail) {
      resp.data.stateRegionDetails.push({
        stateKey: "NSW",
        distanceKey: null,
        regionKeys: []
      });
    }
    var stateKeyInitial = resp.data.stateRegionDetails.map(x => x.stateKey);
    setFirstRanking(
      produce(firstRanking, x => {
        const selectedStatesFromKeys = initialStates.filter(x =>
          stateKeyInitial.includes(x.id)
        );
        const selectedRegions = selectedStatesFromKeys.map(s => ({
          id: s.id,
          capitalCity: s.capitalCity + "+",
          states: s.states
        }));
        setSelectedStates(selectedStatesFromKeys);
        setRegionsList(
          produce(regionsList, x => {
            x = selectedRegions;
            return x;
          })
        );

        let responseData = resp.data;

        let today = new Date();

        if (responseData.modifiedDate) {
          let modifiedDate = new Date(responseData.modifiedDate);
          setIsNotAvailableToBrokers(
            !responseData.isVerified &&
              Math.round((today - modifiedDate) / millisecondsInADay)
          );
        }

        if (responseData.lastVerifiedDate) {
          setIsNotAvailableToBrokers(
            moment().diff(responseData.lastVerifiedDate, "days") > 30
          );
        }

        let isSwitchedOn =
          (lender &&
            lender.categoriesOffered &&
            lender.categoriesOffered.firstRanking) === true;

        setIsVerifiedButNotSwitchedOn(
          responseData.isVerified === true && !isSwitchedOn
        );

        setHasIncompleteMandatoryFields(
          hasMissingMandatoryFields(responseData)
        );

        return resp.data;
      })
    );
    setPageLoad(false);
  };

  const hasMissingMandatoryFields = data => {
    return (
      !(
        data.securityTypeKeys.length > 0 &&
        data.available &&
        data.preferredParcelSizeMin &&
        data.preferredParcelSizeMax &&
        data.rateMin &&
        data.rateMax &&
        data.higherRateMax &&
        data.higherRateMin &&
        (data.estabFeeMin || data.estabFeeMin === 0) &&
        (data.estabFeeMax || data.estabFeeMax === 0) &&
        data.stateRegionDetails &&
        _.isEmpty(
          mustBeArray(data.stateRegionDetails).filter(
            x => x.distanceKey === null && x.regionKeys.length < 1
          )
        ) &&
        data.pointOfDifference &&
        data.pointOfDifference.length >= 10
      ) === true
    );
  };

  const listStates = async () => {
    const resp = await dispatch(
      lendersLookUpHandler({ action: LENDERS_STATES_SUBURBS_TYPE, userType })
    );
    if (resp.status) {
      setStatesList(
        produce(statesList, x => {
          return resp.data;
        })
      );

      return resp.data;
    } else {
      errorDisplay(resp.data.errors);
    }
  };

  const listDistances = async () => {
    const resp = await dispatch(
      lendersLookUpHandler({ action: LENDERS_DISTANCES_TYPE, userType })
    );
    if (resp.status) {
      setDistancesList(
        produce(distancesList, x => {
          return resp.data;
        })
      );
    } else {
      errorDisplay(resp.data.errors);
    }
  };

  const listSecurityTypes = async () => {
    const resp = await dispatch(
      lendersLookUpHandler({
        action: LENDERS_FIRST_RANKING_SECURITY_TYPE,
        userType
      })
    );
    if (resp.status) {
      setSecurityList(
        produce(securityList, x => {
          return resp.data;
        })
      );

      setResidentialType(
        produce(residentialType, x => {
          return resp.data
            .find(x => x.id === "Residential")
            .securityTypes.map(x => x.id);
        })
      );

      setIndustrialType(
        produce(industrialType, x => {
          return resp.data
            .find(x => x.id === "Industrial")
            .securityTypes.map(x => x.id);
        })
      );

      setCommercialType(
        produce(commercialType, x => {
          return resp.data
            .find(x => x.id === "Commercial")
            .securityTypes.map(x => x.id);
        })
      );

      setSpecialisedType(
        produce(specialisedType, x => {
          return resp.data
            .find(x => x.id === "Specialised")
            .securityTypes.map(x => x.id);
        })
      );
    } else {
      errorDisplay(resp.data.errors);
    }
  };

  const saveChangesImpl = async options => {
    const {
      cancelToken,
      firstRanking,
      userId,
      residentialType,
      specialisedType,
      industrialType,
      commercialType,
      userType
    } = options;
    if (
      firstRanking &&
      Number(firstRanking.available) === 0 &&
      (firstRanking.isVerified || !hasMissingMandatoryFields(firstRanking))
    ) {
      return setZeroAvailableWarningVisible(true);
    }
    setIsSaving(true);
    let payLoad = { ...firstRanking };
    if (
      _.isEmpty(
        residentialType.filter(type =>
          firstRanking.securityTypeKeys.includes(type)
        )
      )
    ) {
      payLoad = {
        ...payLoad,
        lvrResidentialMin: null,
        lvrResidentialMax: null
      };
    }
    if (
      _.isEmpty(
        [...industrialType, ...commercialType, ...specialisedType].filter(
          type => firstRanking.securityTypeKeys.includes(type)
        )
      )
    ) {
      payLoad = {
        ...payLoad,
        lvrNonResidentialMin: null,
        lvrNonResidentialMax: null
      };
    }
    if (userType === ADMIN_USER) {
      await dispatch(
        saveFirstRankingFieldAsAdmin({
          cancelToken: cancelToken,
          payLoad: payLoad,
          id: userId
        })
      ).then(resp => {
        if (!resp.status) {
          let errors = mustBeArray(resp && resp.data && resp.data.errors);
          errors = errors.reduce((a, b) => {
            return a.concat(b.message);
          }, []);
          // errorDisplay(resp.data.errors);
          setErrorsList(errors);
        } else {
          setErrorsList([]);
        }
        checkProductState();
        setHasPendingSave(false);
        setIsSaving(false);
      });
    } else {
      await dispatch(
        saveFirstRankingField({
          cancelToken: cancelToken,
          payLoad: payLoad,
          id: userId
        })
      ).then(resp => {
        if (!resp.status) {
          let errors = mustBeArray(resp && resp.data && resp.data.errors);
          errors = errors.reduce((a, b) => {
            return a.concat(b.message);
          }, []);
          // errorDisplay(resp.data.errors);
          setErrorsList(errors);
        } else {
          setErrorsList([]);
        }
        checkProductState();
        setIsSaving(false);
        setHasPendingSave(false);
      });
    }
  };

  const handleSavingErrors = ({ resp, data }) => {
    if (resp.data.errors.length > 0) {
      setErrorsList(mustBeArray(resp && resp.data && resp.data.errors));
    } else if (data && data.isVerified) {
      if (!resp.status) {
        let errors = mustBeArray(resp && resp.data && resp.data.errors);
        errors = errors.reduce((a, b) => {
          return a.concat(b.message);
        }, []);
        setErrorsList(errors);
      } else {
        setErrorsList([]);
      }
    } else {
      setErrorsList(mustBeArray(resp && resp.data && resp.data.errors));
    }
  };

  const verify = async () => {
    if (firstRanking && Number(firstRanking.available) === 0) {
      return setZeroAvailableWarningVisible(true);
    }
    setIsVerifying(true);
    if (userType === ADMIN_USER) {
      await dispatch(
        verifyFirstRankingFieldsAsAdmin({
          id: userId
        })
      ).then(resp => {
        setIsVerifying(false);
        handleSavingErrors({ resp, data: firstRanking });
        onInitialized();
        delay(500).then(() => {
          getUserProfile();
        });
      });
    } else {
      await dispatch(
        verifyFirstRankingFields({
          id: userId
        })
      ).then(resp => {
        setIsVerifying(false);
        handleSavingErrors({ resp, data: firstRanking });
        onInitialized();
        delay(500).then(() => {
          getUserProfile();
        });
      });
    }
  };

  const handleCancel = () => {
    setPauseLoading(false);
    setZeroAvailableWarningVisible(false);
  };
  const handleOk = () => {
    setPauseLoading(true);
    toggleSubscriptions({
      profileType,
      productType: "firstRanking",
      id: userId
    }).then(resp => {
      setPauseLoading(false);
      setZeroAvailableWarningVisible(false);
      if (resp.status) {
        message.success(
          profileType === "lender"
            ? "Categories offered updated successfully."
            : "Categories subscribed to updated successfully."
        );
        getUserProfile();
      }
    });
  };
  const isResidentialSecurityTypeValidated = () => {
    if (!firstRanking) return false;
    if (
      isResidential &&
      (!firstRanking.lvrResidentialMin || !firstRanking.lvrResidentialMax)
    )
      return false;
    return true;
  };
  const isNonResidentialSecurityTypeValidated = () => {
    if (!firstRanking) return false;
    if (
      isNonResidential &&
      (!firstRanking.lvrNonResidentialMin || !firstRanking.lvrNonResidentialMax)
    )
      return false;
    return true;
  };
  const isEstabFeeValidated = () => {
    if (!firstRanking) return false;
    if (!(firstRanking.estabFeeMin || firstRanking.estabFeeMin === 0))
      return false;
    if (!(firstRanking.estabFeeMax || firstRanking.estabFeeMax === 0))
      return false;
    if (Number(firstRanking.estabFeeMin) > Number(firstRanking.estabFeeMax))
      return false;
    return true;
  };
  const getMissingRequiredFields = () => {
    if (!firstRanking || pageLoad) return true;
    if (
      !isResidentialSecurityTypeValidated() ||
      !isNonResidentialSecurityTypeValidated() ||
      !isEstabFeeValidated()
    ) {
      return true;
    }
    return false;
  };
  const handleShowClearDetailsModal = () => {
    setShowClearDetailsModal(true);
  }
  const handleClearDetailsModalClose = () => {
    setShowClearDetailsModal(false);
  }
  const handleClearDetailsChange = async options => {
    if(!isVerifying && !isSaving){
      let cancelToken = Axios.CancelToken.source();

      setClearingDetails(true);
      let categoryType = {
        categoryType: "FirstRanking"
      };
      if (userType === ADMIN_USER) {
        await dispatch(
          clearDetailsFieldsAsAdmin({
            id: userId,
            categoryType: categoryType,
            cancelToken: cancelToken
          })
        ).then(resp => {
          if(!resp.status){
            let errors = mustBeArray(resp && resp.data && resp.data.errors);
            errors = errors.reduce((a, b) => {
              return a.concat(b.message);
            }, [])
            setErrorsList(errors);
          } else {
            setErrorsList([]);
            onInitialized();
            delay(500).then(() => {
              getUserProfile();
            });
          }

          setClearingDetails(false);
          setShowClearDetailsModal(false);
        });
      }
      else {
        await dispatch(
          clearDetailsFields({
            id: userId,
            categoryType: categoryType,
            cancelToken: cancelToken
          })
        ).then(resp => {
          if(!resp.status){
            let errors = mustBeArray(resp && resp.data && resp.data.errors);
            errors = errors.reduce((a, b) => {
              return a.concat(b.message);
            }, [])
            setErrorsList(errors);
          } else {
            setErrorsList([]);
            onInitialized();
            delay(500).then(() => {
              getUserProfile();
            });
          }

          setClearingDetails(false);
          setShowClearDetailsModal(false);
        });
      }
    }
  }
  return (
    <React.Fragment>
      <ZeroAvailableWarning
        handleCancel={handleCancel}
        handleOk={handleOk}
        visible={zeroAvailableWarningVisible}
        isCategoryOffered={isCategoryOffered({
          data: profileType === "lender" ? lender : broker,
          category: "firstRanking"
        })}
        pauseLoading={pauseLoading}
      />
      <Visible visible={isNotAvailableToBrokers}>
        <Row>
          <Col span={24}>
            <Alert
              message={LENDER_PROFILE_MORE_THAN_MONTH_LAST_VERIFIED_WARNING}
              type="warning"
            />
          </Col>
        </Row>
        <br />
      </Visible>
      <Visible visible={hasIncompleteMandatoryFields}>
        <Row>
          <Col span={24}>
            <Alert
              message={LENDER_PROFILE_INCOMPLETE_MANATORY_FIELD_WARNING}
              type="warning"
            />
          </Col>
        </Row>
        <br />
      </Visible>
      <Visible visible={isVerifiedButNotSwitchedOn}>
        <Row>
          <Col span={24}>
            <Alert
              message="This lending profile is not available to brokers because it has not been switched on above in the “Categories Offered section”"
              type="warning"
            />
          </Col>
        </Row>
        <br />
      </Visible>
      {!_.isEmpty(errorsList) && (
        <Row className="m-b-16">
          <Col span={24}>
            <Alert
              message={
                <div>
                  <div>
                    The following errors must be resolved before this criteria
                    can be verified:
                  </div>
                  <ul>
                    {errorsList.map((errors, i) => {
                      return <li key={i}>{errors}</li>;
                    })}
                  </ul>
                </div>
              }
              type="warning"
            />
          </Col>
        </Row>
      )}

      <Visible visible={isVerifying}>
        <span className="p-5">Verifying...</span>
      </Visible>
      <Visible visible={isSaving}>
        <span className="p-5">Saving...</span>
      </Visible>
      <Visible visible={isClearingDetails}>
        <span className="p-5">Clearing...</span>
      </Visible>

      <Row gutter={36}>
        <Col span={14}>
          <div className="flex space-between flex-wrap">
            <div className="flex flex-wrap flex-align-center">
              <div className="m-b-6 flex flex-align-center">
                Available &nbsp;
                <InputNumber
                  formatter={value =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, "")}
                  onChange={e =>
                    setFirstRanking(
                      produce(firstRanking, x => {
                        x.available = e;
                      })
                    )
                  }
                  className="w-150 m-l-6 hideNumberPointers"
                  disabled={!userHasPermissionToUpdate}
                  value={firstRanking.available}
                />
              </div>
              <span className="p-b-25" style={{ color: "red" }}>
                *{" "}
              </span>
              <div className="flex flex-wrap flex-align-center">
                <div className="m-b-6 flex flex-align-center">
                <Button
                    onClick={handleShowClearDetailsModal}
                    type="secondary"
                    className="m-lr-6"
                    disabled={isSaving || isVerifying}
                    hidden={!userHasPermissionToUpdate}
                  >
                    Clear Form
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-justify-end flex-wrap m-b-6">
              <Visible visible={isResidential}>
                <div className="flex flex-align-center">
                  LVR (residential) &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.lvrResidentialMin = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 hideNumberPointers"
                    disabled={!userHasPermissionToUpdate}
                    value={firstRanking.lvrResidentialMin}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </Visible>
              <Visible visible={isResidential}>
                <div className="flex flex-align-center m-l-10">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.lvrResidentialMax = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 hideNumberPointers"
                    disabled={!userHasPermissionToUpdate}
                    value={firstRanking.lvrResidentialMax}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </Visible>
            </div>
          </div>
          <div className="flex space-between flex-wrap">
            <div className="flex flex-wrap flex-align-center">
              Preferred parcel size &nbsp;
              <InputNumber
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                onChange={e =>
                  setFirstRanking(
                    produce(firstRanking, x => {
                      x.preferredParcelSizeMin = e;
                    })
                  )
                }
                className="w-150 m-l-4 m-b-6 m-r-10 hideNumberPointers"
                disabled={!userHasPermissionToUpdate}
                value={firstRanking.preferredParcelSizeMin}
              />
              <span className="p-b-18" style={{ color: "red" }}>
                *{" "}
              </span>
              to &nbsp;
              <InputNumber
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
                onChange={e =>
                  setFirstRanking(
                    produce(firstRanking, x => {
                      x.preferredParcelSizeMax = e;
                    })
                  )
                }
                className="w-150 m-l-4 m-b-6 hideNumberPointers"
                value={firstRanking.preferredParcelSizeMax}
                disabled={!userHasPermissionToUpdate}
              />
              <span className="p-b-18" style={{ color: "red" }}>
                *{" "}
              </span>
            </div>
            <Visible visible={isNonResidential}>
              <div className="flex flex-wrap flex-align-center">
                <div>
                  LVR (non-residential) &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.lvrNonResidentialMin = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.lvrNonResidentialMin}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
                <div className="m-l-10">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.lvrNonResidentialMax = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.lvrNonResidentialMax}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </div>
            </Visible>
          </div>
          <Row align="middle" className="m-b-6">
            <Col span={16} className="m-t-16">
              <Card>
                <div className="title-center">Security type</div>

                <Visible visible={lookupsLoading}>
                  <Skeleton active />
                </Visible>
                <Visible visible={!lookupsLoading}>
                  {securityList.map((data, i) => {
                    return (
                      <div className="flex flex-wrap p-b-6" key={i}>
                        <div className="flex-1-1-19">{`${data.name}:`}</div>
                        <div className="flex-1-1-81 ">
                          {mustBeArray(data.securityTypes).map(
                            (security, j) => {
                              return (
                                <Checkbox
                                  checked={
                                    firstRanking.securityTypeKeys.length > 0 &&
                                    firstRanking.securityTypeKeys.includes(
                                      security.id
                                    )
                                  }
                                  disabled={!userHasPermissionToUpdate}
                                  value={security.id}
                                  key={j}
                                  onChange={e =>
                                    setFirstRanking(
                                      produce(firstRanking, x => {
                                        let checkBoxValue = e.target.value;
                                        let checked = e.target.checked;
                                        if (
                                          checked === true &&
                                          !x.securityTypeKeys.includes(
                                            checkBoxValue
                                          )
                                        ) {
                                          x.securityTypeKeys.push(
                                            checkBoxValue
                                          );
                                        } else if (
                                          checked === false &&
                                          x.securityTypeKeys.includes(
                                            checkBoxValue
                                          )
                                        ) {
                                          x.securityTypeKeys = x.securityTypeKeys.filter(
                                            s => s !== checkBoxValue
                                          );
                                        }
                                      })
                                    )
                                  }
                                >
                                  {security.name}
                                </Checkbox>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  })}
                </Visible>
              </Card>
              <StatesForm
                userHasPermissionToUpdate={userHasPermissionToUpdate}
                statesList={statesList}
                state={selectedStates}
                regionsList={regionsList}
                selectedRegion={selectedRegion}
                distancesList={distancesList}
                selectedDistance={selectedDistance}
                fields={firstRanking}
                loading={lookupsLoading}
                handleChangeDistance={({ value, stateId }) =>
                  setFirstRanking(
                    produce(firstRanking, x => {
                      x.stateRegionDetails.forEach(s => {
                        if (s.stateKey === stateId) {
                          s.distanceKey = value;
                        }
                      });
                      return x;
                    })
                  )
                }
                handleChangeState={key => onStateChanged(key)}
                disabled={!userHasPermissionToUpdate}
                handleChangeRegion={({ isChecked, value, stateId }) =>
                  setFirstRanking(
                    produce(firstRanking, x => {
                      x.stateRegionDetails.forEach(s => {
                        if (s.stateKey === stateId) {
                          let regionKeys = s.regionKeys;
                          if (isChecked === true) {
                            if (!regionKeys.includes(value)) {
                              s.regionKeys.push(value);
                            }
                          } else if (isChecked === false) {
                            if (regionKeys.includes(value)) {
                              s.regionKeys = s.regionKeys.filter(
                                rk => rk !== value
                              );
                            }
                          }
                        }
                      });
                      return x;
                    })
                  )
                }
              />
            </Col>
            <span className="p-b-30" style={{ color: "red" }}>
              *{" "}
            </span>

            <Col span={8} className="">
              <div className="flex flex-justify-end flex-wrap m-b-6">
                <div className="flex flex-align-center">
                  Lower Rate &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.rateMin = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.rateMin}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
                <div className="m-l-10 flex flex-align-center">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.rateMax = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.rateMax}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </div>

              <div className="flex flex-justify-end flex-wrap m-b-6">
                <div className="flex flex-align-center">
                  Higher Rate &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.higherRateMin = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.higherRateMin}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
                <div className="m-l-10 flex flex-align-center">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.higherRateMax = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.higherRateMax}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </div>

              <div className="clearfix" />
              <div className="flex flex-justify-end flex-align-center flex-wrap m-b-6">
                <div>
                  Estab Fee &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.estabFeeMin = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 hideNumberPointers"
                    value={firstRanking.estabFeeMin}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
                <div className="m-l-10">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    onChange={e =>
                      setFirstRanking(
                        produce(firstRanking, x => {
                          x.estabFeeMax = e;
                        })
                      )
                    }
                    className="w-80 m-l-4 hideNumberPointers"
                    value={firstRanking.estabFeeMax}
                    disabled={!userHasPermissionToUpdate}
                  />
                </div>
                <span className="p-b-25" style={{ color: "red" }}>
                  *{" "}
                </span>
              </div>
              <div className="clearfix" />
              <div className="flex flex-justify-end flex-wrap m-b-6">
                <div className="flex flex-align-center">
                  Total Cost of Funds &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.totalCostOfFundsLow}
                    disabled={true}
                  />
                </div>
                <div className="m-l-10 flex flex-align-center">
                  to &nbsp;
                  <InputNumber
                    formatter={value => `${value}%`}
                    parser={value => value.replace(/\%\s?|(,*)/g, "")}
                    className="w-80 m-l-4 m-b-6 hideNumberPointers"
                    value={firstRanking.totalCostOfFundsHigh}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="clearfix" />
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row className="m-b-15" gutter={16}>
            <Col span={4}>Point of difference</Col>
            <Col span={19}>
              <Input.TextArea
                rows={4}
                value={firstRanking.pointOfDifference}
                onChange={e =>
                  setFirstRanking(
                    produce(firstRanking, x => {
                      x.pointOfDifference = e.currentTarget.value;
                    })
                  )
                }
                disabled={!userHasPermissionToUpdate}
              />
            </Col>
            <span className="p-b-30" style={{ color: "red" }}>
              *{" "}
            </span>
          </Row>
          <Row className="m-b-12" gutter={16} >
          <Col span={4}>Syndicate loans</Col>
            <Col span={15}>Are you prepared to loan in a Syndicate using the Bransgroves Lawyers double blind syndicate
            deed and Bransgroves Lawyers trustee?</Col>
            <Radio.Group>
              <Radio 
                checked={firstRanking.isSyndicateLender === true} 
                value={true} 
                onChange={e => 
                  setFirstRanking(
                    produce(firstRanking, x=> {
                      x.isSyndicateLender = true;
                    })
                  )
                }
                disabled={!userHasPermissionToUpdate}>
                Yes
              </Radio>
              <Radio 
                checked={firstRanking.isSyndicateLender === false} 
                value={false}
                onChange={e => 
                  setFirstRanking(
                    produce(firstRanking, x=> {
                      x.isSyndicateLender = false;
                      x.advertiseToOtherSyndicateLenders = false;
                    })
                  )
                }
                disabled={!userHasPermissionToUpdate}>
                No
              </Radio>
            </Radio.Group>
          </Row>
          <Visible visible={firstRanking.isSyndicateLender}>
            <Row className="m-b-15" gutter={16}>
              <Col span={4}></Col>
              <Col span={15}>Do you want to advertise this category to other Syndicate Lenders only?</Col>
              <Radio.Group>
                <Radio 
                  checked={firstRanking.advertiseToOtherSyndicateLenders === true} 
                  value={true}
                  onChange={e => 
                    setFirstRanking(
                      produce(firstRanking, x=> {
                        x.advertiseToOtherSyndicateLenders = true;
                      })
                    )
                  }
                  disabled={!userHasPermissionToUpdate}>
                  Yes
                </Radio>
                <Radio 
                  checked={firstRanking.advertiseToOtherSyndicateLenders === false} 
                  value={false}
                  onChange={e => 
                    setFirstRanking(
                      produce(firstRanking, x=> {
                        x.advertiseToOtherSyndicateLenders = false;
                      })
                    )
                  }
                  disabled={!userHasPermissionToUpdate} >
                  No
                </Radio>
              </Radio.Group>
            </Row>
          </Visible>
          <Row className="m-b-6" gutter={16}>
            <Col span={4}>Last Verified</Col>
            <Col span={20}>
              <Input
                disabled={true}
                className="w-170"
                value={asDefaultDisplayFormatDate(
                  firstRanking.lastVerifiedDate
                )}
              />
              {userHasPermissionToUpdate && (
                <React.Fragment>
                  <Button
                    onClick={verify}
                    disabled={
                      isVerifying || hasPendingSave || !_.isEmpty(errorsList)
                    }
                    type="primary"
                    className="m-lr-6"
                  >
                    Verify
                  </Button>
                  <span className="f-s-12">
                    You must verify every calendar month or this matrix will be
                    paused
                  </span>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        visible={showClearDetailsModal}
        onCancel={handleClearDetailsModalClose}
        closable={false}
        footer={[
          <Button
            type="primary"
            key="a"
            onClick={options =>
              handleClearDetailsChange(options)
            }
            disabled={isClearingDetails}
          >
            Okay
          </Button>,
          <Button key="b" 
            onClick={handleClearDetailsModalClose}
            disabled={isClearingDetails}>
            Cancel
          </Button>
        ]}
      >
        <div>
          Are you sure you wish to proceed on clearing the form?
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default connect()(FirstRankingImprovedImpl);
