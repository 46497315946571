import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { loansScenarioLookups } from "../../../actions/lookup";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { mustBeArray } from "../../../libs";

const ScenarioLookup = ({ value, handleFieldChange, className, disabled = false }) => {
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  useEffect(() => {
    dispatchAction(loansScenarioLookups({cancelToken: signal.token})).then(resp => {
      setList(mustBeArray(resp));
    })
  }, []);
  return (
    <div className={`flex flex-align-center ${className || ""}`}>
      <b>Scenario</b>
      <Select
        className="w-180 m-l-6"
        value={value}
        onChange={value =>
          handleFieldChange({ field: "scenarioKey", value })
        } 
        allowClear={false}
        disabled={disabled}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default ScenarioLookup;
