import { BGS_LAWFIRM_ROLE } from "../SystemRoles";

/** @type {import("./types/policy").AuthorizationPolicy} */
export function isLawFirmUserPolicy(roles, permissions, user) {
  let passed = (roles ?? []).some(x => x === BGS_LAWFIRM_ROLE);
  return Promise.resolve(passed);
}

/** @type {import("./types/policy").AuthorizationPolicy} */
export function isNotLawFirmUserPolicy(roles, permissions, user) {
  let passed = !(roles ?? []).some(x => x === BGS_LAWFIRM_ROLE);
  return Promise.resolve(passed);
}


/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isLawFirmOwnProfilePolicy(currentlyViewedLawFirmId) {
  return async function(roles, permissions, user) {
    if (
      currentlyViewedLawFirmId === null ||
      currentlyViewedLawFirmId === undefined
    ) {
      return false;
    }

    const isLawFirm = await isLawFirmUserPolicy(roles, permissions, user);

    return (
      isLawFirm &&
      parseInt(currentlyViewedLawFirmId) === parseInt(user["app.user.lawfirmid"])
    );
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isNotLawFirmOwnProfilePolicy(currentlyViewedLawFirmId) {
  return async function(roles, permissions, user) {
    if (
      currentlyViewedLawFirmId === null ||
      currentlyViewedLawFirmId === undefined
    ) {
      return false;
    }

    const isOwnProfile = await isLawFirmOwnProfilePolicy(
      currentlyViewedLawFirmId
    )(roles, permissions, user);

    return !isOwnProfile;
  };
}
