import { Icon } from 'antd';
import React from 'react';

const CustomSorter = ({
  className,
  sortType
}) => {
  return(
    <span style={{display:'flex', flexDirection: "column"}}  className={`custom-sorter ${className}`}>
      <Icon type="caret-up" className={sortType === "asc" ? "active" : ""} />
      <Icon type="caret-down" className={`m-t-2 ${sortType === "desc" ? "active" : ""}`} />
    </span>
  )
}

export default CustomSorter;
