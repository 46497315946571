import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import {
  IMarketingCompanyEmailPayload,
  IMarketingTestEmailPayload,
  IMarketingUserEmailPayload,
  InvalidResponse,
  ValidResponse
} from "../libs/types";
import Axios from "axios";
import { reject } from "lodash";

export const sendEmailToCompany = ({
  payload
}: {
  payload: FormData;
}) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls?.MARKETING_SEND_COMAPANY_URL || ""
    });
    const response: any = await http.postFormData(url, payload);

    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response);
    } else {
      return ResponseHandler.inValidObject(response);
    }
  }
};

export const sendEmailToUser = ({
  payload
}: {
  payload: FormData;
}) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls?.MARKETING_SEND_USER_URL || ""
    });
    const response: any = await http.postFormData(url, payload);

    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response);
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

export const sendEmailToTest = ({
  payload
}: {
  payload: FormData;
}) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls?.MARKETING_SEND_TEST_URL || ""
    });
    const response: any = await http.postFormData(url, payload);

    if (ResponseHandler.isValidStatus(response)) {
      return ResponseHandler.validObject(response);
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};
