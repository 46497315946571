import { GET_BROKER_PROFILE } from "../constants/action-type/brokers";
import {
  UPDATE_CURRENT_PROFILE_LOADING,
  CLEAR_CURRENT_PROFILE_PICTURE,
  CLEAR_PROFILE_DETAILS,
  UPDATE_ACTIVE_PORTRAIT,
  GET_LAW_FIRM_PROFILE
} from "../constants/action-type/individual";
import { GET_LENDER_PROFILE } from "../constants/action-type/lenderProfile";
import {
  SET_ACTIVE_PROFILE,
  SET_TAB_PROFILE_SETTINGS
} from "../constants/action-type/profile";

const initialState = {
  details: {},
  matchesList: [],
  matchesTotal: 0,
  lender: {},
  broker: {},
  firstRanking: {},
  activeProfile: {},
  fileNotesList: [],
  fileNotesTotal: 0,
  profileLoading: false,
  activePortrait: {},
  lawFirm: {},
};

export default function profileReducer(state = initialState, action) {
  const { type, data, total } = action;
  switch (type) {
    case "SAVE_PROFILE": {
      return {
        ...state,
        details: {
          ...data
        }
      };
    }
    case SET_ACTIVE_PROFILE:
      return {
        ...state,
        activeProfile: {
          ...data
        }
      };
    case "CLEAR_PROFILE": {
      return {
        ...state,
        details: {},
        matchesList: [],
        matchesTotal: 0,
        lender: {},
        broker: {},
        lawFirm: {},
        firstRanking: {},
        fileNotesList: [],
        fileNotesTotal: 0
      };
    }
    case GET_LENDER_PROFILE: {
      return {
        ...state,
        lender: {
          ...data
        },
        broker: {},
        lawFirm: {}
      };
    }
    case GET_LAW_FIRM_PROFILE: {
      return {
        ...state, lawFirm: {...data}, lender: {}, broker: {}
      }
    }
    case "GET_LENDERS_FIRST_RANKING_PROFILE": {
      return {
        ...state,
        firstRanking: {
          ...data
        }
      };
    }
    case "GET_LENDERS_SECOND_RANKING_PROFILE": {
      return {
        ...state,
        secondRanking: {
          ...data
        }
      };
    }
    case "GET_LENDERS_CONSTRUCTION_PROFILE": {
      return {
        ...state,
        construction: {
          ...data
        }
      };
    }
    case "GET_LENDERS_MEZZANINE_PROFILE": {
      return {
        ...state,
        mezzanine: {
          ...data
        }
      };
    }
    case "GET_LENDERS_LANDBANK_PROFILE": {
      return {
        ...state,
        landbank: {
          ...data
        }
      };
    }
    case "GET_LENDERS_RURAL_PROFILE": {
      return {
        ...state,
        rural: {
          ...data
        }
      };
    }
    case "GET_LENDERS_PROFESSIONAL_DETAIL_PROFILE": {
      return {
        ...state,
        professionalDetails: {
          ...data
        }
      };
    }
    case GET_BROKER_PROFILE: {
      return {
        ...state,
        broker: {
          ...data
        },
        lender: {},
        lawFirm: {}
      };
    }
    case CLEAR_CURRENT_PROFILE_PICTURE: {
      return {
        ...state,
        broker: {
          ...state.broker,
          imageFile: null
        },
        lender: {
          ...state.lender,
          imageFile: null
        }
      };
    }
    case CLEAR_PROFILE_DETAILS: {
      return {
        ...state,
        broker: {},
        lender: {},
        lawFirm: {}
      };
    }
    case SET_TAB_PROFILE_SETTINGS: {
      return {
        ...state,
        broker: {
          ...state.broker,
          resetTab: action.resetActiveTab
        },
        lender: {
          ...state.lender,
          resetTab: action.resetActiveTab
        }
      };
    }
    // Master Switch
    case "LENDER_MASTER_SWITCH_TURN_OFF": {
      return {
        ...state,
        lender: {
          ...state.lender,
          isMasterSwitchOn: false
        }
      };
    }
    case "LENDER_MASTER_SWITCH_TURN_ON": {
      return {
        ...state,
        lender: {
          ...state.lender,
          isMasterSwitchOn: true
        }
      };
    }
    case "BROKER_MASTER_SWITCH_TURN_OFF": {
      return {
        ...state,
        broker: {
          ...state.broker,
          isMasterSwitchOn: false
        }
      };
    }
    case "BROKER_MASTER_SWITCH_TURN_ON": {
      return {
        ...state,
        broker: {
          ...state.broker,
          isMasterSwitchOn: true
        }
      };
    }


    case "LAWFIRM_MASTER_SWITCH_TURN_OFF": {
      return {
        ...state,
        lawFirm: {
          ...state.lawFirm,
          isMasterSwitchOn: false
        }
      };
    }
    case "LAWFIRM_MASTER_SWITCH_TURN_ON": {
      return {
        ...state,
        lawFirm: {
          ...state.lawFirm,
          isMasterSwitchOn: true
        }
      };
    }
    // Profile Status
    case "LENDER_PROFILE_STATUS_TURN_OFF": {
      return {
        ...state,
        lender: {
          ...state.lender,
          isProfileActive: false
        }
      };
    }
    case "LENDER_PROFILE_STATUS_TURN_ON": {
      return {
        ...state,
        lender: {
          ...state.lender,
          isProfileActive: true
        }
      };
    }
    case "BROKER_PROFILE_STATUS_TURN_OFF": {
      return {
        ...state,
        broker: {
          ...state.broker,
          isProfileActive: false
        }
      };
    }
    case "BROKER_PROFILE_STATUS_TURN_ON": {
      return {
        ...state,
        broker: {
          ...state.broker,
          isProfileActive: true
        }
      };
    }
    case "LAWFIRM_PROFILE_STATUS_TURN_OFF": {
      return {
        ...state,
        lawFirm: {
          ...state.lawFirm,
          isProfileActive: false
        }
      };
    }
    case "LAWFIRM_PROFILE_STATUS_TURN_ON": {
      return {
        ...state,
        lawFirm: {
          ...state.lawFirm,
          isProfileActive: true
        }
      };
    }
    case "GET_BROKERS_PERSONAL_DETAIL_PROFILE":
    case "GET_LAW_FIRM_PERSONAL_DETAIL_PROFILE":
    case "GET_BROKERS_PROFESSIONAL_DETAIL_PROFILE":
    case "GET_LENDERS_PERSONAL_DETAIL_PROFILE": {
      return {
        ...state,
        personalDetails: {
          ...data
        }
      };
    }

    case "GET_LENDERS_FILE_NOTES":
    case "GET_LAW_FIRM_FILE_NOTES":
    case "GET_BROKERS_FILE_NOTES":
    case "GET_NOTES_SYSTEM_USER":
    case "GET_NOTES_NON_SYSTEM_USER": {
      return {
        ...state,
        fileNotesList: data,
        fileNotesTotal: total
      };
    }

    case "GET_MONTHLYSCORES_SYSTEM_USER": {
      return {
        ...state,
        fileNotesList: data,
        fileNotesTotal: total
      }
    }

    case UPDATE_CURRENT_PROFILE_LOADING: {
      return {
        ...state,
        profileLoading: data
      }
    }

    case UPDATE_ACTIVE_PORTRAIT: {
      return {
        ...state,
        activePortrait: data
      }
    }

    default:
      return state;
  }
}
