import { Button, Checkbox, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { loansInterestLookups } from "../../../actions/lookup";
import Axios from "axios";
import { mustBeArray } from "../../../libs";
import { ILookupItem } from "../../../libs/types";

interface InterestLookupProps {
  value: string;
  handleFieldChange: (payload: { field: string; value: any }) => void;
  generalView?: boolean;
  className?: string;
  disabled?: boolean
}

export const InterestLookup = ({
  value,
  className,
  handleFieldChange,
  disabled = false
}: InterestLookupProps) => {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();
  const [list, setList] = useState<ILookupItem[]>([]);

  useEffect(() => {
    dispatch(loansInterestLookups({ cancelToken: signal.token })).then(x => {
      setList(mustBeArray(x));
    });
  }, []);

  return (
    <div className={`flex flex-align-center m-r-20 ${className || ""}`}>
      <b>Interest (Payable) </b>
      <Select
        className="w-150 m-l-6"
        value={value}
        onChange={(value:string) => handleFieldChange({ field: "interestKey", value })}
        allowClear={false}
        disabled={disabled}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InterestLookup);
