import { connect, useSelector } from "react-redux";
import { Button, Col, Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { updateHeader } from "../../actions/member";
import {
  DecoratedFormControl,
  FormDecoratorProvider
} from "../../components/Common/decoratedFormControl";
import Password from "antd/lib/input/Password";
import { changeLoggedInUserPassword } from "../../actions/authorization";
import history from "../../routes/history";
import FormItem from "antd/lib/form/FormItem";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";

const ChangePasswordImpl = props => {
  const { dispatch, form } = props;
  const [processing, setProcessing] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const user = useSelector(state => state?.authorization?.user);
  const header = useSelector(state => state.member.header);
  const [isUserLawfirm, setIsUserLawfirm] = useState(false);

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(user.roles, user.permissions, user);
    setIsUserLawfirm(isLawfirm);
  }

  useEffect(() => {
    checkIfUserIsLawfirm();
    dispatch(
      updateHeader({
        header: {
          title: "Change Password",
          action: "",
          page: "change-password",
          enableBack: false,
          showSettings: false,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: true,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
  }, []);

  // Override notes and searchbox for specific users
  useEffect(()=>{
    dispatch(
      updateHeader({
        header: {
          ...header,
          showNotesButton: !isUserLawfirm,
          showSearchBox: !isUserLawfirm
        }
      })
    );
  }, [isUserLawfirm])

  useEffect(() => {
    const fields = form.getFieldsError();
    const fieldNames = Object.keys(fields);
    const isDirty = fieldNames.every(x => form.isFieldTouched(x));
    const formInvalid = fieldNames.some(x => fields[x]);

    const matchStatus = passwordMatchValidationStatus();
    if (isDirty) {
      setIsValid(!formInvalid && matchStatus.status === "success");
    }
  }, [form.getFieldsError()]);

  const changePassword = () => {
    form.validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setProcessing(true);

      const response = await dispatch(changeLoggedInUserPassword(values));

      if (response.status) {
        message.success("Successfully changed your password.");
        history.push("/");
      }
      setProcessing(false);
    });
  };

  const passwordHasInput = () => {
    const pass = form.getFieldValue("newPassword");
    return !!pass;
  };

  const passwordMatchValidationStatus = () => {
    const pass = form.getFieldValue("newPassword");
    const confirmPass = form.getFieldValue("confirmNewPassword");

    if (passwordHasInput() && confirmPass !== undefined && confirmPass === "") {
      return {
        status: "error",
        message: "Please enter your password again"
      };
    }

    if (
      passwordHasInput() &&
      pass !== confirmPass &&
      confirmPass !== undefined
    ) {
      return {
        status: "error",
        message: "Password does not match"
      };
    }

    return {
      status: "success",
      message: ""
    };
  };

  const confirmPasswordValidationStatus = passwordMatchValidationStatus();
  const labelSpan = { span: 8 };
  const columnSpan = { offset: 5, span: 10 };
  return (
    <div className="flex flex-center label-right m-t-30">
      <Form style={{ minWidth: "800px" }} colon={false} hideRequiredMark={true}>
        <FormDecoratorProvider decorator={form.getFieldDecorator}>
          <DecoratedFormControl
            label=""
            name="currentPassword"
            labelCol={labelSpan}
            wrapperCol={columnSpan}
            rules={[
              {
                required: true,
                message: "Please enter your current password"
              }
            ]}
          >
            <Password placeholder="Current Password" />
          </DecoratedFormControl>
          <DecoratedFormControl
            label=""
            name="newPassword"
            labelCol={labelSpan}
            wrapperCol={columnSpan}
            rules={[
              { required: true, message: "Please enter your new password" }
            ]}
          >
            <Password placeholder="New Password" />
          </DecoratedFormControl>
          <DecoratedFormControl
            label=""
            name="confirmNewPassword"
            labelCol={labelSpan}
            wrapperCol={columnSpan}
            validateStatus={confirmPasswordValidationStatus.status}
            help={confirmPasswordValidationStatus.message}
          >
            <Password placeholder="Confirm New Password" />
          </DecoratedFormControl>
          <FormItem labelCol={labelSpan} wrapperCol={columnSpan}>
            <Col span={processing ? 8 : 6}>
              <Button
                icon="file"
                type="primary"
                className="f-w-500"
                loading={processing}
                disabled={!isValid}
                onClick={() => changePassword()}
              >
                Save
              </Button>
            </Col>
            <Col span={6}>
              <Button
                className="f-w-500"
                disabled={processing}
                onClick={() => history.push("/")}
              >
                Cancel
              </Button>
            </Col>
          </FormItem>
        </FormDecoratorProvider>
      </Form>
    </div>
  );
};

export const ChangePasswordPage = connect()(
  Form.create({ name: "Change Password" })(ChangePasswordImpl)
);
