import React, { useEffect, useState } from "react";
import { FinderFilterFieldChangeHandlerParams } from "../../../libs/types";
import { Checkbox } from "antd";
import { mustBeArray } from "../../../libs";
import { useDispatch } from "react-redux";
import Axios from "axios";
import authService from "../../../actions/AuthorizeService";
import { finderCreditLookups } from "../../../actions/finderUtility";

type CreditProps = {
  handleFieldChange: (
    params: FinderFilterFieldChangeHandlerParams<any[]>
  ) => void;
  value: number[];
};

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

export default function Credit({
  value = [],
  handleFieldChange = () => {}
}: CreditProps) {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [list, setList] = useState<Option[]>([]);

  async function getLookupValues() {
    const isLoggedIn = await authService.isAuthenticated();
    dispatch(
      finderCreditLookups({
        cancelToken: signal.token,
        generalView: !isLoggedIn
      })
    ).then(x => {
      const translated = x.map(v => ({
        value: v.id,
        label: v.name
      }));
      setList(mustBeArray(translated));
    });
  }

  useEffect(() => {
    getLookupValues();
  }, []);

  return (
    <div>
      <Checkbox.Group
        value={mustBeArray(value)}
        onChange={(value = []) => handleFieldChange({ field: "credit", value })}
      >
        {list.map(x => {
          return (
            <div key={x.value}>
              <Checkbox value={x.value}>{x.label}</Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    </div>
  );
}
