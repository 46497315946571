import { Table } from "antd";
import { PaginationConfig } from "antd/lib/pagination";
import { ColumnProps, SorterResult } from "antd/lib/table";
import React from "react";
import { AllUsersListItem } from "../../libs/types";
import { asDefaultDisplayFormatDate } from "../../libs";
import history from "../../routes/history";

type AllUsersListProps = {
  loading: boolean;
  total: number;
  page: number;
  handleTableChange: (
    pagination: PaginationConfig,
    sorter: SorterResult<AllUsersListItem>
  ) => void;
  data: AllUsersListItem[];
};

function AllUsersList({
  loading,
  page = 1,
  total = 0,
  handleTableChange,
  data = []
}: AllUsersListProps) {
  const formatDecimalToCurrency = (value: number) => {
    return value
      ? value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : "$0";
  };

  function displayFinancier(value: string | null, record: AllUsersListItem) {
    let financier = "#";
    if (record.profile === "LENDER" || record.profile === "DUAL") {
      financier = `/users/${record.lenderId}/lender`;
    } else if (record.profile === "BROKER") {
      financier = `/users/${record.brokerId}/broker`;
    }

    return value ? (
      <a href={financier} target="_blank">
        {value || "N/A"}
      </a>
    ) : (
      <span>N/A</span>
    );
  }

  function displayLoan(value: string | null, record: AllUsersListItem) {
    return value ? (
      <a href={`/loans/${record.lastLoanId}/view`} target="_blank">
        {value || "N/A"}
      </a>
    ) : (
      <span>N/A</span>
    );
  }

  function displayCriteria(value:string){
    return <span style={{color: value.includes('-') ? "red" : ""}}>
      {value}
    </span>
  }

  function displayFundsAvailable(value:number | null, record: AllUsersListItem){
    if(record.criteria === 'N/A'){
      return "N/A"
    }

    return formatDecimalToCurrency(value ?? 0);
  }

  function displayLastLoginBy(value:string | null, record: AllUsersListItem){
    return value || "N/A";
  }

  function displayLastLogin(value:any | null, record: AllUsersListItem){

    return asDefaultDisplayFormatDate(value) || "N/A";
  }

  const colTemplate: ColumnProps<AllUsersListItem>[] = [
    {
      title: "Financier",
      dataIndex: "companyName",
      render: displayFinancier,
      width: "8%"
    },
    {
      title: "Profile",
      dataIndex: "profile"
    },
    {
      title: "User 1",
      dataIndex: "user1",
      render: displayFinancier,
      width: "8%"
    },
    {
      title: "User 2",
      dataIndex: "user2",
      render: displayFinancier,
      width: "8%"
    },
    {
      title: "User 3",
      dataIndex: "user3",
      render: displayFinancier,
      width: "8%"
    },
    {
      title: "Last Call With",
      dataIndex: "lastCallWith",
      width: "8%"
    },
    {
      title: "Last Call",
      dataIndex: "lastCallDate",
      render: value => asDefaultDisplayFormatDate(value)
    },
    {
      title: "Last Login",
      dataIndex: "lastLoginDate",
      render: displayLastLogin
    },
    {
      title: "Last Login By",
      dataIndex: "lastLoginBy",
      width: "8%",
      render: displayLastLoginBy
    },
    {
      title: "Criteria",
      dataIndex: "criteria",
      render: displayCriteria
    },
    {
      title: "Last Loan",
      dataIndex: "lastLoanNo",
      render: displayLoan
    },
    {
      title: "Last Date",
      dataIndex: "lastLoanDate",
      render: value => asDefaultDisplayFormatDate(value)
    },
    {
      title: "Last Suburb",
      dataIndex: "lastLoanSuburb"
    },
    {
      title: "Last Partner",
      dataIndex: "lastPartner"
    },
    {
      title: "Last Principal",
      dataIndex: "lastPrincipal",
      render: value => formatDecimalToCurrency(value)
    },
    {
      title: "Funds Available",
      dataIndex: "fundsAvailable",
      render: displayFundsAvailable
    }
  ];

  return (
    <Table
      dataSource={data}
      loading={loading}
      columns={colTemplate}
      pagination={{ current: page, pageSize: 20, total }}
      onChange={(pagination: PaginationConfig, filters, sorter) => {
        handleTableChange(pagination, sorter);
      }}
    />
  );
}

export default AllUsersList;
