import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Navbar from "../components/navBar";
import { Layout } from "antd";
import { HandleProgressBar } from "../Common/progressBar";
import * as actions from "../actions/member";
import Loading from "../components/loading";
import PageHeader from "../components/PageHeader";

const { Header, Content} = Layout;

class TemplateNavbar extends Component {
  state = {
    loading: false,
    responsive: false,
    collapsed: false
  };

  render() {
    const { loading } = this.state;
    const {index, member, generalView} = this.props;
    const isFinderUtility = window.location.pathname?.includes("finder-utility");
    return loading ? (
      <Loading />
    ) : (
      <Layout
        style={this.state.responsive ? { marginLeft: 0 } : {}}
        className={isFinderUtility ? "finder-utility-header" : ""}
      >
          <Header>
            <Navbar {...this.props} />
          </Header>

          <Content>
            <HandleProgressBar />
            <PageHeader index={index} member={member} 
              updateHeader={this.props.updateHeader} 
              profileType={this.props.profileType}
              match={this.props.match}
              generalView={generalView}
              isFinderUtility={isFinderUtility}
              />
            <div className="content p-lr-16 p-b-16">{this.props.children}</div>
          </Content>
      </Layout>
    );
  }
}

TemplateNavbar.propTypes = {
  children: PropTypes.element.isRequired
};

function mapStateToProps(state) {
  return { member: state.member };
}

export default connect(
  mapStateToProps,
  actions
)(TemplateNavbar);
