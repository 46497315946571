const initialState = {};

export default function uiNavbar(state = initialState, action) {
  const {type,data} = action;
  switch (type) {
    case 'SET_HEADER':
      return {
        ...state,
        data:data
      };
    case 'UNSET_HEADER':
      return {
        ...state,
        data:{}
      };
    default:
      return state;
  }
}
