import React, { useEffect, useState } from "react";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { isLenderOwnProfilePolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import axios from "axios";
import { connect } from "react-redux";
import {
  ADMIN_USER,
  DUAL_USER,
  LAW_FIRM_USER
} from "../../constants/systemUsers";
import { AU_DATE_FORMAT } from "../../constants/moment";
import { LENDER_USER, BROKER_USER } from "../../constants/systemUsers";
import { asDefaultDisplayFormatDate, mustBeArray } from "../../libs/utilities";
import { Table } from "antd";
import {
  getMatchingLoans,
  getPresettledLoans,
  getCurrentLoans,
  getDischargedLoans,
  getDoneWithMeLoans
} from "../../actions/loans";
import history from "../../routes/history";

import useLoansTableColumns from "../../Common/Hooks/ColTemplate/useLoansTableColumns";
import { isLawFirmUserPolicy } from "../../Common/Authorization/policies/isLawFirmUserPolicy";

const LoansTables = ({
  user,
  userId,
  activeSubKey,
  profileType,
  getMatchingLoans,
  getPresettledLoans,
  getCurrentLoans,
  getDischargedLoans,
  getDoneWithMeLoans,
  loanSearchQueryParams,
  lender,
  generalView = false
}) => {
  const [loading, setListLoading] = useState(false);
  const [loanList, setLoanList] = useState([]);
  const [sortInfo, setSortInfo] = useState({});
  const [isBrokerProfile, setIsBrokerProfile] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    pageSize: 20
  });
  const [isUserLawfirm, setIsUserLawfirm] = useState(false);

  const signal = axios.CancelToken.source();

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field
      };
    }
    queryParams = {
      ...paginationConfig
    };
    if (loanSearchQueryParams != null) {
      queryParams = { ...queryParams, ...loanSearchQueryParams };
    }
    return queryParams;
  };

  const handleFetch = async () => {
    setListLoading(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";

    const isOwnLenderProfile = await isLenderOwnProfilePolicy(lender.id)(
      user.roles,
      user.permissions,
      user
    );
    // setIsAdmin(userType === ADMIN_USER);
    //for if conditions: Set the list to be retrieved based from the passed activeKey
    if (activeSubKey === "matchingYourSearchLoans") {
      getMatchingLoans({
        id: userId,
        userType: userType,
        cancelToken: signal.token,
        profileType: profileType,
        queryParams: getQueryParams(),
        generalView
      }).then(resp => {
        setListLoading(false);
        if (resp.status) {
          setLoanList(mustBeArray(resp.data));
          setTotalRecords(resp.total);
        }
      });
    } else if (activeSubKey === "preSettlementLoans") {
      getPresettledLoans({
        id: userId,
        userType: userType,
        cancelToken: signal.token,
        profileType: profileType,
        queryParams: getQueryParams()
      }).then(resp => {
        setListLoading(false);
        if (resp.status) {
          setLoanList(mustBeArray(resp.data));
          setTotalRecords(resp.total);
        }
      });
    } else if (activeSubKey === "currentLoans") {
      if (
        profileType === BROKER_USER ||
        profileType === LAW_FIRM_USER ||
        ((profileType === LENDER_USER &&
          (userType == ADMIN_USER || isOwnLenderProfile)) ||
          (lender && lender.showCurrentLoanBook))
      ) {
        getCurrentLoans({
          id: userId,
          profileType: profileType,
          userType: userType,
          cancelToken: signal.token,
          queryParams: getQueryParams(),
          generalView
        }).then(resp => {
          setListLoading(false);
          if (resp.status) {
            setLoanList(mustBeArray(resp.data));
            setTotalRecords(resp.total);
          }
        });
      }
    } else if (activeSubKey === "dischargedLoans") {
      if (
        profileType === BROKER_USER ||
        profileType === LAW_FIRM_USER ||
        ((profileType === LENDER_USER &&
          (userType == ADMIN_USER || isOwnLenderProfile)) ||
          (lender && lender.showDischargedLoanBook))
      ) {
        getDischargedLoans({
          id: userId,
          profileType: profileType,
          userType: userType,
          cancelToken: signal.token,
          queryParams: getQueryParams(),
          generalView
        }).then(resp => {
          setListLoading(false);
          if (resp.status) {
            setLoanList(mustBeArray(resp.data));
            setTotalRecords(resp.total);
          }
        });
      }
    } else if (activeSubKey === "doneWithMeLoans") {
      if (
        profileType === LENDER_USER ||
        profileType === BROKER_USER ||
        profileType === DUAL_USER
      ) {
        getDoneWithMeLoans({
          id: userId,
          profileType,
          cancelToken: signal.token,
          queryParams: getQueryParams()
        }).then(resp => {
          setListLoading(false);
          if (resp.status) {
            setLoanList(mustBeArray(resp.data));
            setTotalRecords(resp.total);
          }
        });
      }
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationConfig({
      pageSize: 20,
      page: pagination.current
    });

    setSortInfo({
      field: sorter.field,
      order: !sorter.order
        ? undefined
        : sorter.order === "ascend"
        ? "asc"
        : "desc"
    });
  };

  const formatDecimalToCurrency = value => {
    return value
      ? value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,

          maximumFractionDigits: 0
        })
      : "$0";
  };

  const formatDecimalWithDefaultValue = (value, precision) => {
    return value
      ? value.toFixed(precision)
      : value == 0
      ? (0).toFixed(precision)
      : "";
  };

  const formatDecimal2 = value => {
    return value ? value.toFixed(2) : "";
  };

  const tablePagination = {
    current: paginationConfig?.page,
    total: totalRecords,
    pageSize: paginationConfig.pageSize ?? 20
  };

  const handleProfileClick = (record, actionSource) => {
    if (actionSource === "broker") {
      history.push(
        !generalView
          ? `/users/${record.attributedBrokerId}/broker`
          : `/general/brokers/${record.attributedBrokerId}`
      );
    } else if (actionSource === "lender") {
      history.push(`/users/${record.attributedLenderId}/lender`);
    } else if (actionSource === "lenderList") {
      history.push(`/users/${record.loanLenderId}/lender`);
    } else if (actionSource === "brokerList") {
      history.push(
        !generalView
          ? `/users/${record.loanBrokerId}/broker`
          : `/general/brokers/${record.loanBrokerId}`
      );
    }
  };

  const handleLoanNumberClick = record => {
    history.push(
      generalView ? `/general/loans/${record.id}` : `/loans/${record.id}/view`
    );
  };

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(user.roles, user.permissions, user);
    setIsUserLawfirm(isLawfirm);
  }

  useEffect(
    () => {
      let isBroker = profileType === BROKER_USER;
      setIsBrokerProfile(isBroker);
      checkIfUserIsLawfirm();
      if (
        activeSubKey === "matchingYourSearchLoans" ||
        activeSubKey === "preSettlementLoans" ||
        activeSubKey === "currentLoans" ||
        activeSubKey === "dischargedLoans" ||
        activeSubKey === "doneWithMeLoans"
      ) {
        setLoanList([]);
        handleFetch();
      }
    },
    [userId, activeSubKey, paginationConfig] //function to retrieve list for tab
  );

  const { colTemplate, expandedRowLoanListColumns } = useLoansTableColumns({
    profileType,
    asDefaultDisplayFormatDate,
    formatDecimal2,
    formatDecimalToCurrency,
    formatDecimalWithDefaultValue,
    generalView,
    handleLoanNumberClick,
    handleProfileClick,
    sortInfo,
    isUserLawfirm
  });
  return (
    <div>
      {/* FOR BROKER PROFILE VIEW */}
      <Table
        className="loan-tabs-table"
        style={{ display: "", whiteSpace: "nowrap" }}
        expandIconAsCell={true} // expandIconColumnIndex={record => (record.loanLendersBrokers > 1 ? 0 : "")}
        expandedRowRender={
          !generalView
            ? record =>
                record.loanLendersBrokers.length > 1 && (
                  <Table
                    columns={expandedRowLoanListColumns}
                    dataSource={mustBeArray(record.loanLendersBrokers)}
                    pagination={false}
                    size="extra small"
                  ></Table>
                )
            : undefined
        }
        columns={colTemplate}
        dataSource={mustBeArray(loanList)}
        pagination={tablePagination}
        onChange={handleTableChange}
        loading={loading}
      />

      {/* FOR LENDER PROFILE VIEW */}
      <Table
        style={{ display: "none", whiteSpace: "nowrap" }}
        className="loan-tabs-table"
        columns={colTemplate}
        dataSource={mustBeArray(loanList)}
        pagination={tablePagination}
        onChange={handleTableChange}
        loading={loading}
        size="small"
      ></Table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender,
    broker: state.profile.broker,
    lawFirm: state.profile.lawFirm
  };
};
const mapDispatchToProps = {
  getMatchingLoans,
  getPresettledLoans,
  getCurrentLoans,
  getDischargedLoans,
  getDoneWithMeLoans
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoansTables);
