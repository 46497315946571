import React, { createContext, useContext, forwardRef } from "react";
import { Form } from "antd";

export const FormDecoratorContext = createContext();
export const FormDecoratorProvider = ({ decorator, children }) => (
  <FormDecoratorContext.Provider value={decorator}>
    {children}
  </FormDecoratorContext.Provider>
);

export const useDecoratorContext = () => useContext(FormDecoratorContext);

export const DecoratedFormControl = forwardRef(
  /**
   * @param {{ decoratorFunc, name: string, rules: any[], label: string, className: SVGFESpecularLightingElement, validateStatus: "error" | "validating" | "success" | "warning", help: string, valuePropName?: string, labelCol: any, wrapperCol: any }}
   */
  (
    {
      decoratorFunc,
      name,
      rules,
      label,
      validateStatus,
      help,
      className,
      valuePropName = "value",
      wrapperCol,
      labelCol,
      children
    },
    ref
  ) => {
    // If Decorator func is available via props, we use that. Else we try getting it from a context
    if (decoratorFunc) {
      return (
        <Form.Item
          ref={ref}
          validateStatus={validateStatus}
          help={help}
          label={label}
          className={className}
          wrapperCol={wrapperCol}
          labelCol={labelCol}
        >
          {decoratorFunc(name, { rules: rules })(children)}
        </Form.Item>
      );
    }

    const decorator = useDecoratorContext(FormDecoratorContext);

    if (decorator) {
      return (
        <Form.Item
          ref={ref}
          validateStatus={validateStatus}
          help={help}
          label={label}
          className={className}
          wrapperCol={wrapperCol}
          labelCol={labelCol}
        >
          {decorator(name, { rules: rules, valuePropName })(children)}
        </Form.Item>
      );
    }

    throw new Error("Could not find any form decorator to use");
  }
);
