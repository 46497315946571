const environment = {
  baseurl: parseEnv(window.env.BE_URL) ?? process.env.REACT_APP_BE_URL ?? "https://localhost:5001",
  callback_url: parseEnv(window.env.CALLBACK_URL) ?? process.env.REACT_APP_FE_CALLBACK_URL ?? "http://localhost:3000/confirm-login",
  redirect_url: parseEnv(window.env.FE_REDIRECT_URL) ?? process.env.REACT_APP_FE_REDIRECT_URL ??
  "http://localhost:3000/confirm-logout"
};

function parseEnv(env) {
  // Replacement Marker, ignore as it wasn't replaced
  if (/#\[.*\]#/.test(env)) {
    return null;
  }

  return env;
}

export default environment;
