import { Card, Form, Radio } from "antd";
import React from "react";

const SyndicateFunders = ({
  getFieldDecorator,
  canEdit
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative">
        <div className="title-center card-title">Syndicate Funders</div>
      <Form.Item label="1st Ranking">
        {getFieldDecorator("syndicateFundersFirstRanking")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="2nd Ranking">
        {getFieldDecorator("syndicateFundersSecondRanking")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Construction">
        {getFieldDecorator("syndicateFundersConstruction")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Mezzanine">
        {getFieldDecorator("syndicateFundersMezzanine")(
          <Radio.Group disabled={!canEdit}> 
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Land Bank">
        {getFieldDecorator("syndicateFundersLandBank")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Rural">
        {getFieldDecorator("syndicateFundersRural")(
          <Radio.Group disabled={!canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Card>
  );
};
export default SyndicateFunders;
