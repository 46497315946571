import { LIST_TRIGGERS, LIST_TRIGGERS_LOOKUP } from "../constants/action-type/triggers";

const initState = {
  triggerList: [],
  lookup: []
};

/**
 *
 * @param {{ type: string, data: any, total: number }} action
 */
export default function triggersReducer(
  state = initState,
  action
) {
  const { type, data } = action;
  switch (type) {
    case LIST_TRIGGERS:
      return {
        ...state,
        triggerList: data ?? [],
      };
    case LIST_TRIGGERS_LOOKUP:
      return {
        ...state,
        lookup: data ?? []
      };
    default:
      return state;
  }
}
