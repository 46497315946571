import React from "react";
import NumberInput from "../../../Common/NumberInput";

const LowAndHighPercentageFilters = ({ value, handleFieldChange }) => {
  return (
    <div className="flex flex-align-center flex-wrap gap-12">
      <div>
        <div className="bold">From:</div>
        <NumberInput
          formatter={e => `${e}%`}
          parser={e => e.replace(/\%\s?|(,*)/g, "")}
          className=" hideNumberPointers w-180"
          value={value?.low}
          onChange={e => handleFieldChange({ field: "lowerRate", value: e, sub: "low" })}
        />
      </div>
      <div>
        <div className="bold">To:</div>
        <NumberInput
          formatter={e => `${e}%`}
          parser={e => e.replace(/\%\s?|(,*)/g, "")}
          className="hideNumberPointers w-180"
          value={value?.high}
          onChange={e => handleFieldChange({ field: "lowerRate", value: e, sub: "high" })}
        />
      </div>
    </div>
  );
};

export default LowAndHighPercentageFilters;
