import { Col, Form, Button, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateHeader } from "../../actions/member";
import {
  DayActivity,
  FinderFunction,
  FundsAvailableEmail,
  LoanTab,
} from "../../components/PrivacySettings";
import { Row } from "antd/es/grid";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Loading from "../../components/loading";
import { isLenderUserPolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import authService from "../../actions/AuthorizeService";
import {
  ADMIN_USER,
  DUAL_USER,
  LENDER_USER
} from "../../constants/systemUsers";
import { getLenderProfile } from "../../actions/profile";
import { getLendersPrivacySettings, updateLendersPrivacySettings } from "../../actions/privacySettings";
import history from "../../routes/history";

const PrivacySettingsView = props => {
  const dispatchAction = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const lender = useSelector(state => state?.profile?.lender);
  const user = useSelector(state => state?.authorization?.user);
  const signal = axios.CancelToken.source();
  const { match, form } = props;
  const { getFieldDecorator } = form;
  const [defaultValuesUpdated, setDefaultValuesUpdated] = useState(false);

  useEffect(() => {
    dispatchAction(
      updateHeader({
        header: {
          title: "",
          action: "",
          page: "privacySettings",
          enableBack: false,
          showSettings: false,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: true,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
  }, []);

  useEffect(() => {
    getUserData();
    getCurrentPrivacyDate();
  }, []);

  const getCurrentPrivacyDate = () => {
    const id = match?.params?.id;
    dispatchAction(
      getLendersPrivacySettings({ cancelToken: signal.token, id })
    ).then(resp => {
      setPageLoading(false);
      if (resp) {
        const {
          finderFunctionOptIn,
          fundsAvailableShowCurrentLoanBook,
          fundsAvailableShowDischargedLoanBook,
          fundsAvailableShowSuburbs,
          loanTabShowCurrentLoanBook,
          loanTabShowDischargedLoanBook,
          ninetyDayActivityShowName
        } = resp;
        props.form.setFieldsValue({
          finderFunctionOptIn,
          fundsAvailableShowCurrentLoanBook,
          fundsAvailableShowDischargedLoanBook,
          fundsAvailableShowSuburbs,
          loanTabShowCurrentLoanBook,
          loanTabShowDischargedLoanBook,
          ninetyDayActivityShowName
        });
        setTimeout(() => {
          setDefaultValuesUpdated(true);
        }, 300);
      }
    });
  };

  const getUserData = async () => {
    const id = match?.params?.id;
    var isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    var systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    let userDetails = await authService.getUser();
    let usersLenderId = userDetails["app.user.lenderid"];
    var roleType = systemUser
      ? ADMIN_USER
      : user.roles?.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : "";

    dispatchAction(
      getLenderProfile({
        id,
        userType: roleType,
        notClearProfileImage: true,
        isUserViewingSelfProfile: usersLenderId === id
      })
    ) 
  };

  const handleSave = () => {
    setProcessing(true);
    const {form} = props;
    const payload = {
      finderFunctionOptIn: form.getFieldValue("finderFunctionOptIn"), 
          fundsAvailableShowCurrentLoanBook: form.getFieldValue("fundsAvailableShowCurrentLoanBook"), 
          fundsAvailableShowDischargedLoanBook: form.getFieldValue("fundsAvailableShowDischargedLoanBook"), 
          fundsAvailableShowSuburbs: form.getFieldValue("fundsAvailableShowSuburbs"), 
          loanTabShowCurrentLoanBook: form.getFieldValue("loanTabShowCurrentLoanBook"), 
          loanTabShowDischargedLoanBook: form.getFieldValue("loanTabShowDischargedLoanBook"), 
          ninetyDayActivityShowName: form.getFieldValue("ninetyDayActivityShowName")
    }

    dispatchAction(updateLendersPrivacySettings({cancelToken: signal.token, id: match?.params?.id, payload}))
    .then(resp => {
      history.push(`/users/${lender.id}/lender`)
      setProcessing(false);
    });
    

  };

  useEffect(() => {
    if (props?.form?.getFieldValue("autoAddNewBrokers") === "true") {
      props.form.setFieldsValue({
        addAfter: null,
        loansDone: null
      });
    }
  }, [props?.form?.getFieldValue("autoAddNewBrokers")]);

  useEffect(() => {
    if (props?.form?.getFieldValue("addAfter") === "true") {
      props.form.setFieldsValue({
        autoAddNewBrokers: null
      });
    }
  }, [props?.form?.getFieldValue("addAfter")]);

  const defaultLabelColSpan = { span: 10 };
  const defaultValueColSpan = { span: 14 };
  return (
    <Form
      labelCol={defaultLabelColSpan}
      wrapperCol={defaultValueColSpan}
      className="p-lr-140 shrinked-form"
      colon={false}
    >
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 m-b-50 flex-center">
            <Row>
            <Col className="text-center">Lender Profile Privacy Settings</Col>
            <Col className="darkred text-center">{lender?.companyName}</Col>
            </Row>
            
          </div>
          <Row gutter={24} className="m-t-24">
            <Col md={24} lg={9} offset={3}>
              <FundsAvailableEmail getFieldDecorator={getFieldDecorator} />
              <DayActivity getFieldDecorator={getFieldDecorator} /> 
            </Col>
            <Col md={24} lg={9} className="m-l-10">
              <LoanTab getFieldDecorator={getFieldDecorator} />
              <FinderFunction getFieldDecorator={getFieldDecorator} />
            </Col>
            <Col md={24} lg={11}>
              
            </Col>
          </Row>
          <Row gutter={24}>
            <Col>
            <div className="flex flex-wrap flex-center">
                <Button
                    icon="file"
                    type="primary"
                    className="f-w-500"
                    loading={processing}
                    // disabled={!isValid}
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                  <Button
                    className="f-w-500 m-l-15"
                    disabled={processing}
                    onClick={() => history.push(`/users/${lender.id}/lender`)}
                  >
                    Cancel
                  </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default Form.create({ name: "Profile Settings" })(PrivacySettingsView);
