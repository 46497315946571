import { Form, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { connect, useDispatch } from "react-redux";
import { updateHeader, updateRouteOptions } from "../../actions/member";
import {
  saveProfile,
  getLendersFirstRankingProfile,
  getLendersSecondRankingProfile,
  getLendersConstructionProfile,
  getLendersMezzanineProfile,
  getLendersLandBankProfile,
  getLendersRuralProfile,
  saveFirstRankingField,
  saveSecondRankingField,
  saveConstructionField,
  saveMezzanineField,
  saveLandBankField,
  saveRuralField,
  listLendersMatches,
  updateMatches,
  profileStatusTurnOn,
  profileStatusTurnOff,
  toggleSubscriptions,
  getLenderProfile,
  getBrokerProfile,
  setActiveProfile,
  masterSwitchTurnOff,
  masterSwitchTurnOn,
  updateIndividualProfileV2
} from "../../actions/profile";
import {
  lendersLookUpHandler,
  listLendersMatchesLookup
} from "../../actions/lookup";
import UserDetails from "../../components/Profile/UserDetails";
import {
  isLenderOwnProfilePolicy,
  isLenderUserPolicy
} from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import {
  isBrokerOwnProfilePolicy,
  isBrokerUserPolicy
} from "../../Common/Authorization/policies/isBrokerUserPolicy";
import {
  isLawFirmOwnProfilePolicy,
  isLawFirmUserPolicy
} from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import {
  errorDisplay,
  evaluatePolicy,
  getActiveKey,
  getUsersProfileType,
  mustBeArray,
  userHasActionRights
} from "../../libs/utilities";
import PortraitView from "../../components/Profile/protrait-view";
import ProfileUtility from "../../components/Profile/Utility/utility";
import {
  EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER,
  LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
  LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER,
  PROFILE_EDIT_OPTION
} from "../../constants/routeOptions";
import { LENDER_PROFILE_ACTIVE } from "../../constants/action-type/profile";
import authService from "../../actions/AuthorizeService";
import {
  ADMIN_USER,
  DUAL_USER,
  LENDER_USER
} from "../../constants/systemUsers";
import _ from "lodash";
import ConfirmationModal from "../../Common/ConfirmationModal";
import React from "react";
import { getProfileStatusWarning } from "../../libs/user";
import {
  LENDER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT,
  LENDER_MASTER_SWITCH_ON_CONFIRMATION_TEXT
} from "../../constants/warnings";
import { FormDecoratorProvider } from "../../components/Common/decoratedFormControl";

const ProfileImpl = ({
  member,
  lendersLookUpHandler,
  updateHeader,
  match,
  lender,
  user,
  firstRanking,
  listLendersMatches,
  getLendersFirstRankingProfile,
  getLendersSecondRankingProfile,
  getLendersConstructionProfile,
  getLendersMezzanineProfile,
  getLendersLandBankProfile,
  getLendersRuralProfile,
  saveSecondRankingField,
  saveConstructionField,
  saveMezzanineField,
  saveLandBankField,
  saveRuralField,
  saveFirstRankingField,
  profileType,
  updateRouteOptions,
  profileStatusTurnOn,
  profileStatusTurnOff,
  toggleSubscriptions,
  getLenderProfile,
  individualUser,
  masterSwitchTurnOff,
  masterSwitchTurnOn,
  form,
  updateIndividualProfileV2,
}) => {
  const dispatchAction = useDispatch();
  const [userProfile, setUserProfile] = useState({});
  const [portraitsUpdated, setPortraitsUpdated] = useState(false);
  const [current, setCurrent] = useState(1);
  const [currentPortrait, setCurrentPortrait] = useState(null);
  const { getFieldDecorator } = form;
  const { header } = member;
  const [activeKey, setActiveKey] = useState("");
  const [activeSubKey, setActiveSubKey] = useState("");
  const [userType, setUserType] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [isSystemUser, setIsSystemUser] = useState(false);
  const [userHasPermissionToUpdate, setUsersPermission] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [profileStatusLoading, setProfileStatusLoading] = useState(false);
  const [canToggleProfileSwitch, setCanToggleProfileSwitch] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false
  );
  const [confirmationText, setConfirmationText] = useState("");
  const [activeField, setActiveField] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);

  const loansSearch = useLocation().search;
  const loanSearchQueryParams = loansSearch.length
    ? queryString.parse(loansSearch)
    : null;
  useEffect(() => {
    updateHeader({
      header: {
        title: header && header.title,
        action: "edit",
        page: "profile",
        enableBack: false,
        showSettings: true,
        showMasterSwitch: true,
        showIsProfileActive: true,
        hasSwitchProfile: true,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: true,
        currentPortraitIndividualId: lender.currentPortraitIndividualId ? 
          lender.currentPortraitIndividualId : header.currentPortraitIndividualId,
        omicronBdm: lender.omicronBdmId
          ? { id: lender.omicronBdmId, name: lender.omicronBdm }
          : header.omicronBdm,
        showPageTitle: false,
        showDeactivatedMessage: !isSystemUser &&  (lender.isMasterSwitchOn === undefined ? false : !lender.isMasterSwitchOn),
      }
    });

    if (user.roles.length > 0 && !initialized) {
      
      getProfileDetails();
      setUserIsSystemUser();
      setInitialized(true);
    }
  }, [user, isSystemUser]);

  useEffect(() => {
    if(currentPortrait != null){
      updateHeader({
        header: {
          ...(member?.header ?? {}),
          currentPortraitIndividualId: lender.currentPortraitIndividualId ? 
            lender.currentPortraitIndividualId : currentPortrait.individualId ? 
          currentPortrait.individualId : member.header.currentPortraitIndividualId,
        }
      });
    }

  }, [currentPortrait])

  useEffect(() => {
    getProfileDetails();
    setUserIsSystemUser();
    //setInitialized(true);
    loadUserInformation();
  }, [window.location.href]);

  useEffect(() => {
    setInitialActiveTab();
  }, [lender]);

  useEffect(() => {
    if (activeKey == "loans") {
      initLoanCriteriaSubKey();
    }
  }, [activeKey]);

  const loadUserInformation = async () => {
    setPageLoading(true);
    setCurrent(1);
    const id = match?.params?.id;
    const isLender = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    let userDetails = await authService.getUser();
    let usersLenderId = userDetails["app.user.lenderid"];

    const isOwnProfile =
      (await evaluatePolicy(user, isBrokerOwnProfilePolicy(id))) ||
      (await evaluatePolicy(user, isLenderOwnProfilePolicy(id)));
    setCanToggleProfileSwitch(systemUser || isOwnProfile);

    const roleType = systemUser
      ? ADMIN_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : "";
    const resp = await getLenderProfile({
      id,
      userType: roleType,
      notClearProfileImage: false,
      isUserViewingSelfProfile: usersLenderId === id
    });
    if (resp.status && resp.data) {
      updateHeader({
        header: {
          ...(member?.header ?? {}),
          title: `${resp.data.firstname} ${resp.data.lastname}`,
          hasSwitchProfile: true,
          showDeactivatedMessage: !systemUser ? !resp.data.isMasterSwitchOn : false,
        }
      });
      const sortedPortraits = _.sortBy(
        resp.data.portraits ? resp.data.portraits : [],
        "appearanceOrder"
      );
      setUserProfile({ ...resp.data, portraits: sortedPortraits });
      setCurrentPortrait(sortedPortraits[0]);
      const isMyProfile = parseInt(user["app.user.lenderid"]) === resp.data.id;
      const lenderBrokerId = resp.data.brokerId;
      dispatchAction(setActiveProfile(lenderBrokerId, resp.data.id, LENDER_PROFILE_ACTIVE));

      let newRouteOptions = lenderBrokerId
        ? LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER
        : LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER;

      newRouteOptions = [...newRouteOptions].map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });

      const menuOptions = systemUser
        ? [...PROFILE_EDIT_OPTION]
        : isMyProfile
        ? [...EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, ...PROFILE_EDIT_OPTION]
        : [];

      setPortraitsUpdated(!portraitsUpdated);
      
      await updateRouteOptions({
        routeOptions: _.concat(newRouteOptions, menuOptions)
      });
    }
    setPageLoading(false);
  };

  async function setInitialActiveTab() {
    if (!lender.resetTab) {
      return;
    }
    const lenderUser = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const brokerUser = await isBrokerUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const lawFirmUser = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnLenderProfile = await isLenderOwnProfilePolicy(
      match && match.params && match.params.id
    )(user.roles, user.permissions, user);

    const searchField =
      window.location &&
      window.location.search &&
      mustBeArray(window.location.search.split("="))[1];
    let activeTab = getActiveKey({
      profileType: "lender",
      activeKey: searchField
    });

    if (activeTab) {
      return setActiveKey(activeTab);
    }
    if (loanSearchQueryParams != null) {
      setActiveKey("loanstab");
      setActiveSubKey("matchingYourSearchLoans");
    } else {
      if (systemUser || isOwnLenderProfile) {
        setActiveKey("matches");
      } else if (
        (lawFirmUser|| brokerUser || lenderUser) && !isOwnLenderProfile
      ) {
        setActiveKey("fileNotes");
      } else {
        setActiveKey("loans");
        setActiveSubKey("4");
      }
    }
  }

  async function setUserIsSystemUser() {
    let isSysUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsSystemUser(isSysUser);
  }

  async function getProfileDetails() {
    var isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnProfile = await isLenderOwnProfilePolicy(
      match && match.params && match.params.id
    )(user.roles, user.permissions, user);
    const usertype = getUsersProfileType({
      isOwnProfile,
      isSystemUser,
      profileType
    });
    setUserType(usertype);
    setUsersPermission(
      userHasActionRights({ isSystemUser, profileType, isOwnProfile })
    );
  }

  async function handleTabChange(newKey) {
    const isLender = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isBroker = await isBrokerUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const isOwnProfile = await isLenderOwnProfilePolicy(
      match && match.params && match.params.id
    )(user.roles, user.permissions, user);

    if (newKey == "loans") {
      setActiveKey("loans");
      setActiveSubKey("4");
    } else if (newKey == "loanstab") {
      setActiveKey("loanstab");
      if (loanSearchQueryParams != null) {
        setActiveSubKey("matchingYourSearchLoans");
      } else {
        if (isSystemUser || isOwnProfile) {
          setActiveSubKey("preSettlementLoans");
        } else {
          setActiveSubKey("currentLoans");
        }
      }
    } else {
      setActiveKey(newKey);
    }
  }

  function initLoanCriteriaSubKey() {
    if (!lender || !lender.categoriesOffered) {
      return;
    } else {
      if (lender.categoriesOffered.firstRanking) {
        setActiveSubKey("4");
      } else if (lender.categoriesOffered.secondRanking) {
        setActiveSubKey("5");
      } else if (lender.categoriesOffered.construction) {
        setActiveSubKey("6");
      } else if (lender.categoriesOffered.mezzanine) {
        setActiveSubKey("7");
      } else if (lender.categoriesOffered.landBank) {
        setActiveSubKey("8");
      } else if (lender.categoriesOffered.rural) {
        setActiveSubKey("9");
      }
    }
  }

  function handleSubTabChange(newKey) {
    if (newKey == "loans") {
      setActiveSubKey("4");
    } else if (newKey == "loanstab") {
      setActiveSubKey("preSettlementLoans");
    } else {
      setActiveSubKey(newKey);
    }
  }

  const handleProfileActiveChange = () => {
    const warningMessage = getProfileStatusWarning({
      profileType,
      userType,
      isProfileActive: !!lender?.isProfileActive
    });
    setConfirmationText(warningMessage);
    setActiveField("isProfileActive");
    setConfirmationModalVisible(true);
  };

  const handleConfirmProfileActiveChange = () => {
    const actionName = !!lender?.isProfileActive
      ? profileStatusTurnOff
      : profileStatusTurnOn;
    const actionMessage = !!lender?.isProfileActive
      ? "Profile paused successfully."
      : "Profile set to active successfully.";
    actionName({ id: lender?.id, profileType: profileType }).then(resp => {
      handleModalClose();
      if (resp.status) {
        message.success(actionMessage);
        loadUserInformation();
      } else {
        errorDisplay(resp?.data?.errors);
      }
    });
  };

  const handleMasterSwitchChange = () => {
    const text = lender?.isMasterSwitchOn
      ? LENDER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT
      : LENDER_MASTER_SWITCH_ON_CONFIRMATION_TEXT;
    setConfirmationText(text);
    setActiveField("isMasterSwitchActive");
    setConfirmationModalVisible(true);
  };

  const handleConfirmMasterSwitchChange = () => {
    const actionName = lender?.isMasterSwitchOn
      ? masterSwitchTurnOff
      : masterSwitchTurnOn;
    const actionMessage = lender?.isMasterSwitchOn ? "off" : "on";
    actionName({ id: lender?.companyId, profileType: profileType }).then(
      resp => {
        if (resp.status) {
          message.success(
            `Master switch turned ${actionMessage} successfully.`
          );
          loadUserInformation();
        }
      }
    );
  };

  const handleModalClose = () => {
    setConfirmationModalVisible(false);
    setActiveField("");
    setConfirmationText("");
  };

  const handleConfirm = () => {
    activeField === "isProfileActive"
      ? handleConfirmProfileActiveChange()
      : handleConfirmMasterSwitchChange();
    handleModalClose();
  };

  const userId = match.params.id;

  const handleFormChange = ({ label, value }) => {
    if (
      label === "website" ||
      label === "companyName" ||
      label === "companyACN" ||
      label === "previewCompanyLogo" ||
      label === "portraits"
    ) {
      setUserProfile({ ...userProfile, [label]: value });
      setPortraitsUpdated(label === "portraits" ? !portraitsUpdated : portraitsUpdated);
      return;
    }
    const updatedPortraits = [...userProfile.portraits].map((portrait, i) => {
      if (i === current - 1) {
        portrait[label] = value;
      }
      return portrait;
    });
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
  };

  const handleSave = () => {
    form.validateFields(err => {
      if (err) {
        console.log(err, "error");
        return;
      }
      setSaveLoading(true);
      const updatedProfile = {
        portraits: userProfile.portraits,
        website: userProfile.website,
        omicronBdmId: header?.omicronBdm?.id,
        logoFile: userProfile.logoFile,
        individualId: userProfile.individualId,
        companyACN: userProfile.companyACN,
        companyName: userProfile.companyName,
        previewCompanyLogo: userProfile.previewCompanyLogo
      };
      updateIndividualProfileV2(updatedProfile, isSystemUser, "lender", lender.id).then(
        resp => {
          setCurrent(1);
          setCurrentPortrait(userProfile.portraits[0]);
          setSaveLoading(false);
          if (resp.status) {
            message.success("Profile updated successfully.");
            updateHeader({header: {editMode: false}});
            loadUserInformation();
          }
        }
      );
    });
  };

  const handleRemove = () => {
    const updatedPortraits = [...userProfile.portraits].filter(
      (_portrait, i) => i !== current - 1
    );
    setUserProfile({ ...userProfile, portraits: updatedPortraits });
    setCurrent(1);
    setPortraitsUpdated(!portraitsUpdated);

    message.success("User successfully deleted from this Profile and the Platform.");
  };

  return pageLoading || userType === null ? (
    <Spin />
  ) : (
    <div className="m-n-t-20">
      <Form hideRequiredMark colon={false}>
        <FormDecoratorProvider decorator={getFieldDecorator}>
          <div className="flex align-center gap-16 m-t-20">
            <PortraitView
              portraits={userProfile?.portraits}
              form={form}
              handleFormChange={handleFormChange}
              currentPage={current}
              setCurrent={setCurrent}
              setCurrentPortrait={setCurrentPortrait}
              isSystemUser={isSystemUser}
              company={{
                companyACN: userProfile?.companyACN,
                companyName: userProfile?.companyName,
                website: userProfile?.website
              }}
              portraitsUpdated={portraitsUpdated}
              handleRemove={handleRemove}
            />
            <ProfileUtility
              user={userProfile}
              userType={LENDER_USER}
              showIsProfileActive={header.showIsProfileActive}
              profileStatusLoading={profileStatusLoading}
              canToggleProfileSwitch={canToggleProfileSwitch}
              handleProfileActiveChange={handleProfileActiveChange}
              showMasterSwitch={header.showMasterSwitch}
              handleMasterSwitchChange={handleMasterSwitchChange}
              form={form}
              handleFormChange={handleFormChange}
              handleSave={handleSave}
              saveLoading={saveLoading}
              isSystemUser={isSystemUser}
            />
          </div>
        </FormDecoratorProvider>
      </Form>
      <UserDetails
        activeKey={activeKey}
        activeSubKey={activeSubKey}
        lenderId={lender.id}
        handleTabChange={newKey => handleTabChange(newKey)}
        handleSubTabChange={newKey => handleSubTabChange(newKey)}
        loanSearchQueryParams={loanSearchQueryParams}
        lendersLookUpHandler={options =>
          lendersLookUpHandler({ ...options, userType })
        }
        getLendersFirstRankingProfile={options =>
          getLendersFirstRankingProfile({ ...options, userType })
        }
        getLendersSecondRankingProfile={options =>
          getLendersSecondRankingProfile({ ...options, userType })
        }
        getLendersConstructionProfile={options =>
          getLendersConstructionProfile({ ...options, userType })
        }
        getLendersMezzanineProfile={options =>
          getLendersMezzanineProfile({ ...options, userType })
        }
        getLendersLandBankProfile={options =>
          getLendersLandBankProfile({ ...options, userType })
        }
        getLendersRuralProfile={options =>
          getLendersRuralProfile({ ...options, userType })
        }
        listLendersMatches={options =>
          listLendersMatches({ ...options, userType })
        }
        listLendersMatchesLookup={options =>
          lendersLookUpHandler({
            ...options,
            userType,
            action: "LENDER_MATCHES_STATUS_LOOKUP"
          })
        }
        saveFirstRankingField={options =>
          saveFirstRankingField({ ...options, userType })
        }
        saveSecondRankingField={options =>
          saveSecondRankingField({ ...options, userType })
        }
        saveConstructionField={options =>
          saveConstructionField({ ...options, userType })
        }
        saveMezzanineField={options =>
          saveMezzanineField({ ...options, userType })
        }
        saveLandBankField={options =>
          saveLandBankField({ ...options, userType })
        }
        saveRuralField={options => saveRuralField({ ...options, userType })}
        firstRanking={firstRanking}
        match={match}
        userId={userId}
        updateMatches={updateMatches}
        profileType={profileType}
        isSystemUser={isSystemUser}
        userType={userType}
        lender={lender}
        userHasPermissionToUpdate={userHasPermissionToUpdate}
        getLenderProfile={getLenderProfile}
        getBrokerProfile={getBrokerProfile}
        toggleSubscriptions={toggleSubscriptions}
        user={user}
      />
      <ConfirmationModal
        confirmationText={confirmationText}
        visible={confirmationModalVisible}
        handleModalClose={handleModalClose}
        handleConfirm={handleConfirm}
        handleProfileActiveChange={handleProfileActiveChange}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender,
    firstRanking: state.profile.firstRanking,
    activeProfile: state.profile && state.profile.activeProfile,
    individualUser:
      (state.authorization && state.authorization.individualUser) || {},
    routeOptions: mustBeArray(state?.member?.routesOptions)
  };
};

const mapDispatchToProps = {
  updateHeader,
  saveProfile,
  lendersLookUpHandler,
  getLendersFirstRankingProfile,
  getLendersSecondRankingProfile,
  getLendersConstructionProfile,
  getLendersMezzanineProfile,
  getLendersLandBankProfile,
  getLendersRuralProfile,
  saveFirstRankingField,
  saveSecondRankingField,
  saveConstructionField,
  saveMezzanineField,
  saveLandBankField,
  saveRuralField,
  listLendersMatches,
  listLendersMatchesLookup,
  updateMatches,
  updateRouteOptions,
  profileStatusTurnOn,
  profileStatusTurnOff,
  toggleSubscriptions,
  getLenderProfile,
  getBrokerProfile,
  masterSwitchTurnOff,
  masterSwitchTurnOn,
  updateIndividualProfileV2
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "LendeProfile" })(ProfileImpl));
