import { Icon, InputNumber, Select } from "antd";
import React, { useContext } from "react";
import { mustBeArray } from "../../../libs";
import LoanCreateContext from "./LoanCreateContext";

const AttributedBrokersView = ({ loanBrokers }) => {
  const { brokersList, displayBrokersModal } = useContext(LoanCreateContext);
  return (
    <div>
      {mustBeArray(loanBrokers).map((data, i) => {
        return (
          <div className="m-b-8 flex" key={i}>
            <Select
              value={data.brokerId}
              className="w-220 m-l-8 m-r-10"
              allowClear={false}
              disabled
            >
              {brokersList.map(broker => {
                return (
                  <Select.Option key={broker.id} value={broker.id}>
                    {broker.name}
                  </Select.Option>
                );
              })}
            </Select>
            {i === 0 && (
              <>
                <span style={{ color: "red" }}>
                  *
                </span>
                <Icon
                  type="right"
                  className="color-success f-s-20 f-w-800 p-l-10 p-r-10 hand"
                  onClick={displayBrokersModal}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default AttributedBrokersView;
