import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";

export const getEmailSubscriptions = ({ cancelToken, id }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint:  apiUrls.GET_INDIVIDUAL_EMAIL_SUBSCRIPTIONS,
          options: {id}
        });
        const response =  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

  export const updateEmailSubscriptions = ({ cancelToken, id, payload }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint:  apiUrls.GET_INDIVIDUAL_EMAIL_SUBSCRIPTIONS,
          options: {id}
        });
        const response =  await http.put(url, payload, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  