import React, { Fragment } from "react";
import { Button, message, Modal } from "antd";
import history from "../routes/history";
import { connect } from "react-redux";
import {
  checkSession,
  getProfileInfo,
  getUserData,
  signoutUser,
  updateRouteOptions
} from "../actions/member";
import {
  addBrokerProfile,
  addLenderProfile,
  deleteBrokerProfile,
  deleteLenderProfile,
  getLenderProfile,
  getBrokerProfile,
  setActiveProfile,
  masterSwitchTurnOff,
  profileStatusTurnOff,
  profileStatusTurnOn,
  resendInvitation,
  resetPassword,
  deleteFinancierAccount,
  deleteLawFirmAccount,
  clearProfileDetails,
  updateProfileLoading
} from "../actions/profile";
import SettingDropdownOption from "./SettingDropDown";
import ProfileSwitch from "./ProfileSwitchOption";
import { errorDisplay, mustBeArray } from "../libs/utilities";
import { isSystemUserPolicy } from "../Common/Authorization/policies/isSystemUserPolicy";
import {
  ADMIN_USER,
  BROKER_USER,
  LAW_FIRM_USER,
  LENDER_USER
} from "../constants/systemUsers";
import {
  BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
  DEFAULT_ROUTES_SYSTEM_USER,
  LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
  PROFILE_HAS_NOT_ACCEPTED_INVITATION,
  CAN_RESET_PROFILE_PASSWORD
} from "../constants/routeOptions";
import { Visible } from "./Common/visiblityWrapper";
import { isLenderUserPolicy } from "../Common/Authorization/policies/isLenderUserPolicy";
import { isBrokerUserPolicy } from "../Common/Authorization/policies/isBrokerUserPolicy";
import _ from "lodash";
import authService from "../actions/AuthorizeService";
import UniversalSearchBox from "./Common/UniversalSearchBox";
import { FINDER_FILTER, FINDER_USER_TYPE } from "../constants";
import { defaultFilters } from "./FinderUtility";
import OmicronBDMLookup from "./PageHeaders/omicronBDMLookup";
import OmicronBDMView from "./PageHeaders/omicronBDMView";
import { isLawFirmUserPolicy } from "../Common/Authorization/policies/isLawFirmPolicy";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "",
      visible: false,
      loading: false,
      deleteLenderBrokerErrorText: "",
      showLenderBrokerDeleteErrorModal: false,
      deleteFinancierDeleteConfirmationText: "",
      showFinancierDeleteConfirmationModal: false,
      isCurrentUserLawFirm: false,
      deleteLawFirmDeleteConfirmationText: "",
      deleteLawFirmErrorText: "",
      showLawFirmDeleteErrorModal: false,
      showLawFirmDeleteConfirmationModal: false,
    };
  }
  componentDidMount() {
    this.getProfileDetails();
  }
  getProfileDetails = async () => {
    const { user } = this.props;
    let isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    let isAdmin = await isSystemUserPolicy(user.roles, user.permissions, user);
    let isLawFirm = await isLawFirmUserPolicy(user.roles);
    const currentUserType = this.getUserType(
      isLender,
      isBroker,
      isAdmin,
      isLawFirm
    );
    this.setState({
      userType: currentUserType,
      isCurrentUserLawFirm: isLawFirm
    });
  };

  getUserType = (isLender, isBroker, isAdmin, isLawFirm) => {
    if (isLender) return LENDER_USER;
    if (isBroker) return BROKER_USER;
    if (isAdmin) return ADMIN_USER;
    if (isLawFirm) return LAW_FIRM_USER;
    return "";
  };

  onActionClicked = () => {
    const { action, page } = this.props.header;
    const { match } = this.props;
    switch (page?.toLowerCase()) {
      case "open cases":
        if (action === "add") return history.push("/cases/create");
        else
          return history.push(
            `/cases/${match && match.params && match.params.id}/edit`
          );
      default:
        break;
    }
  };

  backHistory = () => {
    history.goBack();
  };

  onDeleteFinancier = async (broker, lender) => {
    this.setState({
      deleteFinancierDeleteConfirmationText: "",
      showFinancierDeleteConfirmationModal: false
    });
    const response = await this.props.deleteFinancierAccount({
      id: broker.companyId || lender.companyId
    });
    if (response.status) {
      message.success("Account deleted successfully");
      await this.handleUpdateRouteOptions({
        routeOptions: DEFAULT_ROUTES_SYSTEM_USER
      });
      return history.push("/users");
    } else {
      if (response.data.errors.some(x => x.code == "ERR_HASLOANS")) {
        this.setState({
          showLenderBrokerDeleteErrorModal: true,
          deleteLenderBrokerErrorText: response.data.errors[0].message
        });
      } else {
        errorDisplay(response && response.data && response.data.errors);
      }
    }

    this.setState({ loading: false });
  };

  onDeleteLawFirm = async (lawFirm) => {
    this.setState({
      deleteLawFirmDeleteConfirmationText: "",
      showLawFirmDeleteConfirmationModal: false
    });
    const response = await this.props.deleteLawFirmAccount({
      id: lawFirm.companyId
    });
    if (response.status) {
      message.success("Account deleted successfully");
      await this.handleUpdateRouteOptions({
        routeOptions: DEFAULT_ROUTES_SYSTEM_USER
      });
      return history.push("/users");
    } else {
      if (response.data.errors.some(x => x.code == "ERR_HASLOANS")) {
        this.setState({
          showLawFirmDeleteErrorModal: true,
          deleteLawFirmErrorText: response.data.errors[0].message
        });
      } else {
        errorDisplay(response && response.data && response.data.errors);
      }
    }

    this.setState({ loading: false });
  }

  handleNoteButtonClick = async () => {
    const { match, user } = this.props;
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );

    const userRole = isSystemUser ? ADMIN_USER : "";

    history.push({
      pathname: "/users/notes",
      state: { match: match, userType: userRole }
    });
  };

  onCancel = () => {
    this.setState({ visible: false, loading: false });
  };

  getActionProps = () => {
    let buttonProps = { icon: "", label: "" };
    switch (this.props.header?.action) {
      case "add":
        buttonProps = { icon: "plus-circle", label: "New" };
        break;
      case "edit":
        buttonProps = { icon: "edit", label: "Edit" };
        break;
      default:
        break;
    }
    return buttonProps;
  };

  handleUpdateRouteOptions = async ({ routeOptions }) => {
    await this.props.updateRouteOptions({ routeOptions });
  };
  handleProfileAction = async option => {
    const { lender, match, user, broker, lawFirm, header } = this.props;
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    let isLawFirm = await isLawFirmUserPolicy(user.roles);
    let userDetails = await authService.getUser();
    let usersLenderId = userDetails["app.user.lenderid"];
    let usersBrokerId = userDetails["app.user.brokerid"];

    switch (option && option.action) {
      case "addBroker": {
        this.setState({ loading: true });
        const resp = await this.props.addBrokerProfile({
          id: lender && lender.companyId
        });
        if (resp.status) {
          message.success("Broker profile added successfully");
          this.props
            .getLenderProfile({
              id: match && match.params && match.params.id,
              userType: isSystemUser ? ADMIN_USER : LENDER_USER,
              isUserViewingSelfProfile:
                usersLenderId === (match && match.params && match.params.id)
            })
            .then(response => {
              if (response.status) {
                history.push(
                  `/users/${response.data && response.data.brokerId}/broker`
                );
              }
            });
        }
        this.setState({ loading: false });
        break;
      }
      case "addLender": {
        this.setState({ loading: true });
        const userType = isSystemUser ? ADMIN_USER : BROKER_USER;
        const response = await this.props.addLenderProfile({
          id: broker.companyId,
          userType
        });
        if (response.status) {
          message.success("Lender profile added successfully");
          const response = await this.props.getBrokerProfile({
            id: match.params.id,
            userType
          });

          this.setState({ loading: false });
          if (response.status) {
            history.push(`/users/${response.data.lenderId}/lender`);
          }
        }
        break;
      }
      case "deleteBroker": {
        this.setState({ loading: true });
        const resp = await this.props.deleteBrokerProfile({
          id: lender && lender.companyId
        });
        if (resp.status) {
          message.success("Broker profile deleted successfully.");
          const response = await this.props.getLenderProfile({
            id: match?.params?.id,
            userType: isSystemUser ? ADMIN_USER : LENDER_USER,
            isUserViewingSelfProfile: usersLenderId === match?.params?.id
          });

          this.props.setActiveProfile(
            lender.brokerId,
            lender.id,
            this.props.activeProfile.active
          );

          let updatedRoutes = [];
          if (!response.data.hasAcceptedInvitation) {
            updatedRoutes = [
              ...LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
              ...PROFILE_HAS_NOT_ACCEPTED_INVITATION
            ];
          } else {
            updatedRoutes = [
              ...LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER,
              ...CAN_RESET_PROFILE_PASSWORD
            ];
          }
          await this.handleUpdateRouteOptions({ routeOptions: updatedRoutes });
        } else {
          if (resp.data.errors.some(x => x.code == "ERR_HASLOANS")) {
            this.setState({
              showLenderBrokerDeleteErrorModal: true,
              deleteLenderBrokerErrorText: resp.data.errors[0].message
            });
          } else {
            errorDisplay(resp && resp.data && resp.data.errors);
          }
        }
        this.setState({ loading: false });
        break;
      }
      case "deleteLender": {
        this.setState({ loading: true });
        const userType = isSystemUser ? ADMIN_USER : BROKER_USER;
        const response = await this.props.deleteLenderProfile({
          id: broker.companyId,
          userType
        });
        if (response.status) {
          message.success("Lender profile deleted successfully");
          const response = await this.props.getBrokerProfile({
            id: match.params.id,
            userType,
            isUserViewingSelfProfile:
              usersBrokerId === (match && match.params && match.params.id)
          });
          this.props.setActiveProfile(
            response?.data?.id,
            response?.data?.lenderId,
            this.props.activeProfile.active
          );
          let updatedRoutes = [];
          if (!response?.data?.hasAcceptedInvitation) {
            updatedRoutes = [
              ...BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
              ...PROFILE_HAS_NOT_ACCEPTED_INVITATION
            ];
          } else {
            updatedRoutes = [
              ...BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER,
              ...CAN_RESET_PROFILE_PASSWORD
            ];
          }
          await this.handleUpdateRouteOptions({ routeOptions: updatedRoutes });
        } else {
          if (response.data.errors.some(x => x.code == "ERR_HASLOANS")) {
            this.setState({
              showLenderBrokerDeleteErrorModal: true,
              deleteLenderBrokerErrorText: response.data.errors[0].message
            });
          } else {
            errorDisplay(response && response.data && response.data.errors);
          }
        }
        this.setState({ loading: false });
        break;
      }
      case "deleteFinancierAccount": {
        this.setState({ loading: true });
        var companyName = "";
        var relatedUsers = "";
        if (Object.keys(broker).length > 0) {
          companyName = broker.companyName;
          relatedUsers = broker.portraits
            .map(u => u.firstname + " " + u.lastname)
            .join("\r\n");
        } else {
          companyName = lender.companyName;
          relatedUsers = lender.portraits
            .map(u => u.firstname + " " + u.lastname)
            .join("\r\n");
        }
        if (
          broker.associatedLoansCountAsBroker <= 0 ||
          lender.associatedLoansCountAsLender <= 0
        ) {
          this.setState({
            deleteFinancierDeleteConfirmationText:
              "You are about to delete " +
              companyName +
              " along with the following users: \n" +
              relatedUsers,
            showFinancierDeleteConfirmationModal: true
          });
        } else {
          this.onDeleteFinancier(broker, lender);
        }

        break;
      }
      case "deleteLawFirmAccount":
        this.setState({ loading: true });
        const relatedToLawFirm = lawFirm.portraits
          .map(u => u.firstname + " " + u.lastname)
          .join("\r\n");
        if (lawFirm.associatedLoansCountAsLawyer <= 0) {
          this.setState({
            deleteLawFirmDeleteConfirmationText:
              "You are about to delete " +
              lawFirm.companyName +
              " along with the following users: \n" +
              relatedToLawFirm,
            showLawFirmDeleteConfirmationModal: true
          });
        } else {
          this.onDeleteLawFirm(lawFirm);
        }
        break;
      case "resendInvitation": {
        this.setState({ loading: true });
        const response = await this.props.resendInvitation({
          id: this.props.activePortrait?.individualId
        });
        if (response.status) {
          message.success("Invitation sent successfully");
        } else {
          errorDisplay(response.data.errors);
        }
        this.setState({ loading: false });
        break;
      }
      case "resetPassword": {
        this.setState({ loading: true });
        const response = await this.props.resetPassword({
          id: this.props.activePortrait?.individualId
        });
        if (response.status) {
          message.success("Password reset email sent successfully");
        } else {
          errorDisplay(response.data.errors);
        }
        this.setState({ loading: false });
        break;
      }
      case "accessProfile":
        return history.push("/users");
      case "privacySettings":
        history.push(`/privacy-settings/${match?.params?.id}`);
        break;
      case "privacySettingsOwnProfile":
        history.push(`/privacy-settings/${usersLenderId}`);
        break;
      case "emailSubscriptions":
        var id = lender.currentIndividualId
          ? lender.currentIndividualId
          : broker.currentIndividualId
          ? broker.currentIndividualId
          : header.currentPortraitIndividualId;
        history.push(`/email-subscriptions/${id}`);
        break;
      case "finderUtility":
        this.handleRedirct();
        break;
      case "addUser":
        history.push(
          `/companies/${broker.companyId ||
            lender.companyId ||
            lawFirm.companyId}/users/create`
        );
        break;
      case "editAccount":
        // Prevent showing of searchbox as lawfirm
        this.props.updateHeader({ header: { editMode: true, showSearchBox: !isLawFirm  } });
        break;
      default:
        break;
    }
  };

  handleSearchBoxChange = async(e, data) => {
    if (e != undefined) {
      if (e.searchType === "LOAN") {
        history.push(`/loans/${e.id}/view`);
      } else {
        const userType = e.userType === "Law Firm" ? "lawfirm" : e.userType;
        await this.props.clearProfileDetails();
        await this.props.updateProfileLoading(true);
        setTimeout(() => {
          history.push({
            pathname: `/users/${e.profileId}/${userType}`,
            state: { user: data.user }
          });
        }, 200);
      }
    }
  };

  handleLenderBrokerErrorModalClose = () => {
    this.setState({
      deleteLenderBrokerErrorText: "",
      showLenderBrokerDeleteErrorModal: false
    });
  }
  handleFinancierDeleteConfirmationModalClose = () => {
    this.setState({
      deleteFinancierDeleteConfirmationText: "",
      showFinancierDeleteConfirmationModal: false,
      loading: false
    });
  }
  handleLawFirmErrorModalClose = () => {
    this.setState({
      deleteLawFirmErrorText: "",
      showLawFirmDeleteErrorModal: false
    });
  }
  handleLawFirmDeleteConfirmationModalClose = () => {
    this.setState({
      deleteLawFirmDeleteConfirmationText: "",
      showLawFirmDeleteConfirmationModal: false,
      loading: false
    });
  }
  handleRedirct = () => {
    if (!window.location.url?.includes("finder-utility")) {
      localStorage.setItem(FINDER_FILTER, defaultFilters);
      localStorage.setItem(FINDER_USER_TYPE, "");
    }
    history.push("/finder-utility");
  };
  handleDeleteFinancierChange = () => {
    const { lender, broker, lawFirm } = this.props;
    this.onDeleteFinancier(broker, lender, lawFirm);
  }
  handleDeleteLawFirmChange = () => {
    const {lawFirm} = this.props;
    this.onDeleteLawFirm(lawFirm);
  }


  render() {
    const {
      showSettings,
      hasSwitchProfile,
      showNotesButton,
      editMode,
      showOmicronBDM,
      showPageTitle,
      allowSwitchProfile,
      showSearchBox,
      showDeactivatedMessage
    } = this.props.header;
    const { isFinderUtility, routeOptions, generalView, lawFirm } = this.props;
    const isLawFirmUser = lawFirm?.id;
    return (
      <div className="page-header m-b-20">
        {!generalView && (
          <div
            className={
              isFinderUtility ? "header-actions" : "float-right p-r-100"
            }
          >
            <div>
              {showNotesButton && (
                <Fragment>
                  <Button onClick={this.handleNoteButtonClick}>
                    Notes
                  </Button>
                </Fragment>
              )}
              {showSearchBox && <UniversalSearchBox
                {...this.props}
                handleSearchBoxChange={this.handleSearchBoxChange}
              />}
              {showSettings && (
                <SettingDropdownOption
                  className=""
                  handleProfileAction={this.handleProfileAction}
                  handleSignOut={this.props.signoutUser}
                  routeOptions={routeOptions}
                  loading={this.state.loading}
                  isCurrentUserLawFirm={this.state.isCurrentUserLawFirm}
                  user={this.props.user}
                />
              )}
            </div>
          </div>
        )}
        {/* Header title and options */}
        <div
          className={`flex flex-wrap m-n-t-58 m-l-260 w-percent-53 flex-center  m-b-36 p-r-24 ${
            showPageTitle ? "flex-justify-center" : "space-between"
          }`}
        >
          {showDeactivatedMessage && (
            <div className="deactivated-message">
              <h2>THE ACCOUNT HAS BEEN DEACTIVATED</h2>
              <p>If it belongs to you contact</p>
              <p>your BDM to request activation</p>
            </div>
          )}
          {showPageTitle ? (
            <div className={`profile-switch profile-switch-${showDeactivatedMessage ? "auto": "fixed"}`}>
              <h1>Law Firm Profile</h1>
            </div>
          ) : (
            <Fragment>
              <div className={`profile-switch profile-switch-${showDeactivatedMessage ? "auto": "fixed"}`}>
                <Visible visible={hasSwitchProfile}>
                  <ProfileSwitch allowSwitchProfile={allowSwitchProfile}/>
                </Visible>
              </div>
              <Visible visible={showOmicronBDM}>
                {editMode && this.state.userType === ADMIN_USER ? (
                  <OmicronBDMLookup />
                ) : (
                  <OmicronBDMView />
                )}
              </Visible>
            </Fragment>
          )}
        </div>
        <div className="clearfix" />
        <Modal
          visible={this.state.showLenderBrokerDeleteErrorModal}
          onCancel={this.handleLenderBrokerErrorModalClose}
          closable={false}
          title="Bad Idea!"
          footer={[
            <Button key="b" onClick={this.handleLenderBrokerErrorModalClose}>
              Cancel
            </Button>
          ]}
        >
          {this.state.deleteLenderBrokerErrorText}
        </Modal>
        <Modal
          visible={this.state.showFinancierDeleteConfirmationModal}
          onCancel={this.handleFinancierDeleteConfirmationModalClose}
          closable={false}
          title="Heads up!"
          footer={[
            <Button
              type="primary"
              key="a"
              onClick={() => this.handleDeleteFinancierChange()}
            >
              Okay
            </Button>,
            <Button
              key="b"
              onClick={this.handleFinancierDeleteConfirmationModalClose}
            >
              Cancel
            </Button>
          ]}
        >
          <div className="whiteSpace">
            {this.state.deleteFinancierDeleteConfirmationText}
          </div>
          <div>Are you sure you wish to proceed?</div>
        </Modal>

        {/* Lawfirm delete modals*/}
        <Modal
          visible={this.state.showLawFirmDeleteErrorModal}
          onCancel={this.handleLawFirmErrorModalClose}
          closable={false}
          title="Bad Idea!"
          footer={[
            <Button key="b" onClick={this.handleLawFirmErrorModalClose}>
              Cancel
            </Button>
          ]}
        >
          {this.state.deleteLawFirmErrorText}
        </Modal>
        <Modal
          visible={this.state.showLawFirmDeleteConfirmationModal}
          onCancel={this.handleFinancierDeleteConfirmationModalClose}
          closable={false}
          title="Heads up!"
          footer={[
            <Button
              type="primary"
              key="a"
              onClick={() => this.handleDeleteLawFirmChange()}
            >
              Okay
            </Button>,
            <Button
              key="b"
              onClick={this.handleLawFirmDeleteConfirmationModalClose}
            >
              Cancel
            </Button>
          ]}
        >
          <div className="whiteSpace">
            {this.state.deleteLawFirmDeleteConfirmationText}
          </div>
          <div>Are you sure you wish to proceed?</div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  member: state.member || {},
  header: state.member && state.member.header,
  routeOptions: mustBeArray(state.member && state.member.routesOptions),
  lender: state.profile.lender,
  broker: state.profile.broker,
  lawFirm: state.profile.lawFirm,
  activePortrait: state.profile.activePortrait,
  user: state.authorization.user,
  activeProfile: state.profile.activeProfile
});

const mapDispatchToProps = {
  signoutUser,
  getUserData,
  getProfileInfo,
  checkSession,
  addBrokerProfile,
  addLenderProfile,
  deleteBrokerProfile,
  deleteLenderProfile,
  getLenderProfile,
  getBrokerProfile,
  updateRouteOptions,
  setActiveProfile,
  masterSwitchTurnOff,
  profileStatusTurnOff,
  profileStatusTurnOn,
  resendInvitation,
  resetPassword,
  deleteFinancierAccount,
  deleteLawFirmAccount,
  clearProfileDetails,
  updateProfileLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
