import React, { Fragment } from "react";
import { Menu } from "antd";
import history from "../routes/history";
import { AuthorizedView } from "../Common/Authorization/AuthorizedView";
import { Visible } from "./Common/visiblityWrapper";

const SettingMenu = ({
  handleSignOut,
  routeOptions,
  handleProfileAction,
  loading,
  isCurrentUserLawFirm,
  user
}) => {
  const handleAction = option => {
    if(option.id === 9 && isCurrentUserLawFirm){
      history.push(`/users/${user["app.user.lawfirmid"]}/lawfirm`);

      return;
    }
    if ((option.url !== undefined || option.url !== null) && !option.action) {
      history.push(option.url);

      return;
    }
    handleProfileAction(option);
  };
  return (
    <Fragment>
      <Visible visible={loading}>
        <Menu>
          {routeOptions.map((option, i) => {
            return (
              <Menu.Item disabled key={i}>
                {option.name}
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item onClick={handleSignOut}>
            <span className="action-logout">Sign Out</span>
          </Menu.Item>
        </Menu>
      </Visible>
      <Visible visible={!loading}>
        <Menu>
          {routeOptions.map((option, i) => {
            if (option.policy) {
              return (
                <AuthorizedView policy={option.policy} key={`a${i}`}>
                  <Menu.Item key={i} onClick={() => handleAction(option)}>
                    {option.name}
                  </Menu.Item>
                </AuthorizedView>
              );
            }

            return (
              <Menu.Item
                key={i}
                onClick={() => {
                  option.url ? history.push(option.url) : handleAction(option);
                }}
              >
                {option.name}
              </Menu.Item>
            );
          })}
          <Menu.Divider />
          <Menu.Item onClick={handleSignOut}>
            <span className="action-logout">Sign Out</span>
          </Menu.Item>
        </Menu>
      </Visible>
    </Fragment>
  );
};

export default SettingMenu;
