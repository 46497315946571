import { Table } from "antd";
import React, { useEffect } from "react";
import moment from 'moment';
import history from "../../routes/history";
import { AU_DATE_FORMAT, DEFAULT_DATE_FORMAT } from "../../constants/moment";
import { mustBeArray } from "../../libs";

const AllLoansList = ({ list, loading, sortInfo, page, handleTableChange, total, className="" }) => {
  const formatDecimalToCurrency = value => {
    return value
      ? value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      : "$0";
  };

  const handleLoanNumberClick = record => {
    history.push(`/loans/${record.id}/view`);
  };

  const handleProfileClick = (record, actionSource, profileType) => {
    let profileId;
    if (actionSource === "mainList") {
      profileId = profileType === 'lender' ? 
        record.associatedLenderId : record.associatedBrokerId;
    } else if (actionSource === "lenderList") {
      profileId = record.loanLenderId;
    } else if (actionSource === "brokerList") {
      profileId = record.loanBrokerId;
    }
    history.push(`/users/${profileId}/` + profileType);
  };

  const formatDecimal2 = value => {
    return value ? value.toFixed(2) : "";
  };

  const formatDecimalWithDefaultValue = (value, precision) => {
    return value
      ? value.toFixed(precision)
      : value == 0
      ? (0).toFixed(precision)
      : "";
  };

  const asDefaultDisplayFormatDate = dateString => {
    if (typeof dateString === "string" && dateString !== "") {
      dateString = moment.utc(dateString).local();
      return moment(dateString).format(DEFAULT_DATE_FORMAT);
    }

    if (dateString instanceof moment) {
      return dateString.format(DEFAULT_DATE_FORMAT);
    }
    return "";
  };

  const colTemplate = [
    {
      title: (
        <span>
          Loan
          <br />
          No.
        </span>
      ),
      dataIndex: "loanNo",
      width: "6%",
      ellipsis: false,
      sorter: false,
      align: "left",
      sortOrder: false,
      render: (data, record) => {
        return (
          <span
            className={record.loanLendersBrokers.length < 2 ? "hideExpandIcon" : ""}
            onClick={() => handleLoanNumberClick(record)}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: "Lender",
      align: "left",
      dataIndex: "associatedLenderCompany",
      width: "8%",
      ellipsis: true,
      sorter: false,
      sortOrder: false,
      render: (data, record) => {
        if(record.associatedLenderCompany === "Privacy Engaged"){
          return (
            <span>
              {data}
            </span>
          );
        }
        else{
          return (
            <span
              onClick={() => handleProfileClick(record, "mainList", "lender")}
              style={{ color: "blue" }}
            >
              {data}
            </span>
          );
        }
      }
    },
    {
      title: "Broker",
      align: "left",
      dataIndex: "associatedBrokerCompany",
      width: "8%",
      ellipsis: true,
      sorter: false,
      sortOrder: false,
      render: (data, record) => {
        return (
          <span
            onClick={() => handleProfileClick(record, "mainList", "broker")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        );
      }
    },
    {
      title: (
        <span>
          Suburb
          <br />& State
        </span>
      ),
      dataIndex: "suburbState",
      align: "left",
      width: "10%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: "Priority",
      align: "left",
      dataIndex: "priority",
      width: "4%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: "Principal",
      align: "left",
      dataIndex: "principal",
      width: "7%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{formatDecimalToCurrency(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Lower
          <br />
          Rate
        </span>
      ),
      dataIndex: "lowerRate",
      align: "left",
      width: "4%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Higher
          <br />
          Rate
        </span>
      ),
      dataIndex: "higherRate",
      align: "left",
      width: "4%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: (
        <span>
          Estab
          <br />%
        </span>
      ),
      dataIndex: "estab",
      align: "left",
      width: "3%",
      ellipsis: false,
      sorter: false,
      sortOrder:
        sortInfo && sortInfo.field === "estab" && sortInfo.order
          ? sortInfo.order === "asc"
            ? "ascend"
            : "descend"
          : false,
      render: (data) => {
        return <span>{formatDecimalWithDefaultValue(data, 1)}</span>;
      }
    },
    {
      title: (
        <span>
          LVR
          <br />%
        </span>
      ),
      dataIndex: "lvr",
      align: "left",
      width: "3%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{formatDecimal2(data)}</span>;
      }
    },
    {
      title: "Scenario",
      dataIndex: "scenario",
      align: "left",
      width: "4%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: (
        <span>
          Security
          <br />
          Type
        </span>
      ),
      dataIndex: "securityType",
      align: "left",
      ellipsis: true,
      sorter: false,
      width: "6%",
      sortOrder: false
    },
    {
      title: "Partner",
      dataIndex: "partner",
      align: "left",
      width: "5%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: "Associate",
      dataIndex: "associate",
      align: "left",
      width: "5%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "left",
      width: "4%",
      ellipsis: true,
      sorter: false,
      sortOrder: false
    },
    {
      title: "Settlement",
      dataIndex: "settlement",
      align: "left",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      dataType: "date",
      dateFormat: AU_DATE_FORMAT,
      render: (data) => {
        return <span>{asDefaultDisplayFormatDate(data)}</span>;
      }
    },
    {
      title: "Repayment",
      align: "left",
      dataIndex: "repayment",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      dataType: "date",
      dateFormat: AU_DATE_FORMAT,
      render: (data, record) => {
        return <span>{asDefaultDisplayFormatDate(data)}</span>;
      }
    },
    {
      title: "Discharged",
      dataIndex: "discharged",
      align: "left",
      width: "5%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      dataType: "date",
      dateFormat: AU_DATE_FORMAT,
      render: (data, record) => {
        return <span>{asDefaultDisplayFormatDate(data)}</span>;
      }
    },
    {
      title: (
        <span style={{ textAlign: "left" }}>
          Broker <br />
          <span style={{ color: "#FFBF00" }}> &#9733;</span>
        </span>
      ),
      dataIndex: "brokerRating",
      align: "left",
      width: "4%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{data ? data : ""}</span>;
      }
    },
    {
      title: (
        <span style={{ textAlign: "left" }}>
          Lender <br />
          <span style={{ color: "#FFBF00" }}> &#9733;</span>
        </span>
      ),
      dataIndex: "lenderRating",
      align: "left",
      width: "4%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{data ? data : ""}</span>;
      }
    },
    {
      title: "Shortfall",
      dataIndex: "shortfall",
      align: "left",
      width: "4%",
      ellipsis: false,
      sorter: false,
      sortOrder: false,
      render: (data) => {
        return <span>{formatDecimalToCurrency(data)}</span>;
      }
    }
  ];

  const expandedRowLoanListColumns = [
    {
      dataIndex: "",
      key: "",
      width: "2%"
    },
    {
      title: "Lender List",
      dataIndex: "loanLenderName",
      key: "loanLenderName",
      align: 'left',
      width: 200,
      render: (data, record) => {
        if(record.loanLenderId && record.loanLenderName === "Privacy Engaged"){
          return (
            <span>{data}</span>
          )
        }else if(record.loanLenderId){
          return (
            <span
            onClick={() => handleProfileClick(record, "lenderList", "lender")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
          )
        }
      }
    },
    {
      dataIndex: "loanLenderContribution",
      key: "loanLenderContribution",
      width: 200,
      align: 'left',
      render: (data, record) => {
        return <span>{data ? formatDecimalToCurrency(data) : ''}</span>;
      }
    },
    {
      title: "Broker List",
      dataIndex: "loanBrokerName",
      key: "loanBrokerName",
      align: 'left',
      width: 200,
      render: (data, record) => {
        return record.loanBrokerId ? (
          <span
            onClick={() => handleProfileClick(record, "brokerList", "broker")}
            style={{ color: "blue" }}
          >
            {data}
          </span>
        ) : (<span>{data}</span>);
      }
    },
    {
      dataIndex: "",
      key: ""
    }
  ];
  return (
    <Table
      className={`loan-tabs-table ${className}`}
      style={{ whiteSpace: "nowrap" }}
      expandIconAsCell
      expandedRowRender={record =>
        record.loanLendersBrokers.length > 1 ? (
          <Table
            columns={expandedRowLoanListColumns}
            dataSource={mustBeArray(record.loanLendersBrokers)}
            pagination={false}
            size="extra small"
          />
        ) : (
          <div className="test">test</div>
        )
      }
      columns={colTemplate}
      dataSource={list} 
      loading={loading}
      pagination={{current:page, pageSize: 20, total}}
      onChange={handleTableChange}
      
    />
  );
};
export default AllLoansList;
