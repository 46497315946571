import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";

export const finderSecurityTypesLookups = ({ cancelToken, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_SECURITY_TYPE_LOOKUP :  apiUrls.FINDER_SECURITY_TYPE_LOOKUP,
          options: { }
        });
        const response = generalView ? await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  
  
  export const finderStatesAndSuburbsLookups = ({ cancelToken, search="", generalView=false }) => {
    return async() => {
        const apiUrl = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_SUBURB_STATES_LOOKUP : apiUrls.FINDER_SUBURB_STATES_LOOKUP,
          options: {}
        });
        const url = search ? `${apiUrl}?search=${search}` : apiUrl;
        const response = generalView ? await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  
  const getLowerTermUrl = (generalView) => {
    return generalView ? apiUrls.GENERAL_FINDER_TERM_LOWER_LOOKUP : apiUrls.FINDER_TERM_LOWER_LOOKUP;
  };

  const getHigherTermUrl = (generalView) => {
    return generalView ? apiUrls.GENERAL_FINDER_TERM_HIGHER_LOOKUP: apiUrls.FINDER_TERM_HIGHER_LOOKUP;
  }

  export const finderTermLookups = ({ cancelToken, type, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: type==="lower" ? getLowerTermUrl(generalView)  : getHigherTermUrl(generalView),
          options: {}
        });
        const response = generalView ? await http.publicGet(url, cancelToken) :   await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  
  export const finderScenarioLookups = ({ cancelToken, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_SCENARIO_LOOKUP :  apiUrls.FINDER_SCENARIO_LOOKUP,
          options: {}
        });
        const response = generalView ? await http.publicGet(url, cancelToken) :   await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  
  export const finderBrokersLookups = ({ cancelToken, search, generalView=false, isSystemUser }) => {
    return async() => {
        const apiUrl = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_BROKERS_LOOKUP : apiUrls.FINDER_BROKERS_LOOKUP,
          options: {}
        });
        const url = search ? `${apiUrl}?${search}` : apiUrl;
        const response = generalView ? await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };
  
  export const finderLendersLookups = ({ cancelToken, search, generalView=false, isSystemUser }) => {
    return async() => {
        const apiUrl = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_LENDERS_LOOKUP : 
            isSystemUser ? apiUrls.FINDER_LENDERS_LOOKUP_AS_SYSTEM_USER : apiUrls.FINDER_LENDERS_LOOKUP,
          options: {}
        });
        const url = search ? `${apiUrl}?${search}` : apiUrl;
        const response = generalView ?  await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  }; 
  
  export const finderPiorityLookup = ({ cancelToken, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_PRIORITY_LOOKUP : apiUrls.FINDER_PRIORITY_LOOKUP,
          options: {}
        });
        const response = generalView ? await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

  export const finderRadiusLookups = ({ cancelToken, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_RADIUS_LOOKUP : apiUrls.FINDER_RADIUS_LOOKUP,
          options: {}
        });
        const response = generalView ? await http.publicGet(url, cancelToken) : await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

  export const finderSettlementDateLookups = ({ cancelToken, generalView=false }) => {
    return async() => {
        const url = APIHandler.constructEndpoint({
          endpoint: generalView ? apiUrls.GENERAL_FINDER_SETTLEMENT_DATE_LOOKUP : apiUrls.FINDER_SETTLEMENT_DATE_LOOKUP,
          options: {}
        });
        const response = generalView ? await http.publicGet(url, cancelToken) :  await http.get(url, cancelToken);
        if (ResponseHandler.isValidStatus(response)) {
          return  ( 
                response.data && response.data.result
                  ? response.data.result
                  : response.data
          );
        } else {
          return  ResponseHandler.inValidObject(response);
        } 
      }
  };

/**
 * 
 * @param {{cancelToken: any, generalView: boolean}} params
 * @returns {Promise<import("../libs/types").ILookupItem[]>} 
 */
export const finderCreditLookups = ({ cancelToken,generalView = false }) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: !generalView ? apiUrls.FINDER_CREDIT_LOOKUP : apiUrls.GENERAL_FINDER_CREDIT_LOOKUP,
      options: {}
    });
    const response = await http.get(url, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return  ( 
            response.data && response.data.result
              ? response.data.result
              : response.data
      );
    } else {
      return  ResponseHandler.inValidObject(response);
    } 
  }
};

/**
 * 
 * @param {{cancelToken: any, generalView: boolean}} param0 
 * @returns {Promise<import('../libs/types').ILookupItem[]>}
 */
export const finderInterestLookups = ({ cancelToken, generalView = false }) => {
  return async () => {
      const url = APIHandler.constructEndpoint({
        endpoint: !generalView ? apiUrls.FINDER_INTEREST_LOOKUP : apiUrls.GENERAL_FINDER_INTEREST_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};

/**
 * 
 * @param {{cancelToken: any, generalView: boolean}} param0 
 * @returns {Promise<import('../libs/types').ILookupItem[]>}
 */
export const finderSubdivisionTypeLookups = ({ cancelToken, generalView }) => {
  return async() => {
      const url = APIHandler.constructEndpoint({
        endpoint: !generalView ? apiUrls.FINDER_SCENARIO_SUBDIVISION_TYPE_LOOKUP : apiUrls.GENERAL_FINDER_SCENARIO_SUBDIVISION_TYPE_LOOKUP,
        options: {}
      });
      const response = await http.get(url, cancelToken);
      if (ResponseHandler.isValidStatus(response)) {
        return  ( 
              response.data && response.data.result
                ? response.data.result
                : response.data
        );
      } else {
        return  ResponseHandler.inValidObject(response);
      } 
    }
};