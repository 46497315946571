import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, Table } from "antd";
import { tableColumnGenerator } from "../../../libs/table";
import { mustBeArray } from "../../../libs/utilities";
import NumberInput from "../../../Common/NumberInput";
import history from "../../../routes/history";
import { isLawFirmUserPolicy } from "../../../Common/Authorization/policies/isLawFirmUserPolicy";
import { ILoanSnapshotLender } from "../../../libs/types";

interface LogsAttributedLendersProps {
  attributedLenderList: ILoanSnapshotLender[] | null;
}

const SnapshotAttributedLenders = ({
  attributedLenderList
}: LogsAttributedLendersProps) => {
  const { user } = useSelector((state: any) => state.authorization);

  const [isUserLawfirm, setIsUserLawfirm] = useState(false);

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsUserLawfirm(isLawfirm);
  };

  useEffect(() => {
    checkIfUserIsLawfirm();
  }, [user]);

  const handleLenderProfileClick = (lenderId: any) => {
    history.push(`/users/${lenderId}/lender`);
  };

  return (
    <div className="m-n-t-15">
      {mustBeArray(attributedLenderList).map((data, i) => {
        return (
          <div className="m-b-8 m-l-4 m-t-6" key={i}>
            {data.loanLenderId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: !isUserLawfirm ? "blue" : "inherit",
                  cursor: !isUserLawfirm ? "pointer" : "default"
                }}
                disabled={false}
                value={data.loanLenderName}
                onClick={() => {
                  if (!isUserLawfirm) {
                    handleLenderProfileClick(data.loanLenderId);
                  }
                }}
              ></Input>
            )}
            {!data.loanLenderId && (
              <Input
                style={{
                  width: "220px",
                  caretColor: "transparent",
                  color: "darkred",
                  cursor: "default"
                }}
                disabled={false}
                value={data.loanLenderName}
              ></Input>
            )}
            <NumberInput
              formatter={(value: any) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
              className="m-l-6 m-r-6 hideNumberPointers w-150"
              value={data.loanLenderContribution}
              disabled
              maxLength={undefined}
              maxValue={undefined}
              onBlur={() => {}}
              onChange={() => {}}
              placeholder={""}
            ></NumberInput>
          </div>
        );
      })}
    </div>
  );
};

export default SnapshotAttributedLenders;
