import { Select } from 'antd'
import React, { useContext } from 'react'
import { FinderFilterFieldChangeHandlerParams } from '../../../libs/types';
import FinderUtilityContext from '../FinderUtilityContext';


type ValuationProps = {
  handleFieldChange: (params: FinderFilterFieldChangeHandlerParams<string>) => void,
  value: string
}

export default function Valuation({value, handleFieldChange}: ValuationProps) {
  const {yesNoList} : any = useContext(FinderUtilityContext);

  return (
    <div className={`flex flex-align-center m-r-20`}>
      <Select
        className="w-100 m-l-6"
        value={value}
        onChange={(v = "") =>
          handleFieldChange({ field: "valuationRequired", value: v })
        }
        allowClear={false}
      >
        {yesNoList.map((data: {id: string, name: string}) => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  )
}