import { Col, Form, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import RadioInCard from "../../components/Common/RadioInCard";
import { updateHeader } from "../../actions/member";
import AllUsersList from "../../components/Users/AllUsersList";
import { PaginationConfig } from "antd/lib/pagination";
import Axios from "axios";
import _, { filter } from "lodash";
import { mustBeArray } from "../../libs";
import {
  AllUsersListItem,
  AllUsersSearchPayload,
  IBaseLookupItem,
  RadioOption
} from "../../libs/types";
import {
  financierTypeLookup,
  firstFundsAvailableLookup,
  getAllUsersList,
  profileStatusLookup,
  settledLoansLookup,
  getAllUsersBdmLookup
} from "../../actions/users";
import { FormComponentProps } from "antd/lib/form";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { ADMIN_USER } from "../../constants/systemUsers";
import { SorterResult } from "antd/lib/table";
import useUpdateUsersRouteOptions from "../../Common/Hooks/useUpdateUsersRouteOptions";

interface AllUsersProps extends FormComponentProps {
  updateHeader: typeof updateHeader;
  financierTypeLookup: typeof financierTypeLookup;
  profileStatusLookup: typeof profileStatusLookup;
  settledLoansLookup: typeof settledLoansLookup;
  firstFundsAvailableLookup: typeof firstFundsAvailableLookup;
  getAllUsersList: typeof getAllUsersList;
  getAllUsersBdmLookup: typeof getAllUsersBdmLookup
}

export const AllUsers = React.forwardRef((props: AllUsersProps, ref) => {
  const cancelToken = Axios.CancelToken.source();

  const user = useSelector((state: any) => state.authorization.user);

  useUpdateUsersRouteOptions();

  const [filters, setFilters] = useState({
    omicronBdmId: "null",
    typeKey: "null",
    settledLoansKey: "null",
    profileStatusKey: "MasterSwitchOn",
    firstFundsAvailableKey: "null",
    page: 1,
    pageSize: 20, // Page size fixed to 20
    orderBy: "",
    sort: ""
  });
  const [bdmList, setBdmList] = useState<IBaseLookupItem[]>([]);
  const [loading, setLoading] = useState(false);

  const [filterProfileStatus, setFilterProfileStatus] = useState<RadioOption[]>(
    []
  );
  const [filterFirstFundsAvailable, setFilterFirstFundsAvailable] = useState<
    RadioOption[]
  >([]);
  const [filterFinancierType, setFilterFinancierType] = useState<RadioOption[]>(
    []
  );
  const [filterSettledLoans, setFilterSettledLoans] = useState<RadioOption[]>(
    []
  );
  const [rows, setRows] = useState<AllUsersListItem[]>([]);
  const [total, setTotal] = useState(0);

  function changeFilterToRadio(items: IBaseLookupItem[] = []): RadioOption[] {
    return mustBeArray(items).map(v => {
      const converted: RadioOption = {
        label: v.name || "",
        value: v.id || 0
      };
      return converted;
    });
  }

  const getBdmList = _.debounce(async (search: string = "") => {
    const s: any = await props.getAllUsersBdmLookup({ search });
    const list: IBaseLookupItem[] = mustBeArray(s.data);
    setBdmList([
      ...list,
      {
        id: null,
        name: "All"
      }
    ]);
    let usersSystemUserId = parseInt(user["app.user.systemuserid"]);
    let bdm = list.filter(bdm => bdm.id == usersSystemUserId);
    if (bdm.length > 0) {
      setFilters(s => ({ ...s, omicronBdmId: `${usersSystemUserId}` }));
    }
  }, 500);

  async function getAllUsers(payload: AllUsersSearchPayload) {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    const res = await props.getAllUsersList({
      cancelToken: cancelToken.token,
      userType,
      payload
    });
    setTotal(res.total);
    setRows(res.rows);
    setLoading(false);
  }

  async function getFinancierTypes() {
    const res = await props.financierTypeLookup({
      cancelToken: cancelToken.token
    });
    setFilterFinancierType([
      ...changeFilterToRadio(res),
      {
        label: "All",
        value: "null"
      }
    ]);
  }

  async function getProfileStatuses() {
    const res = await props.profileStatusLookup({
      cancelToken: cancelToken.token
    });
    setFilterProfileStatus(changeFilterToRadio(res));
  }

  async function getFirstFundsAvailable() {
    const res = await props.firstFundsAvailableLookup({
      cancelToken: cancelToken.token
    });
    setFilterFirstFundsAvailable([
      ...changeFilterToRadio(res),
      {
        label: "All",
        value: "null"
      }
    ]);
  }

  async function getSettledLoans() {
    const res = await props.settledLoansLookup({
      cancelToken: cancelToken.token
    });
    setFilterSettledLoans([
      ...changeFilterToRadio(res),
      {
        label: "All",
        value: "null"
      }
    ]);
  }

  function changeFilter(
    type:
      | "omicronBdmId"
      | "typeKey"
      | "settledLoansKey"
      | "profileStatusKey"
      | "firstFundsAvailableKey",
    value: string | number
  ) {
    setFilters(s => ({ ...s, [type]: value }));
  }

  function handleTableChange(
    pagination: PaginationConfig,
    sorter: SorterResult<AllUsersListItem>
  ) {
    pagination.current;
    setFilters(s => ({
      ...s,
      page: pagination.current || 1,
      sort: sorter.field,
      orderBy: sorter.order
    }));
  }

  useEffect(() => {
    props.updateHeader({
      header: {
        title: "All Users",
        action: "",
        page: `all-users`,
        enableBack: false,
        showSettings: true,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    getBdmList();
    getProfileStatuses();
    getFirstFundsAvailable();
    getFinancierTypes();
    getSettledLoans();
  }, []);

  useEffect(() => {}, [filters]);

  useEffect(() => {
    if (bdmList.length > 0) {
      setLoading(true);
      const payload: AllUsersSearchPayload = {
        ...filters,
        typeKey: filters.typeKey === "null" ? null : filters.typeKey,
        settledLoansKey:
          filters.settledLoansKey === "null" ? null : filters.settledLoansKey,
        profileStatusKey:
          filters.profileStatusKey === "null" ? null : filters.profileStatusKey,
        firstFundsAvailableKey:
          filters.firstFundsAvailableKey === "null"
            ? null
            : filters.firstFundsAvailableKey,
        omicronBdmId: parseInt(filters.omicronBdmId) || null
      };
      getAllUsers(payload);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [filters, bdmList]);

  return (
    <div className="profile-content">
      <div className=" m-n-t-80 m-l-220 w-percent-67">
        <Row type="flex" gutter={[8, 8]}>
          <Col>
            <RadioInCard
              label="Type"
              value={filters.typeKey}
              onChange={v => changeFilter("typeKey", v)}
              options={filterFinancierType}
            />
          </Col>
          <Col>
            <Typography.Title level={4}>All Users</Typography.Title>
          </Col>
          <Col>
            <RadioInCard
              label="Settled Loans"
              value={filters.settledLoansKey}
              onChange={v => changeFilter("settledLoansKey", v)}
              options={filterSettledLoans}
            />
          </Col>
        </Row>
        <Row type="flex" gutter={[8, 8]}>
          <Col>
            <label>
              <span className="m-r-8">BDM</span>
              {bdmList.length > 1 && <Select
                value={filters.omicronBdmId}
                onChange={(v: string) => changeFilter("omicronBdmId", v)}
                showSearch
                filterOption={false}
                onSearch={getBdmList}
                style={{ minWidth: "113px" }}
              >
                {bdmList.length > 0 &&
                  bdmList.map(v => (
                    <Select.Option key={v.id || ""} value={`${v.id}`}>
                      {v.name}
                    </Select.Option>
                  ))}
              </Select>}
            </label>
          </Col>
          <Col>
            <label>
              <span className="m-r-8">Profile</span>
              {filterProfileStatus.length > 0 && <Select
                value={filters.profileStatusKey}
                onChange={(v: string) => changeFilter("profileStatusKey", v)}
                style={{ minWidth: "146px" }}
              >
                {filterProfileStatus.map((v, i) => (
                  <Select.Option key={i} value={v.value}>
                    {v.label}
                  </Select.Option>
                ))}
              </Select>}
            </label>
          </Col>
          <Col>
            <RadioInCard
              label="1st Funds Available"
              value={filters.firstFundsAvailableKey}
              onChange={v => changeFilter("firstFundsAvailableKey", v)}
              options={filterFirstFundsAvailable}
            />
          </Col>
        </Row>
      </div>
      <div className="m-t-16">
        <Row gutter={[16, 16]}>
          <Col>
            <AllUsersList
              loading={loading}
              page={filters.page}
              total={total}
              handleTableChange={handleTableChange}
              data={rows}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
});

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateHeader,
  financierTypeLookup,
  profileStatusLookup,
  settledLoansLookup,
  firstFundsAvailableLookup,
  getAllUsersList,
  getAllUsersBdmLookup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "allUsers" })(AllUsers));

// pages/Users/AllUsers.tsx