import React, { useEffect, useState } from 'react'
import authService from '../../actions/AuthorizeService';


export default function useGetLenderBrokerId() {
  const [userLenderId, setUserLenderId] = useState<number>();
  const [userBrokerId, setUserBrokerId] = useState<number>();

  async function getIds(){
    let userDetails = await authService.getUser();
    let lenderId = userDetails["app.user.lenderid"];
    let brokerId = userDetails["app.user.brokerid"];

    setUserLenderId(parseInt(lenderId) || undefined);
    setUserBrokerId(parseInt(brokerId) || undefined);
  }

  useEffect(()=>{
    getIds();
  },[]);

  return {
    userLenderId,
    userBrokerId
  }
}