// Profile Status
// Lender
export const LENDER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING =
  "You have chosen to make this profile active. This means the Lender will now be included on Funds Available emails for the Categories Offered whose verifications are up to date. You should only do this if you are instructed to do so. Are you sure you wish to proceed?";
export const LENDER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING =
  "You have chosen to make this Lender profile inactive. This means the Lender will not be included on any Funds Available emails. You should only do this if you are instructed to do so. Are you sure you wish to proceed?";
export const LENDER_PROFILE_STATUS_ACTIVE_SELF_WARNING =
  "You have chosen to make your profile active. This means you will now send Funds Available emails to Brokers for the categories you have chosen. Are you sure you wish to proceed?";
export const LENDER_PROFILE_STATUS_PAUSE_SELF_WARNING =
  "You have chosen to make your profile inactive. This means you will no longer send Funds Available emails to Brokers. Are you sure you wish to proceed?";

// Broker
export const BROKER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING =
  "You have chosen to make this Broker profile active. This means the Broker will now receive Funds Available emails for the categories chosen. You should only do this if you are instructed to do so. Are you sure you wish to proceed?";
export const BROKER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING =
  "You have chosen to make this Broker profile inactive. This means the Broker will no longer receive Funds Available emails. You should only do this if you are instructed to do so. Are you sure you wish to proceed?";
export const BROKER_PROFILE_STATUS_ACTIVE_SELF_WARNING =
  "You have chosen to make your profile active. This means you will now receive Funds Available emails for the categories you have chosen. Are you sure you wish to proceed? ";
export const BROKER_PROFILE_STATUS_PAUSE_SELF_WARNING =
  "You have chosen to make your profile inactive. This means you will no longer receive Funds Available emails. Are you sure you wish to proceed?";

// Law Firm
export const LAW_FIRM_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING =
  "You have chosen to make this Law firm profile active. Are you sure you wish to proceed?";
export const LAW_FIRM_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING =
  "You have chosen to make this Law firm profile inactive. Are you sure you wish to proceed?";
export const LAW_FIRM_PROFILE_STATUS_ACTIVE_SELF_WARNING =
"You have chosen to make your profile active. Are you sure you wish to proceed? ";
export const LAW_FIRM_PROFILE_STATUS_PAUSE_SELF_WARNING =
"You have chosen to make your profile inactive. Are you sure you wish to proceed?";

// Inactive Account
export const INACTIVE_ACCOUNT_SUBSCRIPTION_CHANGE_WARNING =
  "Your profile status is currently set to inactive. Even though you have turned on this category brokers will not see it until you unpause your profile.";
export const AT_LEAST_ONE_FIELD_SELECTED_SUBSCRIPTION_WARNING =
  "Your profile status is currently set to active. This means you must have at least one category turned on. If you do not want to offer any categories on you should pause your profile.";

// Incomplete lender categories warning
export const LENDER_PROFILE_INCOMPLETE_MANATORY_FIELD_WARNING =
  "This category is currently not visible to brokers because it has incomplete mandatory fields.";
export const LENDER_PROFILE_UNVERIFIED_WARNING =
  "This category is currently not visible to brokers because it has not been verified.";
export const LENDER_PROFILE_MORE_THAN_MONTH_LAST_VERIFIED_WARNING =
  "This category is currently not visible to brokers because it has been more than 30 days since it was last verified.";

export const STATE_REQUIRED_WARNING = "State is required.";

// Master switch off errors
export const MASTER_SWITCH_OFF_OMICRON_STATUS_CHANGE_ERROR =
  "The Master Switch on this Profile has been switched off. To change the matching status you must first of all turn the master switch on.";

// Inactive Profile warnings
export const INACTIVE_PROFILE_MATCHES_LIST_WARNING =
  "This profile is currently inactive and so there is no matching being done. To change the matching status you must first of all make the profile active.";

// 0 in available warnings
export const ZERO_AVAILABLE_WARNING_OFFERED_CATEGORY =
  "This is not possible because you cannot offer $0 to brokers looking for funds. Instead you need to pause offering this category. Your criteria will be preserved for when you do once again have funds to offer. Pause now?";
export const ZERO_AVAILABLE_WARNING_CATEGORY_NOT_OFFERED =
  "This is not possible because you cannot offer $0 to brokers looking for funds.";

// Master Switch Confirmation messages
export const LENDER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT =
  "You have chosen to turn off the Master Switch OFF for this Lender. The Lender will no longer be able to log in or receive automated email. Brokers will no longer receive emails featuring this lender or see the Lender in their match lists. Are you sure you wish to proceed?";
export const LENDER_MASTER_SWITCH_ON_CONFIRMATION_TEXT =
  "You have chosen to turn the Master Switch ON for this Lender. The Lender will now be able to log in and configure their profile. Brokers will receive Fund Available emails (if they are set in matches to do so). Are you sure you wish to proceed?";
export const BROKER_MASTER_SWITCH_OFF_CONFIRMATION_TEXT =
  "You have chosen to turn off the Master Switch OFF for this Broker. The Broker will no longer be able to log in or receive automated email. Lenders will no longer receive emails featuring this broker or see the Broker in their match lists. Are you sure you wish to proceed?";
export const BROKER_MASTER_SWITCH_ON_CONFIRMATION_TEXT =
  "You have chosen to turn the Master Switch ON for this Broker. The Broker will now be able to log in and configure their profile. Lenders will receive Fund Available emails (if they are set in matches to do so). Are you sure you wish to proceed?";
  export const LAW_FIRM_MASTER_SWITCH_OFF_CONFIRMATION_TEXT = "You have chosen to turn off the Master Switch OFF for this Law Firm. The Law Firm will no longer be able to log in or receive automated email. Are you sure you wish to proceed?";
  export const LAW_FIRM_MASTER_SWITCH_ON_CONFIRMATION_TEXT = "You have chosen to turn the Master Switch ON for this Law Firm. The Law Firm will now be able to log in and configure their profile. Are you sure you wish to proceed?";