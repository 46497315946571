import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visible } from "../../Common/visiblityWrapper";
import { Input, Upload, message } from "antd";
import { getDataUrlFromBlob, constructUrl } from "../../../libs";
import { DecoratedFormControl } from "../../Common/decoratedFormControl";

const UtilityCompany = ({ user, handleFormChange, form, isSystemUser }) => {
  const logo = user?.logoFile?.fileUrl;
  const previewLogo = user?.previewCompanyLogo?.url;
  const editMode = useSelector(state => state?.member?.header?.editMode);
  const [companyRequired, setCompanyRequired] = useState(false);

  useEffect(() => {
    const acn = form?.getFieldValue("companyACN");
    setCompanyRequired(!!acn);
  }, [form?.getFieldValue("companyACN")]);

  useEffect(() => {
    form.validateFields(["companyName", "companyACN"]);
  }, [companyRequired]);

  const updateAvatarLogo = async fileInfo => {
    const isImage = /image\//.test(fileInfo.file.type);
    if (!fileInfo.file) {
      return;
    }

    if (!isImage) {
      message.error("You can only choose image files.");
      return;
    }

    const url = await getDataUrlFromBlob(fileInfo.file);
    handleFormChange({ label: "previewCompanyLogo", value: { url, file: fileInfo.file } });
  };

  return (
    <div className="profile-utility-company">
      {editMode ? (
        <Fragment>
          <Upload.Dragger
            listType="picture-card"
            showUploadList={false}
            className="basic-detail-upload"
            customRequest={e => updateAvatarLogo(e)}
            style={{ height: "75px", width: "165px" }}
          >
            <Visible visible={!previewLogo}>Drag Logo here</Visible>
            <Visible visible={previewLogo}>
              <div
                style={{
                  backgroundImage: `url(${previewLogo}) `,
                  backgroundPosition: "center center ",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  objectFit: "cover",
                  width: "165px",
                  height: "83px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "-18px"
                }}
              />
            </Visible>
          </Upload.Dragger>
          <div>
            <DecoratedFormControl
              {...{
                labelCol: { span: 0 },
                wrapperCol: { span: 24 }
              }}
              label=""
              name="website"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Input
                placeholder="Website"
                onChange={e =>
                  handleFormChange({ label: "website", value: e.target.value })
                }
              />
            </DecoratedFormControl>
          </div>
          <div>
            <DecoratedFormControl
              {...{
                labelCol: { span: 0 },
                wrapperCol: { span: 24 }
              }}
              label=""
              name="companyName"
              rules={[
                {
                  required: companyRequired,
                  message: "Please enter company name"
                }
              ]}
            >
              <Input
                placeholder="Company name"
                onChange={e =>
                  handleFormChange({
                    label: "companyName",
                    value: e.target.value
                  })
                }
                disabled={!isSystemUser}
              />
            </DecoratedFormControl>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {logo && <img src={logo} height={75} />}
          {user?.website && (
            <a href={constructUrl(user?.website)} target="_blank">
              {user?.website}
            </a>
          )}
          {user?.companyName && <div>{user?.companyName}</div>}
          {user?.companyACN && <div>ACN {user?.companyACN}</div>}
        </Fragment>
      )}
    </div>
  );
};
export default UtilityCompany;
