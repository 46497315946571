import _ from "lodash";
import axios from "axios";
import authService from "../actions/AuthorizeService";
import { saveAs } from "file-saver";
import moment from "moment";
import { mustBeArray, parseMomentPropsToDate } from "../libs/utilities";
import history from "../routes/history";
import qs from "qs";
import { QueryParameterNames } from "../constants/ApiAuthorizationConstants";

const defaultAjaxTimeout = 30000;
const defaultDownloadTime = 300000; 

const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  return new File([theBlob], fileName);
};

let loginRedirectRequested = false;

function handleUnauthorizedRequest() {
  if (!loginRedirectRequested) {
    const returnUrl = window.location.href;
    const redirectUrl = `/login?${QueryParameterNames.ReturnUrl}=${encodeURI(
      returnUrl
    )}`;

    loginRedirectRequested = true;
    return history.push(redirectUrl, { local: true });
  }
}

const requestPayloadWrapper = function(method) {
  return async function(url, data, queryParams) {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    };
    return axios({
      method,
      url,
      headers,
      data,
      params: queryParams,
      timeout: defaultAjaxTimeout,
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if ([403, 500].includes(status))
          return history.push(`/exception/${status}`, { local: true });
        if(status === 404)
          return history.push(`/`, { local: true });
        return status >= 200 && status < 500;
      }
    });
  };
};

const requestWrapper = function(method) {
  return async function(url, cancelToken) {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    };
    return axios({
      method,
      url,
      headers,
      cancelToken,
      timeout: defaultAjaxTimeout,
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if ([403, 500].includes(status)){
          if(status == 403){
            if(url.includes("lender") || url.includes("broker"))
              return history.push('/exception/403/inactive-profile', { local: true })
          }

          return history.push(`/exception/${status}`, { local: true });
        }
        if(status === 404){
          return history.push(`/`, { local: true });
        }
        return status >= 200 && status < 500;
      }
    });
  };
};

const requestGetWithParamsWrapper = function() {
  return async function(url, params, cancelToken, handleServerError) {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": `application/json`,
      Authorization: `Bearer ${accessToken}`
    };

    return axios({
      method: "get",
      url,
      headers,
      params: parseMomentPropsToDate(params),
      cancelToken,
      timeout: defaultAjaxTimeout,
      paramsSerializer: params =>
        qs.stringify(params, { arrayFormat: "repeat" }),
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if (handleServerError && status === 500) return status;
        if ([403, 500].includes(status))
          return history.push(`/exception/${status}`, { local: true });
        if(status === 404)
          return history.push(`/`, { local: true });
        return status >= 200 && status < 500;
      }
    });
  };
};

const requestFileWrapper = function(method) {
  return async function(url, payload, name, fileList) {
    const accessToken = await authService.getAccessToken();
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const formData = new FormData();
      // const {file, name} = payload;
      // formData.append("file", blobToFile(file, name));
      if (!_.isUndefined(payload)) {
        formData.append(`${name}`, JSON.stringify(payload));
      }
      mustBeArray(fileList).map((file, index) => {
        formData.append(index, blobToFile(file, file?.name));
      });
      // if(!_.isUndefined(name)){
      //   formData.append("title",name);
      // }
      req.open(method, url, true);
      req.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      req.send(formData);
      req.onreadystatechange = () => {
        return resolve(req);
      };
    });
  };
};

const fileDownloadRequestWrapper = function(method) {
  return async (url, data) => {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": "application/octet-stream",
      Authorization: `Bearer ${accessToken}`
    };

    Object.keys(data || {}).forEach(key => {
      if (data[key] instanceof moment) {
        data[key] = data[key].toDate();
      }
    });
    const fileResult = await axios({
      method,
      url,
      headers,
      params: data,
      timeout: defaultDownloadTime,
      paramsSerializer: function(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if ([403, 500].includes(status))
          return history.push(`/exception/${status}`, { local: true });
        if(status === 404)
          return history.push(`/`, { local: true });
        return status >= 200 && status < 500;
      },
      responseType: "blob"
    });

    const contentDisposition = fileResult.request.getResponseHeader(
      "Content-Disposition"
    );
    let fileName = "file";
    if (contentDisposition && contentDisposition.includes("attachment")) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    saveAs(fileResult.data, fileName);

    return { status: true };
  };
};

const requestArrayBufferWrapper = function(method) {
  return async function(url, payload) {
    const accessToken = await authService.getAccessToken();
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const formData = new FormData();
      const { file, questions, title } = payload;
      formData.append("file", file);
      if (!_.isUndefined(questions)) {
        formData.append("questions", JSON.stringify(questions || {}));
      }
      req.responseType = "arraybuffer";
      req.open(method, url, true);
      req.setRequestHeader("Authorization", `bearer ${accessToken}`);
      req.send(formData);
      req.onreadystatechange = () => {
        if (req.readyState === XMLHttpRequest.DONE) {
          return resolve(req);
        }
      };
    });
  };
};

const requestPdf = function(method) {
  return async function(url, cancelToken) {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    };
    return axios({
      method,
      url,
      headers,
      cancelToken,
      timeout: defaultAjaxTimeout,
      responseType: "blob",
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if ([403, 500].includes(status))
          return history.push(`/exception/${status}`, { local: true });
        if(status === 404)
          return history.push(`/`, { local: true });
        return status >= 200 && status < 500;
      }
    });
  };
};

const requestFormData = function(method) {
  return async function(url, formData, cancelToken) {
    const accessToken = await authService.getAccessToken();
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accessToken}`
    };
    return axios({
      method,
      url,
      headers,
      cancelToken,
      data: formData,
      timeout: defaultAjaxTimeout,
      validateStatus: status => {
        if (status === 401) return handleUnauthorizedRequest();
        if ([403, 500].includes(status))
          return history.push(`/exception/${status}`, { local: true });
        if(status === 404)
          return history.push(`/`, { local: true });
        return status >= 200 && status < 500;
      }
    });
  };
};

const requuestAllWrapper = function(method) {
  return async function(requests) {
    return axios[method](requests);
  };
};

// Public requests
const publicRequestWrapper = function (method) {
  return async function (url, cancelToken) {
    const headers = {
      "Content-Type": "application/json",
    };
    return axios({
      method,
      url,
      headers,
      cancelToken,
      timeout: defaultAjaxTimeout,
      validateStatus: (status) => {
        return status >= 200 && status < 500;
      },
    });
  };
};

export const http = {
  get: requestWrapper("get"),
  getWithQueryParams: requestGetWithParamsWrapper(),
  put: requestPayloadWrapper("put"),
  patch: requestPayloadWrapper("patch"),
  post: requestPayloadWrapper("post"),
  del: requestWrapper("delete"),
  postFile: requestFileWrapper("post"),
  postFormData: requestFormData("post"),
  putFormData: requestFormData("put"),
  updateFile: requestFileWrapper("put"),
  downloadFile: fileDownloadRequestWrapper("get"),
  all: requuestAllWrapper("all"),
  postPreviewFIle: requestArrayBufferWrapper("post"),
  getPdf: requestPdf("get"),
  // Public Requests
  publicGet: publicRequestWrapper("get")
};
