import { Avatar } from 'antd';
import React from 'react';
const UserProfileImage = ({url, height}) => {
    return  url ? (
      <Avatar icon="user" size={height} shape="square" src={url}/> 
          ) : ( 
              <Avatar icon="user" size={height} shape="square" />
          )
}
 
export default UserProfileImage;