import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import NumberInput from "../../../Common/NumberInput";

interface ConstructionModalProps {
  loanDetails: any;
  handleFieldChange?: (payload: { field: string; value: any }) => void;
  generalView?: boolean;
  className?: string;
  disabled?: boolean
}

interface Construction {
  presalesDebtCoverRequired: string;
  loanAmountPercentageGstEx: number | null;
  qsRequired: string;
  isBuilderDeveloper: string;
  isBuildToHold: string;
  tdcEquity: number | null;
  projectManagerRequired: string;
  contingenciesAllowance: number | null;
  developerSuccessfulProjects: number | null;
  preCommittedCommercialTenants: string;
}

const YesNoOptions = [
  {
    label: "Yes",
    value: "true"
  },
  {
    label: "No",
    value: "false"
  }
];

const defaultConstructionDetails: Construction = {
  presalesDebtCoverRequired: "false",
  loanAmountPercentageGstEx: null,
  qsRequired: "false",
  isBuilderDeveloper: "false",
  isBuildToHold: "false",
  tdcEquity: null,
  projectManagerRequired: "false",
  contingenciesAllowance: null,
  developerSuccessfulProjects: 0,
  preCommittedCommercialTenants: "false"
};

function ConstructionModal({
  loanDetails,
  handleFieldChange = () => {},
  generalView = false,
  className = '',
  disabled = false
}: ConstructionModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [construction, setConstruction] = useState<Construction>(
    defaultConstructionDetails
  );

  function loadDetails() {
    if (loanDetails.constructionScenario) {
      setConstruction({
        presalesDebtCoverRequired: toStringValue(
          loanDetails.constructionScenario.presalesDebtCoverRequired
        ),
        loanAmountPercentageGstEx:
          loanDetails.constructionScenario.loanAmountPercentageGstEx || null,
        qsRequired: toStringValue(loanDetails.constructionScenario.qsRequired),
        isBuilderDeveloper: toStringValue(
          loanDetails.constructionScenario.isBuilderDeveloper
        ),
        isBuildToHold: toStringValue(
          loanDetails.constructionScenario.isBuildToHold
        ),
        tdcEquity: loanDetails.constructionScenario.tdcEquity || null,
        projectManagerRequired: toStringValue(
          loanDetails.constructionScenario.projectManagerRequired
        ),
        contingenciesAllowance:
          loanDetails.constructionScenario.contingenciesAllowance || null,
        developerSuccessfulProjects:
          loanDetails.constructionScenario.developerSuccessfulProjects || null,
        preCommittedCommercialTenants: toStringValue(
          loanDetails.constructionScenario.preCommittedCommercialTenants
        )
      });
    } else {
      setConstruction(defaultConstructionDetails);
    }
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function toBoolean(value: string) {
    return value === "true";
  }

  function toStringValue(value: boolean | undefined | null) {
    return value ? "true" : "false";
  }

  function typeCheckConvert(value: string | number | null) {
    return typeof value === "string" ? toBoolean(value) : value;
  }

  function handleLocalChange(field: string, value: string | number) {
    setConstruction(s => ({
      ...s,
      [field]: value
    }));
  }

  function handleOk() {
    if (!generalView) {
      const payload = {
        presalesDebtCoverRequired: typeCheckConvert(
          construction.presalesDebtCoverRequired
        ),
        loanAmountPercentageGstEx: typeCheckConvert(
          construction.loanAmountPercentageGstEx
        ),
        qsRequired: typeCheckConvert(construction.qsRequired),
        isBuilderDeveloper: typeCheckConvert(construction.isBuilderDeveloper),
        isBuildToHold: typeCheckConvert(construction.isBuildToHold),
        tdcEquity: typeCheckConvert(construction.tdcEquity),
        projectManagerRequired: typeCheckConvert(
          construction.projectManagerRequired
        ),
        contingenciesAllowance: typeCheckConvert(
          construction.contingenciesAllowance
        ),
        developerSuccessfulProjects: typeCheckConvert(
          construction.developerSuccessfulProjects
        ),
        preCommittedCommercialTenants: typeCheckConvert(
          construction.preCommittedCommercialTenants
        )
      };
      handleFieldChange({
        field: "constructionScenario",
        value: payload
      });
    }

    closeModal();
  }

  function handleCancel() {
    closeModal();
    loadDetails();
  }

  useEffect(() => {
    loadDetails();
  }, [loanDetails]);

  return (
    <>
      <Button className={className} onClick={openModal}>
        Construction
      </Button>
      <Modal
        visible={isModalOpen}
        width={500}
        onCancel={handleCancel}
        footer={
          <div className="flex space-between">
            <span>
              <Button type="primary" onClick={handleOk} className="m-r-10">
                OK
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </span>
          </div>
        }
      >
        <div className="m-b-8 m-l-8">Construction</div>
        <div className="flex flex-align-center m-b-10">
          <span>Presales debt cover required?</span>
          <Select
            className="w-200 m-l-6"
            value={construction.presalesDebtCoverRequired}
            onChange={(v: string) =>
              handleLocalChange("presalesDebtCoverRequired", v)
            }
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        {construction.presalesDebtCoverRequired === "true" && (
          <div className="flex flex-align-center m-b-10">
            <span>Percentage of loan amount exclusive of GST</span>
            <NumberInput
              formatter={(value: any) => `${value}%`}
              parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
              className="m-l-6 hideNumberPointers"
              value={construction.loanAmountPercentageGstEx}
              onBlur={() => {}}
              onChange={(value: number) =>
                handleLocalChange("loanAmountPercentageGstEx", value)
              }
              placeholder={""}
              maxLength={undefined}
              maxValue={undefined}
              disabled={generalView || disabled}
            />
          </div>
        )}
        <div className="flex flex-align-center m-b-8">
          <span>QS required</span>
          <Select
            className="w-200 m-l-6"
            value={construction.qsRequired}
            onChange={(v: string) => handleLocalChange("qsRequired", v)}
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Builder/Developer</span>
          <Select
            className="w-200 m-l-6"
            value={construction.isBuilderDeveloper}
            onChange={(v: string) => handleLocalChange("isBuilderDeveloper", v)}
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Build to hold</span>

          <Select
            className="w-200 m-l-6"
            value={construction.isBuildToHold}
            onChange={(v: string) => handleLocalChange("isBuildToHold", v)}
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Equity percentage of total development costs* (TDC) %</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={construction.tdcEquity}
            onBlur={() => {}}
            onChange={(value: number) => handleLocalChange("tdcEquity", value)}
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
            disabled={generalView || disabled}
          />
        </div>
        <div>
          <p className="f-s-11">
            *Residual land value, post DA professional fees, council fees and
            contributions, SBBIS bond, contract sum including builder's margin,
            contingency allowance, project manager costs, provision for 50% of
            agents presales, capitalised interest and line fee, holding costs
            during construction (land tax and rates), project marketing costs
          </p>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Project manager required</span>

          <Select
            className="w-200 m-l-6"
            value={construction.projectManagerRequired}
            onChange={(v: string) =>
              handleLocalChange("projectManagerRequired", v)
            }
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Contingencies allowance percentage of construction sum</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={construction.contingenciesAllowance}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange("contingenciesAllowance", value)
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
            disabled={generalView || disabled}
          />
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Developer past successful projects (number up to 100)</span>
          <NumberInput
            formatter={(value: any) => `${value}`}
            parser={(value: string) => value}
            className="m-l-6 hideNumberPointers"
            value={construction.developerSuccessfulProjects}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange(
                "developerSuccessfulProjects",
                value > 100 ? 100 : value
              )
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={100}
            disabled={generalView || disabled}
          />
        </div>
        <div className="flex flex-align-center m-b-10">
          <span>Pre-committed commercial tenants</span>

          <Select
            className="w-200 m-l-6"
            value={construction.preCommittedCommercialTenants}
            onChange={(v: string) =>
              handleLocalChange("preCommittedCommercialTenants", v)
            }
            disabled={generalView || disabled}
          >
            {YesNoOptions.map(x => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    </>
  );
}

export default ConstructionModal;
