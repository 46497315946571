import { Rate } from "antd";
import React from "react";
import { getRoundedRatings } from "../libs";

const RateView = ({ value, className, loansCount }) => {
  return (
    <div
      className={`flex-1-1-16 display-stars flex column flex-center p-l-12 ${className}`}
    >
      <div className="flex flex-center gap-8">
        <span className="f-w-600 f-s-40">{value?.toFixed(1)}</span>
        <div className="w-50 l-h-18">Out of 5 Stars</div>
      </div>
      <Rate value={getRoundedRatings(value)} className="no-pointer-event m-n-t-14" allowHalf />
      <div
        className={`${
          value === 5 ? "color-danger f-w-600 f-s-22" : "f-s-18"
        } text-center`}
      >
        {value === 5 && ("Top Rated!")}
        {value !== 5 && (<>Average rating across {loansCount}<br></br>reviewed loans</>)}
      </div>
    </div>
  );
};
export default RateView;
