import { Button, Modal } from 'antd';
import React from 'react';

const ConfirmationModal = ({visible, handleModalClose, handleConfirm, confirmationText}) => {
    return(
        <Modal
        visible={visible}
        onCancel={handleModalClose}
        closable={false}
        footer={[
          <Button
            type="primary"
            key="a"
            onClick={handleConfirm}
          >
            Okay
          </Button>,
          <Button key="b" onClick={handleModalClose}>
            Cancel
          </Button>
        ]}
      >
        {confirmationText}
      </Modal>
    )
}
export default ConfirmationModal;