import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isLawFirmUserPolicy } from "../Authorization/policies/isLawFirmUserPolicy";
import { isSystemUserPolicy } from "../Authorization/policies/isSystemUserPolicy";
import { isLenderUserPolicy } from "../Authorization/policies/isLenderUserPolicy";
import { isBrokerUserPolicy } from "../Authorization/policies/isBrokerUserPolicy";

export default function useCheckUserRoles() {
  const { user } = useSelector((s: any) => s.authorization);

  const [isLawFirmUser, setIsLawFirm] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [isDualUser, setIsDualUser] = useState(false);
  const [isLenderUser, setIsLenderUser] = useState(false);
  const [isBrokerUser, setIsBroker] = useState(false);

  const checkIfUserIsLawfirm = async () => {
    const result = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsLawFirm(result);
    return result;
  };

  const checkIfUserIsSystemAdmin = async () => {
    const result = await isSystemUserPolicy(user.roles, user.permissions, user);
    setIsSystemAdmin(result);
    return result;
  };

  const checkIfUserIsLender = async () => {
    const result = await isLenderUserPolicy(user.roles, user.permissions, user);
    setIsLenderUser(result);
    return result;
  };

  const checkIfUserIsBroker = async () => {
    const result = await isBrokerUserPolicy(user.roles, user.permissions, user);
    setIsBroker(result);
    return result;
  };

  const checkIfUserIsDual = async () => {
    const brokerResult = await isBrokerUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const lenderResult = await isLenderUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsDualUser(brokerResult && lenderResult);
    return brokerResult && lenderResult;
  };

  useEffect(() => {
    checkIfUserIsLawfirm();
    checkIfUserIsLender();
    checkIfUserIsBroker();
    checkIfUserIsSystemAdmin();
    checkIfUserIsDual();
  }, [user]);

  return {
    isSystemAdmin,
    isBrokerUser,
    isLenderUser,
    isDualUser,
    isLawFirmUser,
    checkIfUserIsSystemAdmin,
    checkIfUserIsLender,
    checkIfUserIsBroker,
    checkIfUserIsDual,
    checkIfUserIsLawfirm
  };
}
