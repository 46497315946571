import { Col, Form, Button, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { updateHeader } from "../../actions/member";
import SyndicateFunders from "../../components/EmailSubscriptions/syndicateFunders";
import brokerSubscriptions from "../../components/EmailSubscriptions/brokerSubscriptions";
import LenderSubscriptions from "../../components/EmailSubscriptions/lenderSubscriptions";
import NinetyDaysActivity from "../../components/EmailSubscriptions/ninetyDaysActivity";
import { Row } from "antd/es/grid";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Loading from "../../components/loading";
import { isLenderUserPolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import authService from "../../actions/AuthorizeService";
import {
  errorDisplay,
} from "../../libs/utilities";
import {
  ADMIN_USER,
  BROKER_USER,
  DUAL_USER,
  LENDER_USER
} from "../../constants/systemUsers";
import { getIndividualDetails } from "../../actions/profile";
import { getEmailSubscriptions, updateEmailSubscriptions } from "../../actions/emailSubscriptions";
import history from "../../routes/history";
import BrokerSubscriptions from "../../components/EmailSubscriptions/brokerSubscriptions";

const EmailSubscriptionsView = props => {
  const dispatchAction = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const lender = useSelector(state => state?.profile?.lender);
  const user = useSelector(state => state?.authorization?.user);
  const [redirectionUrl, setRedirectionUrl] = useState("/");
  const [individual, setIndividual] = useState({});
  const [verifiedLenderCategories, setVerifiedLenderCategories] = useState([]);
  const [canEditLenderSubscriptions, setCanEditLenderSubscriptions] = useState(true);
  const [canEditBrokerSubscriptions, setCanEditBrokerSubscriptions] = useState(true);
  const [isMasterSwitchOn, setIsMasterSwitchOn] = useState(false);
  const signal = axios.CancelToken.source();
  const { match, form } = props;
  const { getFieldDecorator } = form;
  const [defaultValuesUpdated, setDefaultValuesUpdated] = useState(false);
  useEffect(() => {
    dispatchAction(
      updateHeader({
        header: {
          title: "",
          action: "",
          page: "privacySettings",
          enableBack: false,
          showSettings: false,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: true,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
  }, []);

  useEffect(() => {
    getUserData();
    getUserEmailSubscriptions();
  }, []);

  const getUserEmailSubscriptions = () => {
    const id = match?.params?.id;
    dispatchAction(
      getEmailSubscriptions({ cancelToken: signal.token, id })
    ).then(resp => {
      setPageLoading(false);
      if (resp) {
        setIsMasterSwitchOn(resp.isMasterSwitchOn);
        setCanEditBrokerSubscriptions(resp.canEditBrokerSubscriptions);
        setCanEditLenderSubscriptions(resp.canEditLenderSubscriptions);
        setVerifiedLenderCategories(resp.verifiedLenderCategories);
        const {
          syndicateFundersConstruction,
          syndicateFundersFirstRanking,
          syndicateFundersLandBank,
          syndicateFundersMezzanine,
          syndicateFundersRural,
          syndicateFundersSecondRanking,
          lenderConstruction,
          lenderFirstRanking,
          lenderLandBank,
          lenderMezzanine,
          lenderRural,
          lenderSecondRanking,
          brokerConstruction,
          brokerFirstRanking,
          brokerLandBank,
          brokerMezzanine,
          brokerRural,
          brokerSecondRanking,
          ninetyDaysActivity
        } = resp;
        props.form.setFieldsValue({
          syndicateFundersConstruction,
          syndicateFundersFirstRanking,
          syndicateFundersLandBank,
          syndicateFundersMezzanine,
          syndicateFundersRural,
          syndicateFundersSecondRanking,
          lenderConstruction,
          lenderFirstRanking,
          lenderLandBank,
          lenderMezzanine,
          lenderRural,
          lenderSecondRanking,
          brokerConstruction,
          brokerFirstRanking,
          brokerLandBank,
          brokerMezzanine,
          brokerRural,
          brokerSecondRanking,
          ninetyDaysActivity
        });
        setTimeout(() => {
          setDefaultValuesUpdated(true);
        }, 300);
      }
    });
  };

  const getUserData = async () => {
    const id = match?.params?.id;
    var isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    var systemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    let userDetails = await authService.getUser();
    var roleType = systemUser
      ? ADMIN_USER
      : user.roles?.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : BROKER_USER;

    dispatchAction(
      getIndividualDetails({
        id,
        userType: roleType,
      })
    ).then(resp => {
      setPageLoading(false);
      if (resp) {
        setIndividual(resp.data);
        let individualDetails = resp.data;
        if(individualDetails.lenderId != null){
          setRedirectionUrl(`/users/${individualDetails.lenderId}/lender`)
          return
        }
    
        else if(individualDetails.brokerId != null){
          setRedirectionUrl(`/users/${individualDetails.brokerId}/broker`)
          return
        }
      }
    });
  };

  const handleSave = () => {
    setProcessing(true);
    const {form} = props;
    const payload = {
      syndicateFundersConstruction: form.getFieldValue("syndicateFundersConstruction"), 
      syndicateFundersFirstRanking: form.getFieldValue("syndicateFundersFirstRanking"), 
      syndicateFundersLandBank: form.getFieldValue("syndicateFundersLandBank"), 
      syndicateFundersMezzanine: form.getFieldValue("syndicateFundersMezzanine"), 
      syndicateFundersRural: form.getFieldValue("syndicateFundersRural"), 
      syndicateFundersSecondRanking: form.getFieldValue("syndicateFundersSecondRanking"),
      lenderConstruction: form.getFieldValue("lenderConstruction"), 
      lenderFirstRanking: form.getFieldValue("lenderFirstRanking"), 
      lenderLandBank: form.getFieldValue("lenderLandBank"), 
      lenderMezzanine: form.getFieldValue("lenderMezzanine"), 
      lenderRural: form.getFieldValue("lenderRural"), 
      lenderSecondRanking: form.getFieldValue("lenderSecondRanking"),
      brokerConstruction: form.getFieldValue("brokerConstruction"), 
      brokerFirstRanking: form.getFieldValue("brokerFirstRanking"), 
      brokerLandBank: form.getFieldValue("brokerLandBank"), 
      brokerMezzanine: form.getFieldValue("brokerMezzanine"), 
      brokerRural: form.getFieldValue("brokerRural"), 
      brokerSecondRanking: form.getFieldValue("brokerSecondRanking"),
      ninetyDaysActivity: form.getFieldValue("ninetyDaysActivity")
      
    }

    dispatchAction(updateEmailSubscriptions ({cancelToken: signal.token, id: match?.params?.id, payload}))
    .then(resp => {
      if(resp && resp.data && resp.data.errors){
        errorDisplay(resp.data.errors);
        setProcessing(false);
      }
      else{
        history.push(redirectionUrl)
        setProcessing(false);
      }
    });
    

  };

  useEffect(() => {
    if (props?.form?.getFieldValue("autoAddNewBrokers") === "true") {
      props.form.setFieldsValue({
        addAfter: null,
        loansDone: null
      });
    }
  }, [props?.form?.getFieldValue("autoAddNewBrokers")]);

  useEffect(() => {
    if (props?.form?.getFieldValue("addAfter") === "true") {
      props.form.setFieldsValue({
        autoAddNewBrokers: null
      });
    }
  }, [props?.form?.getFieldValue("addAfter")]);

  const defaultLabelColSpan = { span: 7 };
  const defaultValueColSpan = { span: 17 };
  return (
    <Form
      labelCol={defaultLabelColSpan}
      wrapperCol={defaultValueColSpan}
      className="p-lr-140 shrinked-form"
      colon={false}
    >
      {pageLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-wrap w-percent-100 f-s-24 f-w-500 m-b-50 flex-center">
            Email Subscriptions for&nbsp;<span className="color-danger">{` ${individual?.firstName} ${individual?.lastName}`}</span>
          </div>
          <Row gutter={24} className="m-t-24">
            <Col md={24} lg={5} offset={!individual.lenderId || !individual.brokerId ? 3 : 0}>
              <NinetyDaysActivity canEdit={isMasterSwitchOn} getFieldDecorator={getFieldDecorator} />
            </Col>
            <Col md={24} lg={6} className="m-l-10" hidden={!individual.brokerId}>
              <BrokerSubscriptions getFieldDecorator={getFieldDecorator} canEdit={canEditBrokerSubscriptions && isMasterSwitchOn}/>
            </Col>
            <Col md={24} lg={6} className="m-l-10" hidden={!individual.lenderId}>
              <LenderSubscriptions verifiedLenderCategories={verifiedLenderCategories} canEdit={canEditLenderSubscriptions && isMasterSwitchOn} getFieldDecorator={getFieldDecorator} />
            </Col>
            <Col md={24} lg={6} className="m-l-10">
              <SyndicateFunders canEdit={isMasterSwitchOn} getFieldDecorator={getFieldDecorator} />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col md={24} lg={8} offset={8}>
            <div className="flex flex-wrap flex-center">
                <Button
                    icon="file"
                    type="primary"
                    className="f-w-500"
                    loading={processing}
                    disabled={!isMasterSwitchOn}
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                  <Button
                    className="f-w-500 m-l-15"
                    disabled={processing}
                    onClick={() => history.push(redirectionUrl)}
                  >
                    Cancel
                  </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};

export default Form.create({ name: "Email Subscriptions" })(EmailSubscriptionsView);
