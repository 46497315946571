import { Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mustBeArray } from "../../../libs";
import { debounce } from "lodash";
import { getAllLoansPartnerLookups } from "../../../actions/loans";

const AllLoansPartnerLookup = ({ value, handlePartnerChange, className }) => {
  const [list, setList] = useState([]); 
    const dispatchAction = useDispatch();
  useEffect(() => {
    dispatchAction(
      getAllLoansPartnerLookups({ search: "" })
    ).then(resp => {
      setList(mustBeArray(resp?.data));
    });
  }, []);
 

  const handleSearch = e => {
    dispatchAction(
      getAllLoansPartnerLookups({ search: e })
    ).then(resp => {
      setList(mustBeArray(resp?.data));
    });
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300));
  return (
    <div className={`flex flex-align-center ${className ?? ""}`}>
      <span>Partner</span>
      <Select
        className="w-200 m-l-6"
        value={value}
        onChange={handlePartnerChange}
        allowClear={false}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
      >
        <Select.Option key="all" value="all">
              All
            </Select.Option>
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
    </div>
  );
};

export default AllLoansPartnerLookup;
