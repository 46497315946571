import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Typography,
  Upload
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import RadioInCard from "../../components/Common/RadioInCard";
import history from "../../routes/history";
import { FormComponentProps } from "antd/es/form/Form";
import { updateHeader } from "../../actions/member";
import TemplateLoader from "../../components/Common/tempateLoader";
import Axios from "axios";
import {
  getOmicronBdmLookup,
  marketingCategorySubscribeLookup,
  marketingCompaniesLookup,
  marketingDaysSubscribeLookup,
  marketingSenderLookup,
  marketingStatesLookup,
  marketingUsersLookup,
  marketingUserTypesLookup,
  marketingVisibleUserLookup
} from "../../actions/lookup";
import { errorDisplay, mustBeArray } from "../../libs";
import _ from "lodash";
import {
  IBaseLookupItem,
  IMarketingCompanyLookupItem,
  IMarketingSenderLookupItem,
  IMarketingUserLookupItem,
  RadioOption
} from "../../libs/types";
import {
  sendEmailToCompany,
  sendEmailToTest,
  sendEmailToUser
} from "../../actions/marketingEmail";
import { UploadChangeParam } from "antd/lib/upload";

const typeToAddList: RadioOption[] = [
  {
    label: "Company",
    value: "COMPANY"
  },
  {
    label: "User",
    value: "USER"
  }
];

const SentToType = {
  Company: "COMPANY",
  User: "USER",
  None: "ALL"
};

interface CreateEmailProps extends FormComponentProps {
  marketingSenderLookup: typeof marketingSenderLookup;
  marketingCompaniesLookup: typeof marketingCompaniesLookup;
  marketingUsersLookup: typeof marketingUsersLookup;
  marketingUserTypesLookup: typeof marketingUserTypesLookup;
  marketingStatesLookup: typeof marketingStatesLookup;
  marketingVisibleUserLookup: typeof marketingVisibleUserLookup;
  marketingCategorySubscribeLookup: typeof marketingCategorySubscribeLookup;
  marketingDaysSubscribeLookup: typeof marketingDaysSubscribeLookup;
  sendEmailToCompany: typeof sendEmailToCompany;
  sendEmailToUser: typeof sendEmailToUser;
  sendEmailToTest: typeof sendEmailToTest;
}

export const CreateEmail = React.forwardRef((props: CreateEmailProps, ref) => {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [testEmail, setTestEmail] = useState("");
  const [testEmailHasError, setTestEmailHasError] = useState(false);
  const [senderList, setSenderList] = useState<IMarketingSenderLookupItem[]>(
    []
  );
  const [usersList, setUsersList] = useState<IMarketingUserLookupItem[]>([]);
  const [companiesList, setComaniesList] = useState<
    IMarketingCompanyLookupItem[]
  >([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [filterUserTypes, setFilterUserTypes] = useState<RadioOption[]>([]);
  const [filterStates, setFilterStates] = useState<RadioOption[]>([]);
  const [filterVisibleUser, setFilterVisibleUser] = useState<RadioOption[]>([
    {
      label: "Visible Users",
      value: "true"
    },
    {
      label: "Invisible Users",
      value: "false"
    },
    {
      label: "Filter Off (All)",
      value: "null"
    }
  ]);
  const [filterCategorySubsribe, setFilterCategorySubscribe] = useState<
    RadioOption[]
  >([]);
  const [filterDaysSubscribe, setFilterDaysSubscribe] = useState<RadioOption[]>(
    [
      {
        label: "Yes",
        value: "true"
      },
      {
        label: "No",
        value: "false"
      },
      {
        label: "Filter Off (All)",
        value: "null"
      }
    ]
  );

  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
    getFieldError
  } = props.form;
  const typeSelected: string | undefined = getFieldValue("type");
  const selectedToSendError = getFieldError("selectedToSend");
  const typeError = getFieldError("type");

  const toSendList = useMemo(() => {
    return typeSelected === "COMPANY" ? companiesList : usersList;
  }, [usersList, companiesList, typeSelected]);

  function changeFilterToRadio(
    items: IBaseLookupItem[] = [],
    labelKey: "name" | "id" = "name"
  ): RadioOption[] {
    return items.map(v => {
      const converted: RadioOption = {
        label: `${v[labelKey]}` || "",
        value: `${v.id}` || ""
      };
      return converted;
    });
  }

  // Lookups
  const getSenders = _.debounce(async (search: string = "") => {
    const s = await props.marketingSenderLookup({
      search,
      cancelToken: signal.token
    });
    setSenderList(mustBeArray(s));
  }, 500);

  const getCompanies = _.debounce(async (search: string = "") => {
    const response = await props.marketingCompaniesLookup({
      search,
      cancelToken: signal.token
    });
    setComaniesList(mustBeArray(response));
  }, 500);

  const getUsers = _.debounce(async (search: string = "") => {
    const response = await props.marketingUsersLookup({
      search,
      cancelToken: signal.token
    });

    setUsersList(mustBeArray(response));
  }, 500);

  async function getStates() {
    const response = await props.marketingStatesLookup({
      cancelToken: signal.token
    });
    setFilterStates([
      ...changeFilterToRadio(response, "id"),
      {
        label: "Filter Off (All)",
        value: "null"
      }
    ]);
  }

  async function getUserTypes() {
    const response = await props.marketingUserTypesLookup({
      cancelToken: signal.token
    });
    setFilterUserTypes([
      ...changeFilterToRadio(response),
      {
        label: "Filter Off (All)",
        value: "null"
      }
    ]);
  }

  async function getCategorySubscribe() {
    const response = await props.marketingCategorySubscribeLookup({
      cancelToken: signal.token
    });
    setFilterCategorySubscribe([
      ...changeFilterToRadio(response),
      {
        label: "Filter Off (All)",
        value: "null"
      }
    ]);
  }

  function setRadioOptionDisabled(options: RadioOption[], disabled: boolean) {
    return options.map(v => ({
      ...v,
      disabled
    }));
  }

  // Main functions
  function handleUserCompanyLookup(search = "") {
    if (typeSelected === "COMPANY") {
      getCompanies(search);
    } else if (typeSelected === "USER") {
      getUsers(search);
    }
  }

  function displayToast(response: any) {
    if (response.status) {
      message.success("Email sent successfully");
    } else {
      errorDisplay(response.data.errors);
    }
  }

  async function submit(type = "user") {
    if (type === "test") {
      const emailBody = getFieldValue("emailBody") || "";
      const emailSubject = getFieldValue("emailSubject") || "";
      const senderEmail = getFieldValue("senderEmail");
      const attachments: UploadChangeParam | undefined = getFieldValue(
        "attachments"
      );
      const attachmentList = attachments ? attachments.fileList : [];
      let totalAttachmentSize = 0;
      const formData = new FormData();

      try {
        if (testEmail === "") {
          message.error("No test email/s added");
          setTestEmailHasError(true);
          return;
        }
        setIsSubmitLoading(true);
        const testEmails = testEmail.split(";");
        formData.append(
          "emailBody",
          JSON.stringify({
            emailBody,
            emailSubject,
            senderEmail,
            testEmails
          })
        );
        attachmentList.forEach(x => {
          if (x.originFileObj) {
            totalAttachmentSize += x.originFileObj.size;
            formData.append("attachments", x.originFileObj);
          }
        });
        if (totalAttachmentSize / 1024 ** 2 > 25) {
          message.error("Total attachments size cannot exceed more than 25MB");
          return;
        }
        const response: any = await props.sendEmailToTest({
          payload: formData
        });
        displayToast(response);
      } catch (error) {
        message.error("The process could not be completed. Please try again.");
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      props.form.validateFields(async (errors, values) => {
        if (errors) {
          message.error("Please check for missing fields");
          return;
        }
        try {
          setIsSubmitLoading(true);
          const attachments: UploadChangeParam | undefined = getFieldValue(
            "attachments"
          );
          const attachmentList = attachments ? attachments.fileList : [];
          let totalAttachmentSize = 0;
          const formData = new FormData();

          attachmentList.forEach(x => {
            if (x.originFileObj) {
              totalAttachmentSize += x.originFileObj.size;
              formData.append("attachments", x.originFileObj);
            }
          });

          if (typeSelected === SentToType.User) {
            formData.append(
              "emailBody",
              JSON.stringify({
                emailBody: values.emailBody || "",
                emailSubject: values.emailSubject,
                senderEmail: values.senderEmail,
                individualIds: mustBeArray(values.selectedToSend)
              })
            );
            if (totalAttachmentSize / 1024 ** 2 > 25) {
              message.error(
                "Total attachments size cannot exceed more than 25MB"
              );
              return;
            }
            const response: any = await props.sendEmailToUser({
              payload: formData
            });
            displayToast(response);
          } else if (
            typeSelected === SentToType.Company ||
            typeSelected === SentToType.None
          ) {
            const nullValue = "null";
            const booleanValue = (value: string) => value === "true";

            formData.append(
              "emailBody",
              JSON.stringify({
                emailBody: values.emailBody || "",
                emailSubject: values.emailSubject,
                senderEmail: values.senderEmail,
                isVisible:
                  values.visibleKey === nullValue
                    ? null
                    : booleanValue(values.visibleKey),
                categorySubscriptionKey:
                  values.categorySubscriptionKey === nullValue
                    ? null
                    : values.categorySubscriptionKey,
                profileTypeKey:
                  values.profileTypeKey === nullValue
                    ? null
                    : values.profileTypeKey,
                stateKey:
                  values.stateKey === nullValue ? null : values.stateKey,
                isSubscribedTo90Days:
                  values.subscribe90DaysKey === nullValue
                    ? null
                    : booleanValue(values.subscribe90DaysKey),
                companyIds: mustBeArray(values.selectedToSend)
              })
            );

            const response: any = await props.sendEmailToCompany({
              payload: formData
            });
            displayToast(response);
          } else {
            message.error("Please check for missing fields");
          }
        } catch (error) {
          message.error(
            "The process could not be completed. Please try again."
          );
        } finally {
          setIsSubmitLoading(false);
        }
      });
    }
  }

  useEffect(() => {
    dispatch(
      updateHeader({
        header: {
          title: "Marketing Email",
          action: "",
          page: `marketing-email`,
          enableBack: false,
          showSettings: false,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: false,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
    getSenders();
    getStates();
    getUserTypes();
    getCategorySubscribe();
    getSenders();
  }, []);

  useEffect(() => {
    resetFields(["selectedToSend"]);
    if (typeSelected === SentToType.Company) {
      getCompanies();
      setFilterUserTypes(s => setRadioOptionDisabled(s, false));
      setFilterCategorySubscribe(s => setRadioOptionDisabled(s, false));
      setFilterDaysSubscribe(s => setRadioOptionDisabled(s, false));
      setFilterStates(s => setRadioOptionDisabled(s, false));
      setFilterVisibleUser(s => setRadioOptionDisabled(s, false));
    } else if (typeSelected === SentToType.User) {
      getUsers();
      setFilterUserTypes(s => setRadioOptionDisabled(s, true));
      setFilterCategorySubscribe(s => setRadioOptionDisabled(s, true));
      setFilterDaysSubscribe(s => setRadioOptionDisabled(s, true));
      setFilterStates(s => setRadioOptionDisabled(s, true));
      setFilterVisibleUser(s => setRadioOptionDisabled(s, true));
    }
  }, [typeSelected]);

  return (
    <Layout.Content className="m-t-42">
      <Form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        {/* Filters start */}
        <div className="m-n-t-80 m-l-220 w-percent-100">
          <Row gutter={[16, 16]} type="flex">
            <Col>
              {getFieldDecorator("stateKey", {
                initialValue: "null"
              })(<RadioInCard options={filterStates} />)}
            </Col>
            <Col>
              <h1>Marketing Email</h1>
            </Col>
            <Col>
              {getFieldDecorator("visibleKey", {
                initialValue: "null"
              })(<RadioInCard options={filterVisibleUser} />)}
            </Col>
            <Col>
              {getFieldDecorator("subscribe90DaysKey", {
                initialValue: "null"
              })(
                <RadioInCard
                  label="90 days subscribed"
                  options={filterDaysSubscribe}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} type="flex">
            <Col>
              {getFieldDecorator("profileTypeKey", {
                initialValue: "null"
              })(<RadioInCard options={filterUserTypes} />)}
            </Col>
            <Col>
              {getFieldDecorator("categorySubscriptionKey", {
                initialValue: "null"
              })(
                <RadioInCard
                  label="Subscribed To"
                  options={filterCategorySubsribe}
                />
              )}
            </Col>
          </Row>
        </div>
        {/* Filters end */}
        <Row className="m-t-32" gutter={[8, 8]}>
          <Col>
            <Row gutter={[8, 8]}>
              <Col>
                <Typography.Paragraph>
                  If you add a Company it will all go to that Company User's
                  subject to the filters above. If you add an individual it will
                  go to that individual regardless of the above filters if you
                  add nothing to the box below it will go to all Users (subject
                  to all filters above)
                </Typography.Paragraph>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={3}>
                {getFieldDecorator("type", {
                  validateTrigger: "onChange",
                  initialValue: SentToType.None
                })(
                  <RadioInCard
                    label="To"
                    options={typeToAddList}
                    hasError={typeError !== undefined && typeError.length > 0}
                  />
                )}
              </Col>
              <Col span={9}>
                {typeSelected &&
                  getFieldDecorator("selectedToSend", {
                    rules: [
                      {
                        required: typeSelected === SentToType.User
                      }
                    ],
                    validateTrigger: "onChange"
                  })(
                    <Select
                      mode="multiple"
                      placeholder="Name"
                      onSearch={handleUserCompanyLookup}
                      filterOption={false}
                      style={{
                        width: "100%",
                        outline:
                          selectedToSendError !== undefined &&
                          selectedToSendError.length > 0
                            ? "solid 1px red"
                            : ""
                      }}
                    >
                      {toSendList.map((v, i) => (
                        <Select.Option key={`${v.id}-${i}`} value={v.id || ""}>
                          {v.name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item>
                  {getFieldDecorator("emailSubject", {
                    initialValue: ""
                  })(<Input placeholder="Subject" />)}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <Typography.Title level={4}>Type your email here</Typography.Title>
          </Col>
          <Col>
            {getFieldDecorator("emailBody", {
              initialValue: "",
              preserve: true,
              validateTrigger: "onChange"
            })(<TemplateLoader />)}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <Typography.Title level={4}>Attachments</Typography.Title>
          </Col>
          <Col span={3}>
            {getFieldDecorator("attachments")(
              <Upload multiple showUploadList beforeUpload={() => false}>
                <Button type="primary" style={{ border: 0 }}>
                  <Icon type="plus-circle" /> Add Attachments
                </Button>
              </Upload>
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Row gutter={8}>
              <Col span={3}>
                <Typography.Text>Sender</Typography.Text>
              </Col>
              <Col span={12}>
                {getFieldDecorator("senderEmail", {
                  rules: [{ required: true, message: "Select a sender" }],
                  initialValue: "system@omicron.net.au"
                })(
                  <Select showSearch onSearch={getSenders} filterOption={false}>
                    {senderList.map((v, i) => (
                      <Select.Option key={i} value={v.email || ""}>
                        {v.senderName}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              htmlType="submit"
              className="m-r-8"
              disabled={isSubmitLoading}
            >
              Send
            </Button>
            <Button onClick={() => history.goBack()}>Cancel</Button>
          </Col>
          <Col span={8}>
            <Row gutter={8}>
              <Col span={3}>
                <Button
                  type="primary"
                  className="m-r-8"
                  onClick={() => submit("test")}
                >
                  Test
                </Button>
              </Col>
              <Col span={16}>
                <Input
                  style={{
                    outline: testEmailHasError ? "solid 1px red" : ""
                  }}
                  value={testEmail}
                  placeholder="Test email - multiple emails can be added, separated by semicolon ;"
                  onChange={({ target: { value } }) => {
                    setTestEmail(value);
                    setTestEmailHasError(false);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal
        closable={false}
        maskClosable={false}
        footer={null}
        centered
        title="Sending e-mails"
        visible={isSubmitLoading}
      >
        <p>
          This window will automatically close when the process is complete, or
          runs into a problem.
        </p>
      </Modal>
    </Layout.Content>
  );
});

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  marketingSenderLookup,
  marketingCompaniesLookup,
  marketingUsersLookup,
  marketingCategorySubscribeLookup,
  marketingStatesLookup,
  marketingUserTypesLookup,
  marketingVisibleUserLookup,
  marketingDaysSubscribeLookup,
  sendEmailToCompany,
  sendEmailToTest,
  sendEmailToUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "marketingEmail" })(CreateEmail));
