import { Select } from "antd";
import React from "react";

type ValuationRequiredLookupProps = {
  value: string | null;
  handleFieldChange: (payload: { field: string; value: any }) => void;
  className?: string;
  disabled?: boolean
};

function ValuationRequiredLookup({
  value,
  handleFieldChange,
  className,
  disabled = false
}: ValuationRequiredLookupProps) {
  const list: { id: string; name: string }[] = [
    {
      id: "true",
      name: "Yes"
    },
    {
      id: "false",
      name: "No"
    }
  ];

  return (
    <div className={`flex flex-align-center m-r-20 ${className || ""}`}>
      <b>Valuation Required</b>
      <Select
        className="w-100 m-l-6"
        value={value || undefined}
        onChange={(value = "") =>
          handleFieldChange({ field: "valuationRequired", value })
        }
        allowClear={false}
        disabled={disabled}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default ValuationRequiredLookup;
