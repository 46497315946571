import { BGS_SYSTEM_USER_ROLE } from "../SystemRoles";
import { BGS_SYSTEM_ADMIN_PERMISSION } from "../SystemPermissions";

/** @type {import("./types/policy").AuthorizationPolicy} */
export function isSystemAdminUserPolicy(roles, permissions, user) {
  let passed =
    (roles ?? []).some(x => x === BGS_SYSTEM_USER_ROLE) &&
    (permissions ?? []).some(x => x === BGS_SYSTEM_ADMIN_PERMISSION);

  return Promise.resolve(passed);
}
