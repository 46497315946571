import React from 'react';

const LabelValuePair = ({valueClassName,labelClassName, label, value, smallValue=false, mobileView}) => {
    const valueFlex = mobileView ? "flex-1-1-60" :  smallValue ? "flex-1-1-80" : "flex-1-1-60";
    const labelFlex = mobileView ? "flex-1-1-26" : smallValue ? "flex-1-1-12" : "flex-1-1-26";
    return (  
        <div className='flex space-between align-end width-full gap-10'>
            <span className={`${valueClassName || ""} ${labelFlex} f-s-16`}>{value}</span>
            <span className={`${labelClassName || ""} ${valueFlex} f-s-16`}>{label}</span>
        </div>
    );
}
 
export default LabelValuePair;