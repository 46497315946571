import React from 'react';
import { Button, Modal } from "antd";
import { ZERO_AVAILABLE_WARNING_CATEGORY_NOT_OFFERED, ZERO_AVAILABLE_WARNING_OFFERED_CATEGORY } from "../../constants/warnings";

const ZeroAvailableWarning = props => {
  const {handleCancel, handleOk, visible, isCategoryOffered, pauseLoading} = props;
  return(
    <Modal
        width={500}
        visible={visible}
        onCancel={handleCancel}
        footer={isCategoryOffered ? [
          <Button
            key={2}
            type="primary"
            loading={pauseLoading}
            onClick={handleOk}
          >
            Yes
          </Button>,
          <Button
            key={3}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        ] :
      [
        <Button
            key={3}
            onClick={handleCancel}
          >
            Cancel
          </Button>
      ]}
      >
        <div className="p-t-24">
            {
              isCategoryOffered ?
              ZERO_AVAILABLE_WARNING_OFFERED_CATEGORY  :
              ZERO_AVAILABLE_WARNING_CATEGORY_NOT_OFFERED
            }
      </div>
      </Modal>
  )
}
export default ZeroAvailableWarning;
