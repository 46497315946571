import React, { useState, useEffect, Fragment } from "react";
import { Form, Select, Button, Spin } from "antd";
import { connect } from "react-redux";
import { updateHeader } from "../../actions/member";
import { clearProfileDetails } from "../../actions/profile";
import { brokersLookUpHandler } from "../../actions/lookup";
import { errorDisplay, mustBeArray } from "../../libs/utilities";
import history from "../../routes/history";
import { ADMIN_USER, LENDER_USER } from "../../constants/systemUsers";
import { isLenderUserPolicy } from "../../Common/Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Visible } from "../../components/Common/visiblityWrapper";

const FormItem = Form.Item;

const FindBroker = props => {
  const {
    updateHeader,
    brokersLookUpHandler,
    user,
    form,
    clearProfileDetails
  } = props;
  const { getFieldDecorator } = form;
  const [brokerList, setList] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [searching, setSearching] = useState(true);

  const getBrokersList = async ({ name, userType }) => {
    try {
      const resp = await brokersLookUpHandler({
        action: "BROKERS_SEARCH_LOOKUP",
        userType,
        queryParams: name ? { search: name } : {}
      });
      if (resp.status) {
        setList(mustBeArray(resp.data));
      } else {
        errorDisplay(resp && resp.data && resp.data.validationErrors);
      }
    } finally {
      setSearching(false);
    }
  };

  const debouncedSearch = AwesomeDebouncePromise(async name => {
    setSearching(true);
    setList([]);
    await getBrokersList({ name, userType: userRole });
  }, 500);
  useEffect(() => {
    updateHeader({
      header: {
        title: "Find a Broker",
        action: "",
        page: "search-broker",
        enableBack: false,
        showSettings: false,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: true,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    getUsersProfile();
  }, []);

  const getUsersProfile = async () => {
    var isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    var isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    const roleType = isLender ? LENDER_USER : isSystemUser ? ADMIN_USER : "";
    setUserRole(roleType);
    getBrokersList({ name: "", userType: roleType });
  };
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      clearProfileDetails();
      history.push(`/users/${values && values.broker}/broker`);
    });
  };
  const checkSubmitDisabled = () => {
    if (form.getFieldValue("broker")) return false;
    return true;
  };
  return (
    <div className="flex flex-center label-right">
      <Form
        style={{ minWidth: "800px" }}
        colon={false}
        hideRequiredMark={true}
        onSubmit={handleSubmit}
        layout="vertical"
        className="flex flex-center column m-t-36"
      >
        <div>
          <FormItem label="">
            {getFieldDecorator("broker", {
              rules: [
                {
                  required: true,
                  message: "Please select a broker"
                }
              ]
            })(
              <Select
                className="w-300"
                placeholder="Name"
                notFoundContent={
                  <Fragment>
                    <Visible visible={searching}>
                      <Spin size="small" />
                      ...
                    </Visible>
                    <Visible visible={!searching}>No data</Visible>
                  </Fragment>
                }
                showSearch
                onSearch={async e => await debouncedSearch(e)}
                filterOption={false}
              >
                {mustBeArray(brokerList).map(broker => {
                  return (
                    <Select.Option key={broker.id} value={broker.id}>
                      {broker.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormItem>
        </div>
        <div className="p-r-110 m-t-12">
          <Button
            type="primary"
            loading={saveLoading}
            className={`f-w-500 m-r-10 ${
              checkSubmitDisabled() ? "bg-light-primary color-white" : ""
            }`}
            htmlType="submit"
            disabled={checkSubmitDisabled()}
          >
            Submit
          </Button>
          <Button
            icon="close"
            className="f-w-500"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};
const mapDispatchToProps = {
  updateHeader,
  brokersLookUpHandler,
  clearProfileDetails
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    user: state.authorization && state.authorization.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create("findBroker")(FindBroker));
