import { BROKER_USER, LAW_FIRM_USER, LENDER_USER } from "../constants/systemUsers";
import { BROKER_PROFILE_STATUS_ACTIVE_SELF_WARNING, BROKER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING, BROKER_PROFILE_STATUS_PAUSE_SELF_WARNING, BROKER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING, LAW_FIRM_PROFILE_STATUS_ACTIVE_SELF_WARNING, LAW_FIRM_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING, LAW_FIRM_PROFILE_STATUS_PAUSE_SELF_WARNING, LAW_FIRM_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING, LENDER_PROFILE_STATUS_ACTIVE_SELF_WARNING, LENDER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING, LENDER_PROFILE_STATUS_PAUSE_SELF_WARNING, LENDER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING } from "../constants/warnings";

export const getProfileStatusWarning = ({ profileType, userType, isProfileActive }) => {
    switch (profileType) {
      case "lender":
        if (isProfileActive) {
          return userType === LENDER_USER
            ? LENDER_PROFILE_STATUS_PAUSE_SELF_WARNING
            : LENDER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING;
        } else {
          return userType === LENDER_USER
            ? LENDER_PROFILE_STATUS_ACTIVE_SELF_WARNING
            : LENDER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING;
        }
      case "broker":
        if (isProfileActive) {
          return userType === BROKER_USER
            ? BROKER_PROFILE_STATUS_PAUSE_SELF_WARNING
            : BROKER_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING;
        } else {
          return userType === BROKER_USER
            ? BROKER_PROFILE_STATUS_ACTIVE_SELF_WARNING
            : BROKER_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING;
        }
      case LAW_FIRM_USER:
        if (isProfileActive) {
          return userType === LAW_FIRM_USER
            ? LAW_FIRM_PROFILE_STATUS_PAUSE_SELF_WARNING
            : LAW_FIRM_PROFILE_STATUS_PAUSE_SYSTEM_USER_WARNING;
        } else {
          return userType === LAW_FIRM_USER
            ? LAW_FIRM_PROFILE_STATUS_ACTIVE_SELF_WARNING
            : LAW_FIRM_PROFILE_STATUS_ACTIVE_SYSTEM_USER_WARNING;
        }

      default:
        return "";
    }
  };