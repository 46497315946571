import Axios from "axios";
import ResponseHandler from "../libs/response";
import { errorDisplay } from "../libs/utilities";

/**
 * @typedef {(dispatch) => Promise<import("../libs/response").ValidResponse} DispatchActionFunction
 */

/**
 * @param {import("../libs/utilities").DispatchAction} dispatch
 * @param {{ data: any }} response
 * @param {string?} action
 * @returns {import("../libs/response").ValidResponse}
 */
export function processRequest(dispatch, response, action) {
  if (
    response &&
    ResponseHandler.isValidStatus(response) &&
    !Axios.isCancel(response)
  ) {
    return dispatch(ResponseHandler.validObject(response?.data, action));
  }

  if (response instanceof Error) {
    return ResponseHandler.inValidObject(response?.response ?? { status: 500 });
  } else if (Axios.isCancel(response)) {
    return ResponseHandler.inValidObject({ status: 404 });
  } else {
    if (response?.data?.errors instanceof Array) {
      errorDisplay(response.data.errors);
    }
    return ResponseHandler.inValidObject(response ?? { status: 500 });
  }
}
