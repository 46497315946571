import { Select } from "antd";
import React, { useContext } from "react";
import { FinderFilterFieldChangeHandlerParams } from "../../../libs/types";
import FinderUtilityContext from "../FinderUtilityContext";

interface InterestProps {
  value: string;
  handleFieldChange: (
    params: FinderFilterFieldChangeHandlerParams<string>
  ) => void;
}

export const Interest = ({ value, handleFieldChange }: InterestProps) => {
  const { interestList }: any = useContext(FinderUtilityContext);

  return (
    <Select
      className="w-150 m-l-6"
      value={value}
      onChange={(value: string) =>
        handleFieldChange({ field: "interestKey", value })
      }
      allowClear={false}
    >
      {interestList.map((data: { id: string; name: string }) => {
        return (
          <Select.Option key={data.id} value={data.id}>
            {data.name}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default Interest;
