import React, {
  Fragment,
  Children,
  isValidElement,
  cloneElement
} from "react";
import { connect } from "react-redux";
import {
  getUserAuthenticationStatus,
  getLoggedInUserProfile
} from "../../actions/authorization";
import { Visible } from "../../components/Common/visiblityWrapper";

/**
 * @typedef {object} PropTypes
 * @property {import("./policies/types/policy").AuthorizationPolicy | import("./policies/types/policy").AuthorizationPolicy[]} policy
 * @property {"and" | "or" | undefined} policyBehavior
 * @property {{ permissions: string[], role: string }} user
 * @property {boolean} isLoggedIn
 * @property {any} notAuthorizedContent
 * @property {import("../../libs/utilities").DispatchAction} dispatch
 */

/**
 * @typedef {object} StateTypes
 * @property {boolean} policyPassed
 */

/** @extends {React.Component<PropTypes, StateTypes>} */
class AuthorizedViewImpl extends React.Component {
  /** @type {StateTypes} */
  state = {
    policyPassed: false
  };

  _subscription = {};

  async componentDidMount() {
    const { dispatch } = this.props;
    await Promise.all([
      dispatch(getUserAuthenticationStatus()),
      dispatch(getLoggedInUserProfile())
    ]);
    await this.authorize();
  }

  async authorize() {
    const { policy, user, isLoggedIn, policyBehavior } = this.props;
    if (isLoggedIn) {
      if (typeof policy === "function") {
        const policyPassed = await policy(user.roles, user.permissions, user); 
        this.setState({ policyPassed });

        return;
      }

      // Array of policies
      if (typeof policy === "object" && policy?.length > 0) {
        // Default behavior is "and"
        if (policyBehavior === "and" || !policyBehavior) {
          const policyPassed = (await Promise.all(
            policy.map(p => p(user.roles, user.permissions, user))
          )).every(x => x);
          this.setState({ policyPassed });
        } else if (policyBehavior === "or") {
          const policyPassed = (await Promise.all(
            policy.map(p => p(user.roles, user.permissions, user))
          )).some(x => x);
          this.setState({ policyPassed });
        }

        return;
      }

      this.setState({ policyPassed: true });

      return;
    }

    this.setState({ policyPassed: false });

    return;
  }

  async componentDidUpdate(prevProps) {
    const { user, policy } = prevProps;
    if (user !== this.props.user || policy !== this.props.policy) {
      await this.authorize();
    }
  }

  decoratedChildrenWithProps() {
    let { children, ...acyclicalProps } = this.props;

    // Remove AuthorizedViewProps
    delete acyclicalProps.policy;
    delete acyclicalProps.user;
    delete acyclicalProps.isLoggedIn;
    delete acyclicalProps.notAuthorizedContent;
    delete acyclicalProps.dispatch;
    delete acyclicalProps.policy;

    return Children.map(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child, { ...acyclicalProps, ...child.props });
      }

      return child;
    });
  }

  render() {
    const { policyPassed, notAuthorizedContent } = this.state;

    return (
      <Fragment>
        <Visible visible={this.props.isLoggedIn}>
          <Visible visible={policyPassed}>
            {this.decoratedChildrenWithProps()}
          </Visible>
          <Visible visible={notAuthorizedContent && !policyPassed}>
            {notAuthorizedContent}
          </Visible>
        </Visible>
      </Fragment>
    );
  }
}

const stateProps = state => {
  return {
    user: state.authorization.user,
    isLoggedIn: state.authorization.isLoggedIn
  };
};

export const AuthorizedView = connect(stateProps)(AuthorizedViewImpl);
