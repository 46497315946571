import { Card, Radio, Row, Col } from "antd";
import React, { Fragment } from "react";

const LoanExpiryFilter = ({ value, handleExpiryChange, list }) => {

  return (
    <Card className="loan-filter">
      <Radio.Group value={value} onChange={handleExpiryChange} size="small" className="flex-container w-percent-100">
        <Row className="flex flex-center flex-v-center m-b-10">
          <div className="w-percent-10">
            <Radio value="all" key="all">
              All
            </Radio>
          </div>
        </Row>
        <div className="flex flex-wrap m-b-10">
          <Row>
            <div className="w-percent-100">
              <span className="m-r-8"><b>Expires in</b></span>
              {list
                .filter((x) => {
                  return x.id.includes("ExpiresIn")
                })
                .map((option,i) => {
                return (
                  <Fragment>
                  <Radio value={option.id} key={option.id}>
                    {option.name}
                  </Radio>
                  {/* {
                    i === 3 && 
                      (<>
                        <Radio value="all" key="all" className="m-l-50">
                          All
                        </Radio>
                        <div></div>
                        <span className="m-r-22"><b>Expired</b></span>
                      </>)
                      
                  } */}
                  </Fragment>
                );
              })}
            </div>
          </Row>
        </div>
        <Row>
          <div>
            <span className="m-r-22"><b>Expired</b></span>
              {list
                .filter((x) => {
                  return x.id.includes("Expired")
                })
                .map((option,i) => {
                return (
                  <Fragment>
                  <Radio value={option.id} key={option.id}>
                    {option.name}
                  </Radio>
                  </Fragment>
                );
              })}
          </div>
        </Row>
        
        
        
      </Radio.Group>
    </Card>
  );
};

export default LoanExpiryFilter;
