import env from "./env";

export const AUTH_CONFIG = {
  authority: env.baseurl,
  client_id: "bransgroves.spa",
  redirect_uri: env.callback_url,
  post_logout_redirect_uri: env.redirect_url,
  response_type: "code",
  scope: "openid app.user app.user.id profile email address IdentityServerApi",
  automaticSilentRenew : true
}
