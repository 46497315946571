import React, {Component} from 'react'
import {Button, Col, Form, Icon, Input, Row} from 'antd';
import {Link} from 'react-router-dom';
import history from '../routes/history';
import {connect} from 'react-redux';
import {forgotPassword, getUserData, login, signUp} from '../actions/member';

const FormItem = Form.Item;

class NormalSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false,
      errorDisplay: 'none',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        console.log(err)
        return;
      }
      this.setState({loadingSubmit: true});
      this.props.onSignUpUser(values).then((resp, reject) => {
        if (resp && resp.type === 'SIGNUP') {
          history.push('/');
        } else {
          // handle reject
          console.log('login rejects', reject);
        }
      })
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <Form className="signUp-form"
            onSubmit={this.handleSubmit}
            hideRequiredMark={false}
      >

        <FormItem>
          <Button
            className="signUp-form-errorButton error-button"
            size="large"
            style={{display: this.props.error ? 'block' : 'none', width: "100%", textAlign: 'left'}}
          >
            <span style={{float: "left"}}>{this.props.error}</span>
          </Button>
        </FormItem>

        <Row gutter={16}>
          <Col span={12}>
            <FormItem
              colon={false}
              label={'First Name'}
            >
              {getFieldDecorator('firstName', {
                rules: [
                  {required: true, message: 'Please input your first name'},
                ],
              })(
                <Input
                  placeholder={'First Name'}
                  size="large"/>
              )}
            </FormItem>
          </Col>


          <Col span={12}>
            <FormItem
              colon={false}
              label={'Last Name'}
            >
              {getFieldDecorator('lastName', {
                rules: [
                  {required: true, message: 'Please input your Last name'},
                ],
              })(
                <Input
                  placeholder={'Last Name'}
                  size="large"/>
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem
          colon={false}
          label={'Email address'}
        >
          {getFieldDecorator('email', {
            rules: [
              {type: 'email', message: 'The input is not valid E-mail!'},
              {required: true, message: 'Please input your email'},
            ],
          })(
            <Input prefix={<Icon type="user" size="large"
                                 style={{color: 'rgba(0,0,0,.25)'}}/>}
                   placeholder={'yourname@example.com'}
                   size="large"/>
          )}
        </FormItem>

        <FormItem
          colon={false}
          label={'Password'}
        >
          {getFieldDecorator('password', {
            rules: [{required: true, message: 'Please input your Password'},
              {validator: this.validateToNextPassword},
            ],
          })(
            <Input prefix={<Icon type="lock"
                                 style={{color: 'rgba(0,0,0,.25)'}}/>}
                   type="password" placeholder={"********"}
                   size="large"/>
          )}
        </FormItem>

        <FormItem
          label="Confirm Password"
        >
          {getFieldDecorator('confirmPassword', {
            rules: [{
              required: true, message: 'Please confirm your password!',
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            <Input prefix={<Icon type="lock"
                                 style={{color: 'rgba(0,0,0,.25)'}}/>}
                   type="password" placeholder={"********"}
                   size="large"/>
          )}
        </FormItem>

        <FormItem className="signUp-form__lastItem">
          <Button type="primary" htmlType="submit"
                  className="signUp-form-button"
                  size="large"
                  theme="filled"
                  loading={this.state.loadingSubmit}
          >
            <span className="signUp-form-button__text"
                  style={{float: "left"}}>
              SIGN UP
            </span>
            <Icon type="arrow-right" className="signUp-form-button__icon"

                  size="large"/>
          </Button>
          <Link to='/login'><Icon type="left"/> Go back and login</Link>
        </FormItem>
      </Form>
    );
  }
}
const mapStateToProps = state => ({
  member: state.member || {},
});

const mapDispatchToProps = {
  getUserData,
  onLoginUser: login,
  onSignUpUser: signUp,
  onForgotPassword: forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(NormalSignUpForm));
