import { Select } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { mustBeArray } from "../../../libs";
import { loansPiorityLookup } from "../../../actions/lookup";
import _ from "lodash";

const PriorityLookup = ({ value, handleFieldChange, className, setPriority }) => {
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  useEffect(() => {
    dispatchAction(loansPiorityLookup({ cancelToken: signal.token })).then(
      resp => {
        setList(mustBeArray(resp));
      }
    );
  }, []);

  useEffect(() => {
    if(!_.isEmpty(list) && value){
      const data = list.find(o => o.id === value);
      setPriority(data?.name);
    }
  },[value, list])

  return (
    <div className={`flex m-l-16 flex-align-center m-r-20 ${className || ""}`}>
      <b>Priority</b>
      <Select
        className="w-140 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "priorityKey", value })}
        allowClear={false}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default PriorityLookup;
