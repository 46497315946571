import React from "react";
const Logo = ({ width }) => {
  return (
    <div className="flex flex-center">
      <img
        src={require("../assets/OmicronCommercialMortgage.jpg")}
        alt="Omircon"
        style={{
          width: width ?? "min(50vw, 200px)",
          cursor: "pointer",
          zIndex: 9999999
        }}
      />
    </div>
  );
};

export default Logo;
