import _ from "lodash";
import { updateRouteOptions } from "../../actions/member";
import { LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER, LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER, BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER, LAWFIRM_PROFILE_ROUTES_SYSTEM_USER, DEFAULT_ROUTES_SYSTEM_USER, EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, PROFILE_EDIT_OPTION } from "../../constants/routeOptions";
import { ADMIN_USER, LAW_FIRM_USER, DUAL_USER, LENDER_USER, BROKER_USER } from "../../constants/systemUsers";
import { evaluatePolicy } from "../../libs";
import { isBrokerUserPolicy, isBrokerOwnProfilePolicy } from "../Authorization/policies/isBrokerUserPolicy";
import { isLawFirmUserPolicy, isLawFirmOwnProfilePolicy } from "../Authorization/policies/isLawFirmUserPolicy";
import { isLenderUserPolicy, isLenderOwnProfilePolicy } from "../Authorization/policies/isLenderUserPolicy";
import { isSystemUserPolicy } from "../Authorization/policies/isSystemUserPolicy";
import { useDispatch, useSelector } from "react-redux";


const useUpdateUsersRouteOptions = async () => {
    const dispatchAction = useDispatch();
    const user: any = useSelector<any>(state => state?.authorization?.user);
    const individualUser:any = useSelector<any>(state => state.authorization && state.authorization.individualUser); 


    let isLender = await isLenderUserPolicy(user.roles, user.permissions, user);
    let isBroker = await isBrokerUserPolicy(user.roles, user.permissions, user);
    let systemUser = await isSystemUserPolicy(user.roles, user.permissions, user);
    let isLawFirm = await isLawFirmUserPolicy(user.roles, [], user);

    const isOwnProfile =
      (await evaluatePolicy(user, isBrokerOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLenderOwnProfilePolicy(user.id))) ||
      (await evaluatePolicy(user, isLawFirmOwnProfilePolicy(user.id)));

    const roleType = systemUser
      ? ADMIN_USER
      : isLawFirm
      ? LAW_FIRM_USER
      : user.roles.length == 2
      ? DUAL_USER
      : isLender
      ? LENDER_USER
      : isBroker
      ? BROKER_USER
      : "";
     
      let newRouteOptions;
      if(roleType === DUAL_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITH_BROKER_PROFILE_SYSTEM_USER;
      }
      else if(roleType === LENDER_USER){
        newRouteOptions = LENDER_PROFILE_ROUTES_WITHOUT_BROKER_PROFILE_SYSTEM_USER
      }
      else if(roleType === BROKER_USER){
        newRouteOptions = BROKER_PROFILE_ROUTES_WITHOUT_LENDER_PROFILE_SYSTEM_USER
      }
      else if(roleType === LAW_FIRM_USER){
        newRouteOptions = LAWFIRM_PROFILE_ROUTES_SYSTEM_USER;
      }
      else {
        newRouteOptions = DEFAULT_ROUTES_SYSTEM_USER;
      }

      newRouteOptions = [...newRouteOptions].map(o => {
        o.name = o.id == 9 ? individualUser.companyName : o.name;
        return o;
      });

      const menuOptions: any = isOwnProfile
        ? [...EMAIL_SUBSCRIPTIONS_OPTION_LENDER_USER, ...PROFILE_EDIT_OPTION]
        : [];
      
      console.log(menuOptions);

      dispatchAction(
        await updateRouteOptions({
          routeOptions: _.concat(newRouteOptions, menuOptions)
        })
      );
  }

  export default useUpdateUsersRouteOptions;