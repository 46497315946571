import React from "react";
import {
  MAX_PRINCIPAL_FINDER_LIST,
  MIN_PRINCIPAL_FINDER_LIST
} from "../../../constants";
import { Select } from "antd";

const PrincipalFilters = ({ value, handleFieldChange }) => {
  return (
    <div className="flex flex-align-center flex-wrap gap-12">
      <div>
        <div className="bold">From:</div>
        <Select
          className="w-180 m-l-6"
          value={value?.low}
          onChange={e =>
            handleFieldChange({ field: "lowerRate", value: e, sub: "low" })
          }
          allowClear={false}
          filterOption={false}
        >
          {MIN_PRINCIPAL_FINDER_LIST.map(data => {
            return (
              <Select.Option key={data.value} value={data.value}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div >
        <div className="bold">To:</div>
        <Select
          className="w-180 m-l-6"
          value={value?.high}
          onChange={e =>
            handleFieldChange({ field: "lowerRate", value: e, sub: "high" })
          }
          allowClear={false}
          filterOption={false}
        >
          {MAX_PRINCIPAL_FINDER_LIST.map(data => {
            return (
              <Select.Option key={data.value} value={data.value}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default PrincipalFilters;
