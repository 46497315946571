import { Col, Row, Typography, Icon, Popconfirm } from "antd";
import React from "react";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";

/**
 * Setting the button prop disabled to true hides the button from view
 * 
 * @typedef LoanFormHeaderButtonProp
 * @property {boolean} visible
 * @property {() => void} callback
 * @property {boolean=} disabled 
 *
 * @typedef LoanFormHeaderSnapshotProp
 * @property {string} editedBy
 * @property {string | null} editedDate
 *
 * @typedef LoanFormHeaderProps
 * @property {LoanFormHeaderButtonProp=} editButton
 * @property {LoanFormHeaderButtonProp=} deleteButton
 * @property {LoanFormHeaderButtonProp=} saveButton
 * @property {LoanFormHeaderButtonProp=} cancelButton
 * @property {LoanFormHeaderSnapshotProp} snapshotOverview
 * @property {boolean=} snapshotMode
 * 
 * @param {LoanFormHeaderProps} Props
 * @returns
 */
const LoanFormHeader = ({
  editButton = {
    visible: false,
    callback: () => {}
  },
  deleteButton = {
    visible: false,
    callback: () => {}
  },
  saveButton = {
    visible: false,
    callback: () => {}
  },
  cancelButton = {
    visible: false,
    callback: () => {}
  },
  snapshotMode = false,
  snapshotOverview = {
    editedBy: '',
    editedDate: ''
  },
}) => {
  return (
    <div className={`m-n-t-${snapshotMode ? '150' : '100'} m-l-220 w-percent-67`} >
      {
        snapshotMode && <>
          <Row type="flex" gutter={[32, 0]}>
          <Col span={24}>
            <Typography.Title level={4} className="darkred">
              {`This is a snapshot of what this record looked like after the edits made by ${snapshotOverview.editedBy} on ${snapshotOverview.editedDate}`}
            </Typography.Title>
          </Col>
        </Row>
        </>
      }
      
        <Row type="flex" gutter={[32, 0]}>
          <Col span={13}>
            <Typography.Title level={3} className="text-right">
              Loan Record
            </Typography.Title>
          </Col>
          <Col span={11}>
            <Row className="flex flex-center h-percent-100" gutter={[32, 0]}>
              {editButton.visible && !editButton.disabled && (
                <Col>
                  <EditIcon className="hand" width={20} height={20} onClick={editButton.callback} />
                </Col>
              )}
              {deleteButton.visible && !deleteButton.disabled && (
                <Col>
                  <Popconfirm
                    title="Are you sure you want to Delete this Loan Record, you will not be able to recover it. Do you wish to Proceed?"
                    onConfirm={deleteButton.callback}
                  >
                    <DeleteIcon width={20} height={20} className="hand" />
                  </Popconfirm>
                </Col>
              )}
              {saveButton.visible && !saveButton.disabled && (
                <Col>
                  <Icon
                    type="save"
                    className="hand f-s-20"
                    onClick={saveButton.callback}
                  />
                </Col>
              )}
              {cancelButton.visible && !cancelButton.disabled && (
                <Col>
                  <Icon
                    type="close"
                    className="hand f-s-20"
                    onClick={cancelButton.callback}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    
  );
};
export default LoanFormHeader;
