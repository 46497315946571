import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import { processRequest } from "./asyncActionProcessor";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { buildQueryParams, requestBuilder } from "../libs/utilities";
import { ADMIN_USER } from "../constants/systemUsers";

export const updateUser = ({ cancelToken, id, payLoad }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "USERS_DETAIL_URL",
        options: { id }
      });
      const response = await http.put(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "UPDATE_USER"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const getUsersList = ({ cancelToken, options = {}, type }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "USERS_URL" });
      const response = await requestBuilder(null, url, options, cancelToken);
      let actionType =
        type === "FORMER" ? "LIST_USERS_FORMER" : "LIST_USERS_CURRENT";
      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              actionType
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const createUser = ({ cancelToken, payLoad }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({ endpoint: "USERS_URL" });
      const response = await http.post(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "CREATE_USER"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const deleteUser = ({ cancelToken, id }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.USERS_DETAIL_URL,
        options: { id }
      });
      const response = await http.del(url, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "DELETE_USER"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

export const createIndividualProfile = ({ cancelToken, payLoad }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "INDIVIDUALS_LIST_URL"
      });
      const response = await http.post(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "CREATE_USER"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};

/**
 *
 * @param {{cancelToken: any, userType: string, payload: import("../libs/types").AllUsersSearchPayload}} param0
 * @returns {Promise<import("../libs/types").PagedCollection<import("../libs/types").AllUsersListItem[]>}
 */
export const getAllUsersList = ({
  cancelToken,
  userType = "",
  payload = {}
}) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint:
        userType === ADMIN_USER
          ? apiUrls.USERS_ALL_SYSTEM_USER_SEARCH_URL
          : apiUrls.USERS_ALL_USER_SEARCH_URL
    });
    const response = await http.get(
      apiUrl +"?"+ buildQueryParams(payload),
      cancelToken
    );
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 *
 * @param {{cancelToken: any}} param0
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>}
 */
export const financierTypeLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.USERS_ALL_FINANCIER_TYPE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 *
 * @param {{cancelToken: any}} param0
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>}
 */
export const settledLoansLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.USERS_ALL_SETTLED_LOANS_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 *
 * @param {{cancelToken: any}} param0
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>}
 */
export const firstFundsAvailableLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.USERS_ALL_FIRST_FUNDS_AVAILABLE_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 *
 * @param {{cancelToken: any}} param0
 * @returns {Promise<import("../libs/types").IBaseLookupItem[]>}
 */
export const profileStatusLookup = ({ cancelToken }) => {
  return async () => {
    const apiUrl = APIHandler.constructEndpoint({
      endpoint: apiUrls.USERS_ALL_PROFILE_STATUS_URL
    });
    const response = await http.get(apiUrl, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

export const getAllUsersBdmLookup = ({
  search=""
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_USERS_BDM_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      try {
        const response = await http.get(url);
        return(resolve(processRequest(dispatch, response, "GET_ALL_USERS_BDM_LOOKUP")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};
