import Axios from "axios";
import { GET_BROKER_MATCHES } from "../constants/action-type/brokers";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { ADMIN_USER } from "../constants/systemUsers";
import { requestBuilder } from "../libs/utilities";
import { processRequest } from "./asyncActionProcessor";

export const getBrokerMatches = ({
  cancelToken = Axios.CancelToken.source(),
  queryParams = {},
  id,
  userType
}) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint:
          userType === ADMIN_USER
            ? apiUrls.BROKER_ADMIN_MATCHES_URL
            : apiUrls.BROKER_MATCHES_URL,
        options: { id }
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, GET_BROKER_MATCHES));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};
