import { BGS_BROKER_ROLE } from "../SystemRoles";
import { isLawFirmUserPolicy } from "./isLawFirmUserPolicy";
import { isLenderUserPolicy } from "./isLenderUserPolicy";
import { isSystemUserPolicy } from "./isSystemUserPolicy";

/** @type {import("./types/policy").AuthorizationPolicy} */
export function isBrokerUserPolicy(roles, permissions, user) {
  let passed = (roles ?? []).some(x => x === BGS_BROKER_ROLE);
  return Promise.resolve(passed);
}

/** @type {import("./types/policy").AuthorizationPolicy} */
export async function isOnlyBrokerUserPolicy(roles, permissions, user) {
  const isBroker = await isBrokerUserPolicy(roles, permissions, user);
  const isLender = await isLenderUserPolicy(roles, permissions, user);
  const isSystemUser = await isSystemUserPolicy(roles, permissions, user); 

  return isBroker && !isLender && !isSystemUser;
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isBrokerOwnProfilePolicy(currentlyViewedBrokerId) {
  return async function(roles, permissions, user) {
    if (
      currentlyViewedBrokerId === null ||
      currentlyViewedBrokerId === undefined
    ) {
      return false;
    }

    const isBroker = await isBrokerUserPolicy(roles, permissions, user);

    return (
      isBroker &&
      parseInt(currentlyViewedBrokerId) === parseInt(user["app.user.brokerid"])
    );
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isNotBrokerOwnProfilePolicy(currentlyViewedBrokerId) {
  return async function(roles, permissions, user) {
    if (
      currentlyViewedBrokerId === null ||
      currentlyViewedBrokerId === undefined
    ) {
      return false;
    }

    const isOwnProfile = await isBrokerOwnProfilePolicy(
      currentlyViewedBrokerId
    )(roles, permissions, user);

    return !isOwnProfile;
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isUserInvolvedPolicy(isMatched) {
  return async function(roles, permissions, user) {
    return isMatched;
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isUserUninvolvedPolicy(currentlyViewedBrokerId, isMatched) {
  return async function(roles, permissions, user) {
    const isSystemUser = await isSystemUserPolicy(roles, permissions, user);
    const isLawFirmUser = await isLawFirmUserPolicy(roles, permissions, user);
    if(isSystemUser || isLawFirmUser) {
      return false;
    }
    const isOwnProfile = await isBrokerOwnProfilePolicy(
      currentlyViewedBrokerId
    )(roles, permissions, user);

    return !isMatched && !isOwnProfile;
  };
}