import React, { useEffect, useState } from "react";
import { Button, Col, Row, Icon } from "antd";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { updateHeader } from "../../actions/member";
import _ from "lodash";
import { tableColumnGenerator } from "../../libs/table";
import history from "../../routes/history";
import {
  asDefaultDisplayFormatDate,
  currencyFormater,
  mustBeArray
} from "../../libs/utilities";
import axios from "axios";
import { connect } from "react-redux";
import { ADMIN_USER } from "../../constants/systemUsers";
import DailyScores from "../Notes/DailyScores";

import {
  getSystemUserNotes,
  getNonSystemUserNotes,
  getMonthlyScores,
  setMonthlyScorePage,
  setNotesAction
} from "../../actions/notes";

const MonthlyScores = props => {
  const {
    updateHeader,
    getMonthlyScores,
    user,
    monthlyScorePage,
    setMonthlyScorePage,
    noteAction,
    setNotesAction
  } = props;

  const signal = axios.CancelToken.source();
  const [filesList, setFilesList] = useState([]);
  const [loading, setListLoading] = useState(true);
  const [sortInfo, setSortInfo] = useState({});
  const [userType, setUserType] = useState(null);
  const [pageSize, setPageSize] = useState(2);
  const [totalRecords, setTotalRecords] = useState(0);
  const [monthToDisplay, setMonthToDisplay] = useState([]);
  const [months, setMonths] = useState([]);
  const [dateTick, setDateTick] = useState();
  const [showNextPageRight, setShowNextPageRight] = useState(true);
  const [showUpButton, setshowUpButton] = useState(false);
  const [recordsLoaded, setRecordsLoaded] = useState(0);
  const [firstMonthInList, setLatestMonthInList] = useState(new Date());
  const [action, setAction] = useState("load");
  const [firstLoad, setFirstLoad] = useState(false);
  const [firstEntry, setFirstEntry] = useState();

  useEffect(() => {
    updateHeader({
      header: {
        title: " ",
        action: "",
        page: "monthly-scores",
        enableBack: false,
        showSettings: true,
        showMasterSwitch: false,
        showIsProfileActive: false,
        hasSwitchProfile: false,
        editMode: false,
        showNotesButton: false,
        showOmicronBDM: false,
        omicronBdm: null,
        showPageTitle: false
      }
    });
    const cleanUp = () => {
      signal.cancel("");
    };

    return cleanUp;
  }, []);

  useEffect(() => {
    handleFetch(monthlyScorePage, action);
  }, [monthlyScorePage, dateTick, noteAction]);

  useEffect(() => {
    setMonthlyScorePage(1);
    handleRefresh();
  }, [noteAction]);

  useEffect(() => {
    var currentDate = new Date();
    // HIDE THE UP BUTTON WHEN THE FIRST MONTH IN THE LIST IS >= CURRENT DATE
    if (
      firstMonthInList.getFullYear() >= currentDate.getFullYear() &&
      firstMonthInList.getMonth() >= currentDate.getMonth()
    ) {
      setshowUpButton(false);
    } else {
      setshowUpButton(true);
    }
  }, [firstMonthInList]);

  useEffect(() => {}, [showNextPageRight]);

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field,
        page: monthlyScorePage,
        pageSize: pageSize,
        lastDateFromQuery: dateTick
      };
    }
    queryParams = {
      page: monthlyScorePage,
      pageSize: pageSize,
      lastDateFromQuery: dateTick
    };
    return queryParams;
  };

  const handleFetch = async (monthlyScorePage, action) => {
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    setUserType(userType);
    let getActionName = getMonthlyScores;
    getActionName({
      userType: userType,
      cancelToken: signal.token,
      queryParams: getQueryParams()
    }).then(resp => {
      setListLoading(false);
      if (resp.status) {
        if (resp.data.length > 0) {
          let initialDataLoaded =
            monthlyScorePage == 1 ? resp.data.length : recordsLoaded;
          // Deduct  the records loaded before making any changes to the record count when moving left
          if (action === "left") {
            setRecordsLoaded(recordsLoaded - filesList.length);
          }
          setFilesList(mustBeArray(resp.data));
          var monthsToDisplay = [];
          var monthsData = [];

          if (action === "right") {
            setRecordsLoaded(initialDataLoaded + resp.data.length);
          }
          // This part is for when the component is initially loaded.
          if (action === "load") {
            setRecordsLoaded(resp.data.length);
          }

          setTotalRecords(resp.total);
          let totalPages = Math.ceil(resp.total/2)

          setShowNextPageRight(totalPages != resp.page);
          //get the months for the current viewed year from the first result
          //use the first retrieved user's data to retrieve the Months to loop through
          resp.data[0].monthlyScores.forEach(item => {
            monthsToDisplay.push(item.dateDisplay);
            let monthData = [];
            resp.data.forEach(months => {
              //retrieve the data (calls) per user and tag them with the current looped month
              let data = months.monthlyScores.filter(
                i => i.dateDisplay === item.dateDisplay
              )[0];
              monthData.push(data);
            });
            monthsData.push({
              dateDisplay: item.dateDisplay,
              callCount1: monthData[0] != undefined ? monthData[0].calls : "",
              callCount2: monthData[1] != undefined ? monthData[1].calls : ""
            });
          });
          setMonths(mustBeArray(monthsToDisplay));
          //Setting a flag to retrieve the very first row ONLY in FIRST LOAD to
          //to have a return point after editing/deleting an entry in notes list
          if (!firstLoad) {
            setFirstEntry(monthsToDisplay[0]);
            setFirstLoad(true);
          }

          setMonthToDisplay(mustBeArray(monthsData));
        }
        setNotesAction("");
      }
    });
  };

  const handleRefresh = () => {
    if (!_.isUndefined(firstEntry)) {
      let firstEntryDate = new Date(firstEntry);
      let setDate = new Date(
        firstEntryDate.setMonth(firstEntryDate.getMonth() + 2)
      );
      setLatestMonthInList(firstEntryDate);
      let dateTick = setDate * 10000 + 621355968000000000;
      setDateTick(dateTick);
    }
  };

  const handleClickRight = () => {
    setListLoading(true);
    let pageNumber = monthlyScorePage + 1;
    setAction("right");
    setMonthlyScorePage(pageNumber);
  };

  const handleClickLeft = () => {
    setListLoading(true);
    let pageNumber = monthlyScorePage - 1;
    setAction("left");
    setMonthlyScorePage(pageNumber);
  };

  const handleClickDown = () => {
    let convertedLatestDate = new Date(
      monthToDisplay[monthToDisplay.length - 2].dateDisplay
    );
    setAction("down");
    setLatestMonthInList(convertedLatestDate);
    let dateTick = convertedLatestDate * 10000 + 621355968000000000;
    setDateTick(dateTick);
  };

  const handleClickUp = () => {
    let convertedLatestDate = new Date(
      monthToDisplay[monthToDisplay.length - 1].dateDisplay
    );
    setLatestMonthInList(convertedLatestDate);
    setAction("up");
    // need to access the succeeding month of the 'latest' month (of the next year)
    // Also used 25 as the conversion of ticks needs further understanding
    let setDate = new Date(
      convertedLatestDate.setMonth(convertedLatestDate.getMonth() + 25)
    );
    setLatestMonthInList(setDate);
    let dateTick = setDate * 10000 + 621355968000000000;
    setDateTick(dateTick);
  };

  return (
    <Row>
      <Col span={4}></Col>
      <Col span={6}>
        <div className="m-b-12" style={{ marginTop: "-100px" }}>
          <div>
            {showUpButton && (
              <div
                style={{ position: "absolute", top: "-80px", left: "-35px" }}
              >
                <Button
                  type="text"
                  className="m-b-5 float-right"
                  onClick={() => handleClickUp()}
                  style={{ borderColor: "white" }}
                >
                  <Icon type="arrow-up" size="large" color="gray" />
                </Button>
              </div>
            )}
            <div
              style={{ position: "absolute", bottom: "12px", left: "-35px" }}
            >
              <Button
                type="text"
                className="m-b-5 float-right"
                onClick={() => handleClickDown()}
                style={{ borderColor: "white" }}
              >
                <Icon type="arrow-down" size="large" color="gray" />
              </Button>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>
                  {monthlyScorePage >= 2 && (
                    <Button
                      type="text"
                      className="m-b-5 float-right"
                      onClick={() => handleClickLeft()}
                      style={{ borderColor: "white" }}
                    >
                      <Icon type="arrow-left" size="large" color="gray" />
                    </Button>
                  )}
                </th>
                {/* PREVIOUS PAGE BUTTON */}
                {filesList.map((item, index) => (
                  <th
                    className="p-l-15 p-r-15"
                    key={item.userName}
                    style={{ width: "fit" }}
                  >
                    {item.userName}
                  </th>
                ))}
                {/* NEXT PAGE BUTTON */}
                {showNextPageRight && (
                  <th>
                    <Button
                      type="text"
                      className="m-b-5 float-right"
                      onClick={() => handleClickRight()}
                      style={{ borderColor: "white" }}
                    >
                      <Icon type="arrow-right" size="large" color="gray" />
                    </Button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {monthToDisplay.map((item, index) => (
                <tr key={item.dateDisplay}>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index}
                  >
                    <span style={{ fontWeight: index === 0 ? "bold" : "" }}>
                      {item.dateDisplay}
                    </span>
                  </td>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index + "_call1"}
                    style={{
                      visibility: item.callCount1 === "" ? "hidden" : "visible"
                    }}
                  >
                    <span
                      style={{
                        color: index === 0 ? "red" : "",
                        fontWeight: index === 0 ? "bold" : ""
                      }}
                    >
                      {item.callCount1} calls
                    </span>
                  </td>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index + "_call2"}
                    style={{
                      visibility: item.callCount2 === "" ? "hidden" : "visible"
                    }}
                  >
                    <span
                      style={{
                        color: index === 0 ? "red" : "",
                        fontWeight: index === 0 ? "bold" : ""
                      }}
                    >
                      {item.callCount2} calls
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
      <Col span={1}></Col>
      <DailyScores></DailyScores>
      <Col span={3}></Col>
    </Row>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    monthlyScorePage: state.notes.monthlyScorePage,
    noteAction: state.notes.noteAction
  };
};

const mapDispatchToProps = {
  updateHeader,
  getSystemUserNotes,
  getNonSystemUserNotes,
  getMonthlyScores,
  setMonthlyScorePage,
  setNotesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyScores);
