import { Select } from "antd";
import React, { useCallback, useContext, } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { mustBeArray } from "../../../libs";
import { debounce } from "lodash";
import { finderStatesAndSuburbsLookups } from "../../../actions/finderUtility";
import FinderUtilityContext from "../FinderUtilityContext";

const SuburbAndStateLookup = ({ value, handleFieldChange }) => {
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source(); 
  const {suburbList, setSuburbList, generalView} = useContext(FinderUtilityContext); 

  const updateList = currentList => {
    const listIncludesState = currentList.find(o => o.name === value);
    if (value && !listIncludesState) {
      const SuburbAndState = value.split(", ");
      setSuburbList([
        ...currentList,
        { name: value, suburb: SuburbAndState[0], state: SuburbAndState[1] }
      ]);
      return;
    }
    setSuburbList(currentList);
  };

  const handleSearch = e => {
    dispatchAction(
      finderStatesAndSuburbsLookups({ cancelToken: signal.token, search: e, generalView })
    ).then(resp => {
      updateList(mustBeArray(resp));
    });
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300));
  return (
      <Select
        className="w-260 m-l-6"
        value={value}
        onChange={value => handleFieldChange({ field: "suburbState", value })}
        allowClear={false}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
      >
        {suburbList.map(data => {
          return (
            <Select.Option key={data.name} value={data.name}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
  );
};

export default SuburbAndStateLookup;
