import env from "../constants/env";
import { templateEngine } from "../libs/utilities";

class ApiUrlHandler {
  constructor() {
    if (!ApiUrlHandler.instance) {
      this._urls = {
        COMPANY_URL: "/<%userArea%>/companies",
        BUSINESS_UNIT_URL: "/<%userArea%>/businessunits",
        COMPANY_LOOKUP_URL: "/<%userArea%>/Lookups/companies",
      };
      ApiUrlHandler.instance = this;
    }

    return ApiUrlHandler.instance;
  }

  constructEndpoint({ endpoint, options = {} }) {
    options.userArea = localStorage.getItem("userArea");
    const url = env.baseurl + templateEngine(this._urls[endpoint], options);
    return url;
  }
}

const instance = new ApiUrlHandler();
Object.freeze(instance);

export default instance;
