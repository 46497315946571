import { Button,  Icon, Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import { deleteIndividualPortrait } from '../../actions/profile';
import { errorDisplay } from '../../libs';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const PortraitRemove = ({name, companyName, soleUser, handleRemove, id}) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const signal = axios.CancelToken.source(); 
    const handleClose = () => setVisible(false);
    const dispatchAction = useDispatch();
    const handleDelete = () => {
        setLoading(true);
        dispatchAction(deleteIndividualPortrait({id, cancelToken: signal.token})).then(resp => {
            setLoading(false);
            if(resp?.status){
                handleRemove();
                setVisible(false);
                return;
            }
            errorDisplay(resp?.data?.errors);
        })
    };

    return(
        <Fragment>
        <div className="flex align-center f-s-16 hand" onClick={() => setVisible(true)}>
                <Icon type="delete" className="m-r-4 f-s-24" /> Remove
        </div>

        <Modal title="Heads up!" visible={visible} onCancel={handleClose}
            footer={soleUser ? null : [
                <Button key="back" onClick={handleClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleDelete} loading={loading}>
                    Delete
                </Button>,
            ]}
        >
            {
                soleUser ? 
                    <span>{name} is the sole User associated with {companyName}. You cannot delete him until you add someone else.</span>
                :
                <Fragment>
                    <p>You are about to delete {name}</p>
                    <p>Are you sure you wish to proceed?</p>
                </Fragment>
            }
        </Modal>
        </Fragment>
    )
};
export default PortraitRemove;