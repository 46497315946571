import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { loansCreditLookups } from "../../../actions/lookup";
import Axios from "axios";
import { mustBeArray } from "../../../libs";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

interface LoanCreditProps {
  loanDetails: any;
  handleFieldChange?: (payload: { field: string; value: any }) => void;
  generalView?: boolean;
  className?: string;
  disabled?: boolean
}

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

export const LoanCredit = ({
  loanDetails,
  handleFieldChange = payload => {},
  generalView = false,
  className = '',
  disabled = false
}: LoanCreditProps) => {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState<Option[]>([]);
  const [credits, setCredits] = useState<number[]>([]);

  function loadDetails() {
    setCredits(loanDetails.credits || []);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleLocalChange(value: any[]){
    setCredits(value);
  }

  function handleOk() {
    closeModal();
    if(!generalView){
      handleFieldChange({ field: "credits", value: credits });
    }
  }

  function handleCancel() {
    closeModal();
    loadDetails();
  }

  useEffect(() => {
    dispatch(
      loansCreditLookups({ cancelToken: signal.token, generalView })
    ).then(x => {
      const translated = x.map(v => ({
        value: v.id,
        label: v.name
      }));
      setList(mustBeArray(translated));
    });
  }, []);

  useEffect(() => {
    loadDetails();
  }, [loanDetails]);

  return (
    <>
      <span className={className}>
        <Button
          className=" w-81"
          onClick={openModal}
          style={{ float: "right" }}
        >
          Credits
        </Button>
      </span>
      <Modal
        visible={isModalOpen}
        width={500}
        onCancel={handleCancel}
        footer={
          <div className="flex space-between">
            <span>
              <Button type="primary" onClick={handleOk} className="m-r-10">
                OK
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </span>
          </div>
        }
      >
        <div className="m-b-8 m-l-8">Credit</div>
        <Checkbox.Group
          value={credits}
          onChange={value => handleLocalChange(value)}
          disabled={generalView || disabled}
        >
          {list.map(x => {
            return (
              <div key={x.value}>
                <Checkbox value={x.value}>{x.label}</Checkbox>
              </div>
            );
          })}
        </Checkbox.Group>
      </Modal>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanCredit);
