import produce from "immer";
import {
  GET_LOGGED_IN_USER_PROFILE,
  IS_USER_LOGGED_IN,
  LAST_VISITED_PROFILE,
  USER_LOGGED_OUT,
  INDIVIDUAL_USER_DETAIL
} from "../constants/action-type/authorization";

const defaultState = {
  user: {
    permissions: [],
    roles: []
    
  },
  lastVisitedProfile: {},
  individualUser: {companyName:''}
};

export default produce((draft, action) => {
  const { user, isLoggedIn, lastVisitedProfile, individualUser } = action;
  switch (action.type) {
    case GET_LOGGED_IN_USER_PROFILE: {
      draft.user = user;
      draft.user.fullName = `${user.given_name} ${user.family_name}`;
      break;
    }
    case IS_USER_LOGGED_IN: {
      draft.isLoggedIn = isLoggedIn;
      break;
    }
    case USER_LOGGED_OUT: {
      draft.user = {
        ...defaultState.user
      };
      draft.lastVisitedProfile = {};
      break;
    }
    case LAST_VISITED_PROFILE: {
      if (lastVisitedProfile.status === 404) {
        draft.lastVisitedProfile = {
          status: lastVisitedProfile.status
        };
      } else {
        draft.lastVisitedProfile = {
          ...lastVisitedProfile.data,
          status: lastVisitedProfile.status
        };
      }

      break;
    }
    case INDIVIDUAL_USER_DETAIL: {
      if(individualUser.status === 200)
      {
        draft.individualUser.companyName = individualUser.data.companyName;
      }
        
      break;
    }
  }
}, defaultState);
