import React, { useEffect, useState } from "react";
import { Button, Col, Icon } from "antd";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { updateHeader } from "../../actions/member";
import _ from "lodash";
import { mustBeArray } from "../../libs/utilities";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { ADMIN_USER } from "../../constants/systemUsers";

import {
  getSystemUserNotes,
  getNonSystemUserNotes,
  getDailyScores,
  setDailyScorePage,
  setNotesAction
} from "../../actions/notes";

const DailyScores = props => {
  const {
    getDailyScores,
    user,
    dailyScorePage,
    setDailyScorePage,
    noteAction,
    setNotesAction
  } = props;

  const signal = axios.CancelToken.source();
  const [filesList, setFilesList] = useState([]);
  const [loading, setListLoading] = useState(true);
  const [sortInfo, setSortInfo] = useState({});
  const [userType, setUserType] = useState(null);
  const [pageSize, setPageSize] = useState(2);
  const [totalRecords, setTotalRecords] = useState(0);
  const [monthToDisplay, setMonthToDisplay] = useState([]);
  const [months, setMonths] = useState([]);
  const [dateTick, setDateTick] = useState();
  const [showNextPageRight, setShowNextPageRight] = useState(true);
  const [recordsLoaded, setRecordsLoaded] = useState(0);
  const [showUpButton, setshowUpButton] = useState(false);
  const [firstDateInList, setLatestDateInList] = useState(new Date());
  const [action, setAction] = useState("load");
  const [firstLoad, setFirstLoad] = useState(false);
  const [firstEntry, setFirstEntry] = useState();
  const [listLeadingDates, setListLeadingDates] = useState([]);

  const getQueryParams = () => {
    let queryParams = {};
    if (sortInfo && sortInfo.field && sortInfo.order) {
      queryParams = {
        sort: sortInfo.order,
        orderBy: sortInfo.field,
        page: dailyScorePage,
        pageSize: pageSize,
        lastDateFromQuery: dateTick
      };
    }
    queryParams = {
      page: dailyScorePage,
      pageSize: pageSize,
      lastDateFromQuery: dateTick
    };
    return queryParams;
  };

  useEffect(() => {
    handleFetch(dailyScorePage, action);
  }, [dailyScorePage, dateTick, noteAction]);

  useEffect(() => {
    setDailyScorePage(1);
    handleRefresh();
  }, [noteAction]);

  useEffect(() => {
    var currentDate = new Date();
    var firstDateInListNoTime = new Date(firstDateInList.setHours(0, 0, 0, 0));
    var currentDateNoTime = new Date(currentDate.setHours(0, 0, 0, 0));
    // HIDE THE UP BUTTON WHEN THE FIRST MONTH IN THE LIST IS >= CURRENT DATE
    if (firstDateInListNoTime >= currentDateNoTime) {
      setshowUpButton(false);
    } else if (firstDateInListNoTime <= currentDateNoTime) {
      setshowUpButton(true);
    }
  }, [firstDateInList]);

  useEffect(() => {
    if (recordsLoaded == totalRecords) {
      setShowNextPageRight(false);
    } else {
      setShowNextPageRight(true);
    }
  }, [recordsLoaded]);

  const handleFetch = async (dailyScorePage, action) => {
    setListLoading(true);
    const userType = (await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    ))
      ? ADMIN_USER
      : "";
    setUserType(userType);
    let getActionName = getDailyScores;
    await getActionName({
      userType: userType,
      cancelToken: signal.token,
      queryParams: getQueryParams()
    }).then(resp => {
      if (resp.status) {
        if (resp.data.length > 0) {
          let initialDataLoaded = dailyScorePage == 1 ? 0 : recordsLoaded;
          // Deduct  the records loaded before making any changes to the record count when moving left
          if (action === "left") {
            setRecordsLoaded(recordsLoaded - filesList.length);
          }

          setFilesList(mustBeArray(resp.data));
          var monthsToDisplay = [];
          var monthsData = [];

          if (action === "right") {
            setRecordsLoaded(initialDataLoaded + resp.data.length);
          }
          // This part is for when the component is initially loaded.
          if (action === "load") {
            setRecordsLoaded(resp.data.length);
          }

          setTotalRecords(resp.total);
          let totalPages = Math.ceil(resp.total/2)

          setShowNextPageRight(totalPages != resp.page);
          //get the months for the current viewed year from the first result
          //use the first retrieved user's data to retrieve the days to loop through
          resp.data[0].monthlyScores.forEach(item => {
            monthsToDisplay.push(item.sortDate);
            let monthData = [];
            resp.data.forEach(months => {
              //retrieve the data (calls) per user and tag them with the current looped days
              let data = months.monthlyScores.filter(
                i => i.sortDate === item.sortDate
              )[0];
              monthData.push(data);
            });
            monthsData.push({
              dateDisplay: item.sortDate,
              callCount1: monthData[0] != undefined ? monthData[0].calls : "",
              callCount2: monthData[1] != undefined ? monthData[1].calls : ""
            });
          });
          setMonths(mustBeArray(monthsToDisplay));
          //Setting a flag to retrieve the very first row ONLY in FIRST LOAD to
          //to have a return point after editing/deleting an entry in notes list
          if (!firstLoad) {
            setFirstEntry(monthsToDisplay[0]);
            setFirstLoad(true);
          }
          setMonthToDisplay(mustBeArray(monthsData));
          setListLoading(false);
        }
        setNotesAction("");
      }
    });
  };

  const handleRefresh = () => {
    if (!_.isUndefined(firstEntry)) {
      let firstEntryDate = new Date(firstEntry);
      let setDate = new Date(
        firstEntryDate.setDate(firstEntryDate.getDate() + 1)
      );
      setLatestDateInList(firstEntryDate);
      //empty the list of leading dates as we go back to the initial dates
      setListLeadingDates([]);
      let dateTick = setDate * 10000 + 621355968000000000;
      setDateTick(dateTick);
    }
  };

  const handleClickDown = () => {
    let convertedLatestDate = new Date(
      monthToDisplay[monthToDisplay.length - 1].dateDisplay
    );

    //Store dates in a date array to keep the leading dates per page (as this pose a problem when going up the vertical list)
    //as the user goes down the list, the latest date on the array will be used as a return point
    let tempListLeadingDates = listLeadingDates;
    tempListLeadingDates.push(monthToDisplay[0].dateDisplay);
    setListLeadingDates(tempListLeadingDates);

    setAction("down");
    setLatestDateInList(convertedLatestDate);
    setListLoading(true);
    let setConvertedLastDate = new Date(
      convertedLatestDate.setDate(convertedLatestDate.getDate() + 1)
    );
    let dateTick =
      setConvertedLastDate.setHours(0, 0, 0, 0) * 10000 + 621355968000000000;
    setDateTick(dateTick);
  };

  const handleClickUp = () => {
    let tempListLeadingDates = listLeadingDates;
    let convertedDate = new Date(
      tempListLeadingDates[tempListLeadingDates.length - 1]
    );
    let convertedFirstDate = new Date(
      convertedDate.setDate(convertedDate.getDate() + 2)
    );
    setAction("up");
    setLatestDateInList(convertedDate);
    let dateTick =
      convertedFirstDate.setHours(0, 0, 0, 0) * 10000 + 621355968000000000;
    setDateTick(dateTick);
    //Remove the most recent date (returning point) as it has already been used.
    tempListLeadingDates.pop(tempListLeadingDates.length - 1);
    setListLeadingDates(tempListLeadingDates);
  };

  const handleClickRight = () => {
    setListLoading(true);
    let pageNumber = dailyScorePage + 1;
    setAction("right");
    setDailyScorePage(pageNumber);
  };

  const handleClickLeft = () => {
    setListLoading(true);
    let pageNumber = dailyScorePage - 1;
    setAction("left");
    setDailyScorePage(pageNumber);
  };

  return (
    <div>
      <Col span={7}>
        <div className="m-b-12" style={{ marginTop: "-100px" }}>
          {showUpButton && (
            <div
              style={{
                position: "absolute",
                top: userType === ADMIN_USER ? "-80px" : "-100px",
                left: "-35px"
              }}
            >
              <Button
                type="text"
                className="m-b-5 float-right"
                onClick={() => handleClickUp()}
                style={{ borderColor: "white" }}
              >
                <Icon type="arrow-up" size="large" color="gray" />
              </Button>
            </div>
          )}
          <div style={{ position: "absolute", bottom: "12px", left: "-40px" }}>
            <Button
              type="text"
              className="m-b-5 float-right"
              onClick={() => handleClickDown()}
              style={{ borderColor: "white" }}
            >
              <Icon type="arrow-down" size="large" color="gray" />
            </Button>
          </div>

          <table>
            <thead>
              <tr>
                <th>
                  {dailyScorePage >= 2 && (
                    <Button
                      type="text"
                      className="m-b-5 float-right"
                      onClick={() => handleClickLeft()}
                      style={{ borderColor: "white" }}
                    >
                      <Icon type="arrow-left" size="large" color="gray" />
                    </Button>
                  )}
                </th>
                {filesList.map((item, index) => (
                  <th className="p-l-15 p-r-15" key={item.userName}>
                    {item.userName}
                  </th>
                ))}
                {showNextPageRight && (
                  <th>
                    <Button
                      type="text"
                      className="m-b-5 "
                      onClick={() => handleClickRight()}
                      style={{ borderColor: "white" }}
                    >
                      <Icon type="arrow-right" size="large" color="gray" />
                    </Button>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {monthToDisplay.map((item, index) => (
                <tr key={item.dateDisplay}>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index}
                  >
                    <span style={{ fontWeight: index === 0 ? "bold" : "" }}>
                      {moment(item.dateDisplay).format("dddd  D MMMM YYYY")}
                    </span>
                  </td>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index + "_call1"}
                    style={{
                      visibility: item.callCount1 === "" ? "hidden" : "visible",
                      fontWeight: index === 0 ? "bold" : ""
                    }}
                  >
                    <span style={{ color: index === 0 ? "red" : "" }}>
                      {item.callCount1} calls
                    </span>
                  </td>
                  <td
                    className="p-l-15 p-r-15"
                    key={item.dateDisplay + "_" + index + "_call2"}
                    style={{
                      visibility: item.callCount2 === "" ? "hidden" : "visible",
                      fontWeight: index === 0 ? "bold" : ""
                    }}
                  >
                    <span style={{ color: index === 0 ? "red" : "" }}>
                      {item.callCount2} calls
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
      <Col span={1}>
        {userType == ADMIN_USER && (
          <div
            className="float-top float-right p-r-75"
            style={{ marginTop: "-75px" }}
          ></div>
        )}
      </Col>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    dailyScorePage: state.notes.dailyScorePage,
    noteAction: state.notes.noteAction
  };
};

const mapDispatchToProps = {
  updateHeader,
  getSystemUserNotes,
  getNonSystemUserNotes,
  getDailyScores,
  setDailyScorePage,
  setNotesAction
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyScores);
