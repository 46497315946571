import { BGS_LENDER_ROLE } from "../SystemRoles";
import { isBrokerUserPolicy } from "./isBrokerUserPolicy";
import { isLawFirmUserPolicy } from "./isLawFirmUserPolicy";
import { isSystemUserPolicy } from "./isSystemUserPolicy";

/** @type {import("./types/policy").AuthorizationPolicy} */
export function isLenderUserPolicy(roles, permissions, user) {
  let passed = (roles ?? []).some(x => x === BGS_LENDER_ROLE);

  return Promise.resolve(passed);
}

/** @type {import("./types/policy").AuthorizationPolicy} */
export async function isOnlyLenderUserPolicy(roles, permissions, user) {
  const isBroker = await isBrokerUserPolicy(roles, permissions, user);
  const isLender = await isLenderUserPolicy(roles, permissions, user);
  const isSystemUser = await isSystemUserPolicy(roles, permissions, user);

  return isLender && !isBroker && !isSystemUser;
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isLenderOwnProfilePolicy(currentlyViewedLenderId) {
  return async function(roles, permissions, user) { 
    if (
      currentlyViewedLenderId === null ||
      currentlyViewedLenderId === undefined
    ) {
      return false;
    }

    const isLender = await isLenderUserPolicy(roles, permissions, user);

    return (
      isLender &&
      parseInt(currentlyViewedLenderId) === parseInt(user["app.user.lenderid"])
    );
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isNotLenderOwnProfilePolicy(currentlyViewedLenderId) {
  return async function(roles, permissions, user) {
    if (
      currentlyViewedLenderId === null ||
      currentlyViewedLenderId === undefined
    ) {
      return false;
    }

    const isOwnProfile = await isLenderOwnProfilePolicy(
      currentlyViewedLenderId
    )(roles, permissions, user);

    return !isOwnProfile;
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isUserInvolvedPolicy(isMatched) {
  return async function(roles, permissions, user) {
    return isMatched;
  };
}

/** @returns {import("./types/policy").AuthorizationPolicy} */
export function isUserUninvolvedPolicy(currentlyViewedLenderId, isMatched) {
  return async function(roles, permissions, user) {
    const isSystemUser = await isSystemUserPolicy(roles, permissions, user);
    const isLawFirmUser = await isLawFirmUserPolicy(roles, permissions, user);
    if(isSystemUser || isLawFirmUser) {
      return false;
    }
    const isOwnProfile = await isLenderOwnProfilePolicy(
      currentlyViewedLenderId
    )(roles, permissions, user);
    return !isMatched && !isOwnProfile;
  };
}

