import authService from "./AuthorizeService";
import {
  GET_LOGGED_IN_USER_PROFILE,
  IS_USER_LOGGED_IN,
  CHANGE_LOGGEDIN_USER_PASSWORD,
  USER_LOGGED_OUT,
  LAST_VISITED_PROFILE,
  UPDATE_LAST_VISITED_PROFILE,
  INDIVIDUAL_USER_DETAIL
} from "../constants/action-type/authorization";
import { http } from "../libs/http";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { processRequest } from "./asyncActionProcessor";

function processPermission(user) {
  if (typeof user?.["app.user.permissions"] === "string") {
    return [user["app.user.permissions"]];
  } else if (user?.["app.user.permissions"]?.length) {
    return user["app.user.permissions"];
  } else {
    return [];
  }
}

function processRoles(user) {
  if (typeof user?.["role"] === "string") {
    return [user?.["role"]];
  } else if (user?.["role"].length) {
    return user["role"];
  } else {
    return [];
  }
}

export function getLoggedInUserProfile() {
  return async dispatch => {
    const user = await authService.getUser();
    dispatch({
      type: GET_LOGGED_IN_USER_PROFILE,
      user:
        {
          ...user,
          permissions: processPermission(user),
          roles: processRoles(user)
        } ?? {}
    });
  };
}

export function getLoggedInIndividualUserDetails() {
  return async dispatch => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.INDIVIDUAL_USER_DETAIL
    });

    try {
      const response = await http.get(url);
      return dispatch({
        type: INDIVIDUAL_USER_DETAIL,
        individualUser: response
      });
    } catch (err) {
      return processRequest(dispatch, err);
    }
  };
}

let currentProfileSyncTimer = null;

const debouncedUpdateLastVisited = (dispatch, profileId, profileType) => {
  const url = APIHandler.constructEndpoint({
    endpoint: apiUrls.ACCOUNTS_ME_LAST_VISITED
  });
  if (currentProfileSyncTimer) {
    clearTimeout(currentProfileSyncTimer);
  }

  currentProfileSyncTimer = setTimeout(() => {
    http.put(url, null, {
      profileId,
      profileType
    });
  }, 10000 /** 10 sec */);
  return dispatch({
    type: UPDATE_LAST_VISITED_PROFILE
  });
};

export function updateActiveProfile(profileId, profileType) {
  return dispatch =>
    debouncedUpdateLastVisited(dispatch, profileId, profileType);
}

export function getLastVisitedProfile() {
  return async dispatch => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.ACCOUNTS_ME_LAST_VISITED
    });

    try {
      const response = await http.get(url);
      return dispatch({
        type: LAST_VISITED_PROFILE,
        lastVisitedProfile: response
      });
    } catch (err) {
      return processRequest(dispatch, err);
    }
  };
}

export function getUserAuthenticationStatus() {
  return async dispatch => {
    const isLoggedIn = await authService.isAuthenticated();

    dispatch({
      type: IS_USER_LOGGED_IN,
      isLoggedIn
    });
  };
}

export function changeLoggedInUserPassword(changePasswordRequest) {
  return async dispatch => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.ACCOUNTS_PASSWORD_CHANGE
    });
    try {
      const response = await http.put(url, changePasswordRequest);
      return processRequest(dispatch, response, CHANGE_LOGGEDIN_USER_PASSWORD);
    } catch (err) {
      return processRequest(dispatch, err);
    }
  };
}

export function logoutUser() {
  return async dispatch => {
    dispatch({
      type: USER_LOGGED_OUT,
      user: {}
    });
  };
}
