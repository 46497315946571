const initialState = {};

export default function uiReducer(state = initialState, action) {
  const {type} = action;
  switch (type) {
    case 'SET_PROGRESS_BAR':
      return {
        ...state,
        progressBarStatus: true
      }
    case 'UNSET_PROGRESS_BAR':
      return {
        ...state,
        progressBarStatus: false
      }
    default:
      return state;
  }
}
