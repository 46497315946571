import React, { useContext } from "react";
import { LENDER_USER } from "../../../constants/systemUsers";
import FinderUtilityContext from "../FinderUtilityContext";
import RateView from "../../../Common/RateView";
import { Button } from "antd";
import { UserProfileImage } from "../../Common";
import MobileKeyMetrics from "./mobileKeyMetrics";

const FinderUserViewMobile = ({ user }) => {
  const { userType, handleViewDetails, generalView } = useContext(FinderUtilityContext);
  const ratingsValue =
    userType === LENDER_USER
      ? user?.avgBrokerStarRating
      : user?.avgLenderStarRating;
  const logo = user?.logoFile?.fileUrl;
  const idText = userType === LENDER_USER ? "lenderId" : "brokerId"; 
  const userFullName = userType === LENDER_USER ? "lenderName" : 'brokerName';

  return (
    <div className="finder-utility-user-card m-b-12 flex column align-center gap-12 overflow-x-hidden">
      {logo && <img src={logo} height={83} />}
      <div className="text-center color-dark f-s-40 p-r-20 total-loans">
        <span className="color-danger">{user?.matchedLoansCount || 0}</span>{" "}
        Matching Loans
      </div>
      {/* Profile Section */}
      <UserProfileImage url={user?.profileImageFile?.fileUrl} height={165} width={165}/>
      <div className='f-s-16 flex column gap-6 width-full'>
          <div className="text-overflow">{user?.[userFullName]}</div>
          <div>{user?.mobileNumber}</div>
          <a target="_top" href={`mailto:${user?.email}`} className="text-overflow">{user?.email}</a>
      </div>
        <MobileKeyMetrics user={user} />
        <RateView
          value={ratingsValue}
          loansCount={user?.reviewedLoansCount}
        />

      {/* View Details */}
      {!generalView && (
          <Button
            type="primary"
            onClick={() => handleViewDetails(user[idText])}
          >
            Details
          </Button>
        )}
    </div>
  );
};

export default FinderUserViewMobile;
