import { Rate } from 'antd';
import React from 'react';
import { Visible } from '../../Common/visiblityWrapper';

const Ratings = ({ value, handleFieldChange }) => { 

  return (
    <div>
    <Rate
        allowClear
        value={value}
        className="m-l-6"
        onChange={e => handleFieldChange({ value: e  })}
    />
    <Visible visible={value == null || value<5}>
      <span className='m-l-10'>(or more)</span>
    </Visible>
    </div>
  );
};

export default Ratings;
