import { Select } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { debounce } from "lodash";
import { omicronBDMLookups } from "../../actions/lookup";
import React, { useState } from "react";
import { mustBeArray } from "../../libs";
import { updateHeader } from "../../actions/member";

const OmicronBDMLookup = () => {
  const dispatchAction = useDispatch();
  const header = useSelector(state => state.member.header);
  const signal = axios.CancelToken.source();
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatchAction(
      omicronBDMLookups({ cancelToken: signal.token, search: "" })
    ).then(resp => {
      updateList(mustBeArray(resp));
    });
  }, []);

  const updateList = currentList => {
    const listIncludesBDM = currentList.find(
      o => o.name === header?.omicronBdm?.name
    );
    //Update the object here to include header?.currentOmicronBDM?.id and display name
    if (header?.omicronBdm?.id && !listIncludesBDM) {
      setList([...currentList, { id: header?.omicronBdm?.id, name:  header?.omicronBdm?.name}]);
      return;
    }
    setList(currentList);
  };

  const handleSearch = e => {
    dispatchAction(
      omicronBDMLookups({ cancelToken: signal.token, search: e })
    ).then(resp => {
      updateList(mustBeArray(resp));
    });
  };

  const handleChange = value => {
    const selectedBdm = list.find(
      o => o.id === value
    );
    dispatchAction(
      updateHeader({
        header: {
          ...header,
          omicronBdm: { id: value, name: selectedBdm.name}
        }
      })
    );
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 300));
  return (
    <div className="flex align-center m-l-32">
      <h1 className="m-b-0">Omicron BDM</h1>
      <Select
        className="w-220 m-l-10"
        value={header.omicronBdm?.id}
        onChange={handleChange}
        allowClear={false}
        onSearch={debouncedSearch}
        showSearch
        filterOption={false}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
export default OmicronBDMLookup;
