import { Icon, InputNumber, Select } from "antd";
import React, { useContext } from "react";
import { mustBeArray } from "../../../libs";
import LoanCreateContext from "./LoanCreateContext";

const AttributedLendersView = ({ loanLenders }) => {
  const { lendersList, displayLendersModal } = useContext(LoanCreateContext);
  return (
    <div>
      {mustBeArray(loanLenders).map((data, i) => {
        return (
          <div className="m-b-8 flex" key={i}>
            <Select
              value={data.lenderId}
              className="w-220 m-l-8 m-r-10"
              allowClear={false}
              disabled
            >
              {lendersList.map(lender => {
                return (
                  <Select.Option key={lender.id} value={lender.id}>
                    {lender.name}
                  </Select.Option>
                );
              })}
            </Select>
            <InputNumber
              placeholder="Amount"
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={value => value.replace(/\$\s?|(,*)/g, "")}
              className=" m-l-6 hideNumberPointers w-150"
              disabled
              value={data.contributionAmount}
            />
            {i === 0 && (
              <>
                <span className="m-l-4" style={{ color: "red" }}>
                  *
                </span>
                <Icon
                  type="right"
                  className="color-success f-s-20 f-w-800 p-l-10 p-r-10 hand"
                  onClick={displayLendersModal}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default AttributedLendersView;
