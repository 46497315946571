import { Select } from "antd";
import React, { useContext }  from "react";
import LoanCreateContext from "./LoanCreateContext";

const LenderBdmView = ({ value, handleFieldChange, className, disabled }) => {
  const {partnerAssociateList} = useContext(LoanCreateContext)
  return (
    <div className={`flex align-start m-l-5  m-r-10 ${className || ""}`}>
      <b className="m-n-t-10">Lender<br></br>BDM</b>
      <Select
        className="w-150 m-l-6"
        value={value}
        disabled={true}
        onChange={value =>
          handleFieldChange({ field: "LenderBdmId", value })
        } 
        allowClear={false}
      >
        {partnerAssociateList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export default LenderBdmView;
