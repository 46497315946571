import {memberAction} from "../constants/action-type/index";
import { DEFAULT_ROUTES_SYSTEM_USER } from "../constants/routeOptions";
import { mustBeArray } from "../libs/utilities";

const initialState = {
  forgotMsg: "",
  error: "",
  timestamp: "",
  loading: false,
  authenticated: false,
  claims: [],
  header: {
    title: "",
    action: "",
    enableBack: false,
    editMode: false,
    showOmicronBDM: false,
    omicronBdm: undefined,
    currentPortraitIndividualId: undefined,
    showSearchBox: true,
    showDeactivatedMessage: false,
  },
  routesOptions: DEFAULT_ROUTES_SYSTEM_USER
};

const {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  LOGOUT,
  LIST_USERS,
  USER_DETAIL,
  AUTH_USER,
  UNAUTH_USER,
  AUTH_IN_PROGRESS,
  AUTH_ERROR,
  FORGOT_SUCCESS,
  CLEARDOWN,
  USER_PROFILE,
  USER_AUTHORIZATION
} = memberAction;

export default function userReducer(state = initialState, action){
  const {data} = action;
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        access_token: data.access_token
      };
    }
    case USER_DETAIL: {
      return {
        ...state,
        ...data
      };
    }
    case SIGNUP: {
      return {
        ...state
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state
      };
    }
    case USER_PROFILE: {
      return {
        ...state,
        ...data,
        user_id: data.sub
      };
    }

    case LOGOUT: {
      return {
        ...state,
        access_token: null
      };
    }

    case USER_AUTHORIZATION: {
      return {
        ...state,
        ...data
      };
    }

    case LIST_USERS: {
      return {
        ...state,
        users: data
      };
    }

    case "UPDATE_HEADER": {
      return {
        ...state,
        header: {
          ...state.header,
          ...data,
          showSearchBox: data? data.showSearchBox === undefined || data.showSearchBox : true, // default to true unless specified
          showDeactivatedMessage: data? data.showDeactivatedMessage === undefined ? false : data.showDeactivatedMessage : false // default to false unless specified
        }
      }
    }
    case "CLEAR_HEADER": {
      return {
        ...state,
        header: {
          title: "",
          action: "",
          enableBack: false,
          editMode: false,
          showSearchBox: data? data.showSearchBox === undefined || data.showSearchBox : true, // default to true unless specified
          showDeactivatedMessage: data? data.showDeactivatedMessage === undefined ? false : data.showDeactivatedMessage : false // default to false unless specified
        }
      }
    }

    case "UPDATE_ROUTE_OPTIONS": {
      if (data[0].policy) {
        return {
          ...state,
          routesOptions: mustBeArray(data)
        };
      }
    }

    case CLEARDOWN:
      return { ...state };
    case AUTH_USER:
      return { ...state, authenticated: true };
    case AUTH_IN_PROGRESS:
      return { ...state, loading: true };
    case UNAUTH_USER:
      return { ...state, authenticated: false };
    case FORGOT_SUCCESS:
      return { ...state, forgotMsg: action.message };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        timestamp: action.timestamp
      };

    default:
      return state;
  }
}
