import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

export const useResizeListener = () => { 
 const [windowSize, setWindowSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
 }); 

 const updateWindowSize = useCallback(() => {
    setWindowSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
    })
 },[]);

  const debouncedWindowSize = _.debounce(updateWindowSize, 200);

  useEffect(() => {
      window.addEventListener('resize', debouncedWindowSize);
    return () => window.removeEventListener('resize', debouncedWindowSize);
  }, []);

  return {
    ...windowSize
  };
};
