import { InputNumber } from 'antd';
import React from 'react';

const MaxLength = 99999999999999
const MaxValue = 99999999999999
const demicalRegex = /^([a-zA-Z(),{}\[\]!@#\\\|`~_$%^&*<>/?+\:\;\'\"=\s-])$/;
const noDecimalRegex = /^([a-zA-Z(),{}\[\]!.@#\\\|`~_$%^&*<>/?+\:\;\'\"=\s-])$/;
const digitsRegex = /^([0-9])/

const NumberInput = ({
    onBlur,
    onChange,
    className,
    value,
    hasDecimal=true,
    decimalPlaces=2,
    placeholder,
    maxLength,
    maxValue,
    disabled = false,
    parser,
    formatter
}) => {
    const handleBlur = (e) => {
        if(onBlur) return onBlur(e)
    }
    const handleChange = e => {
        if(onChange) return onChange( e)
    }
    const validateInputValue = e => {
        const regex = hasDecimal ? demicalRegex : noDecimalRegex;
        if(regex.test(e.key)) return e.preventDefault();
        if(hasDecimal && e.currentTarget.value?.includes('.')) return validateDecimalValues(e.currentTarget.value, e.key, e)
    }
    const validateDecimalValues = (value, key,e) => {
        const decimalValues = value?.split(".")?.[1];
        if(decimalValues && decimalValues.length >decimalPlaces && digitsRegex.test(key)) return e.preventDefault();
    }
    const defaultParserFormatter = parser ? parser :  e => e;

    return(
        <InputNumber
            disabled={disabled}
            className={`${className} hideNumberPointers`}
            value={value}
            onChange={handleChange}
            maxLength={maxLength || MaxLength}
            onBlur={e => handleBlur(defaultParserFormatter(e.target.value))}
            placeholder={placeholder? placeholder : ""}
            onKeyDown={e => validateInputValue(e)}
            formatter={formatter ? formatter : defaultParserFormatter}
            parser={defaultParserFormatter}
          />
    )
}

export default NumberInput