import { http } from "../libs/http";
import ResponseHandler from "../libs/response";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { requestBuilder } from "../libs/utilities";

export const createNewCompany = ({ cancelToken, payLoad }) => {
  return dispatch =>
    new Promise(async (resolve, reject) => {
      const url = APIHandler.constructEndpoint({
        endpoint: "COMPANIES_URL"
      });
      const response = await http.post(url, payLoad, cancelToken);

      if (ResponseHandler.isValidStatus(response)) {
        return resolve(
          dispatch(
            ResponseHandler.validObject(
              response.data && response.data.result
                ? response.data.result
                : response.data,
              "CREATE_COMPANY"
            )
          )
        );
      } else {
        return resolve(ResponseHandler.inValidObject(response));
      }
    }).catch(async err => {
      throw err.message;
    });
};