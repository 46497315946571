import React, { useContext } from "react";
import FinderUtilityUserDetailHeader from "./header";
import KeyMetrics from "./KeyMetrics";
import FinderUserBasicDetail from "./userBasicDetail";
import { LENDER_USER } from "../../../constants/systemUsers";
import FinderUtilityContext from "../FinderUtilityContext";
import RateView from "../../../Common/RateView";
const FinderUserView = ({ user }) => {
  const { userType } = useContext(FinderUtilityContext);
  const ratingsValue = userType === LENDER_USER ? user?.avgBrokerStarRating : user?.avgLenderStarRating;
  return (
    <div className="finder-utility-user-card m-b-12 m-r-50 overflow-x-auto">
      <FinderUtilityUserDetailHeader user={user} />
      <div className="flex space-between flex-wrap gap-14">
      <FinderUserBasicDetail user={user} />
      <KeyMetrics user={user} userType={userType} />
      <RateView value={ratingsValue} className="m-n-t-20" loansCount={user?.reviewedLoansCount}/>
      </div>
    </div>
  );
};

export default FinderUserView;
