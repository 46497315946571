import ErrorMessage from "../constants/errors";
import _ from "lodash";

/**
 * @typedef {object} ValidResponse
 * @property {string} actionType
 * @property {number} total
 * @property {any} data
 * @property {boolean} status
 */

/**
 * @typedef {object} InvalidResponse
 * @property {string} message
 * @property {any} data
 * @property {boolean} status
 */

class ResponseHandler {
  constructor() {
    if (!ResponseHandler.instance) {
      this._data = [];
      ResponseHandler.instance = this;
    }

    return ResponseHandler.instance;
  }

  errorMessageBuilder(errors) {
    return _.reduce(
      errors,
      (errorArray, err) => {
        const errorDesc =
          err.errorMessage && err.errorMessage.hasOwnProperty("message")
            ? err.errorMessage.message
            : "";
        return errorArray.concat([errorDesc]);
      },
      []
    );
  }

  isValidStatus({ status, isAxiosError }) {
    if ((status >= 400 && status <= 599) || isAxiosError) {
      return false;
    }

    return true;
  }

  /** @returns {ValidResponse} */
  validObject(response, actionType, model) {
    function parseResponse(toParse) {
      const hasNoModel = _.isUndefined(model);
      if (toParse && toParse.hasOwnProperty("rows")) {
        if (hasNoModel) {
          return toParse.rows;
        } else {
          return toParse.rows.map(row => new model(row));
        }
      }

      if (!hasNoModel) {
        return new model(toParse);
      }

      return toParse;
    }
    return {
      type: actionType,
      total: response?.total ?? 0,
      data: parseResponse(response),
      status: true,
      page: response?.page ?? null
    };
  }

  /** @returns {InvalidResponse} */
  inValidObject({ status, data }) {
    return {
      message: ErrorMessage[status.toString()],
      data: data,
      status: false
    };
  }
}

const instance = new ResponseHandler();
Object.freeze(instance);

export default instance;
