import React from "react";
import { Select } from "antd";
import { BROKER_USER, LENDER_USER } from "../../../constants/systemUsers";

const LenderBrokerFilter = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange} className="w-230 finder-utility-filter select-no-border">
      <Select.Option key={LENDER_USER} value={LENDER_USER} className="f-w-500 f-s-16">
        Searching for Lenders
      </Select.Option>
      <Select.Option value={BROKER_USER} key={BROKER_USER} className="f-w-500 f-s-16">
        Searching for Brokers
      </Select.Option>
    </Select>
  );
};

export default LenderBrokerFilter;
