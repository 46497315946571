import React, { Fragment, useEffect, useState } from "react";
import RateView from "../../../Common/RateView";
import KeyMetrics from "../../FinderUtility/UserDisplayCard/KeyMetrics";
import { Button, Input, Switch } from "antd";
import UtilityCompany from "./utility-company";
import { AuthorizedView } from "../../../Common/Authorization/AuthorizedView";
import { isSystemUserPolicy } from "../../../Common/Authorization/policies/isSystemUserPolicy";
import { useDispatch, useSelector } from "react-redux";
import { DecoratedFormControl } from "../../Common/decoratedFormControl";
import OrderOfAppearance from "../OrderOfAppearance";
import { updateHeader } from "../../../actions/member";
import { isLawFirmUserPolicy } from "../../../Common/Authorization/policies/isLawFirmUserPolicy";

const ProfileUtility = ({
  userType,
  user,
  canToggleProfileSwitch,
  profileStatusLoading,
  showIsProfileActive,
  handleProfileActiveChange,
  masterSwitchLoading,
  handleMasterSwitchChange,
  showMasterSwitch,
  handleSave,
  form,
  handleFormChange,
  saveLoading,
  isSystemUser
}) => {
  const [acnRequired, setACNRequired] = useState(false);
  const dispatchAction = useDispatch();

  if (!user) return null;
  const editMode = useSelector(state => state?.member?.header?.editMode);
  const userMember = useSelector(state => state.authorization?.user);
  const acnValidation = () => {
    if (
      form &&
      form.getFieldValue("companyName") &&
      form.getFieldValue("companyACN")
    )
      return 9;
    else return 0;
  };

  useEffect(() => {
    const company = form?.getFieldValue("companyName");
    setACNRequired(!!company);
  }, [form?.getFieldValue("companyName")]);

  useEffect(() => {
    form.validateFields(["companyName", "companyACN"]);
  }, [acnRequired]);

  const handleCancel = async () => {
    const isLawFirm = await isLawFirmUserPolicy(
      userMember.roles,
      userMember.permissions,
      userMember
    );
    dispatchAction(
      updateHeader({ header: { editMode: false, showSearchBox: !isLawFirm } })
    );
  };

  return (
    <div className="profile-utility">
      <UtilityCompany
        user={user}
        form={form}
        handleFormChange={handleFormChange}
        isSystemUser={isSystemUser}
      />
      <div className="profile-utility-metric">
        {editMode && (
          <div className="flex space-between align-center p-lr-24 m-b-12">
            <FormActionButtons
              handleSave={handleSave}
              handleCancel={handleCancel}
              saveLoading={saveLoading}
            />
            <div className="flex align-center gap-16">
              <DecoratedFormControl
                {...{
                  labelCol: { span: 6 },
                  wrapperCol: { span: 18 }
                }}
                label="ACN"
                name="companyACN"
                rules={[
                  {
                    required: acnRequired,
                    message: "Please enter ACN"
                  },
                  {
                    min: acnValidation(),
                    message: "Please enter 9 digit for company ACN."
                  },
                  {
                    max: acnValidation(),
                    message: "Please enter 9 digit for company ACN."
                  }
                ]}
              >
                <Input
                  placeholder="Enter"
                  autoComplete="arbitrary-text"
                  onChange={e =>
                    handleFormChange({
                      label: "companyACN",
                      value: e.target.value
                    })
                  }
                  disabled={!isSystemUser}
                />
              </DecoratedFormControl>
              <OrderOfAppearance
                portraits={user.portraits}
                companyName={user.companyName}
                handleFormSave={handleFormChange}
              />
            </div>
          </div>
        )}
        <KeyMetrics user={user} userType={userType} />
        <div className="profile-utility-switch">
          {/* Master Switch */}
          {showMasterSwitch && (
            <AuthorizedView policy={isSystemUserPolicy}>
              <Switch
                className=" master-switch"
                onChange={handleMasterSwitchChange}
                loading={masterSwitchLoading}
                checked={user.isMasterSwitchOn}
              />{" "}
              Master Switch {user.isMasterSwitchOn ? "On" : "Off"}
            </AuthorizedView>
          )}
          {showIsProfileActive && (
            <Fragment>
              <Switch
                className="m-l-32 master-switch"
                onChange={handleProfileActiveChange}
                disabled={!canToggleProfileSwitch}
                loading={profileStatusLoading}
                checked={user.isProfileActive}
              />{" "}
              Profile {user.isProfileActive ? "Active" : "Paused"}
            </Fragment>
          )}
        </div>
      </div>
      <RateView
        loansCount={user.reviewedLoansCount}
        value={user.avgStarRating}
      />
    </div>
  );
};

export default ProfileUtility;

const FormActionButtons = ({ handleSave, handleCancel, saveLoading }) => {
  return (
    <div>
      <Button
        className="m-r-16"
        type="primary"
        htmlType="submit"
        onClick={handleSave}
        loading={saveLoading}
      >
        Save
      </Button>
      <Button onClick={handleCancel}>Cancel</Button>
    </div>
  );
};
