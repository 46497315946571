export const LENDERS_CONSTRUCTION_SECURITY_TYPE =
  "LENDERS_CONSTRUCTION_SECURITY_TYPE";
export const LENDERS_DISTANCES_TYPE = "LENDERS_DISTANCES_TYPE";
export const LENDERS_FIRST_RANKING_SECURITY_TYPE =
  "LENDERS_FIRST_RANKING_SECURITY_TYPE";
export const LENDERS_SECOND_RANKING_SECURITY_TYPE =
  "LENDERS_SECOND_RANKING_SECURITY_TYPE";
export const LENDERS_MEZZANINE_SECURITY_TYPE =
  "LENDERS_MEZZANINE_SECURITY_TYPE";
export const LENDERS_STATES_SUBURBS_TYPE = "LENDERS_STATES_SUBURBS_TYPE";
export const LENDERS_STATES_REGIONS_TYPE = "LENDERS_STATES_REGIONS_TYPE";
export const LENDERS_LAND_BANK_ZONING_TYPE = "LENDERS_LAND_BANK_ZONING_TYPE";
export const LENDERS_LAND_BANK_AGRICULTURE_TYPE =
  "LENDERS_LAND_BANK_AGRICULTURE_TYPE";
