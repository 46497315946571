import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ILoanDetails, ILoanLogsListItemDto } from "../../libs/types";
import { useDispatch, useSelector } from "react-redux";
import { getLoanLogs } from "../../actions/loans";
import Axios from "axios";
import { PaginationConfig } from "antd/lib/pagination";
import { ColumnProps } from "antd/lib/table";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import { asDefaultDisplayFormatDate } from "../../libs";
import moment from "moment";
import { DEFAULT_DATE_WITH_TIME_FORMAT } from "../../constants/moment";

interface ViewLogsProps {
  className?: string;
  loanDetails: ILoanDetails;
}

function ViewLogs({ className = "", loanDetails }: ViewLogsProps) {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const { user } = useSelector((state: any) => state.authorization);

  const [isSystemUser, setIsSystemUser] = useState(false);
  const [list, setList] = useState<ILoanLogsListItemDto[]>([]);
  const [listTotal, setListTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    pageSize: 20
  });

  const checkIfUserIsSystemUser = async () => {
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsSystemUser(isSystemUser);
  };

  function getLogs() {
    setIsLoading(true);
    dispatch(
      getLoanLogs({
        loanId: loanDetails.id,
        isSystemUser,
        cancelToken: signal.token
      })
    )
      .then(x => {
        setList(x.rows);
        setListTotal(x.total);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function handleViewSnapshot(logId: number) {
    const url = `/loans/${loanDetails.id}/logs/${logId}`;
    window.open(url, "_blank");
  }

  const handleTableChange = (pagination: PaginationConfig) => {
    setPaginationConfig({
      pageSize: 20,
      page: pagination.current ? pagination.current : 1
    });
  };

  function formatDateTime(dateString: string) {
    const newDateString = moment.utc(dateString).local();
    return moment(newDateString).format(DEFAULT_DATE_WITH_TIME_FORMAT);
  }

  useEffect(() => {
    checkIfUserIsSystemUser();
  }, []);

  useEffect(() => {
    if (loanDetails.id) {
      getLogs();
    }

    return () => {
      signal.cancel();
    };
  }, [loanDetails]);

  const tablePagination = {
    current: paginationConfig.page,
    total: listTotal,
    pageSize: paginationConfig.pageSize
  };

  const columns: ColumnProps<ILoanLogsListItemDto>[] = [
    {
      title: "Date",
      dataIndex: "id",
      render: (data, record) => {
        return (
          <span
            className="hand"
            onClick={() => handleViewSnapshot(record.id)}
            style={{ color: "blue" }}
          >
            {formatDateTime(record.snapshotDate)}
          </span>
        );
      }
    }
  ];

  return (
    <>
      <span className={className}>
        <Button
          className=" w-81"
          onClick={openModal}
          style={{ float: "right" }}
        >
          Logs
        </Button>
      </span>
      <Modal
        visible={isModalOpen}
        width={500}
        onCancel={closeModal}
        footer={
          <div className="flex space-between">
            <span>
              <Button type="primary" onClick={closeModal} className="m-r-10">
                OK
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </span>
          </div>
        }
      >
        <Table
          columns={columns}
          dataSource={list}
          loading={isLoading}
          pagination={tablePagination}
          onChange={handleTableChange}
        />
      </Modal>
    </>
  );
}

export default ViewLogs;
