import React, { Fragment, useEffect } from "react";
import FilterView from "./filterView";
import { finderFilterTypes } from "../types";

const Sidebar = ({ filters }) => {
  return (
    <Fragment>
      {filters.map((data, i, array) => {
        if (data.type === finderFilterTypes.construction) {
          return (
            !!array.find(
              x =>
                x.type === finderFilterTypes.scenario &&
                x.value === "CONSTRUCTION"
            ) && <FilterView data={data} key={i} />
          );
        } else if (data.type === finderFilterTypes.subdivision) {
          return (
            !!array.find(
              x =>
                x.type === finderFilterTypes.scenario &&
                x.value === "SUBDIVISION"
            ) && <FilterView data={data} key={i} />
          );
        }

        return <FilterView data={data} key={i} />;
      })}
    </Fragment>
  );
};

export default Sidebar;
