import { Select } from "antd";
import React, { useEffect, useState } from "react";
import NumberInput from "../../../Common/NumberInput";
import {
  FinderFilterFieldChangeHandlerParams,
  ILookupItem
} from "../../../libs/types";
import { mustBeArray } from "../../../libs";
import { useDispatch } from "react-redux";
import Axios from "axios";
import authService from "../../../actions/AuthorizeService";
import { finderSubdivisionTypeLookups } from "../../../actions/finderUtility";

interface Subdivision {
  typeKey: string | null;
  presalesDebtCoverRequired: string;
  loanAmountPercentageGstEx: number | null;
  qsRequired: string;
  isBuilderDeveloper: string;
  isBuildToHold: string;
  tdcEquity: number | null;
  projectManagerRequired: string;
  contingenciesAllowance: number | null;
  developerSuccessfulProjects: number | null;
}

interface SubdivisionProps {
  value: Subdivision | undefined;
  handleFieldChange: (
    payload: FinderFilterFieldChangeHandlerParams<any>
  ) => void;
}

const YesNoOptions = [
  {
    label: "Yes",
    value: "true"
  },
  {
    label: "No",
    value: "false"
  }
];

function Subdivision({
  value,
  handleFieldChange = () => {}
}: SubdivisionProps) {
  const dispatch = useDispatch();
  const signal = Axios.CancelToken.source();

  const [typeOptions, setTypeOptions] = useState<ILookupItem[]>([]);
  const [subdivision, setSubdivision] = useState<Subdivision>({
    typeKey: null,
    presalesDebtCoverRequired: "",
    loanAmountPercentageGstEx: null,
    qsRequired: "",
    isBuilderDeveloper: "",
    isBuildToHold: "",
    tdcEquity: null,
    projectManagerRequired: "",
    contingenciesAllowance: null,
    developerSuccessfulProjects: 0
  });

  function handleLocalChange(field: string, value: string | number) {
    setSubdivision(s => ({
      ...s,
      [field]: value
    }));

    const payload = {
      typeKey: subdivision.typeKey || null,
      presalesDebtCoverRequired: subdivision.presalesDebtCoverRequired,
      loanAmountPercentageGstEx: subdivision.loanAmountPercentageGstEx,
      qsRequired: subdivision.qsRequired,
      isBuilderDeveloper: subdivision.isBuilderDeveloper,
      isBuildToHold: subdivision.isBuildToHold,
      tdcEquity: subdivision.tdcEquity,
      projectManagerRequired: subdivision.projectManagerRequired,
      contingenciesAllowance: subdivision.contingenciesAllowance,
      developerSuccessfulProjects: subdivision.developerSuccessfulProjects,
      [field]: value // Overwrites the field with same key
    };
    handleFieldChange({
      field: "subdivisionScenario",
      value: payload
    });
  }

  async function getTypeLookup() {
    const isLoggedIn = await authService.isAuthenticated();
    dispatch(
      finderSubdivisionTypeLookups({
        cancelToken: signal.token,
        generalView: !isLoggedIn
      })
    ).then(x => {
      setTypeOptions(mustBeArray(x));
    });
  }

  useEffect(() => {
    if (value) {
      setSubdivision({
        ...value,
        presalesDebtCoverRequired: value.presalesDebtCoverRequired
          ? value.presalesDebtCoverRequired.toString()
          : "",
        qsRequired: value.qsRequired ? value.qsRequired.toString() : "",
        isBuilderDeveloper: value.isBuilderDeveloper
          ? value.isBuilderDeveloper.toString()
          : "",
        isBuildToHold: value.isBuildToHold
          ? value.isBuildToHold.toString()
          : "",
        projectManagerRequired: value.projectManagerRequired
          ? value.projectManagerRequired.toString()
          : ""
      });
    }
  }, [value]);

  useEffect(() => {
    getTypeLookup();

    return () => {
      signal.cancel();
    };
  }, []);

  return (
    <>
      <div className="flex flex-align-center m-b-10">
        <span>Type</span>
        <Select
          className="w-200 m-l-6"
          value={subdivision.typeKey || undefined}
          onChange={(v: string) => handleLocalChange("typeKey", v || "")}
          allowClear
        >
          {typeOptions.map(data => {
            return (
              <Select.Option key={data.id} value={data.id}>
                {data.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Presales debt cover required?</span>
        <Select
          className="w-200 m-l-6"
          value={subdivision.presalesDebtCoverRequired}
          onChange={(v: string) =>
            handleLocalChange("presalesDebtCoverRequired", v || "")
          }
          allowClear
        >
          {YesNoOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      {subdivision.presalesDebtCoverRequired === "true" && (
        <div className="flex flex-align-center m-b-10">
          <span>Percentage of loan amount exclusive of GST</span>
          <NumberInput
            formatter={(value: any) => `${value}%`}
            parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
            className="m-l-6 hideNumberPointers"
            value={subdivision.loanAmountPercentageGstEx}
            onBlur={() => {}}
            onChange={(value: number) =>
              handleLocalChange("loanAmountPercentageGstEx", value)
            }
            placeholder={""}
            maxLength={undefined}
            maxValue={undefined}
          />
        </div>
      )}
      <div className="flex flex-align-center m-b-8">
        <span>QS required</span>
        <Select
          className="w-200 m-l-6"
          value={subdivision.qsRequired}
          onChange={(v: string) => handleLocalChange("qsRequired", v || "")}
          allowClear
        >
          {YesNoOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Builder/Developer</span>
        <Select
          className="w-200 m-l-6"
          value={subdivision.isBuilderDeveloper}
          onChange={(v: string) =>
            handleLocalChange("isBuilderDeveloper", v || "")
          }
          allowClear
        >
          {YesNoOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Build to hold</span>

        <Select
          className="w-200 m-l-6"
          value={subdivision.isBuildToHold}
          onChange={(v: string) => handleLocalChange("isBuildToHold", v || "")}
          allowClear
        >
          {YesNoOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Equity percentage of total development costs* (TDC) %</span>
        <NumberInput
          formatter={(value: any) => `${value}%`}
          parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
          className="m-l-6 hideNumberPointers"
          value={subdivision.tdcEquity}
          onBlur={() => {}}
          onChange={(value: number) => handleLocalChange("tdcEquity", value)}
          placeholder={""}
          maxLength={undefined}
          maxValue={undefined}
        />
      </div>
      <div>
        <p className="f-s-11">
          *Residual land value, post DA professional fees, council fees and
          contributions, SBBIS bond, contract sum including builder's margin,
          contingency allowance, project manager costs, provision for 50% of
          agents presales, capitalised interest and line fee, holding costs
          during construction (land tax and rates), project marketing costs
        </p>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Project manager required</span>

        <Select
          className="w-200 m-l-6"
          value={subdivision.projectManagerRequired}
          onChange={(v: string) =>
            handleLocalChange("projectManagerRequired", v || "")
          }
          allowClear
        >
          {YesNoOptions.map(x => (
            <Select.Option key={x.value} value={x.value}>
              {x.label}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Contingencies allowance percentage of construction sum</span>
        <NumberInput
          formatter={(value: any) => `${value}%`}
          parser={(value: string) => value.replace(/\%\s?|(,*)/g, "")}
          className="m-l-6 hideNumberPointers"
          value={subdivision.contingenciesAllowance}
          onBlur={() => {}}
          onChange={(value: number) =>
            handleLocalChange("contingenciesAllowance", value)
          }
          placeholder={""}
          maxLength={undefined}
          maxValue={undefined}
        />
      </div>
      <div className="flex flex-align-center m-b-10">
        <span>Developer past successful projects (number up to 100)</span>
        <NumberInput
          formatter={(value: any) => `${value}`}
          parser={(value: string) => value}
          className="m-l-6 hideNumberPointers"
          value={subdivision.developerSuccessfulProjects}
          onBlur={() => {}}
          onChange={(value: number) =>
            handleLocalChange(
              "developerSuccessfulProjects",
              value > 100 ? 100 : value
            )
          }
          placeholder={""}
          maxLength={undefined}
          maxValue={100}
        />
      </div>
    </>
  );
}

export default Subdivision;
