import React, { createElement } from "react";
import { Button, Col } from "antd";
import { connect } from "react-redux";
import config from "./typeConfig";
import authService from "../../actions/AuthorizeService";

class Exception extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false
    };
  }

  async componentDidMount(){
    await this.populateAuthenticationState();
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ authenticated });
  }

  render() {
    const {
      backText = "Back to home",
      linkElement = "a",
      title,
      img,
      actions,
      redirect = "/",
      match
    } = this.props;
    const { authenticated } = this.state;
    const type = match && match.params && match.params.type;
    const pageType = type && config[type] ? type : "404";
    return (
      <div
        className="bg-white p-24"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Col md={12} sm={24} className="m-b-12">
          <img
            src={img || config[pageType].img}
            alt="Exception"
            height="500px"
            width="100%"
          />
        </Col>
        <Col
          md={12}
          sm={24}
          className={`m-b-12 ${Number(type) === 500 ? "p-l-32" : ""}`}
        >
          <div className="f-s-48 f-w-600 m-b-16">
            {title || config[pageType].title}
          </div>
          <div className="f-s-24">{config[pageType].desc}</div>
          <div>
            {actions ||
              createElement(
                linkElement,
                {
                  to: authenticated ? redirect : config[pageType].redirect,
                  href: authenticated ? redirect : config[pageType].redirect
                },
                <Button type="primary m-t-16" size="large">
                  {config[pageType].backText || backText}
                </Button>
              )}
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exception);
