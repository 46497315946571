import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { updateHeader } from "../../actions/member";
import {
  Col,
  Row,
  Input,
  InputNumber,
  Rate,
} from "antd";
import axios from "axios";
import {
  getLoanSnapshotDetails
} from "../../actions/loans";
import AttributedLenders from "../../components/Loans/AttributedLenders";
import {
  asCustomFormatDate,
  asDefaultDisplayFormatDate,
} from "../../libs/utilities";
import _ from "lodash";
import LoanFormHeader from "../../components/Loans/Create/LoadFormHeader";
import AttributedBrokers from "../../components/Loans/AttributedBrokers";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";
import {
  isLawFirmUserPolicy,
} from "../../Common/Authorization/policies/isLawFirmUserPolicy";
import CreditLookup from "../../components/Loans/Create/CreditLookup";
import ConstructionModal from "../../components/Loans/Create/ConstructionModal";
import SubdivisionModal from "../../components/Loans/Create/SubdivisionModal";
import { ILoanSnapshotDetailsDto } from "../../libs/types";
import SnapshotAttributedLenders from "../../components/Loans/Logs/SnapshotAttributedLenders";
import SnapshotAttributedBrokers from "../../components/Loans/Logs/SnapshotAttributedBrokers";


interface LoansSnapshotViewProps {
  match: any,
  user: any,
  getLoanSnapshotDetails: typeof getLoanSnapshotDetails
}

const LoansSnapshotView = ({ match, user, getLoanSnapshotDetails }: LoansSnapshotViewProps) => {
  const dispatch = useDispatch();
  const [isUserLawfirm, setIsUserLawfirm] = useState(false);
  const [loanDetails, setLoanDetails] = useState<ILoanSnapshotDetailsDto>();

  const signal = axios.CancelToken.source();
  const loanId = match.params.id;
  const logId = match.params.logId;

  const checkIfUserIsLawfirm = async () => {
    const isLawfirm = await isLawFirmUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    setIsUserLawfirm(isLawfirm);
  };

  useEffect(() => {
    checkIfUserIsLawfirm();
    loadSnapshotDetails();
    const cleanUp = () => {
      signal.cancel("");
    };
    return cleanUp;
  }, [loanId]);

  useEffect(() => {
    dispatch(updateHeader({
      header: {
        showSearchBox: !isUserLawfirm
      }
    }));
  }, [isUserLawfirm]);

  const loadSnapshotDetails = async () => {
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    getLoanSnapshotDetails({
      loanId,
      logId,
      isSystemUser,
      cancelToken: signal.token
    }).then(x => {
      setLoanDetails(x.data);
    });
  };

  return !loanDetails ? <div className="width-full height-full flex flex-center">
      <span>
        Loading...
      </span>
    </div> :
    <div className="m-b-12 m-t-80">
      <LoanFormHeader snapshotMode={true} 
        snapshotOverview={{
          editedBy: `${loanDetails.lastEditBy}${loanDetails.lastEditByCompany ? ' of ' + loanDetails.lastEditByCompany : ''}`,
          editedDate: asCustomFormatDate(loanDetails.lastEditDate, 'DD MMMM YYYY')
        }}/>
      <div className={`m-t-25 flex flex-wrap`}>
        <div className="m-r-16 flex flex-no-wrap  m-b-10">
          <b>Loan No.</b>
          <Input
            className="m-l-6 hideNumberPointers"
            style={{ width: "115px", marginTop: "-6px" }}
            value={loanDetails.loanNo}
            disabled
            maxLength={6}
          />
        </div>

        <div className="flex flex-wrap m-b-10 m-r-20">
          <span className="m-n-t-15">
            <b>
              Attributed<br></br>Lender
            </b>
          </span>
          <SnapshotAttributedLenders attributedLenderList={loanDetails.associatedLenders} />
        </div>
        <div className="flex flex-wrap m-b-10 m-r-20">
          <span className="m-n-t-15">
            <b>
              Attributed<br></br>Broker
            </b>
          </span>
          <SnapshotAttributedBrokers attributedBrokerList={loanDetails.associatedBrokers} />
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8 m-n-t-15">
            <b>
              Last<br></br>Edit
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled
            value={loanDetails.lastEditBy}
          ></Input>
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8  m-n-t-15">
            <b>
              Broker<br></br>BDM
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled={true}
            value={loanDetails.brokerBdm}
          ></Input>
        </div>
        <div className="flex flex-wrap m-b-10 m-r-10">
          <span className="m-r-8 m-n-t-15">
            <b>
              Lender<br></br>BDM
            </b>
          </span>
          <Input
            style={{
              width: "150px",
              marginTop: "-6px"
            }}
            disabled={true}
            value={loanDetails.lenderBdm}
          ></Input>
        </div>
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Suburb and State</b>
          </span>
          <Input
            style={{ width: "230px" }}
            disabled={true}
            value={loanDetails.suburbState}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Priority</b>
          </span>
          <Input
            style={{ width: "100px" }}
            disabled={true}
            value={loanDetails.priority}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Principal</b>
          </span>
          <InputNumber
            style={{ width: "150px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value?.replace(/\$\s?|(,*)/g, "") || "$"}
            className="m-l-6  hideNumberPointers"
            disabled={true}
            value={loanDetails?.principal || 0}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Lower Rate</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value?.replace(/\%\s?|(,*)/g, "") || "%"}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={parseInt(loanDetails?.lowerRate || "") || 0}
          />
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Higher Rate</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value?.replace(/\%\s?|(,*)/g, "") || "%"}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={parseInt(loanDetails?.higherRate || "") || 0}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Establishment Fee (incl GST)</b>
          </span>
          <InputNumber
            style={{ width: "145px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value?.replace(/\$\s?|(,*)/g, "") || "$"}
            className=" m-l-6 hideNumberPointers"
            disabled={true}
            value={loanDetails?.establishmentFee || 0}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Establishment Fee</b>
          </span>
          <InputNumber
            style={{ width: "80px" }}
            formatter={value => `${value}%`}
            parser={value => value?.replace(/\%\s?|(,*)/g, "") || "%"}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={parseFloat(loanDetails?.establishmentFeePercentage || "") || 0}
          />
        </span>
      </div>
      <div className={`flex flex-wrap flex-align-center ${["CONSTRUCTION", "SUBDIVISION"].includes(loanDetails.scenarioKey) ? "m-n-t-42" : ""}`}>
        <div className={`m-t-12 m-r-24 flex flex-align-center flex-direction-col ${["CONSTRUCTION", "SUBDIVISION"].includes(loanDetails.scenarioKey) ? "p-t-42" : ""}`}>
          <div className="m-b-10">
            <span className="m-r-12">
              <b>Scenario</b>
            </span>
            <Input
              style={{ width: "150px" }}
              disabled={true}
              value={loanDetails.scenario}
            />
          </div>
          {loanDetails.scenarioKey === "CONSTRUCTION" && (
            <ConstructionModal
              loanDetails={loanDetails}
              generalView
              className="w-150 align-self-right"
            />
          )}
          {loanDetails.scenarioKey === "SUBDIVISION" && (
            <SubdivisionModal
              loanDetails={loanDetails}
              generalView
              className="w-150 align-self-right"
            />
          )}
        </div>
        
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Security Type</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails?.securityType}
            className="m-r-12"
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Security Sub-Type</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails?.securitySubType}
            className="m-r-12"
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>LVR</b>
          </span>
          <InputNumber
            style={{ width: "60px" }}
            formatter={value => `${value}%`}
            parser={value => value?.replace(/\%\s?|(,*)/g, "") || ""}
            className="m-l-6 hideNumberPointers"
            disabled={true}
            value={parseInt(loanDetails?.lvr || "") || 0}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Valuation Required</b>
          </span>
          <Input
            style={{ width: "100px" }}
            disabled={true}
            value={loanDetails?.valuationRequired === true ? "Yes" : loanDetails?.valuationRequired === false ? "No" : ""}
            className="m-r-12"
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Interest (Payable)</b>
          </span>
          <Input
            style={{ width: "150px" }}
            disabled={true}
            value={loanDetails?.interest || ""}
            className="m-r-12"
          ></Input>
        </span>
        <CreditLookup loanDetails={loanDetails} generalView className="m-t-12 m-r-12" />
      </div>
      <div className="flex flex-wrap flex-align-center">
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Lawfirm</b>
          </span>
          <Input
            style={{ width: "200px" }}
            disabled={true}
            value={loanDetails.lawFirm}
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Partner</b>
          </span>
          <Input
            style={{ width: "300px" }}
            disabled={true}
            value={loanDetails?.partner}
          ></Input>
        </span>

        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Associate</b>
          </span>
          <Input
            style={{ width: "300px" }}
            disabled={true}
            value={loanDetails?.associate}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Status</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={loanDetails?.status}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Shortfall</b>
          </span>
          <InputNumber
            style={{ width: "100px" }}
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value?.replace(/\$\s?|(,*)/g, "") || "$"}
            disabled={true}
            value={parseInt(loanDetails?.shortfall || "") || 0}
          ></InputNumber>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Settlement Date</b>
          </span>
          <Input
            style={{ width: "150px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails?.settlementDate)}
          />
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Repayment Date</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails?.repaymentDate)}
          ></Input>
        </span>
        <span className="m-t-12 m-r-12">
          <span className="m-r-12">
            <b>Discharged</b>
          </span>
          <Input
            style={{ width: "250px" }}
            disabled={true}
            value={asDefaultDisplayFormatDate(loanDetails?.dischargedDate)}
          ></Input>
        </span>
      </div>
      <Row className="m-t-12">
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker stars from Lender</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails?.brokerStarsFromLender || 0}
              disabled
            ></Rate>
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker review by Lender</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.brokerReviewByLender}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Broker's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            className="loan-detail-text-area "
            disabled
            value={loanDetails?.brokerResponseToLender}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender stars from Broker</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear
              value={loanDetails?.lenderStarsFromBroker || 0}
              disabled
            ></Rate>
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender review by Broker</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.lenderReviewByBroker}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lender's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.lenderResponseToBroker}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer stars from Lender</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails?.lawyerStarsFromLender || 0}
              disabled
            ></Rate>
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer review by Lender</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            value={loanDetails?.lawyerReviewByLender}
            className="loan-detail-text-area "
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.lawyerResponseToLender}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer stars from Broker</b>
          </Col>
          <Col span={16}>
            <Rate
              allowClear={false}
              value={loanDetails?.lawyerStarsFromBroker || 0}
              disabled
            ></Rate>
          </Col>
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer review by Broker</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area"
            value={loanDetails?.lawyerReviewByBroker}
          />
        </Col>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Lawyer's Response</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.lawyerResponseToBroker}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "6px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "top" }}>
          <Col span={5}>
            <b>Notes</b>
          </Col>
          <Input.TextArea
            style={{ width: "666px" }}
            rows={4}
            disabled
            className="loan-detail-text-area "
            value={loanDetails?.notes}
          />
        </Col>
      </Row>
    </div>
};

const mapStateToProps = (state: any) => {
  return {
    member: state.member || {},
    profile: (state.profile && state.profile.details) || {},
    user: state.authorization && state.authorization.user,
    lender: state.profile.lender
  };
};

const mapDispatchToProps = {
  getLoanSnapshotDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoansSnapshotView);
