import { Card, Radio } from 'antd'
import React from 'react'
import { RadioOption } from '../../libs/types'

interface RadioInCardProps  {
  options: RadioOption[],
  onChange?: (value: string) => void,
  value?: string,
  name?: string,
  label?: string,
  hasError?: boolean
}

function RadioInCard({options, value, onChange, name, label, hasError}: RadioInCardProps, ref: any) {
  return (
    <Card className="loan-filter" style={{ maxWidth: "fit-content", outline: hasError ? 'solid 1px red': "" }}>
      {label && <span className="m-r-8">{label}</span>}
      <Radio.Group
        name={name}
        options={options}
        value={value}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </Card>
  );
}

export default React.forwardRef(RadioInCard)