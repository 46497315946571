import { Card, Form, Radio } from "antd";
import React from "react";

const LenderSubscriptions = ({
  getFieldDecorator,
  verifiedLenderCategories,
  canEdit
}) => {
  return (
    <Card className="user-subscription m-b-24 card-padding-12 position-relative">
        <div className="title-center card-title">What you will receive as a Lender</div>
      <Form.Item label="1st Ranking">
        {getFieldDecorator("lenderFirstRanking")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "FirstRanking") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="2nd Ranking">
        {getFieldDecorator("lenderSecondRanking")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "SecondRanking") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Construction">
        {getFieldDecorator("lenderConstruction")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "Construction") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Mezzanine">
        {getFieldDecorator("lenderMezzanine")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "Mezzanine") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Land Bank">
        {getFieldDecorator("lenderLandBank")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "LandBank") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Form.Item label="Rural">
        {getFieldDecorator("lenderRural")(
          <Radio.Group disabled={!verifiedLenderCategories?.some(x => x === "Rural") || !canEdit}>
            <Radio value={true}>Subscribed</Radio>
            <Radio value={false}>Unsubscribed</Radio>
          </Radio.Group>
        )}
      </Form.Item>
    </Card>
  );
};
export default LenderSubscriptions;
