import { Select } from "antd";
import React, { useContext } from "react"; 
import FinderUtilityContext from "../FinderUtilityContext";

const TermLookup = ({ value, handleFieldChange }) => { 
  const {termList} = useContext(FinderUtilityContext); 

  const {high, low} = value
  return (
    <div className="flex flex-align-center flex-wrap gap-12">
    <div>
      <div className="bold">From:</div>
      <Select
        className="w-200"
        value={low}
        onChange={e => handleFieldChange({ field: "term", value: e, sub: "low" })}
        allowClear={false}
        filterOption={false}
      >
        {termList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
    </div>
    <div>
      <div className="bold">To:</div>
      <Select
        className="w-200"
        value={high}
        onChange={e => handleFieldChange({ field: "term", value: e, sub: 'high' })}
        allowClear={false}
        filterOption={false}
      >
        {termList.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select> 
    </div>
  </div>
      
  );
};

export default TermLookup;
