import React from 'react';
import moment from "moment";
import _ from "lodash";
import { DEFAULT_DATE_FORMAT } from "../constants/moment";
import {currencyFormater, mustBeArray, templateEngine} from './utilities';
import { Switch, Popconfirm, Row, Col, Icon, Checkbox, Button } from 'antd';
import history from '../routes/history';

/** 
 * @typedef {object} ColumnDef
 * @property {string} title
 * @property {string} key
 * @property {string} width
 * @property {string} align
 * @property {(record: any) => any} render
 * @property {string} dataIndex
 * @property {boolean} sorter
 * @property {"ascend" | "descend" | boolean} sortOrder
 * @property {string} dataType
 * @property {string} dateFormat
 * @param {ColumnDef[]} cols
 */
export const tableColumnGenerator = (cols) => {
  return _.map(cols, (col, index) => {
    let columnConfig = {
      title: col.title,
      dataIndex: col.dataIndex,
      align: col.align,
      sorter: col.sorter,
      sortOrder: col.sortOrder,
      render: (record) => {
        if (record && record.isArray) return record.join(',');
        else return record;
      }
    };

    if(col.width){
      columnConfig['width'] = col.width;
    }
    if(col.render){
      columnConfig['render'] = col.render;
    }

    const navigateUser = (url,e) => {
      if(e){
        e.preventDefault();
      }
      return history.push(url);
    }

    if (!_.isUndefined(col.link)) {
      columnConfig["render"] = (Name, obj) => {
        const applicationUrl = window.location && window.location.origin;
        const linkUrl = templateEngine(col.link.url, obj);
        return <a onClick={(e) => navigateUser(linkUrl,e)} href={`${applicationUrl}${linkUrl}`} 
        target="__blank">{Name}</a>
      };
    }

    if(col.dataType === "date"){
      columnConfig['render'] = value => {
      if(typeof value == "string") {
        value = moment.utc(value).local();
      }
      let dateFormat = col.dateFormat ? col.dateFormat : DEFAULT_DATE_FORMAT;
      return <span>{value ? (value).format(dateFormat) : ""}</span>
      }
    }

    if(col.dataType === "html"){
      columnConfig['render'] = (value) => {
      return value ?
              value.includes("irreport") || value.includes("furreport") ?
              <div>
                <Button
                onClick={() => col.showEmailPreview(value)}
                >
                  Show Report
                </Button>
              </div>
              :
                <div
                dangerouslySetInnerHTML={{ __html: value }}
                />
                :
                <React.Fragment/>
      } 
    }

    if(col.dataType === "fileList"){
      columnConfig['render'] = record => {
      return mustBeArray(record.files).map((file, i) => {
          return(
          <span key={i}><a href={file.fileUrl} target="__blank">{file.filename}</a>
                 {`${(i === mustBeArray(record.files).length - 1) ? "": ","} `}</span>
          )
        })
       }
      }

      if(["stageOneAction", "stageTwoAction"].includes(col.dataType)){
        columnConfig['render'] = (data,record) => {
        let boxChecked = false;
        let lable;
        switch(col.dataType){
          case "stageOneAction":
            lable="isStageOne";
            if(record && record.isStageOne){
              boxChecked = true;
            }
            break;
          case "stageTwoAction":
            lable="isStageTwo";
            if(record && record.isStageTwo){
              boxChecked = true;
            }
            break;
          default:
            break;
        }
        return <Row justify="space-between">
                <Col span={12}>
                    <Icon type={data ? "check" : "cross"}  />
                </Col>
                <Col span={12}>
                    <Checkbox checked = {boxChecked} onChange={() => col.toggleStageSelection({record, lable})} disabled={!data}/>
                </Col>
              </Row>
        }
      }

    if(col.dataType === "datetime"){
      columnConfig['render'] = value => {

      if(typeof value === "string") {
        value = moment.utc(value).local();
      }
      return <span>{value ? (value).format("DD/MM/YYYY hh:mm:ss A") : ""}</span>
      }
    }

    if (col.dataType === "amount") {  
      columnConfig["render"] = amount => {
        return <span>{currencyFormater(amount)}</span>;
      };
    }

    if (col.dataType === "boolean") {
      columnConfig["render"] = data => {
        return <span>{data ? "Yes" : "No"}</span>;
      };
    }

    
  if (col.dataType === "toggleStatus") {
    columnConfig["render"] = (enabled, record) => {
      return (
        <Popconfirm
          placement="topLeft"
          okText="Yes"
          cancelText="No"
          key="decline"
          title={`Are you sure you want to ${
            enabled ? "disable" : "enable"
          } this ${col.name ? col.name : "user"}?`}
          onConfirm={() => col.action(record)}
        >
          <div className={col.classname}>
            <Switch checked={enabled} disabled={col.disabled} />
          </div>
        </Popconfirm>
      );
    };
  }

    return columnConfig;
  });
};
