import { Select } from "antd";
import React, { useMemo } from "react";
import { AddAndDeleteButton } from "../../../Common/Index";
import NumberInput from "../../../Common/NumberInput";
import RequiredSign from "../../../Common/RequiredSign";

const AddedBrokersDisplayAndUpdate = ({
  index,
  data,
  list,
  loanBrokers,
  handleChange,
  handleBrokerChange,
  handleAdd,
  handleDelete,
  disabled = false
}) => {

  const filteredList = useMemo(() => {
    return list.reduce( (a,b) => { 
      const isSelected = loanBrokers.some(o => o.brokerId === b.id);
      return a.concat(b.id === data.brokerId || !isSelected ? [b] : [])
    },[]);
  },[loanBrokers])

  return (
    <div className="m-b-10 flex">
      <Select
        value={data.brokerId}
        onChange={e =>
          handleBrokerChange({
            value: e,
            index
          })
        }
        className="w-280 m-l-8 m-r-20"
        allowClear={false}
        placeholder="Broker"
        showSearch
        filterOption
        optionFilterProp={"name"}
        disabled={disabled}
      >
        {filteredList.map((broker, index) => {
          return (
            <Select.Option key={broker.id} name={broker.name} value={broker.id}>
              {broker.name}
            </Select.Option>
          );
        })}
      </Select>
      {index === 0 && <RequiredSign />}
      <AddAndDeleteButton
        index={index}
        className="m-l-20"
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        listSize={loanBrokers.length}
        disabled={disabled}
      />
    </div>
  );
};
export default AddedBrokersDisplayAndUpdate;
