import { Alert } from "antd";
import React from "react";
import { mustBeArray } from "../libs";

const ErrorAlertDisplay = ({ errorsList, className }) => {
  return (
    <div className={className || ""}>
      <Alert
        message={
          <div>
            <div>
              The following errors must be resolved before this loan can be
              saved:
            </div>
            <ul>
              {mustBeArray(errorsList).map((errors, i) => {
                return <li key={i}>{errors}</li>;
              })}
            </ul>
          </div>
        }
        type="warning"
      />
    </div>
  );
};
export default ErrorAlertDisplay;
